import React from 'react';
import PropTypes from 'prop-types';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default function FiltersLine(props) {
  const { filterOptions } = props;
  return filterOptions.length ? (
    <div className="m-filter-options-container">
      {filterOptions.map((option) => (
        <span key={option} className="m-filter-option">
          {option}
        </span>
      ))}
    </div>
  ) : null;
}

FiltersLine.propTypes = {
  filterOptions: PropTypes.arrayOf(PropTypes.string),
};

FiltersLine.defaultProps = {
  filterOptions: [],
};
