import { initCacheInvalidatorRegister } from '../cacheRegisterConstructor';

export enum TasksCacheEvents {
  DELETE = 'DELETE',
  UPSERT = 'UPSERT',
}

interface CacheParams {
  projectId?: string;
}

const cacheRegister = initCacheInvalidatorRegister<
  typeof TasksCacheEvents,
  CacheParams
>(TasksCacheEvents);

export const invalidateTasksCache = cacheRegister.invalidate;
export const tasksCacheRegister = cacheRegister.register;
