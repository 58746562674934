import { VortexSetupVars } from 'mapistry-shared';
import { QueryConfig, useQuery } from 'react-query';
import { Api } from '../../apiClient';

function createPageSetupVarsKey() {
  return ['vortexPageSetupVars'] as const;
}

type SetupVarsFetcher = Api.DataHookQueryFn<
  typeof createPageSetupVarsKey,
  typeof Api.fetchVortexSetupVars
>;

const fetcher: SetupVarsFetcher = () => Api.fetchVortexSetupVars();

type UseVortexSetupVarsProps = { config?: QueryConfig<VortexSetupVars> };

/**
 * Most likely you should use `useVortexPageSetupVar` instead of this hook! It guarantees the setup vars are fetched.
 */
export function useVortexSetupVars(
  { config: inputConfig }: UseVortexSetupVarsProps = { config: {} },
) {
  const key = createPageSetupVarsKey();
  const config = {
    ...inputConfig,
    cacheTime: Infinity,
    staleTime: Infinity,
  };

  const { data, ...queryInfo } = useQuery(key, fetcher, config);

  return {
    ...queryInfo,
    setupVars: data,
  };
}
