import {
  CreateWorkflowRequest,
  UpdateWorkflowRequest,
  WorkflowRefResponse,
  WorkflowResponse,
} from 'mapistry-shared';
import http from '../httpClient';

export type FetchWorkflowListParams = {
  organizationId: string;
};

export type FetchWorkflowListResponse = WorkflowRefResponse[];

export async function fetchWorkflowList({
  organizationId,
}: FetchWorkflowListParams) {
  const res = await http.get<FetchWorkflowListResponse>(
    `/api/v2/organizations/${organizationId}/workflows`,
  );
  return res.data;
}

export type FetchWorkflowParams = {
  organizationId: string;
  workflowId: string;
};

export type FetchWorkflowResponse = WorkflowResponse;

export async function fetchWorkflow({
  organizationId,
  workflowId,
}: FetchWorkflowParams) {
  const res = await http.get<FetchWorkflowResponse>(
    `/api/v2/organizations/${organizationId}/workflows/${workflowId}`,
  );
  return res.data;
}

export type CreateWorkflowParams = {
  organizationId: string;
  workflow: CreateWorkflowRequest;
};

export type CreateWorkflowResponse = WorkflowResponse;

export async function createWorkflow({
  organizationId,
  workflow,
}: CreateWorkflowParams) {
  const res = await http.post<CreateWorkflowResponse>(
    `/api/v2/organizations/${organizationId}/workflows`,
    workflow,
  );
  return res.data;
}

export type UpdateWorkflowParams = {
  organizationId: string;
  updatedValues: UpdateWorkflowRequest;
  workflowId: string;
};

export type UpdateWorkflowResponse = WorkflowResponse;

export async function updateWorkflow({
  organizationId,
  updatedValues,
  workflowId,
}: UpdateWorkflowParams) {
  const res = await http.put<UpdateWorkflowResponse>(
    `/api/v2/organizations/${organizationId}/workflows/${workflowId}`,
    updatedValues,
  );
  return res.data;
}
