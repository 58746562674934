import React from 'react';
import ReactSelect from 'react-select';
import PropTypes from 'prop-types';
import { Divider } from '@material-ui/core';

/**
 * @deprecated Use packages/shared/components/Select
 */
const Select = (props) => {
  const { className, error, isFixed, isMulti, noOptionsMessage, ...other } =
    props;

  const propClass = className ? ` ${className}` : '';
  const errorClass = error ? ' error' : '';
  const multiClass = isMulti ? ' mapistry-select--multi' : '';

  const customStyles = {
    menuList: (base) => ({
      ...base,
      maxHeight: 375,
    }),
  };

  const formatGroupLabel = () => (
    <div className="mapistry-select--divider">
      <Divider />
    </div>
  );

  return (
    <ReactSelect
      {...other}
      className={`mapistry-select${propClass}${errorClass}${multiClass}`}
      classNamePrefix="mapistry-select"
      formatGroupLabel={formatGroupLabel}
      isMulti={isMulti}
      menuPosition={isFixed ? 'fixed' : 'absolute'}
      noOptionsMessage={() => noOptionsMessage || 'No Options'}
      styles={customStyles}
    />
  );
};

const OptionType = PropTypes.shape({
  label: PropTypes.string,
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any,
});

Select.propTypes = {
  className: PropTypes.string,
  error: PropTypes.bool,
  isClearable: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isFixed: PropTypes.bool,
  isMulti: PropTypes.bool,
  menuPlacement: PropTypes.string,
  noOptionsMessage: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(OptionType),
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([OptionType, PropTypes.arrayOf(OptionType)]),
};

Select.defaultProps = {
  className: null,
  error: false,
  isClearable: true,
  isDisabled: false,
  isFixed: false,
  isMulti: false,
  menuPlacement: 'bottom',
  noOptionsMessage: null,
  onChange: null,
  options: null,
  placeholder: null,
  value: null,
};

/**
 * @deprecated Use packages/shared/components/Select
 */
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default Select;
