import { Tabs as MuiTabs, Tab as MuiTab } from '@material-ui/core';
import React, { ChangeEvent, useState } from 'react';
import { IconButton } from '../buttons/IconButton';
import { EditableText } from '../EditableText';
import { Loading } from '../Loading';
import { SvgIcon } from '../icons/SvgIcon';

export interface Tab {
  id?: string;
  label: string;
}

interface TabsProps {
  isLoading?: boolean;
  onCreateTab?: () => void;
  onDeleteTab?: (tab: Tab) => void;
  onRenameTab?: (tab: Tab, newLabel: string) => void;
  onSelectTab: (event: ChangeEvent<unknown>, value: Tab) => void;
  selected: Tab;
  tabs: Tab[];
}

export const Tabs = ({
  isLoading,
  onCreateTab,
  onDeleteTab,
  onRenameTab,
  onSelectTab,
  selected,
  tabs,
}: TabsProps) => {
  const [isEditing, setIsEditing] = useState(false);

  const getTabLabel = (tab: Tab) => {
    const isSelected = selected === tab;
    const textLabel =
      isSelected && onRenameTab ? (
        <EditableText
          onEndEdit={(newLabel) => {
            onRenameTab(tab, newLabel);
            setIsEditing(false);
          }}
          onStartEdit={() => setIsEditing(true)}
          text={tab.label}
        />
      ) : (
        tab.label
      );
    const deleteButton =
      isSelected && onDeleteTab && !isEditing ? (
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            onDeleteTab(tab);
          }}
          title={`delete-tab-${tab.id || tab.label}`}
        >
          <SvgIcon identifier="close" />
        </IconButton>
      ) : null;
    return (
      <>
        {textLabel}
        {deleteButton}
      </>
    );
  };

  return isLoading ? (
    <Loading size="small" />
  ) : (
    <MuiTabs value={selected} onChange={onSelectTab}>
      {tabs.map((tab) => (
        <MuiTab
          className="tabs-item"
          component="a"
          key={tab.id || tab.label}
          label={getTabLabel(tab)}
          title={`tab-${tab.id || tab.label}`}
          value={tab}
          disableRipple
        />
      ))}
      {onCreateTab ? (
        <MuiTab
          component="a"
          icon={
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                onCreateTab();
              }}
              title="add-tab"
            >
              <SvgIcon identifier="add" />
            </IconButton>
          }
        />
      ) : null}
    </MuiTabs>
  );
};
