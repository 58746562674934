/* eslint-disable @typescript-eslint/no-var-requires */
import { setupSentry } from '@monorepo/shared/hooks/pageSetup/useSetupSentry';
import { Api } from 'mapistry-shared/api';

const setup = {
  getAppConfig() {
    const APP = {
      apiUrl: process.env.API_URL,
      isNavigationBlocked: false,
    };

    const isBackbone = document.querySelector('li[varname="backbone"]');

    if (isBackbone) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line no-use-before-define
      return getBackboneConfig(APP);
    }
    return APP;
  },
};

function getBackboneConfig(sharedApp) {
  const currentUser = require('./currentUser'); // eslint-disable-line global-require
  const APP = { ...sharedApp };
  const configList = document.getElementById('APP');
  const configItems = configList.getElementsByTagName('li') || [];
  Array.from(configItems).forEach((listItem) => {
    APP[listItem.getAttribute('varname')] = listItem.textContent;
  });
  configList.remove();
  APP.DEFAULTS = {
    TITLEBAR_LOGO: `${APP.assetsRoot}your_logo_here.jpg`,
    LOADER_URL: `${APP.assetsRoot}ajax-login-loader.gif`,
  };

  APP.isMobile = APP.isMobile === 'true';
  Api.setApiUrl(APP.apiUrl);

  APP.log = (options) => {
    if (options.status === 'error') {
      // eslint-disable-next-line no-console
      console.log(options);
    }
    return null;
  };

  // Event aggregator that can be used by any of the views
  // eslint-disable-next-line no-undef
  APP.vent = _.extend({}, Backbone.Events);

  APP.setApiVars = (apiVars) => {
    currentUser.set(apiVars.user);
    APP.featureFlags = apiVars.featureFlags;
    APP.bingApiKey = apiVars.bingApiKey;
    APP.csrfToken = apiVars.csrfToken;
    APP.mapboxToken = apiVars.mapboxToken;
    APP.csvUploadsBucketName = apiVars.csvUploadsBucketName;
    APP.flatfilePublishableKey = apiVars.flatfilePublishableKey;
    APP.flatfileEnvironmentId = apiVars.flatfileEnvironmentId;
    APP.parseGeoFileUrl = apiVars.parseGeoFileUrl;
    APP.recoveryQuestionSet = apiVars.recoveryQuestionSet;
    APP.titlebarLogoBucketName = apiVars.titlebarLogoBucketName;

    if (apiVars.sentryDSN) {
      setupSentry(
        apiVars.sentryDSN,
        apiVars.user?.email,
        APP.environment,
        APP.apiUrl,
      );
    }
  };

  return APP;
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default setup;
