import React, { Component } from 'react';
import PropTypes from 'prop-types';

import TextField from './TextField';

/**
 * @deprecated Use packages/shared/components/EditableText
 */
class EditableText extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditingState: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { isEditing } = this.props;
    if (isEditing && !prevProps.isEditing) {
      this.setState({ isEditingState: true });
    }
  }

  handleStartEdit(e) {
    const { onEditStart } = this.props;
    e.stopPropagation();
    this.setState({ isEditingState: true }, onEditStart);
  }

  handleStopEdit(value) {
    const { onEdit } = this.props;
    this.setState({ isEditingState: false }, () => onEdit(value));
  }

  render() {
    const { text } = this.props;
    const { isEditingState } = this.state;
    return isEditingState ? (
      <TextField
        autoFocus
        controlled={false}
        defaultValue={text}
        onBlur={(e) => this.handleStopEdit(e.target.value)}
      />
    ) : (
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
      <span
        className="hover-edit-identifier"
        onClick={(e) => this.handleStartEdit(e)}
      >
        {text}
      </span>
    );
  }
}

EditableText.propTypes = {
  isEditing: PropTypes.bool,
  onEdit: PropTypes.func.isRequired,
  onEditStart: PropTypes.func,
  text: PropTypes.string,
};

EditableText.defaultProps = {
  isEditing: false,
  onEditStart: null,
  text: null,
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default EditableText;
