import { LoggedItem } from '@monorepo/shared/apiClient/types';
import { parseDateString } from '@monorepo/shared/components/InteractiveFileUpload/helpers/validationAndParsing/dateParsingHelpers';
import { format } from 'date-fns';
import {
  GenericLogLoggedItemType,
  UTCEquivalentOfLocal,
} from 'mapistry-shared';
import {
  LogBulkUploadForApi,
  LogBulkUploadFromFlatfile,
  UserOption,
} from './EditLogModalTypes';

// expects date and time Dates to be in local timezone
// for the time one, the day of the month does not matter
export function formatDatetime(
  date: null | undefined,
  time: null | undefined,
): null;
export function formatDatetime(date: Date, time: Date): string;
export function formatDatetime(
  date: Date | null | undefined,
  time: Date | null | undefined,
): string | null {
  if (!date || !time) {
    return null;
  }
  const dateString = UTCEquivalentOfLocal(date).toISOString().substring(0, 10);
  const timeString = UTCEquivalentOfLocal(time).toISOString().substring(11, 16);
  return `${dateString}T${timeString}:00.000Z`;
}

// expects date like 2021-04-12 and time like '02:23 PM'
export function dateAndTimeStringsToDatetime(
  date: string,
  time: string,
): string {
  const parsedDate = parseDateString(date);
  const todayDate = format(new Date(), 'yyyy-MM-dd'); // could be any date, will be discarded regardless
  const timeDate = new Date(`${todayDate} ${time}`);
  const isoDate = formatDatetime(parsedDate, timeDate);
  return isoDate;
}

export function replaceDateAndTimeFieldsWithOneDatetimeField(
  flatfileResults: LogBulkUploadFromFlatfile[],
): LogBulkUploadForApi[] {
  const resultsWithDatetime = flatfileResults.map((log) => {
    const { logDate, logTime, ...rest } = log;
    const updatedResult = {
      ...rest,
      logDatetime: dateAndTimeStringsToDatetime(logDate, logTime),
    };

    return updatedResult;
  });

  return resultsWithDatetime;
}

// the user will input a name string, like 'Albus Dumbledore', but we need to match that to the userId.
// cannot make this a dropdown because it depends on the type of the loggedValue.
export function replaceUserNamesWithUserIdsForLogsOfTypeUser(
  dataToUpload: LogBulkUploadFromFlatfile[],
  loggedItems: { [key: string]: LoggedItem },
  userOptions: UserOption[],
): LogBulkUploadFromFlatfile[] {
  return dataToUpload.map((logToUpload) => {
    const { loggedItemId } = logToUpload;
    const loggedItem = loggedItems[loggedItemId];
    if (loggedItem?.itemType === GenericLogLoggedItemType.USER) {
      const selectedUser = userOptions.find(
        (u) => u.label === logToUpload.logValue,
      );
      if (!selectedUser) {
        throw new Error(
          `Tried uploading an invalid user for a generic log value of type 'User', ${logToUpload.logValue}`,
        );
      }
      return {
        ...logToUpload,
        logValue: selectedUser.value,
      };
    }
    return logToUpload;
  });
}
