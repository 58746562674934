import styled from 'styled-components';
import { OutlinedButton } from './OutlinedButton';

export const OutlinedPrimaryButton = styled(OutlinedButton)`
  color: ${({ theme }) => theme.colors.lightBlue};
  border: 1px solid ${({ theme }) => theme.colors.lightBlue};

  &:not(.Mui-disabled) {
    font-weight: 400;
    -webkit-text-stroke: 0.5px ${({ theme }) => theme.colors.darkerBlue};
  }

  & svg {
    fill: ${({ theme }) => theme.colors.lightBlue};
  }

  &:active {
    background-color: ${({ theme }) => theme.colors.lightBlue08};
  }

  &:hover {
    /* Need to explicitly set color for buttons that are rendered with <a> element,
       e.g. when href prop is passed */
    color: ${({ theme }) => theme.colors.lightBlue};
    border: 1px solid ${({ theme }) => theme.colors.lightBlue};
  }

  &:focus {
    /* Need to explicitly set color for buttons that are rendered with <a> element,
       e.g. when href prop is passed */
    color: ${({ theme }) => theme.colors.lightBlue};
  }
`;
