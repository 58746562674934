import { InputLabel } from '@material-ui/core';
import Editor from '@monorepo/old-web/js/components/elements/RichTextEditor/Editor';
import React from 'react';
import { useField } from 'react-final-form';

/**
 * @deprecated Use packages/shared/componentsV2/WysiwgEditor.tsx
 */

interface RichTextEditorProps {
  label?: string;
  name: string;
}

export const RichTextEditor = ({ label, name }: RichTextEditorProps) => {
  const { input } = useField(name);
  return (
    <div className="mapistry-rich-text-editor" title={`Enter ${label || name}`}>
      {label && (
        <InputLabel id={`text-editor-${name}-label`}>{label}</InputLabel>
      )}
      <Editor
        aria-labelledby={`text-editor-${name}-label`}
        id={`text-editor-${name}`}
        onChange={input.onChange}
        initialValue={input.value || undefined}
      />
    </div>
  );
};
