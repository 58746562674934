import { SvgIcon } from '@monorepo/shared/components/icons/SvgIcon';
import { RadioGroup } from '@monorepo/shared/components/RadioGroup';
import { FrequencyProvider } from '@monorepo/shared/contexts/FrequencyContext';
import { CUSTOM_FREQUENCY } from '@monorepo/shared/contexts/FrequencyContext/types';
import cn from 'classnames';
import { addYears, format, startOfDay, startOfTomorrow } from 'date-fns';
import _isEqual from 'lodash.isequal';
import {
  CalendarEventStatus,
  IntervalFrequencyEnum,
  IntervalFrequencyFactory,
  localEquivalentOfUTC,
  UTCEquivalentOfLocal
} from 'mapistry-shared';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import AnimatedComponent, {
  ANIMATION_TYPES
} from '../../elements/AnimatedComponent';
import {
  AccordionContainer,
  AddButton,
  DatePicker,
  DocumentUploader,
  FieldWrapper,
  TextField
} from '../../elements/index';
import AddAssignee from '../../elements/user/AddAssignee';
import FrequencyForm from '../../frequencyForm';
import { ProjectTaskType } from '../../propTypes';
import TaskHeader from '../TaskHeader';

const IntervalFrequencyEnumGroup = [CUSTOM_FREQUENCY];
const frequencies = [
  IntervalFrequencyEnum.DAY,
  IntervalFrequencyEnum.WEEK,
  IntervalFrequencyEnum.MONTH,
  IntervalFrequencyEnum.QUARTER,
  IntervalFrequencyEnum.YEAR,
  IntervalFrequencyEnumGroup,
];

export class TaskForm extends Component {
  startOfTomorrow = startOfTomorrow();

  constructor() {
    super();
    this.state = {
      isRecurringHeaderExpanded: false,
      isCollapsed: false,
    };
  }

  handleCollapse = (isCollapsed) => {
    this.setState({ isCollapsed });
  };

  // We want to see a complete, long title in the UI without allowing new lines in the title.
  // It wouldn't be logical, for example, on the compliance calendar, to have multi-line task titles.
  handleTitleChange(value) {
    const { onFieldChange, parentTask, task } = this.props;
    const noNewLinesValue = (value || '').replace(/(\r\n|\n|\r)/g, '');
    return onFieldChange(parentTask, task, 'title', noNewLinesValue);
  }

  handleFrequencyChange = (
    updatedFrequency,
    updatedCustomFrequency,
    updatedStartDate,
  ) => {
    const { onFieldChange, parentTask, task } = this.props;
    const { frequency, customFrequency, startDate } = task;

    const updated = {};

    if (updatedFrequency !== frequency) {
      updated.frequency = updatedFrequency;
    }
    if (updatedCustomFrequency !== customFrequency) {
      updated.customFrequency = updatedCustomFrequency;
    }
    if (updatedStartDate !== startDate) {
      updated.startDate =
        updatedStartDate && UTCEquivalentOfLocal(startOfDay(updatedStartDate));
    }

    if (Object.keys(updated).length > 0) {
      onFieldChange(parentTask, task, updated);
    }
  };

  handleNewFrequencyChange = (
    updatedFrequency,
    updatedCustomFrequency,
    updatedStartDate,
  ) => {
    const { onFieldChange, parentTask, task } = this.props;
    const { newFrequency, newCustomFrequency, newStartDate } = task;

    const updated = {};

    if (updatedFrequency !== newFrequency) {
      updated.newFrequency = updatedFrequency;
    }
    if (updatedCustomFrequency !== newCustomFrequency) {
      updated.newCustomFrequency = updatedCustomFrequency;
    }
    if (updatedStartDate !== newStartDate) {
      updated.newStartDate =
        updatedStartDate && UTCEquivalentOfLocal(startOfDay(updatedStartDate));
    }

    if (Object.keys(updated).length > 0) {
      onFieldChange(parentTask, task, updated);
    }
  };

  handleDateChange = (date, fieldKey) => {
    const { onFieldChange, parentTask, task } = this.props;
    const newDate = UTCEquivalentOfLocal(startOfDay(date));
    onFieldChange(parentTask, task, fieldKey, newDate);
  };

  handleCompleteTask = () => {
    const { onComplete, parentTask, task } = this.props;
    const today = UTCEquivalentOfLocal(new Date());
    const completedDate = task.markedAsCompletedDate ? null : today;
    onComplete(parentTask, task, completedDate);
  };

  recurringHeaderToggle = () => {
    this.setState((prevState) => ({
      isRecurringHeaderExpanded: !prevState.isRecurringHeaderExpanded,
    }));
  };

  completionFields() {
    const { disabled, error, onFieldChange, parentTask, task } = this.props;
    const {
      completedDate,
      completionNotes,
      markedAsCompletedDate,
      permissions,
      status,
    } = task;
    const fieldErrors = error?.fieldErrors || {};
    const { completedDateError } = fieldErrors;

    const noEditingCompletionFields =
      disabled ||
      status === CalendarEventStatus.COMPLETE ||
      !permissions.isAllowedToComplete;

    const localMarkedAsCompletedDate = localEquivalentOfUTC(
      markedAsCompletedDate,
    );
    const formattedMarkedAsCompletedDate =
      localMarkedAsCompletedDate &&
      format(localMarkedAsCompletedDate, 'MM/dd/yyyy');

    return (
      <AnimatedComponent
        isVisible={!!markedAsCompletedDate}
        type={ANIMATION_TYPES.SLIDE_DOWN_PRESENCE}
      >
        <>
          <div className="task-form__fields">
            <FieldWrapper label="Notes from completion">
              <TextField
                controlled
                multiline
                type="text"
                disabled={noEditingCompletionFields}
                value={completionNotes}
                onChange={(e) =>
                  onFieldChange(
                    parentTask,
                    task,
                    'completionNotes',
                    e.target.value,
                  )
                }
              />
            </FieldWrapper>
            <FieldWrapper
              label="Date completed"
              isRequired
              error={!!completedDateError}
              errorMessage={completedDateError}
            >
              <DatePicker
                autoOk
                className="task-form__date-field"
                disabled={noEditingCompletionFields}
                value={localEquivalentOfUTC(completedDate)}
                error={!!completedDateError}
                onChange={(date) =>
                  this.handleDateChange(date, 'completedDate')
                }
                maxDate={this.startOfTomorrow}
              />
            </FieldWrapper>
            <FieldWrapper label="Date marked as complete">
              {formattedMarkedAsCompletedDate}
            </FieldWrapper>
          </div>
          <div className="task-form__completion-separator" />
        </>
      </AnimatedComponent>
    );
  }

  nextDueDate(frequency, customFrequency, startDate) {
    if (
      !frequency ||
      !startDate ||
      !(startDate instanceof Date) ||
      Number.isNaN(startDate.getTime())
    ) {
      return null;
    }

    const intervalFrequency = IntervalFrequencyFactory.For(
      frequency,
      customFrequency,
    );

    const cutoffDate = addYears(startDate, 1);

    let [interval] = intervalFrequency.getMicroIntervals({
      start: startDate,
      end: startDate,
    });
    if (!interval) {
      return null;
    }

    interval = this.nextNotExcludedMicroInterval(
      intervalFrequency,
      interval,
      cutoffDate,
      2,
    );
    if (!interval) {
      return null;
    }

    return interval.end;
  }

  nextNotExcludedMicroInterval(
    intervalFrequency,
    interval,
    cutoffDate,
    count = 1,
  ) {
    let num = 0;
    let curr = interval;

    if (!curr.excluded) {
      num += 1;
    }

    while (curr.excluded || num < count) {
      const start = new Date(curr.end.getTime() + 1);
      if (start > cutoffDate) {
        return null;
      }

      [curr] = intervalFrequency.getMicroIntervals({ start, end: start });
      if (!interval) {
        return null;
      }
      if (!curr.excluded) {
        num += 1;
      }
    }

    return curr;
  }

  nextDueDateMessage(frequency, customFrequency, startDate) {
    const nextDueDate = this.nextDueDate(frequency, customFrequency, startDate);

    if (!nextDueDate) {
      return null;
    }

    return (
      <div className="task-form__fields-next-due-date">
        <SvgIcon
          identifier="information"
          className="task-form__information-icon"
        />
        Your second due date will be {format(nextDueDate, 'eeee, MMMM do')}
      </div>
    );
  }

  editingTaskRecurringFields(noEditing) {
    const { addingTask, canRecur, error, onSetSaveDisabled, task } = this.props;
    const {
      createdAt,
      customFrequency,
      dueDate,
      frequency,
      isRecurring,
      newCustomFrequency,
      newEndDate,
      newFrequency,
      newStartDate,
      startDate,
    } = task;
    if (addingTask || !canRecur || !isRecurring) {
      return null;
    }

    const { isRecurringHeaderExpanded } = this.state;

    const fieldErrors = error?.fieldErrors || {};
    const { frequencyError, startDateError, endDateError } = fieldErrors;

    const createdAtDate =
      typeof createdAt === 'string' ? new Date(createdAt) : createdAt;

    return (
      <AccordionContainer
        animationType={ANIMATION_TYPES.SLIDE_DOWN_PRESENCE}
        isExpanded={isRecurringHeaderExpanded}
        onToggle={this.recurringHeaderToggle}
        title="Recurring task settings"
        titleClassName="task-form__edit-recurring-header"
      >
        <div className="task-form__fields task-form__edit-recurring-content">
          {Boolean(createdAtDate) && (
            <FieldWrapper label="Recurring task creation date">
              {format(createdAtDate, 'MM/dd/yyyy')}
            </FieldWrapper>
          )}
          <FrequencyProvider
            alwaysUseDatePicker
            forceCustomFrequency
            selectedFrequency={newFrequency}
            customFrequency={newCustomFrequency}
            selectedStartDate={localEquivalentOfUTC(newStartDate)}
            onFrequencyChange={this.handleNewFrequencyChange}
            onCustomPopperOpen={(isOpen) => onSetSaveDisabled?.(isOpen)}
            minStartDate={localEquivalentOfUTC(dueDate)}
            isDueDate
          >
            <FrequencyForm
              disabled={noEditing}
              customFrequencyMessageStart="This task will be required"
              frequencyErrorMessage={frequencyError}
              frequencyIsClearable={false}
              frequencyIsRequired
              frequencyLabel="How often would you like to do this task?"
              frequencyOptions={frequencies}
              showDateField={
                newFrequency !== frequency ||
                !_isEqual(customFrequency, newCustomFrequency)
              }
              startDateErrorMessage={startDateError}
              startDateFooterText={this.nextDueDateMessage(
                newFrequency,
                newCustomFrequency,
                localEquivalentOfUTC(newStartDate),
              )}
              startDateIsRequired
              startDateLabel="On what date should this new frequency start?"
            />
          </FrequencyProvider>
          <FieldWrapper
            label="Recurring task end date"
            error={!!endDateError}
            errorMessage={endDateError}
          >
            <DatePicker
              autoOk
              className="task-form__date-field"
              clearable
              disabled={noEditing}
              error={!!endDateError}
              minDate={
                this.nextDueDate(
                  newFrequency,
                  newCustomFrequency || customFrequency,
                  localEquivalentOfUTC(newStartDate || startDate),
                ) || new Date()
              }
              value={localEquivalentOfUTC(newEndDate)}
              onChange={(date) => this.handleDateChange(date, 'newEndDate')}
            />
          </FieldWrapper>
        </div>
      </AccordionContainer>
    );
  }

  yesNoRadioGroup(groupName, disabled, value, onChange) {
    return (
      <RadioGroup
        groupName={groupName}
        onChange={(_, opt) => {
          onChange(opt.value === 'y');
        }}
        options={[
          {
            value: 'y',
            label: 'Yes',
            disabled,
          },
          {
            value: 'n',
            label: 'No',
            disabled,
          },
        ]}
        value={value ? 'y' : 'n'}
      />
    );
  }

  recurringFields(noEditing, hasSubTasks) {
    const {
      addingTask,
      canRecur,
      error,
      onFieldChange,
      onIsRecurringChange,
      onSetSaveDisabled,
      parentTask,
      task,
    } = this.props;
    const {
      addSubtasks,
      dueDate,
      isRecurring,
      frequency,
      customFrequency,
      startDate,
      endDate,
    } = task;

    const fieldErrors = error?.fieldErrors || {};
    const { dueDateError, frequencyError, startDateError, endDateError } =
      fieldErrors;

    const addSubtasksQuestionDisabled = noEditing || hasSubTasks;
    const isNewRecurringParentTask =
      addingTask && canRecur && isRecurring && !parentTask;
    const isSubtaskOnNewRecurringTask = addingTask && isRecurring && parentTask;

    return (
      <>
        {addingTask && canRecur && (
          <FieldWrapper
            label="Do you want this to be a recurring task?"
            isRequired
          >
            {this.yesNoRadioGroup(
              'isRecurring',
              noEditing,
              isRecurring,
              (value) => onIsRecurringChange(parentTask, task, value),
            )}
          </FieldWrapper>
        )}
        {isNewRecurringParentTask ? (
          <>
            <FrequencyProvider
              alwaysUseDatePicker
              forceCustomFrequency
              selectedFrequency={frequency}
              customFrequency={customFrequency}
              selectedStartDate={localEquivalentOfUTC(startDate)}
              onFrequencyChange={this.handleFrequencyChange}
              onCustomPopperOpen={(isOpen) => onSetSaveDisabled?.(isOpen)}
              isDueDate
            >
              <FrequencyForm
                disabled={noEditing}
                customFrequencyMessageStart="This task will be required"
                frequencyErrorMessage={frequencyError}
                frequencyIsRequired
                frequencyLabel="How often would you like to do this task?"
                frequencyOptions={frequencies}
                startDateErrorMessage={startDateError}
                startDateFooterText={this.nextDueDateMessage(
                  frequency,
                  customFrequency,
                  localEquivalentOfUTC(startDate),
                )}
                startDateIsRequired
                startDateLabel="First task due date"
              />
            </FrequencyProvider>
            <FieldWrapper
              label="Recurring task end date"
              error={!!endDateError}
              errorMessage={endDateError}
            >
              <DatePicker
                autoOk
                className="task-form__date-field"
                clearable
                disabled={noEditing}
                error={!!endDateError}
                minDate={
                  this.nextDueDate(
                    frequency,
                    customFrequency,
                    localEquivalentOfUTC(startDate),
                  ) || new Date()
                }
                value={localEquivalentOfUTC(endDate)}
                onChange={(date) => this.handleDateChange(date, 'endDate')}
              />
            </FieldWrapper>
          </>
        ) : (
          <FieldWrapper
            label={
              isSubtaskOnNewRecurringTask
                ? 'First task due date'
                : 'Task due date'
            }
            isRequired
            error={!!dueDateError}
            errorMessage={dueDateError}
          >
            <DatePicker
              autoOk
              className="task-form__date-field"
              disabled={noEditing || (!addingTask && isRecurring)}
              value={localEquivalentOfUTC(dueDate)}
              error={!!dueDateError}
              onChange={(date) => this.handleDateChange(date, 'dueDate')}
            />
          </FieldWrapper>
        )}
        {!parentTask && (
          <FieldWrapper label="Would you like to add subtasks?" isRequired>
            {this.yesNoRadioGroup(
              'addSubtasks',
              addSubtasksQuestionDisabled,
              addSubtasks,
              (value) =>
                onFieldChange(parentTask, task, {
                  addSubtasks: value,
                }),
            )}
          </FieldWrapper>
        )}
      </>
    );
  }

  taskFields(subTasks) {
    const {
      defaultTitle,
      disabled,
      error,
      folderSlugPrefix,
      onError,
      onFieldChange,
      parentTask,
      projectId,
      task,
    } = this.props;

    const {
      id,
      addSubtasks,
      assignees,
      completedDate,
      createdAt,
      deficiency,
      description,
      markedAsCompletedDate,
      title,
      notes,
      isRecurring,
      templateFolderSlug,
      status,
      permissions,
    } = task;

    const fieldErrors = error?.fieldErrors || {};
    const { titleError, descriptionError, assigneesError } = fieldErrors;

    const createdAtDate =
      typeof createdAt === 'string' ? new Date(createdAt) : createdAt;

    const hasSubTasks = !!subTasks.length;
    const isCompleted = !!markedAsCompletedDate || !!completedDate;

    const disableEditing =
      disabled || isCompleted || status === CalendarEventStatus.COMPLETE;

    const noEditing = disableEditing || !permissions.isAllowedToEdit;
    const noCompleting = disableEditing || !permissions.isAllowedToComplete;

    const folderSlug = task.folderSlug || `${folderSlugPrefix}-${id}`;

    return (
      <div
        className={cn('task-form__fields-container', {
          'task-form__fields-container-wide': !addSubtasks,
        })}
      >
        {this.editingTaskRecurringFields(noEditing)}
        {this.completionFields()}
        <div className="task-form__fields">
          {!isRecurring && Boolean(createdAtDate) && (
            <FieldWrapper label="Task creation date">
              {format(createdAtDate, 'MM/dd/yyyy')}
            </FieldWrapper>
          )}
          <FieldWrapper
            label="Title"
            isRequired
            error={!!titleError}
            errorMessage={titleError}
          >
            <TextField
              controlled
              multiline
              type="text"
              disabled={noEditing}
              placeholder={defaultTitle}
              value={title}
              error={!!titleError}
              onChange={(e) => this.handleTitleChange(e.target.value)}
            />
          </FieldWrapper>
          <FieldWrapper label="Task description">
            <TextField
              controlled
              multiline
              type="text"
              disabled={noEditing}
              value={description}
              error={!!descriptionError}
              onChange={(e) =>
                onFieldChange(parentTask, task, 'description', e.target.value)
              }
            />
          </FieldWrapper>
          <FieldWrapper
            label="Assignee(s)"
            isRequired
            error={!!assigneesError}
            errorMessage={assigneesError}
          >
            <AddAssignee
              assignees={assignees}
              disabled={noEditing}
              multi
              onChange={(value) =>
                onFieldChange(parentTask, task, 'assignees', value)
              }
              projectId={projectId}
              trackChanges
            />
          </FieldWrapper>
          {this.recurringFields(noEditing, hasSubTasks)}
          <FieldWrapper label="Deficiency?">
            <TextField
              controlled
              multiline
              type="text"
              disabled={noEditing}
              value={deficiency}
              onChange={(e) =>
                onFieldChange(parentTask, task, 'deficiency', e.target.value)
              }
            />
          </FieldWrapper>

          <FieldWrapper label="Notes">
            <TextField
              controlled
              multiline
              type="text"
              disabled={noCompleting}
              value={notes}
              onChange={(e) =>
                onFieldChange(parentTask, task, 'notes', e.target.value)
              }
            />
          </FieldWrapper>

          <DocumentUploader
            disabled={noCompleting}
            folderSlug={folderSlug}
            labelBeforeAddAttachments
            onError={onError}
            onChange={() => {
              onFieldChange(parentTask, task, {
                folderSlug,
                attachmentsChanged: true,
              });
            }}
            projectId={projectId}
            templateFolderSlug={templateFolderSlug}
            disabledMessage={
              !permissions.isAllowedToEdit
                ? 'Only admins, the author of the task, and users assigned to the task are allowed to edit the task.'
                : ''
            }
          />
        </div>
      </div>
    );
  }

  subTasks(subTasks) {
    const {
      addingTask,
      disabled,
      error,
      folderSlugPrefix,
      onAddSubtask,
      onComplete,
      onDelete,
      onError,
      onFieldChange,
      parentTask,
      projectId,
      task,
    } = this.props;
    if (task.deleted) {
      return null;
    }
    const subTaskErrors = error?.subTaskErrors || [];

    return (
      <div className="task-form__subtasks-container">
        <AnimatedComponent
          isVisible={!parentTask && !!task.addSubtasks}
          type={ANIMATION_TYPES.SLIDE_RIGHT_LEFT_SMOOTH_PRESENCE}
        >
          <div className="task-form__subtasks">
            <div className="task-form__create-task-button">
              <AddButton
                disabled={disabled || !task.permissions.isAllowedToEdit}
                label="Add a subtask"
                onClick={() => onAddSubtask?.(task)}
              />
            </div>
            {subTasks.map((subtask, idx) => (
              <TaskForm
                addingTask={addingTask}
                key={subtask.id}
                defaultTitle={`Subtask ${idx + 1}`}
                disabled={disabled}
                error={subTaskErrors.find((e) => e.taskId === subtask.id)}
                folderSlugPrefix={folderSlugPrefix}
                onComplete={onComplete}
                onDelete={() => onDelete(task, subtask)}
                onError={onError}
                onFieldChange={onFieldChange}
                parentTask={task}
                projectId={projectId}
                task={subtask}
              />
            ))}
          </div>
        </AnimatedComponent>
      </div>
    );
  }

  render() {
    const { isCollapsed } = this.state;
    const { defaultTitle, disabled, noHeader, onDelete, task } = this.props;
    if (task.deleted) {
      return null;
    }
    const subTasks = task.subTasks.filter((t) => !t.deleted);

    return (
      <>
        {!noHeader && (
          <TaskHeader
            canBeCompleted={!task.isTemplate}
            defaultTitle={defaultTitle}
            taskPermissions={task.permissions}
            title={task.title}
            disabled={disabled}
            isCollapsed={isCollapsed}
            isCompleted={!!task.markedAsCompletedDate}
            onCollapse={this.handleCollapse}
            onComplete={this.handleCompleteTask}
            onDelete={() => onDelete(null, task)}
          />
        )}
        <AnimatedComponent
          className="task-form__accordion"
          initial={false}
          isVisible={!isCollapsed}
          type={ANIMATION_TYPES.SLIDE_DOWN_PRESENCE}
        >
          <div className="task-form">
            {this.taskFields(subTasks)}
            {this.subTasks(subTasks)}
          </div>
        </AnimatedComponent>
      </>
    );
  }
}

TaskForm.propTypes = {
  addingTask: PropTypes.bool,
  canRecur: PropTypes.bool,
  defaultTitle: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    fieldErrors: PropTypes.object,
    // eslint-disable-next-line react/forbid-prop-types
    subTaskErrors: PropTypes.arrayOf(PropTypes.object),
  }),
  folderSlugPrefix: PropTypes.string.isRequired,
  noHeader: PropTypes.bool,
  onAddSubtask: PropTypes.func,
  onComplete: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired, // accepts error message as string
  onFieldChange: PropTypes.func.isRequired,
  onIsRecurringChange: PropTypes.func,
  onSetSaveDisabled: PropTypes.func,
  parentTask: ProjectTaskType,
  projectId: PropTypes.string.isRequired,
  task: ProjectTaskType.isRequired,
};

TaskForm.defaultProps = {
  addingTask: false,
  canRecur: false,
  defaultTitle: 'Task',
  disabled: false,
  error: {},
  onAddSubtask: null,
  onIsRecurringChange: null,
  onSetSaveDisabled: null,
  noHeader: false,
  parentTask: null,
};
