import APP from '@monorepo/old-web/js/config';
import { VortexSetupVars } from 'mapistry-shared';
import { useAppContext } from '../../vortex/src/contexts/AuthContext';

/**
 * Only to be used for components that are being used both within the old dashboard (backbone routing), and the vortex.
 * Grabs the page var from AppContext or APP appropriately.
 */
export function useVortexOrOldDashboardPageSetupVar(
  variableName: keyof VortexSetupVars,
) {
  // Outside of Vortex, APP contains both:
  // - non-sensitive variables that are passed to the FE with main html template, they will be always defined;
  // - sensitive variables that are fetched from the BE only when it's verified that a user is logged in,
  //   only then they will be redirected to one of the application route, so it's guaranteed that those vars
  //   are defined inside the app code.
  // Within Vortex, allow Flatfile to function without global APP
  const appVarsFromContext = useAppContext(true);

  const pageVar = appVarsFromContext // equivalent to !APP.backbone
    ? appVarsFromContext[variableName]
    : (APP[variableName] as VortexSetupVars[typeof variableName]);

  return pageVar;
}

export function useVortexPageSetupVar(
  variableName: keyof VortexSetupVars,
): VortexSetupVars[typeof variableName] {
  const appVarsFromContext = useAppContext();
  return appVarsFromContext[variableName];
}
