import { TextField as MuiTextField } from '@material-ui/core';
import { KeyService } from '@monorepo/shared/KeyService';
import React, { useCallback } from 'react';

type MuiTextFieldProps = React.ComponentProps<typeof MuiTextField>;

type CommonTextInputProps = MuiTextFieldProps & {
  endAdornment?: React.ReactNode;
  onEnterKey?: React.KeyboardEventHandler<HTMLInputElement>;
  variant?: 'standard';
};

export type ControlledTextInputProps = Omit<
  CommonTextInputProps,
  'defaultValue'
> & {
  controlled?: true;
};

export type UncontrolledTextInputProps = Omit<CommonTextInputProps, 'value'> & {
  controlled: false | undefined;
};

/* @ts-expect-error - TODO: Fix this the next time the file is edited. */
const isControlled = (props): props is ControlledTextInputProps =>
  props.controlled;

export function TextInput(props: ControlledTextInputProps): React.ReactElement;
export function TextInput(
  props: UncontrolledTextInputProps,
): React.ReactElement;
export function TextInput(
  props: ControlledTextInputProps | UncontrolledTextInputProps,
) {
  const {
    controlled,
    endAdornment = null,
    InputProps = null,
    onEnterKey = undefined,
    ...rest
  } = props;

  const handleKeyDown = useCallback<
    React.KeyboardEventHandler<HTMLInputElement>
  >(
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line consistent-return
    (e) => {
      if (onEnterKey && KeyService.isEnterKey(e.keyCode)) {
        return onEnterKey(e);
      }
    },
    [onEnterKey],
  );
  const onKeyDown = onEnterKey ? handleKeyDown : undefined;
  return (
    <MuiTextField
      classes={{ root: 'mapistry-text-input' }}
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line react/destructuring-assignment
      defaultValue={isControlled(props) ? undefined : props.defaultValue}
      fullWidth
      InputProps={{
        className: 'mapistry-text-input-input',
        endAdornment,
        ...InputProps,
      }}
      onKeyDown={onKeyDown}
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line react/destructuring-assignment
      value={isControlled(props) ? props.value : undefined}
      {...rest}
    />
  );
}
