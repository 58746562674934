/* eslint-disable no-console */
import React, { FC } from 'react';
import * as Sentry from '@sentry/browser';

import { ErrorBoundary, FallbackProps } from 'react-error-boundary';

function DefaultErrorFallback() {
  return (
    <div role="alert">
      <p>Something went wrong, try reloading the page.</p>
    </div>
  );
}

const reportErrorToSentry = (
  error: Error,
  info: { componentStack: string },
) => {
  Sentry.captureException(error, { extra: info });
  console.log('Error caught by React Error Boundary:', error, info);
};

type Props = {
  FallbackComponent: React.ComponentType<FallbackProps>;
};

const ErrorBoundaryLoggingToSentry: FC<Props> = (props) => (
  <ErrorBoundary
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/destructuring-assignment
    FallbackComponent={props.FallbackComponent || DefaultErrorFallback}
    onError={reportErrorToSentry}
  >
    {/* TODO: Fix this the next time the file is edited. */}
    {/* eslint-disable-next-line react/destructuring-assignment */}
    {props.children}
  </ErrorBoundary>
);

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default ErrorBoundaryLoggingToSentry;
