import { SvgIcon } from '@monorepo/shared/components/icons/SvgIcon';
import PropTypes from 'prop-types';
import React from 'react';
import SingleSignature from './SingleSignature';
import IconButton from './buttons/IconButton';

class MultiSignature extends React.Component {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/state-in-constructor
  state = { emptySignerCount: 0 };

  constructor(props) {
    super(props);

    this.handleSignerSelected = this.handleSignerSelected.bind(this);
    this.handleRemoveSigner = this.handleRemoveSigner.bind(this);
  }

  handleSignerSelected(selectedSigner) {
    this.handleRemoveEmptySigner();
    this.addSigner(selectedSigner, false);
  }

  handleSign(currentUserSigner) {
    const { currentUser } = this.props;

    if (currentUserSigner == null) {
      this.addSigner(currentUser, true);
    } else {
      this.sign();
    }
  }

  handleAddEmptySigner() {
    const { emptySignerCount } = this.state;

    this.setState({ emptySignerCount: emptySignerCount + 1 });
  }

  handleRemoveEmptySigner() {
    const { emptySignerCount } = this.state;

    this.setState({ emptySignerCount: emptySignerCount - 1 });
  }

  handleRemoveSigner(signer) {
    const { value, onChange } = this.props;

    if (signer == null) {
      this.handleRemoveEmptySigner();
    } else {
      const signers = value.filter((s) => s.user_id !== signer.id);
      onChange(signers);
    }
  }

  addSigner(user, signed) {
    const { value, onChange } = this.props;

    if (!user) {
      return;
    }
    const newSigner = {
      user_id: user.id,
      user_name: user.name,
      signed,
      signed_at: this.signedAtDate(),
    };
    const signers = value;
    signers.push(newSigner);
    onChange(signers);
  }

  sign() {
    const { value, currentUser, onChange } = this.props;

    const signers = value;
    const signer = signers.find((s) => s.user_id === currentUser.id);
    signer.signed = true;
    signer.signed_at = this.signedAtDate();
    onChange(signers);
  }

  signedAtDate() {
    const dateFormatOptions = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    };
    return new Date().toLocaleDateString('en-US', dateFormatOptions);
  }

  currentUserSignature(currentUserSigner) {
    const { currentUser, onUpdateSignature, errorMessage } = this.props;

    return (
      <SingleSignature
        value={currentUser}
        signed={currentUserSigner && currentUserSigner.signed}
        onRemove={this.handleRemoveSigner}
        onSign={() => this.handleSign(currentUserSigner)}
        onUpdateSignature={onUpdateSignature}
        allowSign
        errorMessage={errorMessage}
      />
    );
  }

  otherSignatures(otherSigners) {
    const { users } = this.props;

    return otherSigners.map((signer) => {
      const user = users.find((u) => u.id === signer.user_id);
      if (!user) {
        return null;
      }
      return (
        <SingleSignature
          key={signer.user_id}
          value={user}
          signed={signer.signed}
          onRemove={this.handleRemoveSigner}
          allowSign={false}
        />
      );
    });
  }

  emptySigners() {
    const { users, value, currentUser } = this.props;
    const { emptySignerCount } = this.state;

    const signerIds = value.map((s) => s.user_id);
    const availableSigners = users.filter(
      (u) => signerIds.indexOf(u.id) < 0 && u.id !== currentUser.id,
    );

    const emptySignerComponents = [];
    for (let i = 0; i < emptySignerCount; i += 1) {
      emptySignerComponents.push(
        <SingleSignature
          key={`empty-signer-${i}`}
          users={availableSigners}
          onChange={this.handleSignerSelected}
          onRemove={this.handleRemoveSigner}
        />,
      );
    }
    return emptySignerComponents;
  }

  addSignerButton(otherSigners) {
    const { users } = this.props;
    const { emptySignerCount } = this.state;

    const numberOfPossibleOtherSigners = users.length - 1;
    const otherSignersInPlace = emptySignerCount + otherSigners.length;
    const shouldShow = numberOfPossibleOtherSigners > otherSignersInPlace;
    return shouldShow ? (
      <IconButton
        className="add-signer-button"
        onClick={() => this.handleAddEmptySigner()}
      >
        <SvgIcon identifier="add" />
        <span className="add-signer-text">Add a signer</span>
      </IconButton>
    ) : null;
  }

  render() {
    const { disabled, value, currentUser } = this.props;

    const currentUserSigner = value.find((s) => s.user_id === currentUser.id);
    const otherSigners = value.filter((s) => s.user_id !== currentUser.id);

    return (
      <div className="multi-signature">
        {!disabled && (
          <>
            {this.currentUserSignature(currentUserSigner)}
            {this.otherSignatures(otherSigners)}
            {this.emptySigners()}
            {this.addSignerButton(otherSigners)}
          </>
        )}
      </div>
    );
  }
}

const SignerOptionType = PropTypes.shape({
  user_id: PropTypes.string,
  user_name: PropTypes.string,
  signed: PropTypes.bool,
  signed_at: PropTypes.string,
});

const UserType = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  signature: PropTypes.string,
});

MultiSignature.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.arrayOf(SignerOptionType),
  users: PropTypes.arrayOf(UserType).isRequired,
  currentUser: UserType.isRequired,
  errorMessage: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onUpdateSignature: PropTypes.func.isRequired,
};

MultiSignature.defaultProps = {
  disabled: false,
  value: null,
  errorMessage: null,
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default MultiSignature;
