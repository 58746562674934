import React, { useMemo } from 'react';
import { useField, UseFieldConfig } from 'react-final-form';
import { RadioGroup } from '../RadioGroup';

type RadioGroupFieldProps<ValueType> = React.ComponentProps<
  typeof RadioGroup
> & {
  label?: string;
  name: string;
  validate?: UseFieldConfig<ValueType>['validate'];
};

export const RadioGroupField = <ValueType,>({
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/prop-types
  helperText,
  label,
  name,
  validate,
  ...rest
}: RadioGroupFieldProps<ValueType>) => {
  const { input, meta } = useField<ValueType>(name, { validate });
  const helpText = useMemo<string | undefined>(() => {
    if (meta.error && (meta.touched || meta.modified)) {
      return meta.error;
    }
    return helperText;
  }, [helperText, meta.error, meta.modified, meta.touched]);
  return (
    <RadioGroup
      {...rest}
      error={!!meta.error && (meta.touched || meta.modified)}
      helperText={helpText}
      label={label}
      onChange={(_, option) => input.onChange(option.value)}
      value={input.value}
    />
  );
};
