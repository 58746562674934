import { createAction } from 'redux-actions';
import { CALL_API } from '../middleware/api';
import * as HTTP from '../utils/HTTP';

import {
  FETCH_USERS_REQUEST,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_FAILURE,
} from '.';

const fetchUsersRequest = createAction(FETCH_USERS_REQUEST);
const fetchUsersSuccess = createAction(
  FETCH_USERS_SUCCESS,
  undefined,
  (_, req) => ({ req }),
);
const fetchUsersFailure = createAction(
  FETCH_USERS_FAILURE,
  undefined,
  (_, req) => ({ req }),
);

export function fetchUsersAction(projectId) {
  return {
    [CALL_API]: (state) => {
      const { user } = state;
      const { forProject, users } = user;
      const isLoading = user.isFetching?.users;
      const sameProject = forProject === projectId;
      if ((isLoading && sameProject) || (sameProject && users?.length)) {
        return null;
      }
      return {
        endpoint: `/api/v2/projects/${projectId}/users`,
        lifecycleActions: [
          fetchUsersRequest,
          fetchUsersSuccess,
          fetchUsersFailure,
        ],
        verb: HTTP.Types.GET,
      };
    },
    payload: { projectId },
  };
}
