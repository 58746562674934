import { Api } from '@monorepo/shared/apiClient';
import { useCurrentUser } from '@monorepo/shared/hooks/useCurrentUser';
import React, { createContext, ReactElement } from 'react';
import { QueryStatus } from 'react-query';

type CurrentUserContextType = {
  currentUser?: Api.User;
  error?: Api.ErrorResponse | null;
  status: QueryStatus;
};

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const CurrentUserContext = createContext<CurrentUserContextType>(undefined!);

type CurrentUserProviderProps = {
  children: React.ReactNode;
  currentUser?: Api.User;
};

export const CurrentUserProvider = (
  props: CurrentUserProviderProps,
): ReactElement => {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/destructuring-assignment
  const { currentUser, error, status } = useCurrentUser(props.currentUser);
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value = { currentUser, error, status };

  return (
    <CurrentUserContext.Provider value={value}>
      {/* TODO: Fix this the next time the file is edited. */}
      {/* eslint-disable-next-line react/destructuring-assignment */}
      {props.children}
    </CurrentUserContext.Provider>
  );
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default CurrentUserContext;
