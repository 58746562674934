import React from 'react';
import PropTypes from 'prop-types';
import { Clear } from './MuiIcon';

class ErrorMessage extends React.Component {
  validationErrorMessage() {
    const { numberOfFormErrors } = this.props;

    if (!numberOfFormErrors) {
      return '';
    }
    return numberOfFormErrors > 1
      ? 'There are multiple errors that need correcting. Please go back and review each issue.'
      : 'There is one error that needs correcting. Please go back and review the issue.';
  }

  render() {
    const { onClose, errorMessage } = this.props;
    const validationMessage = this.validationErrorMessage();

    return (
      <div className="error-message-notification">
        <div className="error-message-box">
          <span className="error-icon">!</span>
          <div>
            {validationMessage && (
              <p className="error-text">{validationMessage}</p>
            )}
            {errorMessage && <p className="error-text">{errorMessage}</p>}
          </div>
          <Clear className="error-close" onClick={onClose} />
        </div>
      </div>
    );
  }
}

ErrorMessage.propTypes = {
  numberOfFormErrors: PropTypes.number,
  errorMessage: PropTypes.string,
  onClose: PropTypes.func,
};

ErrorMessage.defaultProps = {
  numberOfFormErrors: 0,
  errorMessage: '',
  onClose: null,
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default ErrorMessage;
