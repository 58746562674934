import { SvgIcon } from '@monorepo/shared/components/icons/SvgIcon';
import { FrequencyContext } from '@monorepo/shared/contexts/FrequencyContext';
import {
  CUSTOM_FREQUENCY,
  FrequencyOption,
  FrequencyOptions,
} from '@monorepo/shared/contexts/FrequencyContext/types';
import { IntervalFrequencyFactory } from 'mapistry-shared';
import React, { RefObject, useContext, useMemo, useRef } from 'react';
import { SingleOption, SubOptions } from '../../types/ts-types';
import { findSelectedOption } from '../../utils/typeUtils';
import { IconButton, Select } from '../elements';
import CustomFrequencyPopper from './CustomFrequencyPopper';

const formatSingleOption = (
  option: FrequencyOption,
): SingleOption<FrequencyOption> => {
  if (option === CUSTOM_FREQUENCY) {
    return {
      value: option,
      label: 'Custom',
    };
  }
  const intervalFrequency = IntervalFrequencyFactory.For(option);
  return {
    value: option,
    label: intervalFrequency.toString(),
  };
};

export type FrequencySelectProps = {
  customFrequencyMessageStart?: string;
  customPopperAnchor?: RefObject<HTMLElement>;
  customPopperHeader?: string;
  customPopperSubHeader?: string;
  disabled?: boolean;
  frequencyOptions?: FrequencyOptions;
  showCustomFrequencyEditButton?: boolean;
  // select passthrough Props, defined here since Select is not ts
  className?: string;
  error?: boolean;
  isClearable?: boolean;
  isFixed?: boolean;
  menuPlacement?: string;
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  menuPortalTarget?: any;
  placeholder?: string;
};

const defaultProps = {};

const FrequencySelect = (props: FrequencySelectProps) => {
  const {
    customFrequencyMessageStart,
    customPopperAnchor,
    customPopperHeader,
    customPopperSubHeader,
    disabled,
    frequencyOptions,
    showCustomFrequencyEditButton,
    ...passthroughProps
  } = props;

  const {
    customFrequency,
    customPopperOpen,
    onFrequencySelect,
    selectedFrequency,
  } = useContext(FrequencyContext);

  const selectWrapperRef = useRef<HTMLDivElement>(null);
  const formattedFrequencyOptions = useMemo(
    () =>
      frequencyOptions?.map((option) =>
        !Array.isArray(option)
          ? formatSingleOption(option)
          : ({
              options: option.map(formatSingleOption),
            } as SubOptions<FrequencyOption>),
      ),
    [frequencyOptions],
  );

  const isCustomFrequency =
    customFrequency && !customFrequency.nonCustomFrequency;

  const selectedFrequencyOption = useMemo(() => {
    if (!formattedFrequencyOptions || !selectedFrequency) {
      return null;
    }

    const frequency = customFrequency
      ? customFrequency.nonCustomFrequency ?? CUSTOM_FREQUENCY
      : selectedFrequency;

    return findSelectedOption(formattedFrequencyOptions, frequency);
  }, [customFrequency, formattedFrequencyOptions, selectedFrequency]);

  return (
    <>
      {!customPopperAnchor && <span ref={selectWrapperRef} />}
      <Select
        {...passthroughProps}
        isDisabled={customPopperOpen || disabled}
        onChange={(option) =>
          option ? onFrequencySelect(option.value) : onFrequencySelect()
        }
        options={formattedFrequencyOptions}
        value={selectedFrequencyOption}
      />
      {showCustomFrequencyEditButton && isCustomFrequency && (
        <IconButton
          disabled={disabled || customPopperOpen}
          onClick={() => onFrequencySelect(CUSTOM_FREQUENCY)}
          className="custom-frequency-edit-icon"
        >
          <SvgIcon identifier="edit" />
        </IconButton>
      )}
      {customPopperOpen && (
        <CustomFrequencyPopper
          anchorEl={
            customPopperAnchor
              ? customPopperAnchor.current
              : selectWrapperRef.current
          }
          customFrequencyMessageStart={customFrequencyMessageStart}
          header={customPopperHeader}
          subHeader={customPopperSubHeader}
        />
      )}
    </>
  );
};

FrequencySelect.defaultProps = defaultProps;

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default FrequencySelect;
