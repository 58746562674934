import {
  IntervalFrequencyEnum,
  IntervalFrequencyFactory,
} from 'mapistry-shared';
import { SaveState } from '../../types/SaveState';
import {
  customFrequencyOffset,
  defaultSelectedRepeatOn,
  getDateOptions,
} from '../../utils/frequencyUtils';
import {
  ActionType,
  DefaultCustomFrequency,
  PayloadType,
  State,
} from './types';

const updateFrequency = (state: State, payload: PayloadType) => {
  const { alwaysUseDatePicker, forceCustomFrequency, frequencies, isDueDate } =
    payload;

  const updated = {
    ...state,
    localSaveState: SaveState.DIRTY,
    selectedFrequency: payload.selectedFrequency,
    customFrequency: payload.customFrequency,
  };
  const { customFrequency, frequencyChanged, selectedFrequency } = state;

  if (
    forceCustomFrequency &&
    !updated.customPopperOpen &&
    updated.selectedFrequency &&
    updated.customFrequency == null
  ) {
    const interval = IntervalFrequencyFactory.For(updated.selectedFrequency);
    updated.selectedFrequency = interval.frequency;

    const offset =
      state.customFrequency && !updated.selectedStartDate
        ? {
            startDay: state.customFrequency.startDay,
            startMonth: state.customFrequency.startMonth,
          }
        : customFrequencyOffset(
            updated.selectedFrequency,
            updated.selectedStartDate,
            isDueDate,
          );

    updated.customFrequency = {
      ...interval.customFrequency,
      ...offset,
    };
    return updated;
  }

  if (
    updated.customFrequency &&
    selectedFrequency !== updated.selectedFrequency
  ) {
    if (!frequencyChanged) {
      updated.frequencyChanged = true;
    } else {
      updated.customFrequency = {
        ...updated.customFrequency,
        numberOfEvents: 1,
        every: 1,
      };
    }
  }

  if (
    selectedFrequency !== updated.selectedFrequency ||
    (customFrequency &&
      customFrequency.every !== updated.customFrequency?.every)
  ) {
    if (updated.customFrequency) {
      updated.customFrequency = {
        ...updated.customFrequency,
        startMonth: undefined,
        startDay: undefined,
      };
    }

    if (!alwaysUseDatePicker) {
      const dateOptions = getDateOptions(
        frequencies,
        updated.selectedFrequency,
        updated.customFrequency,
      );
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line prefer-destructuring
      updated.selectedStartDate = dateOptions.dates[0];
    }

    if (updated.customFrequency) {
      updated.customFrequency = {
        ...updated.customFrequency,
        ...customFrequencyOffset(
          updated.selectedFrequency,
          updated.selectedStartDate,
          isDueDate,
        ),
      };
    }
  }

  if (
    !payload.noRepeatOn &&
    updated.customFrequency &&
    (selectedFrequency !== updated.selectedFrequency ||
      !customFrequency ||
      customFrequency.every !== updated.customFrequency.every ||
      customFrequency.startMonth !== updated.customFrequency.startMonth ||
      customFrequency.startDay !== updated.customFrequency.startDay)
  ) {
    updated.customFrequency = {
      ...updated.customFrequency,
      repeatOn: defaultSelectedRepeatOn(
        updated.selectedFrequency,
        updated.customFrequency,
      ),
    };
  }

  return updated;
};

export const frequencyStatusReducer = (
  state: State,
  action: { type: ActionType; payload?: PayloadType },
) => {
  const { type, payload } = action;
  let updated: State;
  switch (type) {
    case ActionType.Reset:
      updated = { localSaveState: SaveState.CLEAN };
      break;

    case ActionType.CloseCustomPopper:
      updated = {
        ...state,
        customPopperOpen: false,
      };
      break;

    case ActionType.UndoAndCloseCustomPopper:
      updated = {
        customFrequency: state.prevCustomFrequency,
        selectedFrequency: state.prevFrequency,
        selectedStartDate: state.prevStartDate,
        localSaveState: SaveState.CLEAN,
      };
      break;

    case ActionType.OpenCustomPopper: {
      if (!payload) {
        throw Error('payload missing');
      }

      updated = {
        ...state,
        prevCustomFrequency: state.customFrequency,
        prevFrequency: state.selectedFrequency,
        prevStartDate: state.selectedStartDate,
        customPopperOpen: true,
        frequencyChanged: false,
        localSaveState: SaveState.CLEAN,
      };

      if (state.customFrequency && !state.customFrequency.nonCustomFrequency) {
        break;
      }

      const { alwaysUseDatePicker, frequencies } = payload;

      updated.customFrequency = { ...DefaultCustomFrequency };
      updated.localSaveState = SaveState.DIRTY;
      updated.selectedFrequency = IntervalFrequencyEnum.DAY;

      if (!payload.noRepeatOn) {
        updated.customFrequency.repeatOn = defaultSelectedRepeatOn(
          updated.selectedFrequency,
          updated.customFrequency,
        );
      }

      if (!alwaysUseDatePicker) {
        const dateOptions = getDateOptions(
          frequencies,
          updated.selectedFrequency,
          updated.customFrequency,
        );
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line prefer-destructuring
        updated.selectedStartDate = dateOptions.dates[0];
      } else {
        updated.selectedStartDate = state.selectedStartDate;
      }

      break;
    }

    case ActionType.UpdateFrequency: {
      if (!payload) {
        throw Error('payload missing');
      }

      updated = updateFrequency(state, payload);
      break;
    }

    case ActionType.UpdateStartDate: {
      if (!payload) {
        throw Error('payload missing frequencies');
      }

      const { customFrequency, selectedFrequency } = state;
      const { selectedStartDate, isDueDate } = payload;

      updated = {
        ...state,
        localSaveState: SaveState.DIRTY,
        selectedStartDate,
      };

      if (customFrequency) {
        updated.customFrequency = {
          ...customFrequency,
          ...customFrequencyOffset(
            selectedFrequency,
            selectedStartDate,
            isDueDate,
          ),
        };

        if (
          !payload.noRepeatOn &&
          selectedFrequency === IntervalFrequencyEnum.MONTH &&
          !customFrequency.nonCustomFrequency &&
          customFrequency.startMonth !== updated.customFrequency.startMonth
        ) {
          updated.customFrequency.repeatOn = defaultSelectedRepeatOn(
            selectedFrequency,
            updated.customFrequency,
          );
        }
      }
      break;
    }

    default:
      throw new Error(`Unhandled action type: ${type}`);
  }

  return updated;
};
