import { CreateWorkbookConfig, SheetConfig } from '@flatfile/api/api';
import { initializeFlatfile } from '@flatfile/javascript';
import * as Sentry from '@sentry/browser';
import { useCallback } from 'react';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import { useMountEffect } from '../../hooks/useMountEffect';
import { useVortexOrOldDashboardPageSetupVar } from '../../hooks/usePageVar';
import { createFlatfileListener } from './flatfileEventListeners/flatfileEventListenerClientSide';
import { uploadWorkbookAvoidingSizeLimitForEnumOptions } from './helpers/flatfileApiHelpers';
import { checkWorkbookSettingsAgainstUndocumentedBugs } from './helpers/flatfileSettingsHelpers';
import { flatfileThemeConfig } from './styles/flatfileTheme';
import {
  FlatfileLoggingContext,
  FlatfileOptions,
  RecordHookCallback,
  SUBMIT_ACTION_NAME,
  SubmitDataCallback,
  SubmitErrorCallback,
  WorkbookConfig,
} from './types/flatfileTypes';

type FlatfileUploadModalProps = {
  onClose: () => void;
  onSubmitError?: SubmitErrorCallback;
  customErrorMessage?: string;
  onSubmitData: SubmitDataCallback;
  recordHookCallback?: RecordHookCallback;
  onHandshakeComplete: () => void;
  onInitializationError: (error: unknown) => void;
  showTableForManualInput?: boolean;
  workbook: WorkbookConfig;
  loggingContext: FlatfileLoggingContext;
};

export function FlatfileUploadModal(props: FlatfileUploadModalProps) {
  const [flatfilePublishableKey, flatfileEnvironmentId] = [
    useVortexOrOldDashboardPageSetupVar('flatfilePublishableKey'),
    useVortexOrOldDashboardPageSetupVar('flatfileEnvironmentId'),
  ];

  const {
    onHandshakeComplete,
    onInitializationError,
    onClose,
    onSubmitData,
    onSubmitError,
    customErrorMessage,
    recordHookCallback,
    workbook,
    showTableForManualInput = false,
    loggingContext,
  } = props;

  const { currentUser } = useCurrentUser();
  const customerOptions: FlatfileOptions['userInfo'] = {
    name: currentUser?.email || 'Unknown',
    userId: currentUser?.id,
  };

  const getFirstSheetAndSheetSlug = useCallback((): {
    sheet: SheetConfig;
    sheetSlug: string;
  } => {
    if (workbook.sheets && workbook.sheets.length > 1) {
      throw Error(
        'Current FlatfileUploadModal implementation supports only single sheet per workbook.',
      );
    }

    const sheet = workbook.sheets && workbook.sheets[0];
    if (!sheet) {
      throw Error(
        'Sheet configuration is missing in Flatfile workbook setting.',
      );
    }

    const sheetSlug = sheet.slug;
    if (!sheetSlug) {
      throw Error(
        'The sheet slug is missing in the Flatfile workbook setting - it is required!',
      );
    }

    return { sheet, sheetSlug };
  }, [workbook.sheets]);

  const createWorkbookWithSharedSettings = useCallback(
    (sheet: SheetConfig): WorkbookConfig => {
      const sharedSheetSettingsBetweenAllFlatfileModals: Partial<SheetConfig> =
        {
          allowAdditionalFields: false,
          access: showTableForManualInput
            ? ['import', 'delete', 'edit', 'add']
            : ['import', 'delete', 'edit'],
        };

      const sharedWorkbookSettingsBetweenAllFlatfileModals: Pick<
        CreateWorkbookConfig,
        'settings' | 'actions'
      > = {
        settings: { trackChanges: true }, // waits to enable submit until record hooks are complete
        actions: [
          {
            confirm: true,
            description: 'Are you sure that you are ready to submit your data?',
            label: 'Submit',
            mode: 'foreground',
            operation: SUBMIT_ACTION_NAME,
            primary: true,
            constraints: [
              { type: 'hasAllValid' }, // only allow submitting data when all rows are valid
              { type: 'hasData' }, // require any lines to be filled out
            ],
          },
        ],
      };

      const workbookWithSharedSheetSettings: WorkbookConfig = {
        ...workbook,
        sheets: [{ ...sheet, ...sharedSheetSettingsBetweenAllFlatfileModals }],
      };

      const workbookWithSharedSettings: WorkbookConfig = {
        ...workbookWithSharedSheetSettings,
        ...sharedWorkbookSettingsBetweenAllFlatfileModals,
      };

      checkWorkbookSettingsAgainstUndocumentedBugs(workbookWithSharedSettings);
      return workbookWithSharedSettings;
    },
    [showTableForManualInput, workbook],
  );

  useMountEffect(() => {
    // for now, we only handle uploads with a single sheet
    const { sheet, sheetSlug } = getFirstSheetAndSheetSlug();

    const flatfileEventListener = createFlatfileListener(
      sheetSlug,
      onSubmitData,
      loggingContext,
      {
        onSubmitError,
        recordHookCallback,
        customErrorMessage,
      },
    );

    const workbookWithSharedSettings: WorkbookConfig =
      createWorkbookWithSharedSettings(sheet);

    const placeholderWorkbook: WorkbookConfig = {
      ...workbookWithSharedSettings,
      sheets: [
        {
          ...workbookWithSharedSettings.sheets[0],
          fields: [],
        },
      ],
    };

    const flatfileOptions: FlatfileOptions = {
      name: workbook.name, // this gets shown in the flatfile admin UI
      userInfo: customerOptions,
      publishableKey: flatfilePublishableKey,
      environmentId: flatfileEnvironmentId,
      workbook: placeholderWorkbook,
      sidebarConfig: { showSidebar: false },
      closeSpace: {
        operation: SUBMIT_ACTION_NAME,
        onClose, // this one is for the close button after a successful upload
      },
      onCancel: onClose, // this one is for the x on the top right of the modal
      listener: flatfileEventListener,
      themeConfig: flatfileThemeConfig,
    };

    async function initFlatfile() {
      let spaceId;
      try {
        const data = await initializeFlatfile(flatfileOptions);
        spaceId = data?.spaceId;
        if (!spaceId) {
          Sentry.captureException('First Flatfile initialization went wrong', {
            extra: {
              ...loggingContext,
              flatfileResponse: data,
            },
          });
          throw new Error(
            `Couldn't reach file upload API, please reload your browser and wait a few minutes before trying again.`,
          );
        }
        onHandshakeComplete();

        // errors that happen here don't trigger out placeholder modal
        await uploadWorkbookAvoidingSizeLimitForEnumOptions(
          spaceId,
          workbookWithSharedSettings,
        );
      } catch (e) {
        console.log('Error during Flatfile initialization', e);
        onInitializationError(e);
      }
    }

    void initFlatfile();
  });

  // No need to show anything, Flatfile renders itself in an iframe
  return null;
}
