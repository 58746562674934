import _sortBy from 'lodash.sortby';
import {
  CalendarName,
  ComplexUnitString,
  COMPLEX_UNIT_TYPES_LIST,
  getRelatedUnits,
  isComplexUnit,
  Measurements,
  SIMPLE_UNIT_TYPES,
  splitComplexUnitIntoSimpleUnits,
  UNIT_TYPES_LIST,
  unitsAreRelated,
  UnitString,
  SimpleUnits,
  UnitType,
} from 'mapistry-shared';

const CURRENCY_UNIT_TYPES: UnitType[] = UNIT_TYPES_LIST.filter(
  (u) => u.measure === Measurements.CURRENCY,
);

// The order in which units will appear in the dropdowns and lists.
// Anything that doesn't have measurement type or is not included in this list – will be shown last.
// This list is already long, so user probably doesn't care about the exact order of the units,
// and will type and search for the unit anyway.
// Consider grouping units by measurement type in the UI in the future.
const orderedMeasurements = [
  Measurements.MASS,
  Measurements.VOLUME,
  Measurements.LENGTH,
  Measurements.AREA,
  Measurements.CONCENTRATION,
  Measurements.FLOW_RATE,
  Measurements.PRESSURE,
  Measurements.POWER,
  Measurements.THERMAL,
  Measurements.TIME,
];
const UNIT_TYPES_SORTED = _sortBy(UNIT_TYPES_LIST, (unitType) => {
  const idx = orderedMeasurements.findIndex((m) => m === unitType.measure);
  if (idx !== -1) return idx;
  return orderedMeasurements.length;
});

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default class Units {
  static isComplexUnit = isComplexUnit;

  static getRelatedUnits = getRelatedUnits;

  static areRelated = unitsAreRelated;

  /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
  static getAllForSuite(suite) {
    switch (suite) {
      case CalendarName.GENERIC_LOG:
        return UNIT_TYPES_SORTED;
      default:
        return [];
    }
  }

  static formatUnitDisplayText(givenUnit: UnitString) {
    const u = UNIT_TYPES_LIST.find((unit) => unit.value === givenUnit);
    return u?.label;
  }

  static getAdditionSubtractionUnitsForUnit(unitValue: UnitString) {
    // Can't sum percent with anything else
    // Calculation like "10% + 100 Lbs = 110 Lbs" is not supported yet
    /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
    if (unitValue === SIMPLE_UNIT_TYPES.PERCENT.value) {
      return [SIMPLE_UNIT_TYPES.PERCENT];
    }
    // Unitless values can be summed only with other unitless,
    // Otherwise there is a question which conversion to apply to it,
    // for example, in equation "lbs + grams + unitless = kg", should unitless be converted to lbs, grams, or kg?
    /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
    if (unitValue === SIMPLE_UNIT_TYPES.UNITLESS.value) {
      return [SIMPLE_UNIT_TYPES.UNITLESS];
    }
    return this.getRelatedUnits(unitValue);
  }

  static getMultiplicableUnitsForUnit(unitValue: UnitString) {
    // Unitless, percent and currency can be multiplied to anything
    const universalUnitTypes: UnitType[] = [
      /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
      SIMPLE_UNIT_TYPES.UNITLESS,
      /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
      SIMPLE_UNIT_TYPES.PERCENT,
      ...CURRENCY_UNIT_TYPES,
    ];
    const isUniversalUnitValue = !!universalUnitTypes.find(
      (u) => u.value === unitValue,
    );
    if (isUniversalUnitValue) {
      return UNIT_TYPES_SORTED;
    }

    let possibleOutputUnits = universalUnitTypes;
    const FEET_EXP_ZERO_FIVE_PER_SECOND_UNIT_TYPE = UNIT_TYPES_LIST.find(
      (ut) =>
        ut.value === `${SimpleUnits.FEET_EXP_ZERO_FIVE}/${SimpleUnits.SECONDS}`,
    );

    switch (unitValue) {
      /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
      case SIMPLE_UNIT_TYPES.FEET_EXP_TWO_FIVE.value:
        /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
        possibleOutputUnits.push(SIMPLE_UNIT_TYPES.FEET_EXP_ZERO_FIVE);
        FEET_EXP_ZERO_FIVE_PER_SECOND_UNIT_TYPE &&
          possibleOutputUnits.push(FEET_EXP_ZERO_FIVE_PER_SECOND_UNIT_TYPE);
        break;
      /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
      case SIMPLE_UNIT_TYPES.FEET_EXP_ZERO_FIVE.value:
        /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
        possibleOutputUnits.push(SIMPLE_UNIT_TYPES.FEET_EXP_TWO_FIVE);
        break;
      case `${SimpleUnits.FEET_EXP_ZERO_FIVE}/${SimpleUnits.SECONDS}`:
        /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
        possibleOutputUnits.push(SIMPLE_UNIT_TYPES.FEET_EXP_TWO_FIVE);
        break;
      /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
      case SIMPLE_UNIT_TYPES.SQUARE_FEET.value:
        /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
        possibleOutputUnits.push(SIMPLE_UNIT_TYPES.FEET);
        break;
      /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
      case SIMPLE_UNIT_TYPES.FEET.value:
        /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
        possibleOutputUnits.push(SIMPLE_UNIT_TYPES.FEET);
        /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
        possibleOutputUnits.push(SIMPLE_UNIT_TYPES.SQUARE_FEET);
        break;
      default:
        // Can multiply a complex unit to anything that can be converted to its denominator,
        // for example, "grams/gallon" can be multiplied to gallons, liters or fluid ounces.
        if (this.isComplexUnit(unitValue)) {
          // BTU/SCF is a valid measurement,
          // but since BTU and SCF are also related units – need to treat "BTU/SCF" specially
          if (unitValue === `${SimpleUnits.BTU}/${SimpleUnits.SCF}`) {
            /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
            possibleOutputUnits.push(SIMPLE_UNIT_TYPES.SCF);
            break;
          }
          const [, denominator] = splitComplexUnitIntoSimpleUnits(unitValue);
          possibleOutputUnits = possibleOutputUnits.concat(
            this.getRelatedUnits(denominator),
          );
          // Non-complex unit can be multiplied on a complex unit with related denominator,
          // for example, "dry oz" can be multiplied on "cm3/oz" to get "cm3"
        } else {
          const relatedUnits = this.getRelatedUnits(unitValue);
          const relatedUnitValues = relatedUnits.map((u) => u.value);
          const complexUnits = COMPLEX_UNIT_TYPES_LIST.filter((unitType) => {
            const [, denominatorValue] = splitComplexUnitIntoSimpleUnits(
              unitType.value,
            );

            return relatedUnitValues.includes(denominatorValue);
          });
          possibleOutputUnits = possibleOutputUnits.concat(complexUnits);
        }
    }

    return possibleOutputUnits;
  }

  // Assumption: only two factors can be multiplied
  static getMultiplicableUnitsForEquation(factors: { units: UnitString }[]) {
    const firstUnitValue = factors[0]?.units;
    const secondUnitValue = factors[1]?.units;

    // Wait until both factors are selected
    if (!firstUnitValue || !secondUnitValue) return [];

    // When multiplying on unitless, resulting unit stays original
    const unitless: UnitString[] = [
      /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
      SIMPLE_UNIT_TYPES.UNITLESS.value,
      /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
      SIMPLE_UNIT_TYPES.PERCENT.value,
    ];
    const firstRelatedUnits = this.getRelatedUnits(firstUnitValue);
    if (unitless.includes(secondUnitValue)) {
      return firstRelatedUnits;
    }
    const secondRelatedUnits = this.getRelatedUnits(secondUnitValue);
    if (unitless.includes(firstUnitValue)) {
      return secondRelatedUnits;
    }

    const firstUnitType = UNIT_TYPES_LIST.find(
      (u) => u.value === firstUnitValue,
    );
    const secondUnitType = UNIT_TYPES_LIST.find(
      (u) => u.value === secondUnitValue,
    );

    if (
      (!firstUnitType && !this.isComplexUnit(firstUnitValue)) ||
      (!secondUnitType && !this.isComplexUnit(secondUnitValue))
    )
      throw new Error('one of the factors is invalid');

    // When multiplied to currency, the result is in that currency
    if (firstUnitType?.measure === Measurements.CURRENCY) {
      return [firstUnitType];
    }
    if (secondUnitType?.measure === Measurements.CURRENCY) {
      return [secondUnitType];
    }

    // Special exponent use case
    if (
      /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
      (firstUnitValue === SIMPLE_UNIT_TYPES.FEET_EXP_TWO_FIVE.value &&
        secondUnitValue ===
          `${SimpleUnits.FEET_EXP_ZERO_FIVE}/${SimpleUnits.SECONDS}`) ||
      (firstUnitValue ===
        `${SimpleUnits.FEET_EXP_ZERO_FIVE}/${SimpleUnits.SECONDS}` &&
        /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
        secondUnitValue === SIMPLE_UNIT_TYPES.FEET_EXP_TWO_FIVE.value)
    ) {
      const CUBIC_FEET_PER_SECOND = UNIT_TYPES_LIST.find(
        (ut) => ut.value === `${SimpleUnits.CUBIC_FEET}/${SimpleUnits.SECONDS}`,
      );
      return [CUBIC_FEET_PER_SECOND];
    }
    // Regular exponent use cases which rely on unit type's exponentBase and raisedTo fields
    const isExponentMultiplication =
      firstUnitType &&
      secondUnitType &&
      (firstUnitValue === secondUnitValue ||
        firstUnitType.exponentBase ||
        secondUnitType.exponentBase);
    // Find existing unit with the same exponent base unit and "raised to" value resulted from the multiplication
    if (isExponentMultiplication) {
      const resultRaiseTo =
        (firstUnitType.raisedTo || 1) + (secondUnitType.raisedTo || 1);
      const exponentOutputUnit = UNIT_TYPES_LIST.find((u) => {
        const theSameBase =
          u.exponentBase === firstUnitType.exponentBase ||
          u.exponentBase === firstUnitType.value;
        return theSameBase && u.raisedTo === resultRaiseTo;
      });
      return exponentOutputUnit ? [exponentOutputUnit] : [];
    }

    // Complex units can be multiplied only on their denominator,
    // Resulting equation unit is anything related to complex unit numerator
    if (this.isComplexUnit(firstUnitValue)) {
      const [numerator] = splitComplexUnitIntoSimpleUnits(firstUnitValue);
      return this.getRelatedUnits(numerator);
    }
    if (this.isComplexUnit(secondUnitValue)) {
      const [numerator] = splitComplexUnitIntoSimpleUnits(secondUnitValue);
      return this.getRelatedUnits(numerator);
    }

    return [];
  }

  static getDivisibleUnitsForUnit(unitValue: UnitString) {
    /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
    let possibleOutputUnits: UnitType[] = [SIMPLE_UNIT_TYPES.UNITLESS];

    // Can divide to itself to get unitless or percent result
    const relatedToSelf = this.getRelatedUnits(unitValue);
    possibleOutputUnits = possibleOutputUnits.concat(relatedToSelf);

    // Can't divide complex units even further
    if (this.isComplexUnit(unitValue)) {
      return possibleOutputUnits;
    }

    // Available division operation by measurement group:
    // - volume/mass
    // - volume/time – flow rate
    // - mass/volume – concentration
    // - mass/area
    const numeratorUnitType = UNIT_TYPES_LIST.find(
      (v) => v.value === unitValue,
    );
    const simpleUnitTypes = Object.values(SIMPLE_UNIT_TYPES);
    let denominatorMeasurementGroups = [] as Measurements[];
    if (numeratorUnitType?.measure === Measurements.VOLUME) {
      denominatorMeasurementGroups = [Measurements.MASS, Measurements.TIME];
    } else if (numeratorUnitType?.measure === Measurements.MASS) {
      denominatorMeasurementGroups = [Measurements.VOLUME, Measurements.AREA];
    }
    if (denominatorMeasurementGroups.length) {
      possibleOutputUnits = possibleOutputUnits.concat(
        simpleUnitTypes.filter((u) =>
          u.measure ? denominatorMeasurementGroups.includes(u.measure) : false,
        ),
      );
    }

    // Division operation can also output in existing complex units,
    // Thus possible units are denominators of existing complex units
    const possibleDenominators = COMPLEX_UNIT_TYPES_LIST.reduce(
      (acc, unitType) => {
        const [numeratorValue, denominatorValue] =
          splitComplexUnitIntoSimpleUnits(unitType.value);

        if (unitValue !== numeratorValue) {
          return acc;
        }
        const denominatorUnitType = simpleUnitTypes.find(
          (u) => u.value === denominatorValue,
        );
        if (!denominatorUnitType) return acc;
        return [...acc, denominatorUnitType];
      },
      [] as UnitType[],
    );
    possibleOutputUnits = possibleOutputUnits.concat(possibleDenominators);

    // Can divide by a complex unit if its numerator is the same as the first unit in division equation
    // for example, grams / (grams/gallon) = gallons
    const possibleComplexUnits = COMPLEX_UNIT_TYPES_LIST.filter((unitType) => {
      const [numeratorValue] = splitComplexUnitIntoSimpleUnits(unitType.value);
      return numeratorValue === unitValue;
    });
    possibleOutputUnits = possibleOutputUnits.concat(possibleComplexUnits);

    // Special use cases for division calculations which result in units unnecessary for Factors or Log Items
    switch (unitValue) {
      /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
      case SIMPLE_UNIT_TYPES.BTU.value:
      /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
      // eslint-disable next line no-fallthrough
      case SIMPLE_UNIT_TYPES.MMBTU.value:
        /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
        possibleOutputUnits.push(SIMPLE_UNIT_TYPES.SCF);
        break;
      default:
        break;
    }

    return possibleOutputUnits;
  }

  // Assumption: only two factors can be part of division operation
  static getDivisibleUnitsForEquation(
    factors: { units: UnitString }[],
  ): UnitType[] {
    const firstUnitValue = factors[0]?.units;
    const secondUnitValue = factors[1]?.units;

    // Wait until both factors are selected
    if (!firstUnitValue || !secondUnitValue) return [];

    // When dividing on unitless, resulting unit stays original
    /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
    if (secondUnitValue === SIMPLE_UNIT_TYPES.UNITLESS.value) {
      return this.getRelatedUnits(firstUnitValue);
    }

    // When dividing a unit to itself (or related units), result is unitless or %
    if (this.areRelated(firstUnitValue, secondUnitValue)) {
      // BTU/SCF is a special case since both units are related but it's a valid measurement of heating value
      const btuNominators: UnitString[] = [SimpleUnits.BTU, SimpleUnits.MMBTU];
      const isBTUNominator = btuNominators.includes(firstUnitValue);
      /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
      const isSCFDenominator = secondUnitValue === SIMPLE_UNIT_TYPES.SCF.value;
      const isHeatingValueMeasure = isBTUNominator && isSCFDenominator;
      if (!isHeatingValueMeasure) {
        /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
        return [SIMPLE_UNIT_TYPES.UNITLESS, SIMPLE_UNIT_TYPES.PERCENT];
      }
    }

    // When dividing on complex unit, the result is its denominator
    // for example, grams / (grams/gallon) = gallons
    if (this.isComplexUnit(secondUnitValue)) {
      const [numeratorValue, denominatorValue] =
        splitComplexUnitIntoSimpleUnits(secondUnitValue);

      if (!this.areRelated(firstUnitValue, numeratorValue)) {
        return [];
      }
      return this.getRelatedUnits(denominatorValue);
    }

    // Cannot divide complex units further
    if (this.isComplexUnit(firstUnitValue)) {
      return [];
    }

    const outputUnitValue: ComplexUnitString = `${firstUnitValue}/${secondUnitValue}`;
    return this.getRelatedUnits(outputUnitValue);
  }

  static getExponentialUnitsForUnit() {
    return [SIMPLE_UNIT_TYPES.UNITLESS];
  }

  /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
  static getExponentialUnitsForEquation(factors, factorValuesMap) {
    if (factors.length <= 1) {
      return [];
    }
    const exponentBaseUnits = factors[0].units;
    const exponentRaisedToValue = factorValuesMap[factors[1].resourceId];

    return UNIT_TYPES_LIST.filter(
      (unitType) =>
        unitType.exponentBase === exponentBaseUnits &&
        unitType.raisedTo === exponentRaisedToValue,
    );
  }

  // Returns whether any exponent unit has a given unit as its base for exponent.
  static canBeRaisedToExponent(unitValue: UnitString) {
    const potentialExponentUnit = UNIT_TYPES_LIST.find(
      (unitType) => unitType.exponentBase === unitValue,
    );
    return Boolean(potentialExponentUnit);
  }

  // Searches for all exponent units that have given unit as their base for exponent,
  // returns theirs unique "raisedTo" values
  static getSupportedExponentValuesForUnit(unitValue: UnitString) {
    return UNIT_TYPES_LIST.reduce((supported, unitType) => {
      if (
        !unitType.exponentBase ||
        unitType.exponentBase !== unitValue ||
        !unitType.raisedTo ||
        supported.includes(unitType.raisedTo)
      ) {
        return supported;
      }
      return [...supported, unitType.raisedTo];
    }, [] as number[]);
  }
}
