import { ApiError, BaseService } from './BaseService';

class DocumentService extends BaseService {
  create(projectId, folderSlug, document, shouldBeCacheable = true) {
    const url = `${this.apiUrl}/api/v2/${projectId}/documents/${folderSlug}?cacheable=${shouldBeCacheable}`;
    const formData = new FormData();
    formData.append('file', document);

    return fetch(url, {
      credentials: 'include',
      method: 'POST',
      body: formData,
    }).then((result) => {
      if (result.ok) {
        return result.json();
      }
      throw new ApiError('failed to create document');
    });
  }

  getLocation(projectId) {
    const url = `${this.apiUrl}/api/v2/${projectId}/documents/location`;
    return fetch(url, { credentials: 'include' }).then((result) => {
      if (result.ok) {
        return result.json();
      }
      throw new ApiError('failed to get document location');
    });
  }

  getAll(projectId, folderSlug, shouldBeCacheable = true) {
    const url = `${this.apiUrl}/api/v2/${projectId}/documents/${folderSlug}?cacheable=${shouldBeCacheable}`;
    return fetch(url, { credentials: 'include' }).then((result) => {
      if (result.ok) {
        return result.json();
      }
      throw new ApiError('failed to get documents');
    });
  }

  copy(
    projectId,
    sourceFolderSlug,
    destinationFolderSlug,
    shouldBeCacheable = true,
  ) {
    const url = `${this.apiUrl}/api/v2/${projectId}/documents/copy/${sourceFolderSlug}/${destinationFolderSlug}?cacheable=${shouldBeCacheable}`;
    return fetch(url, { credentials: 'include', method: 'PUT' }).then(
      (result) => {
        if (result.ok) {
          return result.json();
        }
        throw new ApiError('failed to get documents');
      },
    );
  }

  update(document, shouldBeCacheable = true) {
    const url = `${this.apiUrl}/api/v2/${document.projectId}/documents?cacheable=${shouldBeCacheable}`;
    return fetch(url, {
      credentials: 'include',
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(document),
    }).then((result) => {
      if (result.ok) {
        return result.json();
      }
      throw new ApiError('failed to update document');
    });
  }

  updateResource(document, shouldBeCacheable = true) {
    const url = `${this.apiUrl}/api/v2/${document.projectId}/documents/resource?cacheable=${shouldBeCacheable}`;
    return fetch(url, {
      credentials: 'include',
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(document),
    }).then((result) => {
      if (result.ok) {
        return result.json();
      }
      throw new ApiError('failed to update document or resource');
    });
  }

  remove(document) {
    const url = `${this.apiUrl}/api/v2/${document.projectId}/documents/${document.folderSlug}/${document.fileId}`;
    return fetch(url, {
      credentials: 'include',
      method: 'DELETE',
    }).then((result) => {
      if (result.ok) {
        return result.json();
      }
      throw new ApiError('failed to remove document');
    });
  }
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default DocumentService;
