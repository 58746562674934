import React from 'react';
import cn from 'classnames';

type FormProps = {
  children: NonNullable<React.ReactNode>;
  className: string;
  id?: string;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function Form(props: FormProps) {
  const { children, className, id, onSubmit } = props;
  return (
    <form
      id={id}
      className={cn('form--wrapper', className)}
      onSubmit={onSubmit}
    >
      {children}
    </form>
  );
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default Form;
