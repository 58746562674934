import React, { FC, useContext } from 'react';
import { Popper } from '@material-ui/core';
import { ModalDialogContext } from './Modal';

// defined here since Modal is not TypeScript
type ModalDialogContextType = {
  isInModal: boolean;
};

type MapistryPopperProps = {
  anchorEl?: Element | (() => Element) | null;
  children: NonNullable<React.ReactNode>;
  disablePortal?: boolean;
  open: boolean;
};

const MapistryPopper: FC<MapistryPopperProps> = (
  props: MapistryPopperProps,
) => {
  const { anchorEl, children, disablePortal, open } = props;

  const modalDialogContext =
    useContext<ModalDialogContextType>(ModalDialogContext);
  const isInModal = modalDialogContext && modalDialogContext.isInModal;

  // Fix when displaying the Popper inside a modal.
  // Modal has zIndex of 1300, Popper by default does not.
  // When portal is enabled the popper will be rendered after the modal so the same zIndex works so
  // it does not break other modals (like date selector) from displaying on top of it.
  const style = isInModal && !disablePortal ? { zIndex: 1300 } : undefined;

  return (
    <Popper
      anchorEl={anchorEl}
      disablePortal={disablePortal}
      open={open}
      style={style}
    >
      {children}
    </Popper>
  );
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default MapistryPopper;
