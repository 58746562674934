import { ComplianceCalendarEventResponse } from 'mapistry-shared';
import qs from 'qs';
import http from '../httpClient';

export type FetchComplianceCalendarPathParams = {
  projectId: string;
};

export type FetchComplianceCalendarSearchParams = {
  startDate: string;
  endDate: string;
};

export type FetchComplianceCalendarEventsParams =
  FetchComplianceCalendarPathParams & FetchComplianceCalendarSearchParams;

export type FetchComplianceCalendarEventsResponse =
  ComplianceCalendarEventResponse;

export async function fetchComplianceCalendarEvents({
  projectId,
  startDate,
  endDate,
}: FetchComplianceCalendarEventsParams) {
  const res = await http.get<ComplianceCalendarEventResponse>(
    `/api/v2/projects/${projectId}/compliance-calendar/events?${qs.stringify({
      startDate,
      endDate,
    })}`,
  );
  return res.data;
}
