import React, { FC } from 'react';
import { FallbackProps } from 'react-error-boundary';
import Button from '../buttons/Button';

export const CardContentErrorFallback: FC<FallbackProps> = ({
  resetErrorBoundary,
}) => (
  <div className="mapistry-card__error-boundary">
    <div className="mapistry-card__error-boundary__text">
      Something went wrong here. Try reloading the page.
    </div>
    <Button
      className="mapistry-card__error-boundary__button"
      onClick={resetErrorBoundary}
      color="secondary"
    >
      Reload this widget
    </Button>
  </div>
);
