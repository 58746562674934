import { ErrorResponse } from '@monorepo/shared/apiClient/types';
import { ErrorBoundary } from '@monorepo/shared/componentsV2/ErrorBoundary';
import { UserFriendlyErrorMessage } from '@monorepo/shared/componentsV2/errorDisplays/UserFriendlyErrorMessage';
import * as Sentry from '@sentry/browser';
import React, { useCallback, useState } from 'react';
import { MessageBox, MessageBoxStatus } from '../MessageBox';
import { FlatfileUploadModal } from './FlatfileUploadModal';
import { InteractiveFileUploadPlaceholderModal } from './InteractiveFileUploadPlaceholderModal';

type FlatfileUploadModalProps = React.ComponentProps<
  typeof FlatfileUploadModal
>;

type InteractiveFileUploadModalProps = Omit<
  FlatfileUploadModalProps,
  'onHandshakeComplete' | 'onInitializationError'
> & {
  // When Flatfile is instantiated it will use the config it was instantiated with,
  // any changes to that config won't affect how Flatfile instance behave.
  // For this reason we pass only final settings our FlatfileUploadModal wrapper component.
  // If you need to fetch some settings information from the server – set isLoading to true,
  // this will postpone Flatfile instantiation.
  isLoading: boolean;
  isOpen: boolean;
  loadingError?: ErrorResponse | null;
};

export function InteractiveFileUploadModal(
  props: InteractiveFileUploadModalProps,
) {
  const { isLoading, isOpen, loadingError, onClose, workbook, ...restProps } =
    props;

  // Flatfile needs to send a "handshake" server request on initiation
  // to confirm that our license key is valid. Their upload modal won't open while it waits for the response,
  // that's why we continue showing a loader until Flatfile is fully ready.
  const [isFlatfileInstantiated, setIsFlatfileInstantiated] = useState(false);
  const handleHandshakeComplete = () => setIsFlatfileInstantiated(true);
  const [initializationError, setInitializationError] = useState<
    Error | undefined
  >();

  const handleInitializationError = useCallback(
    (err: unknown) => {
      Sentry.captureException(err, {
        tags: { library: 'Flatfile' },
      });

      if (err instanceof Error) {
        setInitializationError(err);
      } else {
        setInitializationError(
          new Error(`Couldn't initialize uploading modal`),
        );
      }
    },
    [setInitializationError],
  );
  const handleClose = () => {
    onClose();
    setIsFlatfileInstantiated(false);
    setInitializationError(undefined);
  };

  const ErrorFallback = useCallback(
    ({ error }) => (
      <InteractiveFileUploadPlaceholderModal
        onClose={onClose}
        title={`Error with ${workbook.name}`}
        isOpen={isOpen}
        error={error}
      />
    ),
    [isOpen, onClose, workbook.name],
  );

  // Not rendering anything when modal is closed, so the next time it's open
  // we re-instantiate Flatfile with potentially updated props
  if (!isOpen) {
    return null;
  }

  const showTempModal = !isFlatfileInstantiated || !!initializationError;
  const canInstantiateFlatfile = !isLoading && !loadingError;

  return (
    <ErrorBoundary Fallback={ErrorFallback}>
      <>
        {showTempModal && (
          <InteractiveFileUploadPlaceholderModal
            title={workbook.name}
            isOpen={isOpen}
            onClose={handleClose}
            isLoading={isLoading}
            error={
              loadingError
                ? new Error('Unable to load the data required for file upload')
                : initializationError
            }
          />
        )}
        {loadingError && (
          <MessageBox
            message={
              <UserFriendlyErrorMessage
                error={loadingError}
                prependedText="Unable to load the data required for file upload."
              />
            }
            status={MessageBoxStatus.STATUS_ERROR}
          />
        )}
        {canInstantiateFlatfile && (
          <FlatfileUploadModal
            workbook={workbook}
            onClose={handleClose}
            onHandshakeComplete={handleHandshakeComplete}
            onInitializationError={handleInitializationError}
            {...restProps}
          />
        )}
      </>
    </ErrorBoundary>
  );
}
