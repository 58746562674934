import {
  CreateLogRequest,
  LogInfoResponse,
  LogRefResponse,
  LogResponse,
  LogSiteRequirementsSummary,
  LogWithSiteConfigCount,
  UpdateLogRequest,
} from 'mapistry-shared';
import http from '../httpClient';

export interface FetchLogsParams {
  organizationId: string;
}

export type FetchLogsResponse = LogRefResponse[];

export async function fetchLogs({
  organizationId,
}: FetchLogsParams): Promise<LogRefResponse[]> {
  const res = await http.get<FetchLogsResponse>(
    `/api/v2/organizations/${organizationId}/logs`,
  );
  return res.data;
}

export interface FetchLogsWithSiteConfigParams {
  organizationId: string;
  projectId: string;
}

export type FetchLogsWithSiteConfigResponse = LogWithSiteConfigCount[];

export async function fetchLogsWithSiteConfig({
  organizationId,
  projectId,
}: FetchLogsWithSiteConfigParams): Promise<FetchLogsWithSiteConfigResponse> {
  const res = await http.get<FetchLogsWithSiteConfigResponse>(
    `/api/v2/organizations/${organizationId}/projects/${projectId}/logs-meta/config-summary`,
  );
  return res.data;
}

export interface FetchLogParams {
  logId: string;
  organizationId: string;
}

export type FetchLogResponse = LogRefResponse;

export async function fetchLog({
  logId,
  organizationId,
}: FetchLogParams): Promise<LogRefResponse> {
  const res = await http.get<FetchLogResponse>(
    `/api/v2/organizations/${organizationId}/logs/${logId}`,
  );

  return res.data;
}

export interface FetchLogInfoParams {
  logId: string;
  organizationId: string;
}

export type FetchLogInfoResponse = LogInfoResponse;

export async function fetchLogInfo({
  logId,
  organizationId,
}: FetchLogInfoParams): Promise<LogInfoResponse> {
  const res = await http.get<FetchLogInfoResponse>(
    `/api/v2/organizations/${organizationId}/logs/${logId}/meta/log-info`,
  );

  return res.data;
}

export interface FetchLogSiteSummaryParams {
  logId: string;
  organizationId: string;
}

export type FetchLogSiteSummaryResponse = LogSiteRequirementsSummary[];

export async function fetchLogSiteSummary({
  logId,
  organizationId,
}: FetchLogSiteSummaryParams): Promise<LogSiteRequirementsSummary[]> {
  const res = await http.get<FetchLogSiteSummaryResponse>(
    `/api/v2/organizations/${organizationId}/logs/${logId}/meta/site-config-summary`,
  );

  return res.data;
}

export type CreateLogParams = { log: CreateLogRequest; organizationId: string };
export type CreateLogResponse = LogResponse;

export async function createLog({ log, organizationId }: CreateLogParams) {
  const res = await http.post<CreateLogResponse>(
    `/api/v2/organizations/${organizationId}/logs`,
    log,
  );
  return res.data;
}

export type UpdateLogParams = { log: UpdateLogRequest; organizationId: string };
export type UpdateLogResponse = LogResponse;

export async function updateLog({ log, organizationId }: UpdateLogParams) {
  const res = await http.put<UpdateLogResponse>(
    `/api/v2/organizations/${organizationId}/logs/${log.id}`,
    log,
  );
  return res.data;
}

export type DeleteLogRequest = { organizationId: string; logId: string };
export type DeleteLogResponse = string;

export async function deleteLog({ logId, organizationId }: DeleteLogRequest) {
  const res = await http.delete<DeleteLogResponse>(
    `/api/v2/organizations/${organizationId}/logs/${logId}`,
  );
  return res.data;
}
