import PendoManager from '@monorepo/old-web/js/managers/pendoManager';
import { FullScreenLoadingPage } from '@monorepo/shared/componentsV2/Loading';
import { useIsRecoveryQuestionSet } from '@monorepo/shared/hooks/pageSetup/useIsRecoveryQuestionSet';
import { useSetupSentry } from '@monorepo/shared/hooks/pageSetup/useSetupSentry';
import { useVortexSetupVars } from '@monorepo/shared/hooks/pageSetup/useVortexSetupVars';
import { useCurrentUserPermissions } from '@monorepo/shared/hooks/permissions/useHasPermissions';
import { useCurrentUser } from '@monorepo/shared/hooks/useCurrentUser';
import { VortexSetupVars } from 'mapistry-shared';
import { useFeatureFlagsQuery } from 'mapistry-shared/api';
import React, { createContext, useContext, useMemo } from 'react';

type AuthContextType = {
  currentUser: Exclude<
    ReturnType<typeof useCurrentUser>['currentUser'],
    undefined
  >;
} & VortexSetupVars;

export const AuthContext = createContext<AuthContextType | undefined>(
  undefined,
);

type AuthProviderProps = {
  children: React.ReactNode;
};

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const {
    error: setupVarsError,
    isLoading: setupVarsAreLoading,
    setupVars,
  } = useVortexSetupVars();

  // permissions and feature flags might decide what the user can see
  // to prevent visual jiggle after either request returns
  // prevent the entire page from loading until permissions/flags got loaded
  const { error: featureFlagsError, isLoading: featureFlagsAreLoading } =
    useFeatureFlagsQuery();

  const {
    error: projectPermissionsError,
    isLoading: projectPermissionsAreLoading,
  } = useCurrentUserPermissions();

  const {
    currentUser,
    isLoading: currentUserIsLoading,
    error: currentUserError,
  } = useCurrentUser();

  const {
    isRecoveryQuestionSet,
    isLoading: recoveryQuestionIsLoading,
    error: recoveryQuestionError,
  } = useIsRecoveryQuestionSet();

  useSetupSentry();

  const errorFetchingData =
    currentUserError ||
    featureFlagsError ||
    projectPermissionsError ||
    setupVarsError ||
    recoveryQuestionError;

  const isLoading =
    currentUserIsLoading ||
    featureFlagsAreLoading ||
    projectPermissionsAreLoading ||
    setupVarsAreLoading ||
    recoveryQuestionIsLoading;

  if (currentUser?.id && !PendoManager.isInitialized(currentUser)) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    PendoManager.configurePendo(currentUser);
  }

  const authContextValue = useMemo(
    () =>
      setupVars && currentUser ? { currentUser, ...setupVars } : undefined,
    [currentUser, setupVars],
  );

  if (isLoading) {
    return <FullScreenLoadingPage />;
  }

  if (currentUserError && !currentUser?.id) {
    window.location.href = '/login';
    return null;
  }

  if (!isLoading && !isRecoveryQuestionSet) {
    window.location.href = '/set-recovery-question';
    return null;
  }

  if (errorFetchingData) {
    throw errorFetchingData;
  }

  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  );
};

export function useAppContext(): AuthContextType;
export function useAppContext(
  purposefullyUsedFromOldApp: true,
): AuthContextType | undefined;
export function useAppContext(
  purposefullyUsedFromOldApp = false,
): AuthContextType | undefined {
  const context = useContext(AuthContext);
  if (context === undefined && !purposefullyUsedFromOldApp) {
    throw new Error('useAppContext must be used within a AuthProvider');
  }
  return context;
}
