import { KeyService } from '@monorepo/shared/KeyService';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { isNullOrUndefined } from '../../utils';
import AnimatedComponent, { ANIMATION_TYPES } from './AnimatedComponent';
import Menu from './Menu';
import { ArrowDropDown, ArrowRight } from './MuiIcon';

class AccordionContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isExpanded: props.isExpanded,
    };
    this.preventToggle = this.preventToggle.bind(this);
    this.toggleIsExpanded = this.toggleIsExpanded.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    const { isExpanded } = this.props;
    const isLocalExpanded = prevState.isExpanded;
    if (!isNullOrUndefined(isExpanded)) {
      if (isExpanded !== isLocalExpanded) {
        this.setState({ isExpanded });
      }
    }
  }

  preventToggle(e) {
    e.stopPropagation();
  }

  toggleIsExpanded() {
    const { onToggle } = this.props;
    this.setState(
      (prevState) => ({ isExpanded: !prevState.isExpanded }),
      onToggle,
    );
  }

  render() {
    const {
      animationType,
      children,
      className,
      onDelete,
      onEdit,
      titleClassName,
      title,
    } = this.props;
    const { isExpanded } = this.state;
    const expandIcon = isExpanded ? <ArrowDropDown /> : <ArrowRight />;

    const menuOptions = [];
    if (onEdit !== AccordionContainer.defaultProps.onEdit) {
      menuOptions.push({
        label: 'Edit',
        iconIdentifier: 'edit',
        onClick: onEdit,
      });
    }
    if (onDelete !== AccordionContainer.defaultProps.onDelete) {
      menuOptions.push({
        label: 'Delete',
        iconIdentifier: 'trash',
        onClick: onDelete,
        danger: true,
      });
    }

    const childrenComponent = animationType ? (
      <div className="accordion-content">
        <AnimatedComponent isVisible={isExpanded} type={animationType}>
          {children}
        </AnimatedComponent>
      </div>
    ) : (
      <div className={`accordion-content${!isExpanded ? ' invisible' : ''}`}>
        {children}
      </div>
    );

    return (
      <div className={`accordion-container ${className || ''}`}>
        <div
          role="button" // Should just be a <button>, but that messes up css I don't want to fix right now.
          tabIndex={0}
          className={`accordion-title ${titleClassName || ''}`}
          onClick={this.toggleIsExpanded}
          onKeyDown={(e) => {
            if (KeyService.triggersButtonClick(e.keyCode)) {
              this.toggleIsExpanded();
            }
          }}
          aria-expanded={isExpanded}
        >
          <div className="accordion-arrow">{expandIcon}</div>
          <div className="flex items-center justify-between w-100">
            {title}
            {!!menuOptions.length && (
              // TODO: Fix this the next time the file is edited.
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
              <div onClick={this.preventToggle}>
                <Menu options={menuOptions} />
              </div>
            )}
          </div>
        </div>
        {childrenComponent}
      </div>
    );
  }
}

AccordionContainer.propTypes = {
  animationType: PropTypes.oneOf(Object.values(ANIMATION_TYPES)),
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  isExpanded: PropTypes.bool,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  onToggle: PropTypes.func,
  title: PropTypes.node.isRequired,
  titleClassName: PropTypes.string,
};

AccordionContainer.defaultProps = {
  animationType: null,
  className: null,
  isExpanded: undefined,
  onDelete: undefined,
  onEdit: undefined,
  onToggle: undefined,
  titleClassName: null,
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default AccordionContainer;
