import { Button, Loading } from '@monorepo/shared/components';
import { useProjectUserOptions } from '@monorepo/shared/hooks/useProjectUserOptions';
import { SaveState } from '@monorepo/shared/types/SaveState';
import React from 'react';
import APP from '../../../config';
import { AnimatedComponent, Modal, SaveButton } from '../../elements';
import { ANIMATION_TYPES } from '../../elements/AnimatedComponent';
import { useCurrentLogProject } from '../LogProjectContext';
import Form from './EditLogModalForm';
import OverWriteDataConfirmation from './EditLogModalOverwriteConfirmation';
import { EditLogModalProps } from './EditLogModalTypes';

const EditLogModal = (props: EditLogModalProps) => {
  const {
    didWriteValues,
    formDraft,
    formErrors,
    formState,
    loggedItemGroups,
    navigateToSettings,
    onClose,
    onDateChange,
    onEdit,
    onOverwriteCancel,
    onOverwriteProceed,
    onSave,
    onTimeChange,
    title = '',
    willOverwriteValues,
    originalReportingUserId,
  } = props;

  const { logProject, isLoading: isLogProjectLoading } = useCurrentLogProject();
  const logProjectStart = logProject?.startDate;

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/destructuring-assignment
  const isLoading = isLogProjectLoading || props.isLoading;

  const { userOptions = [] } = useProjectUserOptions({
    projectId: APP.projectId,
    previouslySavedUserId: originalReportingUserId,
  });

  const explanationText = () => (
    <AnimatedComponent
      isVisible={didWriteValues}
      type={ANIMATION_TYPES.FADE_IN_OUT}
    >
      <span className="air-emission-log-modal__explanation">
        New values have been loaded for this date and time
      </span>
    </AnimatedComponent>
  );

  const header = (
    <div className="air-emission-log-modal-header">
      <h4 className="modal-title">{`${title} Tracking Log`}</h4>
    </div>
  );

  const footer = !willOverwriteValues && (
    <div className="air-emission-log-modal-footer__content">
      {explanationText()}
      <div className="modal-footer__actions">
        <Button color="secondary" onClick={onClose}>
          {[SaveState.SAVED, SaveState.CLEAN].includes(formState)
            ? 'Close'
            : 'Cancel'}
        </Button>
        <span className="ph2" />
        <SaveButton onSave={onSave} saveState={formState} />
      </div>
    </div>
  );

  const noLoggedItems = () =>
    isLoading ? null : (
      <div className="air-emission-log-modal__no-items-container">
        <div className="margin-16">
          You have no emission logged items at this time.
        </div>
        <Button color="secondary" onClick={navigateToSettings}>
          Edit your emission logs
        </Button>
      </div>
    );

  const formOrOverwriteConfirmation = () =>
    willOverwriteValues ? (
      <OverWriteDataConfirmation
        onOverwriteCancel={onOverwriteCancel}
        onOverwriteProceed={onOverwriteProceed}
      />
    ) : (
      <Form
        formErrors={formErrors}
        formDraft={formDraft}
        logProjectStart={logProjectStart}
        onEdit={onEdit}
        onDateChange={onDateChange}
        onTimeChange={onTimeChange}
        userOptions={userOptions}
        loggedItemGroups={loggedItemGroups}
      />
    );

  return (
    /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
    <Modal
      footer={footer}
      fullWidth={false}
      header={header}
      maxWidth="md"
      onClose={onClose}
      open
    >
      <div className="air-emission-log-modal">
        <div className="air-emission-log-modal__body">
          {isLoading && <Loading />}
          {formDraft?.items ? formOrOverwriteConfirmation() : noLoggedItems()}
        </div>
      </div>
    </Modal>
  );
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default EditLogModal;
