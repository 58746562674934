import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup as MuiRadioGroup,
} from '@material-ui/core';
import RadioButtonChecked from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import React from 'react';

interface Option {
  disabled?: boolean;
  label: React.ReactNode;
  value: unknown;
}

interface RadioGroupProps {
  className?: string;
  error?: boolean;
  groupName?: string;
  helperText?: string;
  label?: string;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    option: Option,
  ) => void;
  options?: Option[];
  value?: unknown;
  vertical?: boolean;
}

export const RadioGroup = ({
  className,
  error = false,
  groupName,
  helperText,
  label,
  onChange,
  options = [],
  value,
  vertical = false,
}: RadioGroupProps) => {
  const propClass = className ? ` ${className}` : '';
  const orientationClass = vertical ? 'vertical' : 'horizontal';
  const formattedValue = value == null ? '' : value;

  return (
    <FormControl className={`mapistry-radio-group${propClass}`} error={error}>
      {label ? <FormLabel>{label}</FormLabel> : null}
      <MuiRadioGroup
        aria-label={label}
        className={`group-wrapper ${orientationClass}`}
        name={groupName}
        value={formattedValue}
      >
        {options.map((option) => (
          <FormControlLabel
            key={`${option.value}`}
            value={option.value}
            className="item"
            classes={{ label: 'item-label' }}
            control={
              <Radio
                icon={<RadioButtonUnchecked className="unchecked" />}
                checkedIcon={<RadioButtonChecked className="checked" />}
                className="radio-button"
                onChange={(e) => onChange?.(e, option)}
              />
            }
            label={<span>{option.label}</span>}
            disabled={option.disabled}
          />
        ))}
      </MuiRadioGroup>
      {helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
    </FormControl>
  );
};
