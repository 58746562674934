import { FetchCurrentUserPermissionsResponse } from '@monorepo/shared/apiClient/currentUserPermissions';
import { useCurrentUser } from '@monorepo/shared/hooks/useCurrentUser';
import {
  getUserPermissions,
  PermissionCategory,
} from '@monorepo/shared/utils/permissions';
import { Permissions } from 'mapistry-shared';
import { useQuery } from 'react-query';
import { Api } from '../../apiClient';

export const createKey = () => ['user-permissions'] as const;

type Fetcher = Api.DataHookQueryFn<
  typeof createKey,
  typeof Api.fetchCurrentUserPermissions
>;

export const fetcher: Fetcher =
  (): Promise<FetchCurrentUserPermissionsResponse> =>
    Api.fetchCurrentUserPermissions();

export function useCurrentUserPermissions() {
  const key = createKey();
  const config = {
    cacheTime: Infinity,
    staleTime: Infinity,
  };
  const { data, ...queryInfo } = useQuery(key, fetcher, config);

  return {
    ...queryInfo,
    userPermissions: data,
  };
}

function useHasPermissions(
  permission: Permissions,
  projectOrOrgId?: string,
  permissionCategory?: PermissionCategory,
): boolean {
  const { currentUser, isLoading: isLoadingCurrentUser } = useCurrentUser();
  const { userPermissions, isLoading: isLoadingPermissions } =
    useCurrentUserPermissions();

  if (
    isLoadingCurrentUser ||
    isLoadingPermissions ||
    !currentUser ||
    !userPermissions
  ) {
    return false;
  }

  const projectPermissions = getUserPermissions(
    userPermissions,
    currentUser.id,
    projectOrOrgId,
    permissionCategory,
  );
  const hasPermission = projectPermissions.includes(permission);

  return hasPermission;
}

export function useHasProjectSettingsPermissions(projectId?: string): boolean {
  return useHasPermissions(Permissions.PROJECT_DETAILS_UPDATE, projectId);
}

export function useHasProjectUpdatePermissions(projectId?: string): boolean {
  return useHasPermissions(Permissions.PROJECT_DATA_UPDATE, projectId);
}

export function useHasProjectViewPermissions(projectId?: string): boolean {
  return useHasPermissions(Permissions.PROJECT_DETAILS_READ, projectId);
}

export function useHasOrganizationUpdatePermissions(
  organizationId?: string,
): boolean {
  return useHasPermissions(
    Permissions.ORGANIZATION_UPDATE,
    organizationId,
    PermissionCategory.ORGANIZATION,
  );
}

export function useHasOrgUpdatePermissionOrSuperAdmin(
  organizationId?: string,
): boolean {
  const { currentUser } = useCurrentUser();
  const hasPermission = useHasPermissions(
    Permissions.ORGANIZATION_UPDATE,
    organizationId,
    PermissionCategory.ORGANIZATION,
  );

  return hasPermission || !!currentUser?.is_super_admin;
}
