import React from 'react';
import cn from 'classnames';

interface ToggleSwitchProps {
  isChecked: boolean;
  highContrast?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

function ToggleSwitch(props: ToggleSwitchProps) {
  const { onChange, isChecked, highContrast } = props;
  return (
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label className="custom-switch">
      <input type="checkbox" checked={isChecked} onChange={onChange} />
      <span
        className={cn('custom-switch__slider', {
          'high-contrast': highContrast,
        })}
      />
    </label>
  );
}
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default ToggleSwitch;
