import styled from 'styled-components';
import { BaseButton } from '../BaseButton';

export const OutlinedButton = styled(BaseButton)`
  background-color: inherit;

  &:hover {
    box-shadow: 0 4px 8px ${({ theme }) => theme.colors.black30};
  }

  &.Mui-disabled {
    color: ${({ theme }) => theme.colors.gray999};
    border: 1px solid ${({ theme }) => theme.colors.gray999};

    & svg {
      fill: ${({ theme }) => theme.colors.gray999};
    }
  }
`;
