import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import cn from 'classnames';
import { IconButton } from '@monorepo/shared/components/buttons/IconButton';
import { Clear } from '@monorepo/old-web/js/components/elements/MuiIcon';

interface BaseDialogProps {
  buttons: React.ReactNode;
  children?: React.ReactNode;
  description?: string;
  onClose: () => void;
  open: boolean;
  showCloseButton?: boolean;
  title: string;
}

export function BaseDialog(props: BaseDialogProps) {
  const {
    children,
    buttons,
    open,
    title,
    description,
    onClose,
    showCloseButton,
  } = props;

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        fullWidth
        className="dialog-modal"
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle
          id="form-dialog-title"
          className={cn({ 'with-close-button': showCloseButton })}
        >
          {title}
        </DialogTitle>
        {showCloseButton && (
          <IconButton
            className="close-icon"
            onClick={onClose}
            title="Close dialog box"
          >
            <Clear />
          </IconButton>
        )}
        <DialogContent>
          {Boolean(description) && <p>{description}</p>}
          {children}
        </DialogContent>
        <DialogActions
          classes={{
            spacing: 'dialog-modal-actions',
          }}
        >
          {buttons}
        </DialogActions>
      </Dialog>
    </div>
  );
}
