/* eslint-disable */
let customError = require('custom-error');

module.exports = {
  AuthenticationError: customError('AuthenticationError'),
  UnauthorizedError: customError('UnauthorizedError'),
  PageNotFoundError: customError('PageNotFoundError'),
  GenericError: customError('GenericError'),
  RecoveryQuestionNotSetError: customError('RecoveryQuestionNotSetError'),
};
