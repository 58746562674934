import { Button as MuiButton } from '@material-ui/core';
import cn from 'classnames';
import React from 'react';

type MuiButtonProps = React.ComponentProps<typeof MuiButton>;

export type ButtonProps = {
  children: NonNullable<React.ReactNode>;
  className?: string;
  color?: 'primary' | 'secondary' | 'danger';
  size?: 'small' | 'medium' | 'large';
  disabled?: boolean;
  form?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  startIcon?: MuiButtonProps['startIcon'];
  type?: MuiButtonProps['type'];
  variant?: MuiButtonProps['variant'];
};

export const Button = (props: ButtonProps) => {
  const {
    className,
    color = 'primary',
    size = 'medium',
    children,
    disabled,
    form,
    onClick,
    startIcon,
    type,
    variant,
  } = props;

  return (
    <MuiButton
      className={cn([
        'mapistry-button',
        className,
        { [color || 'primary']: !disabled, disabled, text: variant === 'text' },
        size,
      ])}
      classes={{ label: 'mapistry-button__label' }}
      disabled={disabled}
      disableRipple
      form={form}
      onClick={onClick}
      startIcon={startIcon}
      type={type}
      variant={variant || 'contained'}
    >
      {children}
    </MuiButton>
  );
};
