/* eslint-disable */
// when editing this file please uncomment the above and fix the linting errors
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { SvgIcon } from '@monorepo/shared/components/icons/SvgIcon';

import Button from '../buttons/Button';
import IconButton from '../buttons/IconButton';
import MapistryTooltip from '../MapistryTooltip';

const TableAction = (props) => {
  const {
    actionType,
    onClick,
    editTooltipText,
    deleteTooltipText,
    disabledTooltipText,
    disabled,
  } = props;
  let color;
  let identifier;
  let tooltipText;

  switch (actionType) {
    case 'edit':
      color = 'primary';
      identifier = 'edit';
      tooltipText = editTooltipText || 'Edit';
      break;
    case 'delete':
      color = 'danger';
      identifier = 'trash';
      tooltipText = deleteTooltipText || 'Delete';
      break;
    default:
      throw new Error('TableAction actionType is invalid');
  }

  const buttons = (
    <Fragment>
      <IconButton
        className={`button-link ${actionType}__icon`}
        disabled={disabled}
        onClick={onClick}
      >
        <SvgIcon
          className="grow-large"
          identifier={identifier}
          tooltipTitle={tooltipText}
        />
      </IconButton>
      <Button
        className={`button-link ${actionType}__button`}
        color={color}
        disabled={disabled}
        onClick={onClick}
      >
        {tooltipText}
      </Button>
    </Fragment>
  );

  return disabledTooltipText ? (
    <MapistryTooltip title={disabledTooltipText} placement="top">
      <div>{buttons}</div>
    </MapistryTooltip>
  ) : (
    buttons
  );
};

TableAction.propTypes = {
  actionType: PropTypes.oneOf(['edit', 'delete']).isRequired,
  deleteTooltipText: PropTypes.string,
  disabledTooltipText: PropTypes.string,
  editTooltipText: PropTypes.string,
  disabled: PropTypes.bool,
};

TableAction.defaultProps = {
  deleteTooltipText: null,
  disabledTooltipText: null,
  editTooltipText: null,
  disabled: false,
};

export default TableAction;
