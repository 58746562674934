import { SaveState } from '@monorepo/shared/types/SaveState';
import update from 'immutability-helper';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';
import {
  batchUpdateEmissionLogsAction,
  fetchEmissionItemGroupsAction,
  fetchEmissionLogAction,
} from '../../../actions/air';
import CurrentUserContext from '../../../contexts/CurrentUserContext';
import {
  getIsFetching,
  getLog,
  getLoggedItemGroups,
} from '../../../selectors/genericLogs';
import {
  isNullOrUndefined,
  localEquivalentOfUTC,
  UTCEquivalentOfLocal,
} from '../../../utils';
import withProvider from '../../withProvider';
import { LogProjectProvider } from '../LogProjectContext';
import EditLogModal from './EditLogModal';
import { formatDatetime } from './logUploadHelpers';

class EditLogModalContainer extends Component {
  // eslint-disable-next-line react/static-property-placement
  static contextType = CurrentUserContext;

  constructor(props) {
    super(props);
    this.state = {
      didWriteValues: false,
      formErrors: {
        displayable: [],
      },
      formDraft: null,
      formState: SaveState.CLEAN,
      willOverwriteValues: false,
      originalReportingUserId: null,
    };
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleOverwriteCancel = this.handleOverwriteCancel.bind(this);
    this.handleOverwriteProceed = this.handleOverwriteProceed.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleTimeChange = this.handleTimeChange.bind(this);
  }

  componentDidMount() {
    const {
      fetchEmissionItemGroups,
      fetchLog,
      logProjectId,
      logDatetime,
      projectId,
    } = this.props;
    const now = new Date();
    now.setSeconds(0, 0);
    const datetime = logDatetime && logDatetime < now ? logDatetime : now;
    fetchEmissionItemGroups(projectId, logProjectId);
    fetchLog(
      projectId,
      UTCEquivalentOfLocal(datetime).toISOString(),
      logProjectId,
    );
  }

  componentDidUpdate(prevProps, prevState) {
    const { log, projectId, logProjectId, logDatetime } = this.props;
    const { formDraft } = this.state;

    // log is coming from redux store
    // projectId, logProjectId, and logDatetime are props the modal is launched with
    // we wait until log's properties match the ones the modal is launched with, indicating that
    //  the correct log for this component has been fetched and put in the redux store
    // the last 2 conditions make sure we're only calling setState when it's necessary
    if (
      log &&
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line react/prop-types
      projectId === log.projectId &&
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line react/prop-types
      logProjectId === log.logProjectId &&
      logDatetime?.getTime() === localEquivalentOfUTC(log.datetime).getTime() &&
      !!log.userId &&
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line react/destructuring-assignment
      !this.state.originalReportingUserId
    ) {
      this.setState({ originalReportingUserId: log.userId });
    }

    if (!_.isEqual(prevProps.log, log)) {
      if (!prevState.formDraft) {
        const datetime = log.datetime
          ? localEquivalentOfUTC(log.datetime)
          : new Date();
        this.setState({
          formDraft: {
            ...this.initializeForm(),
            logDate: datetime,
            logTime: datetime,
          },
        });
      } else if (
        !this.hasAnyValuesOutsideOfSubmitByApiOnlyGroups(formDraft.items) &&
        this.hasAnyValuesOutsideOfSubmitByApiOnlyGroups(log.loggedItems)
      ) {
        this.setState({
          didWriteValues: true,
          formDraft: { ...formDraft, ...this.initializeForm() },
        });
        setTimeout(() => {
          this.setState({ didWriteValues: false });
        }, 5000);
      } else if (
        this.hasAnyValuesOutsideOfSubmitByApiOnlyGroups(formDraft.items) &&
        this.hasAnyValuesOutsideOfSubmitByApiOnlyGroups(log.loggedItems)
      ) {
        this.setState({ willOverwriteValues: true });
      }
    }
    if (!_.isEmpty(prevProps.log) && log !== prevProps.log) {
      const datetime = log.datetime
        ? localEquivalentOfUTC(log.datetime)
        : new Date();
      this.setState({
        formDraft: {
          ...this.initializeForm(),
          logDate: datetime,
          logTime: datetime,
        },
      });
    }
    if (formDraft && !formDraft.userId) {
      const { currentUser } = this.context;
      this.setState({ formDraft: { ...formDraft, userId: currentUser.id } });
    }
  }

  handleDateChange(value) {
    const { fetchLog, logProjectId, projectId } = this.props;
    const { formDraft } = this.state;
    if (!value || Number.isNaN(value.getTime())) {
      this.setState({ formDraft: { ...formDraft, logDate: null } });
    } else {
      this.setState(
        {
          formErrors: { displayable: [] },
          formDraft: { ...formDraft, logDate: value },
        },
        () => {
          const datetime = formatDatetime(value, formDraft.logTime);
          if (datetime) {
            fetchLog(projectId, datetime, logProjectId);
          }
        },
      );
    }
  }

  handleTimeChange(value, textValue) {
    const { fetchLog, logProjectId, projectId } = this.props;
    const { formDraft } = this.state;
    if (isNullOrUndefined(value) || (textValue && textValue.includes('_'))) {
      this.setState({ formDraft: { ...formDraft, logTime: textValue } });
    } else {
      this.setState(
        {
          formErrors: { displayable: [] },
          formDraft: { ...formDraft, logTime: value },
        },
        () => {
          const datetime = formatDatetime(formDraft.logDate, value);
          if (datetime) {
            fetchLog(projectId, datetime, logProjectId);
          }
        },
      );
    }
  }

  handleEdit(key, value, itemId) {
    const { formDraft } = this.state;
    if (itemId) {
      const currentItem = formDraft.items[itemId];
      this.setState((prevState) =>
        update(prevState, {
          formState: { $set: SaveState.DIRTY },
          formDraft: {
            items: {
              $merge: {
                [itemId]: { ...currentItem, [key]: value },
              },
            },
          },
        }),
      );
    } else {
      this.setState({
        formState: SaveState.DIRTY,
        formDraft: { ...formDraft, [key]: value },
      });
    }
  }

  handleOverwriteCancel() {
    this.setState({ willOverwriteValues: false });
  }

  handleOverwriteProceed() {
    const { formDraft } = this.state;
    this.setState({
      formDraft: { ...formDraft, ...this.initializeForm() },
      willOverwriteValues: false,
    });
  }

  async handleSave() {
    const formErrors = this.validate();
    if (formErrors.displayable.length) {
      this.setState({ formErrors });
    } else {
      this.saveForm();
    }
  }

  getLoggedItemsOutsideOfSubmitByApiOnlyGroups(items) {
    const { loggedItemGroups } = this.props;

    const loggedItemsOutsideOfSubmitByApiOnlyGroups = items.filter((item) => {
      const group = loggedItemGroups[item.groupId];
      return !group?.submitByApiOnly;
    });

    return loggedItemsOutsideOfSubmitByApiOnlyGroups;
  }

  initializeForm() {
    const { currentUser } = this.context;
    const { log } = this.props;
    const { loggedItems = [] } = log;
    const userId = log.userId || currentUser.id;
    const loggedItemsWithoutApiOnlyGroups =
      this.getLoggedItemsOutsideOfSubmitByApiOnlyGroups(loggedItems);
    const items = loggedItemsWithoutApiOnlyGroups.reduce((acc, item) => {
      const { loggedItemId, ...logItem } = item;
      acc[loggedItemId] = logItem;
      return acc;
    }, {});

    return { userId, items };
  }

  hasAnyValuesOutsideOfSubmitByApiOnlyGroups(log) {
    return Object.keys(log).some((loggedItemId) => {
      const { value, groupId } = log[loggedItemId];
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line react/destructuring-assignment
      const group = this.props.loggedItemGroups[groupId];

      const hasValue = !isNullOrUndefined(value);
      const isInSubmitByApiOnlyGroup = group?.submitByApiOnly;

      return hasValue && !isInSubmitByApiOnlyGroup;
    });
  }

  saveForm() {
    const { batchUpdateEmissionLogs, logProjectId, onSave, projectId } =
      this.props;
    const { formDraft } = this.state;
    const logDatetime = formatDatetime(formDraft.logDate, formDraft.logTime);
    const logs = Object.entries(formDraft.items).map(
      ([loggedItemId, item]) => ({
        id: item.id,
        loggedItemId,
        logValue: item.value,
        logDatetime,
        units: item.units,
        userId: formDraft.userId,
      }),
    );
    batchUpdateEmissionLogs(projectId, logProjectId, logs)
      .then(() =>
        this.setState({ formState: SaveState.SAVED }, () => onSave(formDraft)),
      )
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        const err = JSON.parse(error);
        const displayableErrors = [
          'Something went wrong. Try again later or contact customer service.',
        ];
        if (err.message) {
          displayableErrors.push(`Error Message: ${err.message}`);
        }
        if (err.errors) {
          err.errors.forEach((e) => {
            if (e.detail) {
              displayableErrors.push(e.detail);
            }
          });
        }
        if (error)
          this.setState({
            formErrors: {
              displayable: displayableErrors,
            },
          });
      });
  }

  validate() {
    const { formDraft } = this.state;
    const errors = { displayable: [] };
    if (!formDraft.logDate) {
      errors.logDate = true;
      errors.displayable.push('You must provide a date');
    }
    if (!formDraft.logTime) {
      errors.logTime = true;
      errors.displayable.push('You must provide a time');
    }
    return errors;
  }

  render() {
    const {
      isLoading,
      loggedItemGroups,
      navigateToSettings,
      onClose,
      title,
      logProjectId,
    } = this.props;
    const {
      didWriteValues,
      formDraft,
      formErrors,
      formState,
      willOverwriteValues,
      originalReportingUserId,
    } = this.state;

    return (
      <LogProjectProvider logProjectId={logProjectId}>
        <EditLogModal
          didWriteValues={didWriteValues}
          formDraft={formDraft}
          formErrors={formErrors}
          formState={formState}
          loggedItemGroups={loggedItemGroups}
          isLoading={isLoading}
          navigateToSettings={navigateToSettings}
          onClose={onClose}
          onDateChange={this.handleDateChange}
          onEdit={this.handleEdit}
          onOverwriteCancel={this.handleOverwriteCancel}
          onOverwriteProceed={this.handleOverwriteProceed}
          onSave={this.handleSave}
          onTimeChange={this.handleTimeChange}
          originalReportingUserId={originalReportingUserId}
          title={title}
          willOverwriteValues={willOverwriteValues}
        />
      </LogProjectProvider>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  batchUpdateEmissionLogs: (projectId, logProjectId, logs) =>
    dispatch(batchUpdateEmissionLogsAction(projectId, logProjectId, logs)),
  fetchEmissionItemGroups: (projectId, logProjectId) =>
    dispatch(fetchEmissionItemGroupsAction(projectId, logProjectId)),
  fetchLog: (projectId, datetime, logProjectId) =>
    dispatch(fetchEmissionLogAction(projectId, datetime, logProjectId)),
});

const mapStateToProps = (state, ownProps) => {
  const { project } = state;
  const { logProjectId } = ownProps;
  return {
    isLoading: getIsFetching(state, 'airEmissionLog') || project.isFetching,
    log: getLog(state, logProjectId),
    loggedItemGroups: getLoggedItemGroups(state, logProjectId),
  };
};

EditLogModalContainer.propTypes = {
  batchUpdateEmissionLogs: PropTypes.func.isRequired,
  fetchEmissionItemGroups: PropTypes.func.isRequired,
  fetchLog: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  log: PropTypes.shape({
    datetime: PropTypes.string,
    loggedItems: PropTypes.arrayOf(PropTypes.shape({})),
    userId: PropTypes.string,
  }).isRequired,
  logDatetime: PropTypes.instanceOf(Date),
  loggedItemGroups: PropTypes.shape({}).isRequired,
  logProjectId: PropTypes.string.isRequired,
  navigateToSettings: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

EditLogModalContainer.defaultProps = {
  isLoading: false,
  logDatetime: null,
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default withProvider(
  connect(mapStateToProps, mapDispatchToProps)(EditLogModalContainer),
);
