/* eslint-disable */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { UserType } from '../../propTypes';
import { CancelIcon } from '../MuiIcon';
import UserName from './UserName';
import { KeyService } from '@monorepo/shared/KeyService';
import LoadingIndicator from '../LoadingIndicator';
import MapistryPopover from '../Popover';
import { TextInput } from '@monorepo/shared/components/TextInput';

const DROPDOWN_POSITION = {
  horizontal: 'left',
  vertical: 'bottom',
};

export default class UserSelect extends Component {
  state = {
    anchorEl: null,
    dropdownWidth: null,
    filter: '',
    open: false,
  };

  inputRef = React.createRef();

  handleOpenDropdown = (event) => {
    const { disabled } = this.props;
    if (disabled) {
      return;
    }

    const { currentTarget } = event;
    this.setState({
      anchorEl: currentTarget,
      dropdownWidth: currentTarget.clientWidth,
      open: true,
    });
  };

  handleCloseDropdown = () => {
    this.setState({ open: false });
    if (this.inputRef.current) {
      setTimeout(() => this.inputRef.current.blur(), 0);
    }
  };

  handleOnKeyDown = (event) => {
    if (KeyService.isTabKey(event.keyCode)) this.handleCloseDropdown();
  };

  handleFilterUsers = (event) => {
    const { currentTarget } = event;
    this.setState({ filter: currentTarget.value });
  };

  handleSelect = (user) => {
    const { onSelect } = this.props;
    this.setState({
      filter: '',
      open: false,
    });
    onSelect(user);
  };

  clearSelect = (event) => {
    event.stopPropagation();
    const { onSelect } = this.props;
    onSelect(null);
  };

  popoverTarget() {
    const { disabled, selected } = this.props;
    const { filter } = this.state;

    if (selected) {
      return (
        <div className="user-select__selected-container">
          <UserName
            className="user-select__selected-name"
            name={selected.name}
          />
          {!disabled && (
            <span
              className="user-select__selected-remove"
              onClick={this.clearSelect}
            >
              <CancelIcon className="user-select__selected-remove-icon" />
            </span>
          )}
        </div>
      );
    }
    return (
      <div className="user-select__input-container">
        <TextInput
          disabled={disabled}
          inputRef={this.inputRef}
          onChange={this.handleFilterUsers}
          onKeyDown={this.handleOnKeyDown}
          placeholder="Select a user"
          value={filter}
        />
      </div>
    );
  }

  selectOptions() {
    const { isLoading, users } = this.props;
    const { filter } = this.state;

    if (isLoading) {
      return (
        <LoadingIndicator
          className="user-select__loader"
          isAbsolute={false}
          size={30}
        />
      );
    }

    const filteredUsers = users.filter((user) =>
      user.name.toLowerCase().includes(filter.toLowerCase()),
    );
    if (!filteredUsers.length) {
      return <div className="user-select__empty">No users</div>;
    }
    return filteredUsers.map((user) => (
      <div
        className="user-select__option"
        key={user.userId}
        onClick={() => this.handleSelect(user)}
      >
        <UserName name={user.name} />
      </div>
    ));
  }

  render() {
    const { anchorEl, dropdownWidth, open } = this.state;

    return (
      <Fragment>
        <div onClick={this.handleOpenDropdown}>{this.popoverTarget()}</div>
        <MapistryPopover
          anchorEl={anchorEl}
          anchorOrigin={DROPDOWN_POSITION}
          disableAutoFocus
          disableEnforceFocus
          onClose={this.handleCloseDropdown}
          open={open}
        >
          <div
            className="user-select__popover"
            style={dropdownWidth ? { width: `${dropdownWidth}px` } : {}}
          >
            {this.selectOptions()}
          </div>
        </MapistryPopover>
      </Fragment>
    );
  }
}

UserSelect.propTypes = {
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  selected: UserType,
  users: PropTypes.arrayOf(UserType),
  onSelect: PropTypes.func.isRequired,
};

UserSelect.defaultProps = {
  disabled: false,
  isLoading: false,
  selected: null,
  users: [],
};
