import { Menu, MenuItem } from '@material-ui/core';
import AddIcon from '@svg/add.svg';
import DefaultIcon from '@svg/m-event-calendar-default.svg';
import ExpandIcon from '@svg/m-event-calendar-expand.svg';
import FailureIcon from '@svg/m-event-calendar-failure.svg';
import SuccessIcon from '@svg/m-event-calendar-success.svg';
import WarningIcon from '@svg/m-event-calendar-warning.svg';
import { CalendarEventStatus } from 'mapistry-shared';
import PropTypes from 'prop-types';
import React from 'react';
import { CalendarEventType } from '../../../propTypes';

const EventIcon = (props) => {
  const { iconIdentifier } = props;

  const iconIdentifierToIcon = {
    add: AddIcon,
    expand: ExpandIcon,
    success: SuccessIcon,
    failure: FailureIcon,
    warning: WarningIcon,
    default: DefaultIcon,
  };

  const Icon = iconIdentifierToIcon[iconIdentifier];

  return <Icon className="m-icon" />;
};

EventIcon.propTypes = {
  iconIdentifier: PropTypes.string.isRequired,
};

const EventChoiceMenu = (props) => {
  const {
    eventChoices,
    getAsNeededItemText,
    getRecurringEventItemText,
    menuAnchor,
    onClose,
    onEventClick,
    shouldRenderEvent,
  } = props;

  const asNeededIconIdentifier = (calendarEvent) => {
    if (
      !calendarEvent.attachments[0] || // wastewater events do this
      calendarEvent.attachments[0].isDefault
    ) {
      return 'add';
    }
    if (calendarEvent.status === CalendarEventStatus.COMPLETE) {
      return 'success';
    }
    return 'warning';
  };

  const asNeededStatusClass = (calendarEvent) => {
    if (
      !calendarEvent.attachments[0] || // wastewater events do this
      calendarEvent.attachments[0].isDefault
    ) {
      return '';
    }
    if (calendarEvent.status === CalendarEventStatus.COMPLETE) {
      return 'success';
    }
    return 'warn';
  };

  const asNeededAttributes = (calendarEvent) => ({
    iconIdentifier: asNeededIconIdentifier(calendarEvent),
    itemText: getAsNeededItemText(calendarEvent),
    statusClass: asNeededStatusClass(calendarEvent),
  });

  const recurringIconIdentifier = (calendarEvent) => {
    switch (calendarEvent.status) {
      case CalendarEventStatus.OVERDUE:
        return 'failure';
      case CalendarEventStatus.UPCOMING:
        return 'warning';
      case CalendarEventStatus.COMPLETE:
        return 'success';
      default:
        return 'default';
    }
  };

  const recurringStatusClass = (calendarEvent) => {
    switch (calendarEvent.status) {
      case CalendarEventStatus.OVERDUE:
        return 'danger';
      case CalendarEventStatus.UPCOMING:
        return 'warn';
      case CalendarEventStatus.COMPLETE:
        return 'success';
      default:
        return '';
    }
  };

  const recurringAttributes = (calendarEvent) => ({
    iconIdentifier: recurringIconIdentifier(calendarEvent),
    itemText: getRecurringEventItemText(calendarEvent),
    statusClass: recurringStatusClass(calendarEvent),
  });

  return (
    <Menu
      anchorEl={menuAnchor}
      className="mapistry-menu"
      onClose={onClose}
      open={!!menuAnchor}
    >
      {eventChoices
        .filter(
          (calendarEvent) =>
            shouldRenderEvent && shouldRenderEvent(calendarEvent),
        )
        .map((calendarEvent) => {
          const attributes = calendarEvent.dueDate
            ? recurringAttributes(calendarEvent)
            : asNeededAttributes(calendarEvent);
          return (
            <MenuItem
              key={calendarEvent.eventNumber}
              className={attributes.statusClass}
              onClick={() => onEventClick(calendarEvent)}
            >
              <EventIcon iconIdentifier={attributes.iconIdentifier} />
              <span className="event-choice-menu-item-text">
                {attributes.itemText}
              </span>
            </MenuItem>
          );
        })}
    </Menu>
  );
};

EventChoiceMenu.propTypes = {
  eventChoices: PropTypes.arrayOf(CalendarEventType),
  menuAnchor: PropTypes.shape({}),
  onClose: PropTypes.func.isRequired,
  onEventClick: PropTypes.func.isRequired,
  shouldRenderEvent: PropTypes.func,
  getAsNeededItemText: PropTypes.func.isRequired,
  getRecurringEventItemText: PropTypes.func.isRequired,
};

EventChoiceMenu.defaultProps = {
  eventChoices: [],
  menuAnchor: null,
  shouldRenderEvent: () => true,
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default EventChoiceMenu;
