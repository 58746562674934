import qs from 'qs';
import {
  CreateWastewaterSettingsBulk,
  FetchSamplingResultRequest,
  SamplingResult,
  UploadSamplingResultRequest,
} from 'mapistry-shared';
import http from '../httpClient';

export async function fetchSamplingResults(
  projectId: string,
  requestQuery: FetchSamplingResultRequest,
): Promise<SamplingResult | null> {
  const res = await http.get<SamplingResult | null>(
    `/api/v2/${projectId}/wastewater-sampling-results/exact-date?${qs.stringify(
      requestQuery,
    )}`,
  );
  return res.data;
}

export async function saveWastewaterSamplingResultsBulk(
  projectId: string,
  samplingResults: UploadSamplingResultRequest[],
): Promise<void> {
  const res = await http.post(
    `/api/v2/${projectId}/wastewater-sampling-events/bulk`,
    samplingResults,
  );
  return res.data;
}

export async function saveWastewaterParameterSettingsBulk(
  projectId: string,
  settings: CreateWastewaterSettingsBulk[],
): Promise<void> {
  const res = await http.post(
    `/api/v2/${projectId}/wastewater-sampling-parameters/bulk`,
    settings,
  );
  return res.data;
}
