/* eslint-disable */
// when editing this file please uncomment the above and fix the linting errors
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import MuiTextField from '@material-ui/core/TextField';
import { KeyService } from '@monorepo/shared/KeyService';
import { ReactRefType } from '../propTypes';

/**
 * @deprecated Use packages/shared/components/TextInput
 */
function TextField(props) {
  const {
    autoFocus,
    controlled,
    defaultValue,
    disabled,
    endAdornment,
    error,
    InputProps,
    inputRef,
    multiline,
    onBlur,
    onChange,
    placeholder,
    required,
    rowsMax,
    type,
    value,
    onEnterKey,
  } = props;

  // If this component has a defaultValue, render an uncontrolled input (w/o passing value).
  // If a default value is not passed, render a controlled input.
  // Never, ever pass both a defaultValue and a value prop at the same time.
  if (controlled && defaultValue) {
    throw new Error('Do not pass a defaultValue to a controlled component.');
  } else if (!controlled && value) {
    throw new Error('Do not pass a value to an uncontrolled component.');
  }

  let handleKeyDown = useCallback((e) => {
    if (onEnterKey && KeyService.isEnterKey(e.keyCode)) {
      return onEnterKey(e);
    }
  }, []);
  const onKeyDown = onEnterKey ? handleKeyDown : null;
  return controlled ? (
    <MuiTextField
      autoFocus={autoFocus}
      classes={{ root: 'mapistry-text-field' }}
      disabled={disabled}
      error={error}
      fullWidth
      InputProps={{
        className: 'mapistry-text-field-input',
        endAdornment,
        ...InputProps,
      }}
      inputRef={inputRef}
      multiline={multiline}
      onBlur={onBlur}
      onChange={onChange}
      onKeyDown={onKeyDown}
      placeholder={placeholder}
      required={required}
      rowsMax={rowsMax}
      type={type || 'text'}
      value={value ?? ''}
    />
  ) : (
    <MuiTextField
      autoFocus={autoFocus}
      classes={{ root: 'mapistry-text-field' }}
      defaultValue={defaultValue}
      disabled={disabled}
      error={error}
      fullWidth
      InputProps={{
        className: 'mapistry-text-field-input',
        endAdornment,
        ...InputProps,
      }}
      inputRef={inputRef}
      multiline={multiline}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
      placeholder={placeholder}
      required={required}
      rowsMax={rowsMax}
      type={type || 'text'}
    />
  );
}

TextField.propTypes = {
  autoFocus: PropTypes.bool,
  controlled: PropTypes.bool,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  endAdornment: PropTypes.node,
  error: PropTypes.bool,
  InputProps: PropTypes.shape({}),
  inputRef: ReactRefType,
  multiline: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onEnterKey: PropTypes.func,
};

TextField.defaultProps = {
  autoFocus: false,
  controlled: true,
  defaultValue: null,
  disabled: false,
  endAdornment: null,
  error: false,
  InputProps: null,
  inputRef: null,
  multiline: false,
  onBlur: null,
  onChange: null,
  placeholder: null,
  required: false,
  type: null,
  value: null,
  onEnterKey: null,
};

export default TextField;
