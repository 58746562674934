import { FrequencyContext } from '@monorepo/shared/contexts/FrequencyContext';
import {
  IntervalFrequencyEnum,
  IntervalFrequencyFactory,
} from 'mapistry-shared';
import React, { useContext, useMemo } from 'react';
import { isNullOrUndefined } from '../../utils';
import { DatePicker, Select } from '../elements';

type FrequencyDateSelectorProps = {
  disabled?: boolean;
  error?: boolean;
  isFixed?: boolean;
};

const FrequencyDateSelector = (props: FrequencyDateSelectorProps) => {
  const { disabled, error, isFixed } = props;

  const {
    alwaysUseDatePicker,
    dateOptions,
    minStartDate,
    onStartDateSelect,
    selectedFrequency,
    selectedStartDate,
  } = useContext(FrequencyContext);

  /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
  const formatDate = (date) =>
    `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;

  const formattedDateOptions = useMemo(
    () =>
      dateOptions?.dates.map((option) => ({
        value: option,
        label: formatDate(option),
      })),
    [dateOptions],
  );

  const selectedFormattedStartDate = useMemo(
    () =>
      selectedStartDate
        ? formattedDateOptions?.find(
            (o) => o.value.getTime() === selectedStartDate.getTime(),
          )
        : null,
    [selectedStartDate, formattedDateOptions],
  );

  let earliestStartDate = minStartDate;
  if (dateOptions?.dates.length) {
    /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
    earliestStartDate = new Date(dateOptions.dates[0].getTime());
  }

  const daily = IntervalFrequencyFactory.For(
    IntervalFrequencyEnum.DAY,
  ).valueOf();

  return !dateOptions ||
    dateOptions.intervalFrequency.numberOfEvents() === 0 ||
    dateOptions.intervalFrequency.valueOf() >= daily ? (
    <DatePicker
      autoOk
      disabled={
        disabled ||
        (!alwaysUseDatePicker && isNullOrUndefined(selectedFrequency))
      }
      minDate={earliestStartDate}
      onChange={(value) => onStartDateSelect(value)}
      value={selectedStartDate}
      error={error}
    />
  ) : (
    <Select
      isDisabled={disabled || isNullOrUndefined(selectedFrequency)}
      isFixed={isFixed}
      isClearable={false}
      onChange={(option) => onStartDateSelect(option.value)}
      options={formattedDateOptions}
      value={selectedFormattedStartDate}
      error={error}
    />
  );
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default FrequencyDateSelector;
