import React from 'react';
import InformationalText from './InformationalText';
import { EditLogModalProps, UserOption } from './EditLogModalTypes';

import { FieldWrapper, FormErrors, Select } from '../../elements';
import DateTimeFields from './EditLogModalDateFields';
import LoggedItems from './EditLogModalLoggedItems';

const Reporter = ({
  userOptions,
  onEdit,
  userId,
}: {
  userOptions: UserOption[];
  onEdit: EditLogModalProps['onEdit'];
  userId: string;
}) => (
  <FieldWrapper
    className="air-emission-log-modal__reporter"
    label="Person reporting"
    isRequired
  >
    <Select
      isClearable={false}
      options={userOptions}
      onChange={(option) => onEdit('userId', option?.value)}
      value={userOptions.find((user) => user.value === userId)}
    />
  </FieldWrapper>
);

const EditLogModalForm = ({
  formDraft,
  formErrors,
  logProjectStart,
  loggedItemGroups,
  onDateChange,
  onEdit,
  onTimeChange,
  userOptions,
}: Pick<
  EditLogModalProps,
  | 'formDraft'
  | 'formErrors'
  | 'loggedItemGroups'
  | 'onDateChange'
  | 'onEdit'
  | 'onTimeChange'
> & {
  logProjectStart?: Date;
  userOptions: UserOption[];
}) => (
  <>
    {formErrors.displayable.length > 0 && (
      <div className="form-errors__sticky">
        <FormErrors errors={formErrors.displayable} />
      </div>
    )}
    <InformationalText />
    <DateTimeFields
      formDraft={formDraft}
      logProjectStart={logProjectStart}
      formErrors={formErrors}
      onDateChange={onDateChange}
      onTimeChange={onTimeChange}
    />
    <Reporter
      userOptions={userOptions}
      onEdit={onEdit}
      userId={formDraft.userId}
    />
    <LoggedItems
      formDraft={formDraft}
      formErrors={formErrors}
      loggedItemGroups={loggedItemGroups}
      onEdit={onEdit}
    />
  </>
);

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default EditLogModalForm;
