/* eslint-disable */
const _ = require('underscore');

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function round(number, decimalPlaces) {
  const multiplier = Math.pow(10, decimalPlaces);
  return Math.round(Number(number) * multiplier) / multiplier;
}

function convertSetToArray(set) {
  const list = [];
  set.forEach((value) => list.push(value));
  return list;
}

function setArrayToSet(array, set) {
  if (!Array.isArray(array)) {
    set.add(array);
  } else {
    for (let item of array) {
      set.add(item);
    }
  }
}

function isNullOrUndefined(value) {
  return value == null;
}

const isNumeric = (fieldValue) => _.isFinite(fieldValue);

function isValidDate(value) {
  return value instanceof Date && !Number.isNaN(value.getTime());
}

function delayedModalClose(callbackFn) {
  setTimeout(callbackFn, 500);
}

/*
  This returns the browser equivalent date/time of the given UTC date from
  the server. Equivalent does not mean the same absolute time (that would require
  no conversion). It is the same relative time.
  For example: given then end of the day in UTC, it returns the end of the day
  in the local browser time. Given 2:00pm UTC, it returns 2:00pm browser time.
*/
/**
 * @deprecated Use function of the same name in mapistry-shared
 */
function localEquivalentOfUTC(utcDate) {
  if (isNullOrUndefined(utcDate)) {
    return null;
  }
  const date = typeof utcDate === 'string' ? new Date(utcDate) : utcDate;
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
    date.getUTCMilliseconds(),
  );
}

/**
 * @deprecated Use function of the same name in mapistry-shared
 */
function UTCEquivalentOfLocal(localDate) {
  if (isNullOrUndefined(localDate)) {
    return null;
  }
  const date = typeof localDate === 'string' ? new Date(localDate) : localDate;
  return new Date(
    Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
      date.getMilliseconds(),
    ),
  );
}

const fixedDecimalPlaces = (
  numberToFormat,
  numberOfDecimalPlaces,
  trimTrailingZeros = true,
) => {
  if (!isNumeric(numberToFormat)) {
    return numberToFormat;
  }
  const fixedNumber = Number.parseFloat(numberToFormat).toFixed(
    numberOfDecimalPlaces,
  );
  return trimTrailingZeros
    ? Number.parseFloat(fixedNumber).toString()
    : fixedNumber;
};

const delimitNumbers = (val) => {
  if (isNullOrUndefined(val)) {
    return null;
  }
  return (val + '').replace(/\b(\d+)((\.\d+)*)\b/g, function (a, b, c) {
    return (
      (b.charAt(0) > 0 && !(c || '.').lastIndexOf('.')
        ? b.replace(/(\d)(?=(\d{3})+$)/g, '$1,')
        : b) + c
    );
  });
};

function humanReadableNumber(val, numberOfDecimalPlaces = 2) {
  return delimitNumbers(fixedDecimalPlaces(val, numberOfDecimalPlaces));
}

module.exports = {
  capitalizeFirstLetter,
  convertSetToArray,
  delimitNumbers,
  delayedModalClose,
  fixedDecimalPlaces,
  humanReadableNumber,
  localEquivalentOfUTC,
  isNullOrUndefined,
  isNumeric,
  isValidDate,
  round,
  setArrayToSet,
  UTCEquivalentOfLocal,
};
