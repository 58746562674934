import Tooltip from '@material-ui/core/Tooltip';
import React from 'react';
import PropTypes from 'prop-types';

/**
 * @deprecated Use packages/shared/components/Tooltip
 */
/**
 * Wrapping function components with the MaterialUi Tooltip causes errors - see
 * https://github.com/mui-org/material-ui/issues/15663. Possible solutions:
 *  1. Wrap component with React.forwardRef: https://next--material-ui.netlify.com/guides/composition/#caveat-with-refs
 *  2. If that doesn't work, wrap the component with a plain div as mentioned in
 *    https://github.com/mui-org/material-ui/issues/15663#issuecomment-520778909 :
 *      <Tooltip>
 *        <div>
 *          <MyFunctionComponent />
 *        </div>
 *      </Tooltip
 */
function MapistryTooltip(props) {
  const { children, placement, title } = props;
  return (
    <Tooltip
      classes={{
        tooltip: 'tooltip-label',
        popper: 'mapistry-tooltip',
      }}
      placement={placement}
      title={title}
    >
      {children}
    </Tooltip>
  );
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default MapistryTooltip;

MapistryTooltip.defaultProps = {
  placement: 'bottom',
  title: '',
};

MapistryTooltip.propTypes = {
  children: PropTypes.element.isRequired,
  placement: PropTypes.oneOf([
    'bottom-end',
    'bottom-start',
    'bottom',
    'left-end',
    'left-start',
    'left',
    'right-end',
    'right-start',
    'right',
    'top-end',
    'top-start',
    'top',
  ]),
  title: PropTypes.node,
};
