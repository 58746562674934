import { CalculatedValue } from 'mapistry-shared';
import http from '../httpClient';

export interface FetchCalculatedValuesProps {
  logProjectId?: string;
  projectId?: string;
}

interface FetchCalculatedValuesResponse {
  logProjectId: string;
  records: CalculatedValue[];
}

export async function fetchCalculatedValues({
  logProjectId,
  projectId,
}: FetchCalculatedValuesProps): Promise<CalculatedValue[]> {
  const res = await http.get<FetchCalculatedValuesResponse>(
    `/api/v2/${projectId}/generic-logs/${logProjectId}/calculated-values`,
  );
  return res.data?.records;
}
