import { UnitString } from 'mapistry-shared';
import React, { useMemo } from 'react';
import {
  ChartType,
  MicroIntervalStatistics,
  ResourceKey,
} from '../../types/ts-types';
import { formattedCalendarDateFromEvent } from '../../utils/calendarEvents';
import { capitalizeFirstLetter } from '../../utils/shared';
import MultiAxisChart from './MultiAxisChart';

const COLORS = {
  BLUE: '#0AA5CE',
  PURPLE: '#9c32ff',
  YELLOW: '#F2C407',
  RED: '#FF4136',
};

type MultiAxisChartProps = React.ComponentProps<typeof MultiAxisChart>;

type ResourceKeyWithChartParams = ResourceKey & {
  unit: UnitString;
  chartType: ChartType;
};

type ChartParams = {
  /**
   * The associated resource micro interval statistics. This should be same order as
   *  props.resources. This should be non-empty.
   */
  microIntervalStatistics: MicroIntervalStatistics[];
  /**
   * The resources that will be charted. Axis ordering is inferred from this prop,
   *  with the first resource being the primary axis. This should be non-empty.
   */
  resources: ResourceKeyWithChartParams[];
};

type LoggedValuesOverTimeChartProps = ChartParams & {
  isLoading?: boolean;
};

function LoggedValuesOverTimeChart(props: LoggedValuesOverTimeChartProps) {
  const { resources, microIntervalStatistics, isLoading } = props;
  const chartData = useMemo(() => {
    // infer x axis from micro intervals on first statistics
    /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
    const { frequency } = microIntervalStatistics[0].intervalStatistics[0];
    const categories: string[] =
      /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
      microIntervalStatistics[0].intervalStatistics.map((s) =>
        formattedCalendarDateFromEvent({
          frequency,
          start: s.start,
        }),
      );
    const series: MultiAxisChartProps['series'] = [];
    const xaxis = {
      title: {
        text: `${capitalizeFirstLetter(frequency)}s`,
      },
    };
    const yaxis: MultiAxisChartProps['yaxis'] = [];
    const colors: string[] = [];

    resources.forEach((resourceKey, index) => {
      const ms = microIntervalStatistics[index];
      /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
      const data = ms.intervalStatistics.map((s) => s.stats.totalValue);
      /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
      const { label } = ms.intervalStatistics[0].stats;
      const seriesName = label;
      const { chartType } = resourceKey;
      series.push({
        name: seriesName,
        type: chartType,
        data,
      });
      const axis: MultiAxisChartProps['yaxis'] = {
        title: {
          text: label,
        },
      };
      if (index === 1) {
        axis.opposite = true;
      }
      yaxis.push(axis);
      const col = index === 1 ? COLORS.PURPLE : COLORS.BLUE;
      colors.push(col);
      /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
      const associatedLimitStat = ms.intervalStatistics.find(
        (s) => s.stats.limits?.[0]?.limitFrequency === s.frequency,
      )?.stats.limits?.[0];
      if (associatedLimitStat) {
        /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
        const limitValues = ms.intervalStatistics.map(
          () => associatedLimitStat.value,
        );
        series.push({
          name: associatedLimitStat.label,
          type: 'line',
          data: limitValues,
        });
        yaxis.push({ seriesName, show: false });
        colors.push(COLORS.RED);
      }
    });

    return {
      xaxis,
      yaxis,
      categories,
      series,
      colors,
      plotOptions: {
        bar: {
          endingShape: 'rounded' as const,
          columnWidth: '40',
        },
      },
    };
  }, [resources, microIntervalStatistics]);
  return <MultiAxisChart {...chartData} isLoading={isLoading} />;
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default LoggedValuesOverTimeChart;
