import http from '../httpClient';
import { UnitConversion } from '../types';

export interface FetchUnitConversionsProps {
  logProjectId?: string;
  projectId?: string;
}

interface FetchUnitConversionsResponse {
  logProjectId: string;
  records: UnitConversion[];
}

export async function fetchUnitConversions({
  logProjectId,
  projectId,
}: FetchUnitConversionsProps): Promise<UnitConversion[]> {
  const res = await http.get<FetchUnitConversionsResponse>(
    `/api/v2/${projectId}/generic-logs/${logProjectId}/unit-conversions`,
  );
  return res.data?.records;
}
