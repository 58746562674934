const UserSettings = {
  AIR_EMISSION_CHART: 'airEmissionChart',
  AIR_EMISSION_LIMIT_MONITOR: 'airEmissionLimitMonitor',
  GENERIC_LOGS_CALENDAR: 'genericLogsCalendar',
  FLOW_LOG_CHART: 'flowLogChart',
  TASKS_CARD: 'tasksCard',
  DUPLICATED_WIDGETS: 'duplicatedWidgets',
  WIDGET_FILTERS: 'widgetFilters',
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default UserSettings;
