import styled from 'styled-components';
import { BaseButton } from '../BaseButton';

export const TextButton = styled(BaseButton)`
  background-color: inherit;

  &.Mui-disabled {
    color: ${({ theme }) => theme.colors.gray999};

    & svg {
      fill: ${({ theme }) => theme.colors.gray999};
    }
  }
`;
