import { handleActions } from 'redux-actions';
import update from 'immutability-helper';
import _ from 'underscore';
import {
  CREATE_EMISSION_CALCULATED_VALUE_REQUEST,
  CREATE_EMISSION_CALCULATED_VALUE_SUCCESS,
  CREATE_EMISSION_CALCULATED_VALUE_FAILURE,
  CREATE_EMISSION_FACTOR_REQUEST,
  CREATE_EMISSION_FACTOR_SUCCESS,
  CREATE_EMISSION_FACTOR_FAILURE,
  CREATE_EMISSION_ITEM_GROUP_REQUEST,
  CREATE_EMISSION_ITEM_GROUP_SUCCESS,
  CREATE_EMISSION_ITEM_GROUP_FAILURE,
  CREATE_EMISSION_LIMIT_ITEM_REQUEST,
  CREATE_EMISSION_LIMIT_ITEM_SUCCESS,
  CREATE_EMISSION_LIMIT_ITEM_FAILURE,
  CREATE_EMISSION_LOGGED_ITEM_REQUEST,
  CREATE_EMISSION_LOGGED_ITEM_SUCCESS,
  CREATE_EMISSION_LOGGED_ITEM_FAILURE,
  BATCH_UPDATE_EMISSION_LOGS_REQUEST,
  BATCH_UPDATE_EMISSION_LOGS_SUCCESS,
  BATCH_UPDATE_EMISSION_LOGS_FAILURE,
  CREATE_EMISSION_LOG_TABS_REQUEST,
  CREATE_EMISSION_LOG_TABS_SUCCESS,
  CREATE_EMISSION_LOG_TABS_FAILURE,
  CREATE_EMISSION_ROLLING_CALCULATION_REQUEST,
  CREATE_EMISSION_ROLLING_CALCULATION_SUCCESS,
  CREATE_EMISSION_ROLLING_CALCULATION_FAILURE,
  CREATE_EMISSION_UNIT_CONVERSION_REQUEST,
  CREATE_EMISSION_UNIT_CONVERSION_SUCCESS,
  CREATE_EMISSION_UNIT_CONVERSION_FAILURE,
  DELETE_EMISSION_CALCULATED_VALUE_REQUEST,
  DELETE_EMISSION_CALCULATED_VALUE_SUCCESS,
  DELETE_EMISSION_CALCULATED_VALUE_FAILURE,
  DELETE_EMISSION_FACTOR_REQUEST,
  DELETE_EMISSION_FACTOR_SUCCESS,
  DELETE_EMISSION_FACTOR_FAILURE,
  DELETE_EMISSION_ITEM_GROUP_REQUEST,
  DELETE_EMISSION_ITEM_GROUP_SUCCESS,
  DELETE_EMISSION_ITEM_GROUP_FAILURE,
  DELETE_EMISSION_LIMIT_ITEM_REQUEST,
  DELETE_EMISSION_LIMIT_ITEM_SUCCESS,
  DELETE_EMISSION_LIMIT_ITEM_FAILURE,
  DELETE_EMISSION_LOGGED_ITEM_REQUEST,
  DELETE_EMISSION_LOGGED_ITEM_SUCCESS,
  DELETE_EMISSION_LOGGED_ITEM_FAILURE,
  DELETE_EMISSION_LOG_TABS_REQUEST,
  DELETE_EMISSION_LOG_TABS_SUCCESS,
  DELETE_EMISSION_LOG_TABS_FAILURE,
  DELETE_EMISSION_LOG_REQUEST,
  DELETE_EMISSION_LOG_SUCCESS,
  DELETE_EMISSION_LOG_FAILURE,
  DELETE_EMISSION_ROLLING_CALCULATION_REQUEST,
  DELETE_EMISSION_ROLLING_CALCULATION_SUCCESS,
  DELETE_EMISSION_ROLLING_CALCULATION_FAILURE,
  DELETE_EMISSION_UNIT_CONVERSION_REQUEST,
  DELETE_EMISSION_UNIT_CONVERSION_SUCCESS,
  DELETE_EMISSION_UNIT_CONVERSION_FAILURE,
  FETCH_EMISSION_CALCULATED_VALUES_REQUEST,
  FETCH_EMISSION_CALCULATED_VALUES_SUCCESS,
  FETCH_EMISSION_CALCULATED_VALUES_FAILURE,
  FETCH_EMISSION_FACTORS_REQUEST,
  FETCH_EMISSION_FACTORS_SUCCESS,
  FETCH_EMISSION_FACTORS_FAILURE,
  FETCH_EMISSION_ITEM_GROUPS_REQUEST,
  FETCH_EMISSION_ITEM_GROUPS_SUCCESS,
  FETCH_EMISSION_ITEM_GROUPS_FAILURE,
  FETCH_EMISSION_LIMIT_ITEMS_REQUEST,
  FETCH_EMISSION_LIMIT_ITEMS_SUCCESS,
  FETCH_EMISSION_LIMIT_ITEMS_FAILURE,
  FETCH_EMISSION_LOG_REQUEST,
  FETCH_EMISSION_LOG_SUCCESS,
  FETCH_EMISSION_LOG_FAILURE,
  FETCH_EMISSION_LOGS_REQUEST,
  FETCH_EMISSION_LOGS_SUCCESS,
  FETCH_EMISSION_LOGS_FAILURE,
  FETCH_EMISSION_LOG_PARAMETERS_REQUEST,
  FETCH_EMISSION_LOG_PARAMETERS_SUCCESS,
  FETCH_EMISSION_LOG_PARAMETERS_FAILURE,
  FETCH_EMISSION_LOG_TABS_REQUEST,
  FETCH_EMISSION_LOG_TABS_SUCCESS,
  FETCH_EMISSION_LOG_TABS_FAILURE,
  FETCH_EMISSION_LOGGED_ITEMS_REQUEST,
  FETCH_EMISSION_LOGGED_ITEMS_SUCCESS,
  FETCH_EMISSION_LOGGED_ITEMS_FAILURE,
  FETCH_EMISSION_ROLLING_CALCULATIONS_REQUEST,
  FETCH_EMISSION_ROLLING_CALCULATIONS_SUCCESS,
  FETCH_EMISSION_ROLLING_CALCULATIONS_FAILURE,
  FETCH_EMISSION_UNIT_CONVERSIONS_REQUEST,
  FETCH_EMISSION_UNIT_CONVERSIONS_SUCCESS,
  FETCH_EMISSION_UNIT_CONVERSIONS_FAILURE,
  FETCH_EMISSION_STATISTICS_REQUEST,
  FETCH_EMISSION_STATISTICS_SUCCESS,
  FETCH_EMISSION_STATISTICS_FAILURE,
  FETCH_EMISSION_MICRO_INTERVAL_STATISTICS_REQUEST,
  FETCH_EMISSION_MICRO_INTERVAL_STATISTICS_SUCCESS,
  FETCH_EMISSION_MICRO_INTERVAL_STATISTICS_FAILURE,
  RESET_EMISSION_LOGS,
  UPDATE_EMISSION_CALCULATED_VALUE_REQUEST,
  UPDATE_EMISSION_CALCULATED_VALUE_SUCCESS,
  UPDATE_EMISSION_CALCULATED_VALUE_FAILURE,
  UPDATE_EMISSION_FACTOR_REQUEST,
  UPDATE_EMISSION_FACTOR_SUCCESS,
  UPDATE_EMISSION_FACTOR_FAILURE,
  UPDATE_EMISSION_ITEM_GROUP_REQUEST,
  UPDATE_EMISSION_ITEM_GROUP_SUCCESS,
  UPDATE_EMISSION_ITEM_GROUP_FAILURE,
  UPDATE_EMISSION_LIMIT_ITEM_REQUEST,
  UPDATE_EMISSION_LIMIT_ITEM_SUCCESS,
  UPDATE_EMISSION_LIMIT_ITEM_FAILURE,
  UPDATE_EMISSION_LOGGED_ITEM_REQUEST,
  UPDATE_EMISSION_LOGGED_ITEM_SUCCESS,
  UPDATE_EMISSION_LOGGED_ITEM_FAILURE,
  UPDATE_EMISSION_LOG_TABS_REQUEST,
  UPDATE_EMISSION_LOG_TABS_SUCCESS,
  UPDATE_EMISSION_LOG_TABS_FAILURE,
  UPDATE_EMISSION_ROLLING_CALCULATION_REQUEST,
  UPDATE_EMISSION_ROLLING_CALCULATION_SUCCESS,
  UPDATE_EMISSION_ROLLING_CALCULATION_FAILURE,
  UPDATE_EMISSION_TRACKING_CALENDARS,
  UPDATE_EMISSION_UNIT_CONVERSION_REQUEST,
  UPDATE_EMISSION_UNIT_CONVERSION_SUCCESS,
  UPDATE_EMISSION_UNIT_CONVERSION_FAILURE,
} from '../actions';
import { createKey } from '../utils/createKey';
import { failed, fetching, notFetching } from '.'; // eslint-disable-line import/no-cycle

const INITIAL_STATE = {
  airEmissions: {
    // TODO: this initial state is wrong, these are grouped by logProjectId now
    calculatedValues: [],
    emissionFactors: [],
    itemGroups: {},
    limitItems: [],
    loggedItems: [],
    logParameters: [],
    log: {},
    logs: [],
    logSaved: null,
    logTabs: [],
    statistics: {},
    microIntervalStatistics: {},
    projectHasLogs: false,
    rollingCalculations: [],
    unitConversions: [],
  },
  isFetching: false,
  materialProductionLogs: {},
  materialProductionLogsForChart: {},
  materialProductionLogsForEditing: [],
  materialLimitCalculatedValues: [],
  materialLimits: [],
  materialLimitsForChart: {},
};

const reducers = handleActions(
  {
    [CREATE_EMISSION_CALCULATED_VALUE_REQUEST]: (state) =>
      fetching(state, 'airEmissionsCalculatedValues'),
    [CREATE_EMISSION_CALCULATED_VALUE_SUCCESS]: (state, action) => {
      const { logProjectId } = action.payload;
      return notFetching(
        update(state, {
          airEmissions: {
            [logProjectId]: {
              calculatedValues: {
                $push: [action.payload],
              },
            },
          },
        }),
        'airEmissionsCalculatedValues',
      );
    },
    [CREATE_EMISSION_CALCULATED_VALUE_FAILURE]: (state, action) =>
      failed(
        notFetching(state, 'airEmissionsCalculatedValues'),
        action.payload,
      ),
    [CREATE_EMISSION_FACTOR_REQUEST]: (state) =>
      fetching(state, 'airEmissionsEmissionFactors'),
    [CREATE_EMISSION_FACTOR_SUCCESS]: (state, action) => {
      const { logProjectId } = action.payload;
      return notFetching(
        update(state, {
          airEmissions: {
            [logProjectId]: {
              emissionFactors: {
                $push: [action.payload],
              },
            },
          },
        }),
        'airEmissionsEmissionFactors',
      );
    },
    [CREATE_EMISSION_FACTOR_FAILURE]: (state, action) =>
      failed(notFetching(state, 'airEmissionsEmissionFactors'), action.payload),
    [CREATE_EMISSION_ITEM_GROUP_REQUEST]: (state) =>
      fetching(state, 'airEmissionsItemGroups'),
    [CREATE_EMISSION_ITEM_GROUP_SUCCESS]: (state, action) => {
      const { logProjectId } = action.payload;
      return notFetching(
        update(state, {
          airEmissions: {
            [logProjectId]: {
              itemGroups: {
                [action.payload.id]: {
                  $set: {
                    name: action.payload.name,
                    groupType: action.payload.groupType,
                  },
                },
              },
            },
          },
        }),
        'airEmissionsItemGroups',
      );
    },
    [CREATE_EMISSION_ITEM_GROUP_FAILURE]: (state, action) =>
      failed(notFetching(state, 'airEmissionsItemGroups'), action.payload),
    [CREATE_EMISSION_LIMIT_ITEM_REQUEST]: (state) =>
      fetching(state, 'airEmissionsMaterialLimits'),
    [CREATE_EMISSION_LIMIT_ITEM_SUCCESS]: (state, action) => {
      const { logProjectId } = action.payload;
      return notFetching(
        update(state, {
          airEmissions: {
            [logProjectId]: {
              limitItems: {
                $push: [action.payload],
              },
            },
          },
        }),
      );
    },
    [CREATE_EMISSION_LIMIT_ITEM_FAILURE]: (state, action) =>
      failed(notFetching(state, 'airEmissionsMaterialLimits'), action.payload),
    [CREATE_EMISSION_LOGGED_ITEM_REQUEST]: (state) =>
      fetching(state, 'airEmissionsLoggedItems'),
    [CREATE_EMISSION_LOGGED_ITEM_SUCCESS]: (state, action) => {
      const { logProjectId } = action.payload;
      return notFetching(
        update(state, {
          airEmissions: {
            [logProjectId]: {
              loggedItems: {
                $push: [action.payload],
              },
            },
          },
        }),
        'airEmissionsLoggedItems',
      );
    },
    [CREATE_EMISSION_LOGGED_ITEM_FAILURE]: (state, action) =>
      failed(notFetching(state, 'airEmissionsLoggedItems'), action.payload),

    [CREATE_EMISSION_LOG_TABS_REQUEST]: (state) =>
      fetching(state, 'airEmissionLogTabs'),
    [CREATE_EMISSION_LOG_TABS_SUCCESS]: (state, action) => {
      const { logProjectId } = action.payload;
      return notFetching(
        update(state, {
          airEmissions: {
            [logProjectId]: {
              logTabs: {
                $push: [action.payload],
              },
            },
          },
        }),
        'airEmissionLogTabs',
      );
    },
    [CREATE_EMISSION_LOG_TABS_FAILURE]: (state, action) =>
      failed(notFetching(state, 'airEmissionLogTabs'), action.payload),

    [BATCH_UPDATE_EMISSION_LOGS_REQUEST]: (state) =>
      fetching(state, 'airEmissionLogs'),
    [BATCH_UPDATE_EMISSION_LOGS_SUCCESS]: (state) =>
      notFetching(state, 'airEmissionLogs'),
    [BATCH_UPDATE_EMISSION_LOGS_FAILURE]: (state, action) =>
      failed(notFetching(state, 'airEmissionLogs'), action.payload),

    [CREATE_EMISSION_ROLLING_CALCULATION_REQUEST]: (state) =>
      fetching(state, 'airEmissionsRollingCalculations'),
    [CREATE_EMISSION_ROLLING_CALCULATION_SUCCESS]: (state, action) => {
      const { logProjectId } = action.payload;
      return notFetching(
        update(state, {
          airEmissions: {
            [logProjectId]: {
              rollingCalculations: {
                $push: [action.payload],
              },
            },
          },
        }),
        'airEmissionsRollingCalculations',
      );
    },
    [CREATE_EMISSION_ROLLING_CALCULATION_FAILURE]: (state, action) =>
      failed(
        notFetching(state, 'airEmissionsRollingCalculations'),
        action.payload,
      ),
    [CREATE_EMISSION_UNIT_CONVERSION_REQUEST]: (state) =>
      fetching(state, 'airEmissionsUnitConversions'),
    [CREATE_EMISSION_UNIT_CONVERSION_SUCCESS]: (state, action) => {
      const { logProjectId } = action.payload;
      return notFetching(
        update(state, {
          airEmissions: {
            [logProjectId]: {
              unitConversions: {
                $push: [action.payload],
              },
            },
          },
        }),
        'airEmissionsUnitConversions',
      );
    },
    [CREATE_EMISSION_UNIT_CONVERSION_FAILURE]: (state, action) =>
      failed(notFetching(state, 'airEmissionsUnitConversions'), action.payload),
    [DELETE_EMISSION_FACTOR_REQUEST]: (state) =>
      fetching(state, 'airEmissionsEmissionFactors'),
    [DELETE_EMISSION_FACTOR_SUCCESS]: (state, action) => {
      const { id, logProjectId } = action.payload;
      const deletedRecordIdx = state.airEmissions[
        logProjectId
      ].emissionFactors.findIndex((emissionFactor) => emissionFactor.id === id);
      return notFetching(
        update(state, {
          airEmissions: {
            [logProjectId]: {
              emissionFactors: {
                $splice: [[deletedRecordIdx, 1]],
              },
            },
          },
        }),
        'airEmissionsEmissionFactors',
      );
    },
    [DELETE_EMISSION_FACTOR_FAILURE]: (state, action) =>
      failed(notFetching(state, 'airEmissionsEmissionFactors'), action.payload),
    [DELETE_EMISSION_CALCULATED_VALUE_REQUEST]: (state) =>
      fetching(state, 'airEmissionsCalculatedValues'),
    [DELETE_EMISSION_CALCULATED_VALUE_SUCCESS]: (state, action) => {
      const { id, logProjectId } = action.payload;
      const deletedRecordIdx = state.airEmissions[
        logProjectId
      ].calculatedValues.findIndex(
        (calculatedValue) => calculatedValue.id === id,
      );
      return notFetching(
        update(state, {
          airEmissions: {
            [logProjectId]: {
              calculatedValues: {
                $splice: [[deletedRecordIdx, 1]],
              },
            },
          },
        }),
        'airEmissionsCalculatedValues',
      );
    },
    [DELETE_EMISSION_CALCULATED_VALUE_FAILURE]: (state, action) =>
      failed(
        notFetching(state, 'airEmissionsCalculatedValues'),
        action.payload,
      ),
    [DELETE_EMISSION_ITEM_GROUP_REQUEST]: (state) =>
      fetching(state, 'airEmissionsItemGroups'),
    [DELETE_EMISSION_ITEM_GROUP_SUCCESS]: (state, action) => {
      const { id, logProjectId } = action.payload;
      return notFetching(
        update(state, {
          airEmissions: {
            [logProjectId]: {
              itemGroups: {
                $set: _.omit(state.airEmissions[logProjectId].itemGroups, id),
              },
            },
          },
        }),
        'airEmissionsItemGroups',
      );
    },
    [DELETE_EMISSION_ITEM_GROUP_FAILURE]: (state, action) =>
      failed(notFetching(state, 'airEmissionsItemGroups'), action.payload),
    [DELETE_EMISSION_LIMIT_ITEM_REQUEST]: (state) =>
      fetching(state, 'airEmissionsMaterialLimits'),
    [DELETE_EMISSION_LIMIT_ITEM_SUCCESS]: (state, action) => {
      const { id, logProjectId } = action.payload;
      const deletedRecordIdx = state.airEmissions[
        logProjectId
      ].limitItems.findIndex((limitItem) => limitItem.id === id);
      return notFetching(
        update(state, {
          airEmissions: {
            [logProjectId]: {
              limitItems: {
                $splice: [[deletedRecordIdx, 1]],
              },
            },
          },
        }),
      );
    },
    [DELETE_EMISSION_LIMIT_ITEM_FAILURE]: (state, action) =>
      failed(notFetching(state, 'airEmissionsMaterialLimits'), action.payload),
    [DELETE_EMISSION_LOGGED_ITEM_REQUEST]: (state) =>
      fetching(state, 'airEmissionsLoggedItems'),
    [DELETE_EMISSION_LOGGED_ITEM_SUCCESS]: (state, action) => {
      const { id, logProjectId } = action.payload;
      const deletedRecordIdx = state.airEmissions[
        logProjectId
      ].loggedItems.findIndex((loggedItem) => loggedItem.id === id);
      return notFetching(
        update(state, {
          airEmissions: {
            [logProjectId]: {
              loggedItems: {
                $splice: [[deletedRecordIdx, 1]],
              },
            },
          },
        }),
        'airEmissionsLoggedItems',
      );
    },
    [DELETE_EMISSION_LOGGED_ITEM_FAILURE]: (state, action) =>
      failed(notFetching(state, 'airEmissionsLoggedItems'), action.payload),
    [DELETE_EMISSION_ROLLING_CALCULATION_REQUEST]: (state) =>
      fetching(state, 'airEmissionsRollingCalculations'),
    [DELETE_EMISSION_ROLLING_CALCULATION_SUCCESS]: (state, action) => {
      const { id, logProjectId } = action.payload;
      const deletedRecordIdx = state.airEmissions[
        logProjectId
      ].rollingCalculations.findIndex(
        (rollingCalculation) => rollingCalculation.id === id,
      );
      return notFetching(
        update(state, {
          airEmissions: {
            [logProjectId]: {
              rollingCalculations: {
                $splice: [[deletedRecordIdx, 1]],
              },
            },
          },
        }),
        'airEmissionsRollingCalculations',
      );
    },
    [DELETE_EMISSION_ROLLING_CALCULATION_FAILURE]: (state, action) =>
      failed(
        notFetching(state, 'airEmissionsRollingCalculations'),
        action.payload,
      ),
    [DELETE_EMISSION_UNIT_CONVERSION_REQUEST]: (state) =>
      fetching(state, 'airEmissionsUnitConversions'),
    [DELETE_EMISSION_UNIT_CONVERSION_SUCCESS]: (state, action) => {
      const { id, logProjectId } = action.payload;
      const deletedRecordIdx = state.airEmissions[
        logProjectId
      ].unitConversions.findIndex((unitConversion) => unitConversion.id === id);
      return notFetching(
        update(state, {
          airEmissions: {
            [logProjectId]: {
              unitConversions: {
                $splice: [[deletedRecordIdx, 1]],
              },
            },
          },
        }),
        'airEmissionsUnitConversions',
      );
    },
    [DELETE_EMISSION_UNIT_CONVERSION_FAILURE]: (state, action) =>
      failed(notFetching(state, 'airEmissionsUnitConversions'), action.payload),
    [DELETE_EMISSION_LOG_TABS_REQUEST]: (state) =>
      fetching(state, 'airEmissionLogTabs'),
    [DELETE_EMISSION_LOG_TABS_SUCCESS]: (state, action) => {
      const { id, logProjectId } = action.payload;
      const deletedRecordIdx = state.airEmissions[
        logProjectId
      ].logTabs.findIndex((logTab) => logTab.id === id);
      return notFetching(
        update(state, {
          airEmissions: {
            [logProjectId]: {
              logTabs: {
                $splice: [[deletedRecordIdx, 1]],
              },
            },
          },
        }),
        'airEmissionLogTabs',
      );
    },
    [DELETE_EMISSION_LOG_TABS_FAILURE]: (state, action) =>
      failed(notFetching(state, 'airEmissionLogTabs'), action.payload),

    [DELETE_EMISSION_LOG_REQUEST]: (state) =>
      fetching(state, 'airEmissionLogs'),
    [DELETE_EMISSION_LOG_SUCCESS]: (state) =>
      notFetching(state, 'airEmissionLogs'),
    [DELETE_EMISSION_LOG_FAILURE]: (state, action) =>
      failed(notFetching(state, 'airEmissionLogs'), action.payload),
    [FETCH_EMISSION_ITEM_GROUPS_REQUEST]: (state) =>
      fetching(state, 'airEmissionsItemGroups'),
    [FETCH_EMISSION_ITEM_GROUPS_SUCCESS]: (state, action) => {
      const { records, logProjectId } = action.payload;
      const nextItemGroups = records.reduce(
        (acc, itemGroup) => ({
          ...acc,
          [itemGroup.id]: {
            name: itemGroup.name,
            groupType: itemGroup.groupType,
            submitByApiOnly: itemGroup.submitByApiOnly,
          },
        }),
        {},
      );
      return notFetching(
        update(state, {
          airEmissions: {
            [logProjectId]: (obj) =>
              update(obj || {}, {
                $merge: {
                  itemGroups: nextItemGroups,
                },
              }),
          },
        }),
        'airEmissionsItemGroups',
      );
    },
    [FETCH_EMISSION_ITEM_GROUPS_FAILURE]: (state, action) =>
      failed(notFetching(state, 'airEmissionsItemGroups'), action.payload),
    [FETCH_EMISSION_LIMIT_ITEMS_REQUEST]: (state) =>
      fetching(state, 'airEmissionsMaterialLimits'),
    [FETCH_EMISSION_LIMIT_ITEMS_SUCCESS]: (state, action) => {
      const { records, logProjectId } = action.payload;
      return notFetching(
        update(state, {
          airEmissions: {
            [logProjectId]: (obj) =>
              update(obj || {}, {
                $merge: {
                  limitItems: records,
                },
              }),
          },
        }),
        'airEmissionsMaterialLimits',
      );
    },
    [FETCH_EMISSION_LIMIT_ITEMS_FAILURE]: (state, action) =>
      failed(notFetching(state, 'airEmissionsMaterialLimits'), action.payload),
    [FETCH_EMISSION_CALCULATED_VALUES_REQUEST]: (state) =>
      fetching(state, 'airEmissionsCalculatedValues'),
    [FETCH_EMISSION_CALCULATED_VALUES_SUCCESS]: (state, action) => {
      const { records, logProjectId } = action.payload;
      return notFetching(
        update(state, {
          airEmissions: {
            [logProjectId]: (obj) =>
              update(obj || {}, {
                $merge: {
                  calculatedValues: records,
                },
              }),
          },
        }),
        'airEmissionsCalculatedValues',
      );
    },
    [FETCH_EMISSION_CALCULATED_VALUES_FAILURE]: (state, action) =>
      failed(
        notFetching(state, 'airEmissionsCalculatedValues'),
        action.payload,
      ),
    [FETCH_EMISSION_FACTORS_REQUEST]: (state) =>
      fetching(state, 'airEmissionsEmissionFactors'),
    [FETCH_EMISSION_FACTORS_SUCCESS]: (state, action) => {
      const { records, logProjectId } = action.payload;
      return notFetching(
        update(state, {
          airEmissions: {
            [logProjectId]: (obj) =>
              update(obj || {}, {
                $merge: {
                  emissionFactors: records,
                },
              }),
          },
        }),
        'airEmissionsEmissionFactors',
      );
    },
    [FETCH_EMISSION_FACTORS_FAILURE]: (state, action) =>
      failed(notFetching(state, 'airEmissionsEmissionFactors'), action.payload),

    [FETCH_EMISSION_LOG_REQUEST]: (state) => fetching(state, 'airEmissionLog'),
    [FETCH_EMISSION_LOG_SUCCESS]: (state, action) => {
      const { record, logProjectId } = action.payload;
      return notFetching(
        update(state, {
          airEmissions: {
            [logProjectId]: (obj) =>
              update(obj || {}, {
                $merge: {
                  log: record,
                },
              }),
          },
        }),
        'airEmissionLog',
      );
    },
    [FETCH_EMISSION_LOG_FAILURE]: (state, action) =>
      failed(notFetching(state, 'airEmissionLog'), action.payload),

    [FETCH_EMISSION_LOGS_REQUEST]: (state) =>
      fetching(state, 'airEmissionLogs'),
    [FETCH_EMISSION_LOGS_SUCCESS]: (state, action) => {
      const { startDate, endDate, logProjectId, projectHasLogs } =
        action.payload;
      const logs = state.airEmissions[logProjectId]?.logs || [];
      const nestChildren = (emissionsLogs) =>
        emissionsLogs.map((log) => {
          let children;
          if (log.children) {
            // we need to go through all children because of weekly intervals crossing boundaries
            children = nestChildren(log.children);
          }
          if (log.start === startDate && log.end === endDate) {
            // sort by start date
            children = action.payload.logs.sort((log1, log2) =>
              log1.start < log2.start ? -1 : 1,
            );
          }

          return { ...log, children };
        });

      return notFetching(
        update(state, {
          airEmissions: {
            [logProjectId]: {
              projectHasLogs: { $set: projectHasLogs },
              logs: {
                $set: logs.length ? nestChildren(logs) : action.payload.logs,
              },
            },
          },
        }),
        'airEmissionLogs',
      );
    },
    [FETCH_EMISSION_LOGS_FAILURE]: (state, action) =>
      failed(notFetching(state, 'airEmissionLogs'), action.payload),
    [FETCH_EMISSION_LOG_PARAMETERS_REQUEST]: (state) =>
      fetching(state, 'airEmissionTrackingParametersTabs'),
    [FETCH_EMISSION_LOG_PARAMETERS_SUCCESS]: (state, action) => {
      const { records, logProjectId } = action.payload;
      return notFetching(
        update(state, {
          airEmissions: {
            [logProjectId]: (obj) =>
              update(obj || {}, {
                $merge: {
                  logParameters: records,
                },
              }),
          },
        }),
        'airEmissionTrackingParametersTabs',
      );
    },
    [FETCH_EMISSION_LOG_PARAMETERS_FAILURE]: (state, action) =>
      failed(
        notFetching(state, 'airEmissionTrackingParametersTabs'),
        action.payload,
      ),
    [FETCH_EMISSION_LOG_TABS_REQUEST]: (state) =>
      fetching(state, 'airEmissionLogTabs'),
    [FETCH_EMISSION_LOG_TABS_SUCCESS]: (state, action) => {
      const { records, logProjectId } = action.payload;
      return notFetching(
        update(state, {
          airEmissions: {
            [logProjectId]: (obj) =>
              update(obj || {}, {
                $merge: {
                  logTabs: records,
                },
              }),
          },
        }),
        'airEmissionLogTabs',
      );
    },
    [FETCH_EMISSION_LOG_TABS_FAILURE]: (state, action) =>
      failed(notFetching(state, 'airEmissionLogTabs'), action.payload),
    [FETCH_EMISSION_LOGGED_ITEMS_REQUEST]: (state) =>
      fetching(state, 'airEmissionsLoggedItems'),
    [FETCH_EMISSION_LOGGED_ITEMS_SUCCESS]: (state, action) => {
      const { records, logProjectId } = action.payload;
      // Consider moving the loading state indicator for this further down, so it can be
      // specific for a logProject
      return notFetching(
        update(state, {
          airEmissions: {
            [logProjectId]: (obj) =>
              update(obj || {}, {
                $merge: {
                  loggedItems: records,
                },
              }),
          },
        }),
        'airEmissionsLoggedItems',
      );
    },
    [FETCH_EMISSION_LOGGED_ITEMS_FAILURE]: (state, action) =>
      failed(notFetching(state, 'airEmissionsLoggedItems'), action.payload),
    [FETCH_EMISSION_ROLLING_CALCULATIONS_REQUEST]: (state) =>
      fetching(state, 'airEmissionsRollingCalculations'),
    [FETCH_EMISSION_ROLLING_CALCULATIONS_SUCCESS]: (state, action) => {
      const { records, logProjectId } = action.payload;
      return notFetching(
        update(state, {
          airEmissions: {
            [logProjectId]: (obj) =>
              update(obj || {}, {
                $merge: {
                  rollingCalculations: records,
                },
              }),
          },
        }),
        'airEmissionsRollingCalculations',
      );
    },
    [FETCH_EMISSION_ROLLING_CALCULATIONS_FAILURE]: (state, action) =>
      failed(
        notFetching(state, 'airEmissionsRollingCalculations'),
        action.payload,
      ),
    [FETCH_EMISSION_UNIT_CONVERSIONS_REQUEST]: (state) =>
      fetching(state, 'airEmissionsUnitConversions'),
    [FETCH_EMISSION_UNIT_CONVERSIONS_SUCCESS]: (state, action) => {
      const { records, logProjectId } = action.payload;
      return notFetching(
        update(state, {
          airEmissions: {
            [logProjectId]: (obj) =>
              update(obj || {}, {
                $merge: {
                  unitConversions: records,
                },
              }),
          },
        }),
        'airEmissionsUnitConversions',
      );
    },
    [FETCH_EMISSION_UNIT_CONVERSIONS_FAILURE]: (state, action) =>
      failed(notFetching(state, 'airEmissionsUnitConversions'), action.payload),
    [FETCH_EMISSION_STATISTICS_REQUEST]: (state) =>
      fetching(state, 'airEmissionsStatistics'),
    [FETCH_EMISSION_STATISTICS_SUCCESS]: (state, action) => {
      const { records, logProjectId } = action.payload;
      return notFetching(
        update(state, {
          airEmissions: {
            [logProjectId]: (obj) =>
              update(obj || {}, {
                statistics: (statObj) =>
                  update(statObj || {}, {
                    $merge: records.reduce((acc, statistics, index) => {
                      const {
                        resourceType,
                        resourceId,
                        start,
                        end,
                        unit,
                        limits,
                      } = action.meta.req[index];
                      const key = createKey({
                        resourceType,
                        resourceId,
                        start,
                        end,
                        unit,
                        limits,
                      });
                      acc[key] = statistics;
                      return acc;
                    }, {}),
                  }),
              }),
          },
        }),
        'airEmissionsStatistics',
      );
    },
    [FETCH_EMISSION_STATISTICS_FAILURE]: (state, action) =>
      failed(notFetching(state, 'airEmissionStatistics'), action.payload),
    [FETCH_EMISSION_MICRO_INTERVAL_STATISTICS_REQUEST]: (state) =>
      fetching(state, 'airEmissionsMicroIntervalStatistics'),
    [FETCH_EMISSION_MICRO_INTERVAL_STATISTICS_SUCCESS]: (state, action) => {
      const { records, logProjectId } = action.payload;
      return notFetching(
        update(state, {
          airEmissions: {
            [logProjectId]: (obj) =>
              update(obj || {}, {
                microIntervalStatistics: (statObj) =>
                  update(statObj || {}, {
                    $merge: records.reduce(
                      (acc, microIntervalStatistics, index) => {
                        const {
                          resourceType,
                          resourceId,
                          frequency,
                          start,
                          end,
                          unit,
                          limits,
                        } = action.meta.req[index];
                        const key = createKey({
                          resourceType,
                          resourceId,
                          frequency,
                          start,
                          end,
                          unit,
                          limits,
                        });
                        acc[key] = microIntervalStatistics;
                        return acc;
                      },
                      {},
                    ),
                  }),
              }),
          },
        }),
        'airEmissionsMicroIntervalStatistics',
      );
    },
    [FETCH_EMISSION_MICRO_INTERVAL_STATISTICS_FAILURE]: (state, action) =>
      failed(
        notFetching(state, 'airEmissionsMicroIntervalStatistics'),
        action.payload,
      ),
    [RESET_EMISSION_LOGS]: (state, action) => {
      const logProjectId = action.payload;
      return notFetching(
        update(state, {
          airEmissions: {
            [logProjectId]: (obj) =>
              update(obj || {}, {
                logs: {
                  $set: [],
                },
              }),
          },
        }),
      );
    },
    [UPDATE_EMISSION_CALCULATED_VALUE_REQUEST]: (state) =>
      fetching(state, 'airEmissionsCalculatedValues'),
    [UPDATE_EMISSION_CALCULATED_VALUE_SUCCESS]: (state, action) => {
      const { logProjectId } = action.payload;
      const updatedRecordIdx = state.airEmissions[
        logProjectId
      ]?.calculatedValues.findIndex(
        (calculatedValue) => calculatedValue.id === action.payload.id,
      );
      return notFetching(
        update(state, {
          airEmissions: {
            [logProjectId]: {
              calculatedValues: {
                [updatedRecordIdx]: {
                  $set: action.payload,
                },
              },
            },
          },
        }),
        'airEmissionsCalculatedValues',
      );
    },
    [UPDATE_EMISSION_CALCULATED_VALUE_FAILURE]: (state, action) =>
      failed(
        notFetching(state, 'airEmissionsCalculatedValues'),
        action.payload,
      ),
    [UPDATE_EMISSION_FACTOR_REQUEST]: (state) =>
      fetching(state, 'airEmissionsEmissionFactors'),
    [UPDATE_EMISSION_FACTOR_SUCCESS]: (state, action) => {
      const { logProjectId } = action.payload;
      const updatedRecordIdx = state.airEmissions[
        logProjectId
      ].emissionFactors.findIndex(
        (emissionFactor) => emissionFactor.id === action.payload.id,
      );
      return notFetching(
        update(state, {
          airEmissions: {
            [logProjectId]: {
              emissionFactors: {
                [updatedRecordIdx]: {
                  $set: action.payload,
                },
              },
            },
          },
        }),
        'airEmissionsEmissionFactors',
      );
    },
    [UPDATE_EMISSION_FACTOR_FAILURE]: (state, action) =>
      failed(notFetching(state, 'airEmissionsEmissionFactors'), action.payload),
    [UPDATE_EMISSION_ITEM_GROUP_REQUEST]: (state) =>
      fetching(state, 'airEmissionsItemGroups'),
    [UPDATE_EMISSION_ITEM_GROUP_SUCCESS]: (state, action) => {
      const { logProjectId } = action.payload;
      return notFetching(
        update(state, {
          airEmissions: {
            [logProjectId]: {
              itemGroups: {
                [action.payload.id]: {
                  $merge: {
                    name: action.payload.name,
                  },
                },
              },
            },
          },
        }),
        'airEmissionsItemGroups',
      );
    },
    [UPDATE_EMISSION_ITEM_GROUP_FAILURE]: (state, action) =>
      failed(notFetching(state, 'airEmissionsItemGroups'), action.payload),
    [UPDATE_EMISSION_LIMIT_ITEM_REQUEST]: (state) =>
      fetching(state, 'airEmissionsMaterialLimits'),
    [UPDATE_EMISSION_LIMIT_ITEM_SUCCESS]: (state, action) => {
      const { logProjectId } = action.payload;
      const updatedRecordIdx = state.airEmissions[
        logProjectId
      ].limitItems.findIndex((limitItem) => limitItem.id === action.payload.id);
      return notFetching(
        update(state, {
          airEmissions: {
            [logProjectId]: {
              limitItems: {
                [updatedRecordIdx]: {
                  $set: action.payload,
                },
              },
            },
          },
        }),
      );
    },
    [UPDATE_EMISSION_LIMIT_ITEM_FAILURE]: (state, action) =>
      failed(notFetching(state, 'airEmissionsMaterialLimits'), action.payload),
    [UPDATE_EMISSION_LOGGED_ITEM_REQUEST]: (state) =>
      fetching(state, 'airEmissionsLoggedItems'),
    [UPDATE_EMISSION_LOGGED_ITEM_SUCCESS]: (state, action) => {
      const { logProjectId } = action.payload;
      const updatedRecordIdx = state.airEmissions[
        logProjectId
      ].loggedItems.findIndex(
        (loggedItem) => loggedItem.id === action.payload.id,
      );
      return notFetching(
        update(state, {
          airEmissions: {
            [logProjectId]: {
              loggedItems: {
                [updatedRecordIdx]: {
                  $set: action.payload,
                },
              },
            },
          },
        }),
        'airEmissionsLoggedItems',
      );
    },
    [UPDATE_EMISSION_LOGGED_ITEM_FAILURE]: (state, action) =>
      failed(notFetching(state, 'airEmissionsLoggedItems'), action.payload),
    [UPDATE_EMISSION_TRACKING_CALENDARS]: (state, action) => {
      const { logProjectId, log } = action.payload;
      return notFetching(
        update(state, {
          airEmissions: {
            [logProjectId]: {
              logSaved: {
                $set: log,
              },
            },
          },
        }),
      );
    },
    [UPDATE_EMISSION_UNIT_CONVERSION_REQUEST]: (state) =>
      fetching(state, 'airEmissionsUnitConversions'),
    [UPDATE_EMISSION_UNIT_CONVERSION_SUCCESS]: (state, action) => {
      const { logProjectId } = action.payload;
      const updatedRecordIdx = state.airEmissions[
        logProjectId
      ].unitConversions.findIndex(
        (unitConversion) => unitConversion.id === action.payload.id,
      );
      return notFetching(
        update(state, {
          airEmissions: {
            [logProjectId]: {
              unitConversions: {
                [updatedRecordIdx]: {
                  $set: action.payload,
                },
              },
            },
          },
        }),
        'airEmissionsUnitConversions',
      );
    },
    [UPDATE_EMISSION_UNIT_CONVERSION_FAILURE]: (state, action) =>
      failed(notFetching(state, 'airEmissionsUnitConversions'), action.payload),
    [UPDATE_EMISSION_LOG_TABS_REQUEST]: (state) =>
      fetching(state, 'airEmissionLogTabs'),
    [UPDATE_EMISSION_LOG_TABS_SUCCESS]: (state, action) => {
      const { id, logProjectId } = action.payload;
      const updatedRecordIdx = state.airEmissions[
        logProjectId
      ].logTabs.findIndex((logTab) => logTab.id === id);
      return notFetching(
        update(state, {
          airEmissions: {
            [logProjectId]: {
              logTabs: {
                [updatedRecordIdx]: {
                  $set: action.payload,
                },
              },
            },
          },
        }),
        'airEmissionLogTabs',
      );
    },
    [UPDATE_EMISSION_LOG_TABS_FAILURE]: (state, action) =>
      failed(notFetching(state, 'airEmissionLogTabs'), action.payload),
    [UPDATE_EMISSION_ROLLING_CALCULATION_REQUEST]: (state) =>
      fetching(state, 'airEmissionsRollingCalculations'),
    [UPDATE_EMISSION_ROLLING_CALCULATION_SUCCESS]: (state, action) => {
      const { id, logProjectId } = action.payload;
      const updatedRecordIdx = state.airEmissions[
        logProjectId
      ].rollingCalculations.findIndex(
        (rollingCalculation) => rollingCalculation.id === id,
      );
      return notFetching(
        update(state, {
          airEmissions: {
            [logProjectId]: {
              rollingCalculations: {
                [updatedRecordIdx]: {
                  $set: action.payload,
                },
              },
            },
          },
        }),
        'airEmissionsRollingCalculations',
      );
    },
    [UPDATE_EMISSION_ROLLING_CALCULATION_FAILURE]: (state, action) =>
      failed(
        notFetching(state, 'airEmissionsRollingCalculations'),
        action.payload,
      ),
  },
  INITIAL_STATE,
);

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default reducers;
