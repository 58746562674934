import {
  DatasetRefResponse,
  DatasetResponse,
  DatasetSampleFormulaTermsResponse,
} from 'mapistry-shared';
import http from '../httpClient';

export type FetchDatasetParams = { organizationId: string; datasetId: string };
export type FetchDatasetResponse = DatasetResponse;

export async function fetchDataset({
  organizationId,
  datasetId,
}: FetchDatasetParams): Promise<FetchDatasetResponse> {
  const res = await http.get<FetchDatasetResponse>(
    `/api/v2/organizations/${organizationId}/datasets/${datasetId}`,
  );
  return res.data;
}

export type FetchDatasetListParams = { organizationId: string };
export type FetchDatasetListResponse = DatasetRefResponse[];

export async function fetchDatasetList({
  organizationId,
}: FetchDatasetListParams): Promise<FetchDatasetListResponse> {
  const res = await http.get<FetchDatasetListResponse>(
    `/api/v2/organizations/${organizationId}/datasets`,
  );
  return res.data;
}

export type FetchDatasetSampleFormulaTermsParams = {
  datasetId: string;
  organizationId: string;
};
export type FetchDatasetSampleFormulaTermsResponse =
  DatasetSampleFormulaTermsResponse;

export async function fetchDatasetSampleFormulaTerms({
  datasetId,
  organizationId,
}: FetchDatasetSampleFormulaTermsParams) {
  const res = await http.get<FetchDatasetSampleFormulaTermsResponse>(
    `/api/v2/organizations/${organizationId}/datasets/${datasetId}/sample-formula-terms`,
  );
  return res.data;
}
