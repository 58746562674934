import { SelectableOption } from '@monorepo/shared/types/SelectableOption';
import { useMemo } from 'react';
import { useProjectUsers } from './useProjectUsers';

type UseProjectUserOptions = (args: {
  projectId?: string;
  previouslySavedUserId?: string;
}) => {
  userOptions?: SelectableOption[];
} & Omit<ReturnType<typeof useProjectUsers>, 'users'>;

export const useProjectUserOptions: UseProjectUserOptions = ({
  projectId,
  previouslySavedUserId,
}) => {
  const { users, ...queryInfo } = useProjectUsers({
    projectId,
    includeArchived: true,
  });

  // include all active users as options, and an archived user if they were the original selection for this component
  const userOptions = useMemo(() => {
    const filteredUsers = (users || []).filter(
      (u) => u.userId === previouslySavedUserId || !u.is_archived,
    );
    return filteredUsers.map((u) => ({
      value: u.userId,
      label: u.userName,
    }));
  }, [users, previouslySavedUserId]);

  return { userOptions, ...queryInfo };
};
