/* eslint-disable */
import _ from 'underscore';
import sectorData from 'industrial-stormwater-sector-data';
import samplingUnits from 'industrial-stormwater-sector-data/lib/samplingUnits';
// import LIMIT_TYPE from 'industrial-stormwater-sector-data/lib/limitType';
const LIMIT_TYPE = {
  MAX: 'maximum',
  MIN: 'minimum',
  RANGE: 'range',
};

const getAllSamplingUnits = () => samplingUnits.getAll();
const getAvailableSamplingParameters = (sectorData) =>
  sectorData.getAvailableSamplingParameters();

const getParameterDisplayText = (parameter_slug) => {
  const allParameters = getSectorData().getAvailableSamplingParameters();
  const match = _.find(allParameters, (param) => param.slug === parameter_slug);
  return match ? match.display_text : null;
};
const getSectorData = (identifier) => sectorData(identifier);

const objectToSortedArray = (obj, param) => {
  let output = [];
  _.mapObject(obj, (v) => {
    output.push(v);
  });
  return _.sortBy(output, param);
};

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export {
  capitalizeFirstLetter,
  getAllSamplingUnits,
  getAvailableSamplingParameters,
  getParameterDisplayText,
  getSectorData,
  LIMIT_TYPE,
  objectToSortedArray,
};
