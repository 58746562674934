import { LoggedItem } from '@monorepo/shared/apiClient/types';
import {
  CalculationFactor,
  CustomFrequency,
  GenericLogType,
  IntervalFrequencyEnum,
  LimitItem,
  LogStatistic,
  ResourceFactor,
  RollingCalculationType,
  UnitString,
} from 'mapistry-shared';

/**
 * @deprecated Use IntervalFrequencyEnum from mapistry-shared directly instead
 */
export type Frequency = IntervalFrequencyEnum;

/**
 * @deprecated Use GenericLogType from 'mapistry-shared'
 */
export enum ResourceType {
  LOGGED_ITEM = 'loggedItem',
  CALCULATED_VALUE = 'calculatedValue',
  ROLLING_CALCULATION = 'rollingCalculation',
}

export type Factor = ResourceFactor | CalculationFactor;

// any object for now
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type NotesData = { [key: string]: any };

// Air

/**
 * @deprecated Use LoggedItem from '@monorepo/shared/apiClient/types'
 */
export type GenericLogsLoggedItem = LoggedItem;

/**
 * @deprecated Use LimitItem from '@monorepo/shared/apiClient/types'
 */
export type GenericLogsLimitItem = LimitItem;

/**
 * @deprecated Use CalculatedValue from '@monorepo/shared/apiClient/genericLogs/types'
 */
export type GenericLogsCalculatedValue = {
  id: string;
  projectId: string;
  groupId?: string;
  name: string;
  frequency: Frequency;
  customFrequency: CustomFrequency;
  units: UnitString;
  factors: Factor[];
  notes?: NotesData;
  createdAt: string;
  updatedAt?: string;
};

/**
 * @deprecated Use RollingCalculation from '@monorepo/shared/apiClient/genericLogs/types'
 */
export type GenericLogsRollingCalculation = {
  id: string;
  projectId: string;
  groupId?: string;
  resourceType: ResourceType;
  resourceId: string;
  name: string;
  units: UnitString;
  calculationDuration: number;
  calculationFrequency: Frequency;
  calculationType: RollingCalculationType;
  notes?: NotesData;
  createdAt: string;
  updatedAt?: string;
};

/** @deprecated If this is still needed please move it to mapistry-shared */
export type ResourceKey = {
  resourceId: string;
  resourceType: Exclude<GenericLogType, GenericLogType.LIMIT_ITEM>;
};

export type GenericLogsLoggedItemResource = {
  resourceType: GenericLogType.LOGGED_ITEM;
  resourceId: string;
  entity: LoggedItem;
};

export type GenericLogsCalculatedValueResource = {
  resourceType: GenericLogType.CALCULATED_VALUE;
  resourceId: string;
  entity: GenericLogsCalculatedValue;
};

export type GenericLogsRollingCalculationResource = {
  resourceType: GenericLogType.ROLLING_CALCULATION;
  resourceId: string;
  entity: GenericLogsRollingCalculation;
};

export type ChartableGenericLogsResource =
  | GenericLogsLoggedItemResource
  | GenericLogsCalculatedValueResource
  | GenericLogsRollingCalculationResource;

export function isRollingCalculationResource(
  resource: ChartableGenericLogsResource,
): resource is GenericLogsRollingCalculationResource {
  return resource.resourceType === GenericLogType.ROLLING_CALCULATION;
}

export type LimitableAirEmissionResource =
  | GenericLogsLoggedItemResource
  | GenericLogsCalculatedValueResource;

export type MicroIntervalStatistics = {
  resourceType: ResourceType;
  resourceId: string;
  intervalStatistics: LogStatistic[];
};

// UI centric types
export type ChartType = 'bar' | 'line';

export type ResourceKeyWithChartType = ResourceKey & {
  chartType: ChartType;
};

export type SingleOption<T> = {
  value: T;
  label: string;
};

export type SubOptions<T> = { options: SingleOption<T>[] };

export type Option<T> = SingleOption<T> | SubOptions<T>;

export function isSingleOption<T>(
  option: Option<T>,
): option is SingleOption<T> {
  return (option as SingleOption<T>).value !== undefined;
}

export function isSubOptions<T>(option: Option<T>): option is SubOptions<T> {
  return (option as SubOptions<T>).options !== undefined;
}

export type DocumentType = {
  description: string;
  fileId: string;
  folderSlug: string;
  id: string;
  mimeType: string;
  name: string;
  permissions: {
    hasDeletePermission: boolean;
  };
  processing?: boolean;
  projectId: string;
  src: string;
};
