import React from 'react';
import {
  isMultiErrorResponse,
  isStringErrorResponse,
} from '../../apiClient/httpClient';

function isError(e: unknown): e is Error {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return e instanceof Error;
}

export function UserFriendlyErrorMessage({
  defaultMessage = 'Please try again later.',
  error,
  prependedText,
}: {
  error: unknown;
  defaultMessage?: string;
  prependedText?: string;
}) {
  if (isMultiErrorResponse(error)) {
    return (
      <div>
        {/* don't use the multi-error's top-level message if non-empty prependedText is passed - this prevents having
          similar "summary" messages appearing twice in the message (e.g. "Couldn't save resource. Unable to save
          resource... <rest of error message>") */}
        {prependedText || error.data.message}
        {error.data.errors.map((errMsg) => (
          <div key={errMsg}>- {errMsg}</div>
        ))}
      </div>
    );
  }

  let message: string = defaultMessage;
  if (isStringErrorResponse(error)) {
    message = error.data;
  }
  if (isError(error)) {
    message = error.message;
  }

  return (
    <div>
      {prependedText} {message}
    </div>
  );
}
