import MuiToggleButton from '@material-ui/lab/ToggleButton';
import React, { FC } from 'react';

type ToggleButtonProps = {
  children: NonNullable<React.ReactNode>;
  onChange?: (selected: boolean) => void;
  selected?: boolean;
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
};

export const ToggleButton: FC<ToggleButtonProps> = (
  props: ToggleButtonProps,
) => {
  const { children, onChange, selected, value } = props;
  return (
    <MuiToggleButton
      classes={{
        root: 'mapistry-toggle-button',
        label: 'mapistry-toggle-label',
        selected: 'selected',
      }}
      disableRipple
      onChange={() => onChange && onChange(!selected)}
      selected={selected}
      value={value}
    >
      {children}
    </MuiToggleButton>
  );
};
