import { SaveState } from '@monorepo/shared/types/SaveState';
import { CalendarEventStatus, Days, Months } from 'mapistry-shared';
import PropTypes from 'prop-types';

export const CalendarEventType = PropTypes.shape({
  calendarName: PropTypes.string,
  frequency: PropTypes.string,
  startDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  dueDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  warningDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  status: PropTypes.string,
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/forbid-prop-types
  attachments: PropTypes.array,
});

export const CalendarFrequencyType = PropTypes.shape({
  startDate: PropTypes.string,
  frequency: PropTypes.string,
});

export const ErrorType = PropTypes.shape({
  message: PropTypes.string,
});

export const MenuOptionType = PropTypes.shape({
  danger: PropTypes.bool,
  disabled: PropTypes.bool,
  iconIdentifier: PropTypes.string,
  icon: PropTypes.element,
  label: PropTypes.string,
  disabledWithExplanation: PropTypes.string,
  onClick: PropTypes.func,
});

export const ProjectType = PropTypes.shape({
  id: PropTypes.string,
});

export const UserType = PropTypes.shape({
  userId: PropTypes.string,
  name: PropTypes.string,
});

export const ProjectTaskType = PropTypes.shape({
  advanceNoticeDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  assignees: PropTypes.arrayOf(UserType),
  calendarName: PropTypes.string,
  createdAt: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  creatorId: PropTypes.string,
  completedDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  deficiency: PropTypes.string,
  description: PropTypes.string,
  dueDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  eventNumber: PropTypes.number,
  folderSlug: PropTypes.string,
  formSubmissionSlug: PropTypes.string,
  formTemplateSlug: PropTypes.string,
  id: PropTypes.string,
  isInspection: PropTypes.bool,
  isMarkedCompleted: PropTypes.bool,
  note: PropTypes.string,
  parentTitle: PropTypes.string,
  parentId: PropTypes.string,
  projectId: PropTypes.string,
  startDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  status: PropTypes.oneOf(Object.values(CalendarEventStatus)),
  subTaskCount: PropTypes.number,
});

export const FormCalendar = PropTypes.shape({
  name: PropTypes.string,
  summaryTitle: PropTypes.string,
});

export const CustomFrequency = PropTypes.shape({
  numberOfEvents: PropTypes.number,
  every: PropTypes.number,
  repeatOn: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOf([...Object.values(Days)])),
    PropTypes.arrayOf(PropTypes.oneOf([...Object.values(Months)])),
  ]),
});

export const FrequencyRepeatOption = PropTypes.oneOfType([
  PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOf([...Object.values(Days)]),
      label: PropTypes.string,
    }),
  ),
  PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOf([...Object.values(Months)]),
      label: PropTypes.string,
    }),
  ),
]);

export const FormSaveStateType = PropTypes.oneOf(Object.values(SaveState));

export const ReactRefType = PropTypes.oneOfType([
  PropTypes.func,
  PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
]);
