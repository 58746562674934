import {
  CreateFieldRequest,
  FieldResponse,
  UpdateFieldRequest,
} from 'mapistry-shared';
import http from '../httpClient';

export interface FetchLogFieldsParams {
  logId: string;
  organizationId: string;
}

export type FetchLogFieldsResponse = FieldResponse[];

export async function fetchLogFields({
  logId,
  organizationId,
}: FetchLogFieldsParams): Promise<FieldResponse[]> {
  const res = await http.get<FetchLogFieldsResponse>(
    `/api/v2/organizations/${organizationId}/logs/${logId}/fields`,
  );
  return res.data;
}

export type CreateLogFieldParams = {
  logField: CreateFieldRequest;
  logId: string;
  organizationId: string;
};
export type CreateLogFieldResponse = FieldResponse;

export async function createLogField({
  logField,
  logId,
  organizationId,
}: CreateLogFieldParams) {
  const res = await http.post<CreateLogFieldResponse>(
    `/api/v2/organizations/${organizationId}/logs/${logId}/fields`,
    logField,
  );
  return res.data;
}

export type UpdateLogFieldParams = {
  logField: UpdateFieldRequest;
  logId: string;
  organizationId: string;
};
export type UpdateLogFieldResponse = FieldResponse;

export async function updateLogField({
  logField,
  logId,
  organizationId,
}: UpdateLogFieldParams) {
  const res = await http.put<UpdateLogFieldResponse>(
    `/api/v2/organizations/${organizationId}/logs/${logId}/fields/${logField.id}`,
    logField,
  );
  return res.data;
}

export type DeleteLogFieldRequest = {
  logFieldId: string;
  logId: string;
  organizationId: string;
};
export type DeleteLogFieldResponse = string;

export async function deleteLogField({
  logFieldId,
  logId,
  organizationId,
}: DeleteLogFieldRequest) {
  const res = await http.delete<DeleteLogFieldResponse>(
    `/api/v2/organizations/${organizationId}/logs/${logId}/fields/${logFieldId}`,
  );
  return res.data;
}
