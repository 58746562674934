import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress } from '@material-ui/core';

/**
 * @deprecated Use packages/shared/components/Loading
 */
const LoadingIndicator = ({ className, isAbsolute, size }) => (
  <span className={isAbsolute ? 'loading' : className}>
    <CircularProgress
      className="mapistry-loading-indicator"
      classes={{ colorPrimary: 'mapistry-green' }}
      size={size || 80}
    />
  </span>
);

LoadingIndicator.propTypes = {
  className: PropTypes.string,
  isAbsolute: PropTypes.bool,
  size: PropTypes.number,
};

LoadingIndicator.defaultProps = {
  className: null,
  isAbsolute: true,
  size: 80,
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default LoadingIndicator;
