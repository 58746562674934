import {
  DateLike,
  LogEntryResponse,
  Pagination,
  SaveLogEntryRequest,
  SortDirection,
} from 'mapistry-shared';
import qs from 'qs';
import http, { apiUrl } from '../httpClient';

type BasePathParams = {
  organizationId: string;
  logId: string;
  projectId: string;
};

export type FetchLogEntriesParams = BasePathParams & {
  after?: DateLike;
  before?: DateLike;
  nextPage?: number;
  perPage?: number;
  requestedPage?: number;
  sortDirection?: SortDirection;
  userId?: string;
};

export type FetchLogEntriesResponse = {
  data: LogEntryResponse[];
  pagination: Pagination;
};

const getBasePath = ({ organizationId, projectId, logId }: BasePathParams) =>
  `/api/v2/organizations/${organizationId}/logs/${logId}/projects/${projectId}/entries`;

export async function fetchLogEntries({
  after,
  before,
  logId,
  organizationId,
  perPage,
  projectId,
  requestedPage,
  sortDirection,
  userId,
}: FetchLogEntriesParams): Promise<FetchLogEntriesResponse> {
  const res = await http.get<FetchLogEntriesResponse>(
    `${getBasePath({
      organizationId,
      logId,
      projectId,
    })}?${qs.stringify({
      after,
      before,
      currentPage: requestedPage ?? 0,
      perPage: perPage ?? 50,
      sortDirection,
      userId,
    })}`,
  );
  return res.data;
}

export type CountLogEntriesParams = BasePathParams & {
  after?: DateLike;
  before?: DateLike;
};

export type CountLogEntriesResponse = { count: number };

export async function countLogEntries({
  after,
  before,
  logId,
  organizationId,
  projectId,
}: CountLogEntriesParams): Promise<CountLogEntriesResponse> {
  const res = await http.get<CountLogEntriesResponse>(
    `${getBasePath({
      organizationId,
      logId,
      projectId,
    })}/count?${qs.stringify({ after, before })}`,
  );
  return res.data;
}

export type FetchLogEntryParams = BasePathParams & {
  logEntryId: string;
};

export type FetchLogEntryResponse = LogEntryResponse;

export async function fetchLogEntry({
  organizationId,
  logId,
  logEntryId,
  projectId,
}: FetchLogEntryParams) {
  const res = await http.get<LogEntryResponse>(
    `${getBasePath({
      organizationId,
      logId,
      projectId,
    })}/${logEntryId}`,
  );
  return res.data;
}

type CreateLogEntryParams = BasePathParams & {
  logEntry: SaveLogEntryRequest;
};

export async function createLogEntry({
  organizationId,
  projectId,
  logId,
  logEntry,
}: CreateLogEntryParams): Promise<LogEntryResponse> {
  const res = await http.post<LogEntryResponse>(
    `${getBasePath({
      organizationId,
      logId,
      projectId,
    })}`,
    logEntry,
  );
  return res.data;
}

type UpdateLogEntryParams = BasePathParams & {
  logEntry: SaveLogEntryRequest;
  logEntryId: string;
};

export async function updateLogEntry({
  organizationId,
  projectId,
  logId,
  logEntry,
  logEntryId,
}: UpdateLogEntryParams) {
  const res = await http.put<LogEntryResponse>(
    `${getBasePath({
      organizationId,
      logId,
      projectId,
    })}/${logEntryId}`,
    logEntry,
  );
  return res.data;
}

export type DeleteLogEntryRequest = BasePathParams & {
  logEntryId: string;
};
export type DeleteLogEntryResponse = string;

export async function deleteLogEntry({
  logEntryId,
  logId,
  organizationId,
  projectId,
}: DeleteLogEntryRequest) {
  const res = await http.delete<DeleteLogEntryResponse>(
    `${getBasePath({
      organizationId,
      logId,
      projectId,
    })}/${logEntryId}`,
  );
  return res.data;
}

type UploadLogEntriesParams = BasePathParams & {
  logEntries: SaveLogEntryRequest[];
};

export async function uploadLogEntries({
  organizationId,
  projectId,
  logId,
  logEntries,
}: UploadLogEntriesParams): Promise<void> {
  await http.post<LogEntryResponse[]>(
    `${getBasePath({
      organizationId,
      logId,
      projectId,
    })}/bulk`,
    logEntries,
  );
}

type DownloadLogEntriesParams = BasePathParams & {
  after?: DateLike;
  before?: DateLike;
};

/** This function synchronously opens another browser tab to download the file. It does not wait for the request to complete. */
export function downloadLogEntries({
  logId,
  organizationId,
  projectId,
  after,
  before,
}: DownloadLogEntriesParams): void {
  window.open(
    `${apiUrl}${getBasePath({
      organizationId,
      logId,
      projectId,
    })}/download?${qs.stringify({ after, before })}`,
  );
}
