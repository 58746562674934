import React from 'react';
import PropTypes from 'prop-types';
import { SvgIcon } from '@monorepo/shared/components/icons/SvgIcon';
import { Editor } from 'slate';
import { useSlate } from 'slate-react';

import IconButton from '../buttons/IconButton';

export const SUPPORTED_MARKS = {
  BOLD: {
    style: 'bold',
    label: 'Bold',
    icon: 'bold',
  },
  ITALIC: {
    style: 'italic',
    label: 'Italic',
    icon: 'italic',
  },
  UNDERLINE: {
    style: 'underline',
    label: 'Underline',
    icon: 'underline',
  },
  CODE: {
    style: 'code',
    label: 'Code',
    icon: 'code',
  },
};

const isMarkActive = (editor, markType) => {
  const marks = Editor.marks(editor);
  return marks ? marks[markType] === true : false;
};

export const toggleMark = (editor, markType) => {
  const isActive = isMarkActive(editor, markType);
  if (isActive) {
    Editor.removeMark(editor, markType);
  } else {
    Editor.addMark(editor, markType, true);
  }
};

const MarkButton = (props) => {
  const { type } = props;
  const editor = useSlate();
  const isActive = isMarkActive(editor, type.style);
  const colorClassname = isActive ? 'white bg-green' : 'dark-gray';
  return (
    <IconButton
      onClick={(e) => {
        e.preventDefault();
        toggleMark(editor, type.style);
      }}
      tooltipText={type.label}
    >
      <span className={`text-editor__button-wrapper ${colorClassname}`}>
        <SvgIcon identifier={type.icon} />
      </span>
    </IconButton>
  );
};

MarkButton.propTypes = {
  type: PropTypes.oneOf(Object.values(SUPPORTED_MARKS)).isRequired,
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default MarkButton;
