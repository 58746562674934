import React from 'react';
import PropTypes from 'prop-types';

const Table = (props) => {
  const { children } = props;
  return <div className="mapistry-table__container">{children}</div>;
};

Table.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default Table;
