import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Portal } from 'react-portal';
import { SvgIcon } from '@monorepo/shared/components/icons/SvgIcon';
import { KeyService } from '@monorepo/shared/KeyService';

import AnimatedComponent, { ANIMATION_TYPES } from './AnimatedComponent';

class Flyout extends Component {
  constructor(props) {
    super(props);
    this.handleKeypress = this.handleKeypress.bind(this);
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeypress, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeypress, false);
  }

  handleKeypress(event) {
    const { onClose } = this.props;
    if (KeyService.isEscapeKey(event.keyCode)) {
      onClose();
    }
  }

  render() {
    const { alignment, children, title, onClose, visible } = this.props;

    let alignmentClass;
    let animationType;

    if (!visible) {
      return null;
    }

    if (alignment === 'left') {
      alignmentClass = 'flyout__container--left-aligned';
      animationType = ANIMATION_TYPES.SLIDE_LEFT_RIGHT_PRESENCE;
    } else {
      alignmentClass = 'flyout__container--right-aligned';
      animationType = ANIMATION_TYPES.SLIDE_RIGHT_LEFT_PRESENCE;
    }

    return (
      <Portal>
        <AnimatedComponent
          className={`flyout__container ${alignmentClass}`}
          type={animationType}
        >
          <div className="flyout__content-wrapper">
            <div className="flyout__header-container">
              <span className="flyout__title">{title || ''}</span>
              {/* TODO: Fix this the next time the file is edited. */}
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
              <span className="flyout__close grow" onClick={onClose}>
                <SvgIcon identifier="close" />
              </span>
            </div>
            <div className="flyout__body-container">{children}</div>
          </div>
        </AnimatedComponent>
      </Portal>
    );
  }
}

Flyout.propTypes = {
  alignment: PropTypes.oneOf(['left', 'right']).isRequired,
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  visible: PropTypes.bool.isRequired,
};

Flyout.defaultProps = {
  title: null,
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default Flyout;
