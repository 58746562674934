import React, {
  createContext,
  ReactElement,
  useCallback,
  useContext,
} from 'react';
import {
  MutationResult,
  QueryResult,
  useMutation,
  useQueryCache,
} from 'react-query';
import { Api } from '@monorepo/shared/apiClient';
import {
  logProjectQueryKey,
  useLogProject,
} from '../../hooks/genericLogs/useLogProject';

interface LogProjectContextType extends QueryResult<Api.LogProject> {
  id: string;
  logProject?: Api.LogProject;
}

const LogProjectContext = createContext<LogProjectContextType | undefined>(
  undefined,
);

type UpdateLogProject = [
  (logProject: Partial<Api.LogProject>) => Promise<void>,
  MutationResult<Api.LogProject>,
];

export const useUpdateLogProject = (): UpdateLogProject => {
  const [updateMutation, queryResult] = useMutation(Api.updateLogProject);
  const queryCache = useQueryCache();
  const updateLogProject = useCallback(
    async ({ id, informationalText, intervalStartMonth, name }) => {
      await updateMutation({ id, informationalText, intervalStartMonth, name });
      await queryCache.invalidateQueries(logProjectQueryKey(id));
    },
    [updateMutation, queryCache],
  );
  return [updateLogProject, queryResult];
};

export const useCurrentLogProject = (): LogProjectContextType => {
  const logProjectInfo = useContext(LogProjectContext);
  if (!logProjectInfo) {
    throw new Error('Need to use the LogProjectProvider!');
  }
  return logProjectInfo;
};

type LogProjectProviderProps = {
  logProjectId: string;
  children: React.ReactNode;
};

export const LogProjectProvider = (
  props: LogProjectProviderProps,
): ReactElement => {
  const { logProjectId, children } = props;
  const queryResult = useLogProject(logProjectId);
  return (
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <LogProjectContext.Provider value={{ id: logProjectId, ...queryResult }}>
      {children}
    </LogProjectContext.Provider>
  );
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default LogProjectContext;
