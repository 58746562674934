import { Api } from '@monorepo/shared/apiClient';
import { useQuery } from 'react-query';

export function createCurrentUserKey() {
  return ['currentUser'] as const;
}

type QueryFn = Api.DataHookQueryFn<
  typeof createCurrentUserKey,
  typeof Api.fetchCurrentUser
>;

const fetcher: QueryFn = () => Api.fetchCurrentUser();

export function useCurrentUser(currentUser?: Api.User) {
  const key = createCurrentUserKey();
  const config = {
    initialData: currentUser,
    cacheTime: Infinity,
    staleTime: Infinity,
  };
  const { data, ...queryInfo } = useQuery<Api.User, Api.ErrorResponse>(
    key,
    fetcher,
    config,
  );

  return {
    ...queryInfo,
    currentUser: data,
  };
}
