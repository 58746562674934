import { Tooltip } from '@monorepo/shared/components/Tooltip';
import DocumentIcon from '@svg/document.svg';
import DocumentCsvIcon from '@svg/documentCsv.svg';
import DocumentDocIcon from '@svg/documentDoc.svg';
import DocumentPdfIcon from '@svg/documentPdf.svg';
import DocumentPptIcon from '@svg/documentPpt.svg';
import DocumentXlsIcon from '@svg/documentXls.svg';
import DocumentZipIcon from '@svg/documentZip.svg';
import DownloadIcon from '@svg/download.svg';
import TrashIcon from '@svg/trash.svg';
import React, { useCallback, useMemo } from 'react';
import { DocumentType } from '../../types/ts-types';
import LoadingIndicator from './LoadingIndicator';

type DocumentProps = {
  disabled: boolean;
  document: DocumentType;
  onDelete: (doc: DocumentType) => void;
};

export const Document = (props: DocumentProps) => {
  const { disabled, document, onDelete } = props;
  const { fileId, name, processing, src } = document;

  const handleDelete = useCallback(
    () => onDelete(document),
    [document, onDelete],
  );

  const icon = useMemo(() => {
    switch (document.mimeType) {
      case 'text/csv':
        return <DocumentCsvIcon className="m-icon doc-icon" />;
      case 'application/pdf':
        return <DocumentPdfIcon className="m-icon doc-icon" />;
      case 'application/msword':
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return <DocumentDocIcon className="m-icon doc-icon" />;
      case 'application/vnd.ms-excel':
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        return <DocumentXlsIcon className="m-icon doc-icon" />;
      case 'application/vnd.ms-powerpoint':
      case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        return <DocumentPptIcon className="m-icon doc-icon" />;
      case 'application/zip':
        return <DocumentZipIcon className="m-icon doc-icon" />;
      default:
        return <DocumentIcon className="m-icon doc-icon" />;
    }
  }, [document.mimeType]);

  return (
    <div className="document-component" key={fileId}>
      {icon}
      <div className="name">{name}</div>
      {processing ? (
        <LoadingIndicator size={22} isAbsolute={false} />
      ) : (
        <div className="actions">
          <a className="action-icon download" href={src}>
            <DownloadIcon className="m-icon" />
          </a>
          <Tooltip
            title={
              document.permissions.hasDeletePermission
                ? 'Delete document'
                : 'Only admins can delete documents uploaded by others.'
            }
          >
            <button
              type="button"
              className="action-icon remove icon-button"
              onClick={handleDelete}
              disabled={disabled || !document.permissions.hasDeletePermission}
            >
              <TrashIcon className="m-icon" />
            </button>
          </Tooltip>
        </div>
      )}
    </div>
  );
};
