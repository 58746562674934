import { addDays, startOfDay } from 'date-fns';
import {
  CustomFrequency,
  Days,
  IntervalFrequencyEnum,
  IntervalFrequencyFactory,
  Months,
  ShortDays,
  ShortMonths,
  localEquivalentOfUTC,
} from 'mapistry-shared';
import { SavedFrequency } from '../contexts/FrequencyContext/types';
import { SelectableOption } from '../types/SelectableOption';

type IntervalFrequency = ReturnType<typeof IntervalFrequencyFactory.For>;

const DefaultDays = [Days.MON, Days.TUE, Days.WED, Days.THU, Days.FRI];

const DaysOptions = Object.values(Days).map((day) => ({
  value: day,
  label: ShortDays[day][0],
})) as SelectableOption<Days>[];

export const customFrequencyIntervalOptions = [
  { value: IntervalFrequencyEnum.DAY, label: 'Day' },
  { value: IntervalFrequencyEnum.WEEK, label: 'Week' },
  { value: IntervalFrequencyEnum.MONTH, label: 'Month' },
  { value: IntervalFrequencyEnum.YEAR, label: 'Year' },
];

export const getRepeatOptions = (
  frequency?: IntervalFrequencyEnum | null,
  customFrequency?: CustomFrequency | null,
) => {
  if (!customFrequency) {
    return [];
  }
  const { every, startMonth } = customFrequency;

  if (frequency === IntervalFrequencyEnum.DAY && every === 1) {
    return DaysOptions;
  }

  if (
    frequency === IntervalFrequencyEnum.MONTH &&
    [1, 2, 3, 4, 6].includes(every)
  ) {
    let months = Object.values(Months);
    if (every <= 1) {
      return months.map((month) => ({
        value: month,
        label: ShortMonths[month].substr(0, 3),
      })) as SelectableOption<Months>[];
    }

    if (startMonth != null) {
      const offset = startMonth % every;
      months = months.slice(offset).concat(months.slice(0, offset));
    }

    return months.reduce((acc, month, i) => {
      const mod = i % every;
      if (mod === 0) {
        acc.push({
          value: month,
          label: ShortMonths[month].substr(0, 3),
        });
      } else if (mod === every - 1) {
        const last = acc[acc.length - 1];
        /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
        last.label = `${last.label}-${ShortMonths[month].substr(0, 3)}`;
      }
      return acc;
    }, [] as SelectableOption<Months>[]);
  }

  return [];
};

export const defaultSelectedRepeatOn = (
  frequency?: IntervalFrequencyEnum | null,
  customFrequency?: CustomFrequency,
) => {
  const repeatOptions = getRepeatOptions(frequency, customFrequency);

  if (!repeatOptions.length) {
    return [];
  }
  if (frequency === IntervalFrequencyEnum.DAY) {
    return DefaultDays;
  }
  if (frequency === IntervalFrequencyEnum.MONTH) {
    return (repeatOptions as []).map(
      (o: SelectableOption<Days> | SelectableOption<Months>) => o.value,
    ) as Days[] | Months[];
  }
  return [];
};

const createDateOptions = (
  intervalFrequency: IntervalFrequency,
  earliestDate: Date,
  latestFrequencyDate: Date | undefined,
) => {
  const end = new Date(earliestDate.getTime());
  end.setUTCFullYear(end.getUTCFullYear() + 1);
  const interval = { start: earliestDate, end };
  const microIntervals = intervalFrequency.getMicroIntervals(interval);

  // don't allow picking the exact same date as the last frequency has
  const firstIndex =
    latestFrequencyDate &&
    startOfDay(earliestDate) <= startOfDay(latestFrequencyDate)
      ? 1
      : 0;
  const dates = microIntervals.slice(firstIndex).map((mi) => mi.start);

  return {
    intervalFrequency,
    dates,
  };
};

const dateOptionsFrequency = (
  selectedIntervalFrequency: IntervalFrequency,
  latestIntervalFrequency: IntervalFrequency,
) => {
  if (
    latestIntervalFrequency.valueOf() === 0 ||
    (selectedIntervalFrequency < latestIntervalFrequency &&
      selectedIntervalFrequency.valueOf() !== 0 &&
      !(
        selectedIntervalFrequency.isCustom &&
        latestIntervalFrequency.frequency === IntervalFrequencyEnum.MONTH &&
        latestIntervalFrequency.customFrequency?.every > 1
      ))
  ) {
    if (
      selectedIntervalFrequency.isCustom &&
      selectedIntervalFrequency.frequency === IntervalFrequencyEnum.MONTH &&
      selectedIntervalFrequency.customFrequency?.every > 1
    ) {
      return IntervalFrequencyFactory.For(selectedIntervalFrequency.frequency, {
        ...selectedIntervalFrequency.customFrequency,
        every: 1,
        startMonth: 0,
        startDay: 1,
      });
    }
    return selectedIntervalFrequency;
  }
  return latestIntervalFrequency;
};

/*
  TODO: when we have the start date of the calendar (ie. module start date)
    we need to use it as an additional constraint on the earliest selectable
    date.
*/
export const getDateOptions = (
  frequencies?: SavedFrequency[],
  selectedFrequency?: IntervalFrequencyEnum | null,
  customFrequency?: CustomFrequency | null,
) => {
  let earliestDate = new Date();
  earliestDate.setMonth(0, 1);
  let latestIntervalFrequency = IntervalFrequencyFactory.For(
    IntervalFrequencyEnum.NEVER,
  );
  let latestFrequencyDate;
  const selectedIntervalFrequency = IntervalFrequencyFactory.For(
    selectedFrequency || null,
    customFrequency,
  );
  const latestFrequencyModel =
    frequencies && frequencies[frequencies.length - 1];
  if (latestFrequencyModel) {
    latestIntervalFrequency = IntervalFrequencyFactory.For(
      latestFrequencyModel.frequency,
      latestFrequencyModel.customFrequency,
    );
    latestFrequencyDate = localEquivalentOfUTC(latestFrequencyModel.startDate);
    earliestDate =
      latestFrequencyDate > earliestDate ? latestFrequencyDate : earliestDate;
  }

  const optionsFrequency = dateOptionsFrequency(
    selectedIntervalFrequency,
    latestIntervalFrequency,
  );

  return createDateOptions(optionsFrequency, earliestDate, latestFrequencyDate);
};

// TODO: Move to mapistry-shared used in backend too
export const customFrequencyOffset = (
  frequency?: IntervalFrequencyEnum | null,
  givenDate?: Date | null,
  isDueDate?: boolean,
) => {
  if (!givenDate) {
    return {
      startMonth: undefined,
      startDay: undefined,
    };
  }
  let startDate = new Date(givenDate.getTime());
  if (
    frequency === IntervalFrequencyEnum.MONTH ||
    frequency === IntervalFrequencyEnum.YEAR
  ) {
    if (isDueDate) {
      startDate = addDays(givenDate, 1);
    }
    return {
      startMonth: startDate.getMonth(),
      startDay: startDate.getDate(),
    };
  }
  if (frequency === IntervalFrequencyEnum.WEEK) {
    if (isDueDate) {
      startDate = addDays(givenDate, 1);
    }
    return {
      startMonth: undefined,
      startDay: startDate.getDay(),
    };
  }
  return {
    startMonth: undefined,
    startDay: undefined,
  };
};
