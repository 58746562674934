class BaseValidator {
  constructor(templateField, submissionField) {
    this.templateField = templateField;
    this.submissionField = submissionField;
  }

  validate() {
    const errorMessages = [];
    const requiredErrorMessage = this.requiredValidation();

    if (requiredErrorMessage) {
      errorMessages.push(requiredErrorMessage);
    } else {
      const fieldValidation = this.fieldValidation();
      if (fieldValidation.length) {
        return errorMessages.concat(fieldValidation);
      }
    }

    return errorMessages;
  }

  fieldValidation() {
    return [];
  }

  getValue() {
    return this.submissionField && this.submissionField.fieldValue;
  }

  requiredValidation() {
    if (this.templateField.required && this.isSubmissionEmpty()) {
      return 'This field must have a value';
    }
    return null;
  }

  isSubmissionEmpty() {
    return (
      this.submissionField == null ||
      this.submissionField.fieldValue == null ||
      (this.templateField.type === 'boolean' &&
        this.submissionField.fieldValue === 'Unselected') ||
      this.submissionField.fieldValue.length === 0
    );
  }
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default BaseValidator;
