import TrashIcon from '@svg/trash.svg';
import _get from 'lodash.get';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import { fetchUsersAction } from '../../../actions/user';
import APP from '../../../config';
import { UserType } from '../../propTypes';
import withProvider from '../../withProvider';
import AddButton from '../buttons/AddButton';
import UserSelect from './UserSelect';

class AddAssignee extends Component {
  componentDidMount() {
    const { fetchUsers, projectId } = this.props;
    fetchUsers(projectId);
  }

  handleAdd = () => {
    const { assignees, onChange } = this.props;

    const firstDeleted = assignees.findIndex((user) => user && user.deleted);
    const newAssignees =
      firstDeleted < 0
        ? [...assignees, null]
        : [
            ...assignees.slice(0, firstDeleted),
            null,
            ...assignees.slice(firstDeleted),
          ];

    onChange(newAssignees);
  };

  handleChange = (user, targetIndex) => {
    const { assignees, onChange, multi, trackChanges } = this.props;

    const newUser = user ? { ...user } : null;
    const newAssignees = assignees.map((usr, idx) =>
      idx === targetIndex ? newUser : usr,
    );

    if (multi && trackChanges) {
      if (newUser) {
        const previouslyDeleted = newAssignees.findIndex(
          (a) => a && a.deleted && a.userId === newUser.userId,
        );
        if (previouslyDeleted < 0) {
          newUser.added = true;
        } else {
          newAssignees.splice(previouslyDeleted, 1);
        }
      }

      const deletedUser = assignees[targetIndex];
      if (deletedUser && !deletedUser.added) {
        newAssignees.push({ ...deletedUser, deleted: true });
      }
    }

    onChange(newAssignees);
  };

  handleRemove = (index) => {
    const { assignees, disabled, onChange, multi, trackChanges } = this.props;
    if (disabled) {
      return;
    }

    const userToRemove = assignees[index];
    const newAssignees = [
      ...assignees.slice(0, index),
      ...assignees.slice(index + 1),
    ];
    if (multi && trackChanges && userToRemove && !userToRemove.added) {
      newAssignees.push({ ...userToRemove, deleted: true });
    }

    onChange(newAssignees);
  };

  render() {
    const { disabled, isLoading, multi, trackChanges, users } = this.props;

    const assignees =
      multi && trackChanges
        ? // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line react/destructuring-assignment
          this.props.assignees.filter(
            (user) => !user || (user && !user.deleted),
          )
        : // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line react/destructuring-assignment
          this.props.assignees;

    const selectedUserIds = assignees.reduce(
      (ids, user) => (user ? [...ids, user.userId] : ids),
      [],
    );
    const availableUsers = users.filter(
      (user) => !selectedUserIds.includes(user.userId),
    );
    const disableRemoveIconClass = disabled
      ? ' add-assignee__remove-icon--disabled'
      : '';

    return (
      <>
        {assignees.map((user, index) => (
          <div
            key={user ? user.userId : uuidv4()}
            className="add-assignee__user-row"
          >
            <div className="add-assignee__select">
              <UserSelect
                disabled={disabled}
                isLoading={isLoading}
                selected={user}
                users={availableUsers}
                onSelect={(newUser) => this.handleChange(newUser, index)}
              />
            </div>
            {/* TODO: Fix this the next time the file is edited. */}
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
            <span
              className={`add-assignee__remove-icon${disableRemoveIconClass}`}
              onClick={() => this.handleRemove(index)}
            >
              <TrashIcon className="m-icon" />
            </span>
          </div>
        ))}
        {(!assignees.length || multi) && (
          <AddButton
            disabled={disabled}
            label="Add an assignee"
            onClick={this.handleAdd}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { user } = state;
  const { projectId } = APP;

  return {
    isLoading: _get(user.isFetching, 'users', false),
    projectId,
    users: user.forProject === projectId ? user.users : [],
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchUsers: (projectId) => dispatch(fetchUsersAction(projectId)),
});

AddAssignee.propTypes = {
  assignees: PropTypes.arrayOf(UserType).isRequired,
  disabled: PropTypes.bool,
  fetchUsers: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  multi: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired,
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/forbid-prop-types
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
  trackChanges: PropTypes.bool,
};

AddAssignee.defaultProps = {
  disabled: false,
  multi: false,
  trackChanges: false,
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default withProvider(
  connect(mapStateToProps, mapDispatchToProps)(AddAssignee),
);
