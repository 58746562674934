import styled from 'styled-components';
import { ContainedButton } from './ContainedButton';

export const ContainedPrimaryButton = styled(ContainedButton)`
  text-shadow: ${({ theme }) => `${theme.colors.blue48} 0 0 2px`};
  background-color: ${({ theme }) => theme.colors.lightBlue};

  &:hover {
    /* Need to explicitly set color for buttons that are rendered with <a> element,
       e.g. when href prop is passed */
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.darkBlue};
  }

  &:focus {
    /* Need to explicitly set color for buttons that are rendered with <a> element,
       e.g. when href prop is passed */
    color: ${({ theme }) => theme.colors.white};
  }

  &:active {
    background-color: #1fb1d4;
  }

  &.Mui-disabled {
    color: ${({ theme }) => theme.colors.gray999};
    text-shadow: none;
    background-color: ${({ theme }) => theme.colors.grayeee};

    & svg {
      fill: ${({ theme }) => theme.colors.gray999};
    }
  }
`;
