import { createAction } from 'redux-actions';
import { CALL_API } from '../middleware/api';
import * as HTTP from '../utils/HTTP';
import APP from '../config';

import {
  CREATE_EMISSION_CALCULATED_VALUE_REQUEST,
  CREATE_EMISSION_CALCULATED_VALUE_SUCCESS,
  CREATE_EMISSION_CALCULATED_VALUE_FAILURE,
  CREATE_EMISSION_FACTOR_REQUEST,
  CREATE_EMISSION_FACTOR_SUCCESS,
  CREATE_EMISSION_FACTOR_FAILURE,
  CREATE_EMISSION_ITEM_GROUP_REQUEST,
  CREATE_EMISSION_ITEM_GROUP_SUCCESS,
  CREATE_EMISSION_ITEM_GROUP_FAILURE,
  CREATE_EMISSION_LIMIT_ITEM_REQUEST,
  CREATE_EMISSION_LIMIT_ITEM_SUCCESS,
  CREATE_EMISSION_LIMIT_ITEM_FAILURE,
  CREATE_EMISSION_LOGGED_ITEM_REQUEST,
  CREATE_EMISSION_LOGGED_ITEM_SUCCESS,
  CREATE_EMISSION_LOGGED_ITEM_FAILURE,
  CREATE_EMISSION_LOG_TABS_REQUEST,
  CREATE_EMISSION_LOG_TABS_SUCCESS,
  CREATE_EMISSION_LOG_TABS_FAILURE,
  BATCH_UPDATE_EMISSION_LOGS_REQUEST,
  BATCH_UPDATE_EMISSION_LOGS_SUCCESS,
  BATCH_UPDATE_EMISSION_LOGS_FAILURE,
  CREATE_EMISSION_ROLLING_CALCULATION_REQUEST,
  CREATE_EMISSION_ROLLING_CALCULATION_SUCCESS,
  CREATE_EMISSION_ROLLING_CALCULATION_FAILURE,
  CREATE_EMISSION_UNIT_CONVERSION_REQUEST,
  CREATE_EMISSION_UNIT_CONVERSION_SUCCESS,
  CREATE_EMISSION_UNIT_CONVERSION_FAILURE,
  DELETE_EMISSION_CALCULATED_VALUE_REQUEST,
  DELETE_EMISSION_CALCULATED_VALUE_SUCCESS,
  DELETE_EMISSION_CALCULATED_VALUE_FAILURE,
  DELETE_EMISSION_FACTOR_REQUEST,
  DELETE_EMISSION_FACTOR_SUCCESS,
  DELETE_EMISSION_FACTOR_FAILURE,
  DELETE_EMISSION_ITEM_GROUP_REQUEST,
  DELETE_EMISSION_ITEM_GROUP_SUCCESS,
  DELETE_EMISSION_ITEM_GROUP_FAILURE,
  DELETE_EMISSION_LIMIT_ITEM_REQUEST,
  DELETE_EMISSION_LIMIT_ITEM_SUCCESS,
  DELETE_EMISSION_LIMIT_ITEM_FAILURE,
  DELETE_EMISSION_LOGGED_ITEM_REQUEST,
  DELETE_EMISSION_LOGGED_ITEM_SUCCESS,
  DELETE_EMISSION_LOGGED_ITEM_FAILURE,
  DELETE_EMISSION_LOG_TABS_REQUEST,
  DELETE_EMISSION_LOG_TABS_SUCCESS,
  DELETE_EMISSION_LOG_TABS_FAILURE,
  DELETE_EMISSION_LOG_REQUEST,
  DELETE_EMISSION_LOG_SUCCESS,
  DELETE_EMISSION_LOG_FAILURE,
  DELETE_EMISSION_ROLLING_CALCULATION_REQUEST,
  DELETE_EMISSION_ROLLING_CALCULATION_SUCCESS,
  DELETE_EMISSION_ROLLING_CALCULATION_FAILURE,
  DELETE_EMISSION_UNIT_CONVERSION_REQUEST,
  DELETE_EMISSION_UNIT_CONVERSION_SUCCESS,
  DELETE_EMISSION_UNIT_CONVERSION_FAILURE,
  FETCH_EMISSION_CALCULATED_VALUES_REQUEST,
  FETCH_EMISSION_CALCULATED_VALUES_SUCCESS,
  FETCH_EMISSION_CALCULATED_VALUES_FAILURE,
  FETCH_EMISSION_FACTORS_REQUEST,
  FETCH_EMISSION_FACTORS_SUCCESS,
  FETCH_EMISSION_FACTORS_FAILURE,
  FETCH_EMISSION_ITEM_GROUPS_REQUEST,
  FETCH_EMISSION_ITEM_GROUPS_SUCCESS,
  FETCH_EMISSION_ITEM_GROUPS_FAILURE,
  FETCH_EMISSION_LIMIT_ITEMS_REQUEST,
  FETCH_EMISSION_LIMIT_ITEMS_SUCCESS,
  FETCH_EMISSION_LIMIT_ITEMS_FAILURE,
  FETCH_EMISSION_LOGGED_ITEMS_REQUEST,
  FETCH_EMISSION_LOGGED_ITEMS_SUCCESS,
  FETCH_EMISSION_LOGGED_ITEMS_FAILURE,
  FETCH_EMISSION_LOG_REQUEST,
  FETCH_EMISSION_LOG_SUCCESS,
  FETCH_EMISSION_LOG_FAILURE,
  FETCH_EMISSION_LOGS_REQUEST,
  FETCH_EMISSION_LOGS_SUCCESS,
  FETCH_EMISSION_LOGS_FAILURE,
  FETCH_EMISSION_LOG_PARAMETERS_REQUEST,
  FETCH_EMISSION_LOG_PARAMETERS_SUCCESS,
  FETCH_EMISSION_LOG_PARAMETERS_FAILURE,
  FETCH_EMISSION_LOG_TABS_REQUEST,
  FETCH_EMISSION_LOG_TABS_SUCCESS,
  FETCH_EMISSION_LOG_TABS_FAILURE,
  FETCH_EMISSION_ROLLING_CALCULATIONS_REQUEST,
  FETCH_EMISSION_ROLLING_CALCULATIONS_SUCCESS,
  FETCH_EMISSION_ROLLING_CALCULATIONS_FAILURE,
  FETCH_EMISSION_UNIT_CONVERSIONS_REQUEST,
  FETCH_EMISSION_UNIT_CONVERSIONS_SUCCESS,
  FETCH_EMISSION_UNIT_CONVERSIONS_FAILURE,
  FETCH_EMISSION_STATISTICS_REQUEST,
  FETCH_EMISSION_STATISTICS_SUCCESS,
  FETCH_EMISSION_STATISTICS_FAILURE,
  FETCH_EMISSION_MICRO_INTERVAL_STATISTICS_REQUEST,
  FETCH_EMISSION_MICRO_INTERVAL_STATISTICS_SUCCESS,
  FETCH_EMISSION_MICRO_INTERVAL_STATISTICS_FAILURE,
  RESET_EMISSION_LOGS,
  UPDATE_EMISSION_CALCULATED_VALUE_REQUEST,
  UPDATE_EMISSION_CALCULATED_VALUE_SUCCESS,
  UPDATE_EMISSION_CALCULATED_VALUE_FAILURE,
  UPDATE_EMISSION_FACTOR_REQUEST,
  UPDATE_EMISSION_FACTOR_SUCCESS,
  UPDATE_EMISSION_FACTOR_FAILURE,
  UPDATE_EMISSION_ITEM_GROUP_REQUEST,
  UPDATE_EMISSION_ITEM_GROUP_SUCCESS,
  UPDATE_EMISSION_ITEM_GROUP_FAILURE,
  UPDATE_EMISSION_LIMIT_ITEM_REQUEST,
  UPDATE_EMISSION_LIMIT_ITEM_SUCCESS,
  UPDATE_EMISSION_LIMIT_ITEM_FAILURE,
  UPDATE_EMISSION_LOGGED_ITEM_REQUEST,
  UPDATE_EMISSION_LOGGED_ITEM_SUCCESS,
  UPDATE_EMISSION_LOGGED_ITEM_FAILURE,
  UPDATE_EMISSION_LOG_TABS_REQUEST,
  UPDATE_EMISSION_LOG_TABS_SUCCESS,
  UPDATE_EMISSION_LOG_TABS_FAILURE,
  UPDATE_EMISSION_ROLLING_CALCULATION_REQUEST,
  UPDATE_EMISSION_ROLLING_CALCULATION_SUCCESS,
  UPDATE_EMISSION_ROLLING_CALCULATION_FAILURE,
  UPDATE_EMISSION_TRACKING_CALENDARS,
  UPDATE_EMISSION_UNIT_CONVERSION_REQUEST,
  UPDATE_EMISSION_UNIT_CONVERSION_SUCCESS,
  UPDATE_EMISSION_UNIT_CONVERSION_FAILURE,
} from '.';

const createEmissionCalculatedValueRequest = createAction(
  CREATE_EMISSION_CALCULATED_VALUE_REQUEST,
);
const createEmissionCalculatedValueSuccess = createAction(
  CREATE_EMISSION_CALCULATED_VALUE_SUCCESS,
);
const createEmissionCalculatedValueFailure = createAction(
  CREATE_EMISSION_CALCULATED_VALUE_FAILURE,
);

const createEmissionFactorRequest = createAction(
  CREATE_EMISSION_FACTOR_REQUEST,
);
const createEmissionFactorSuccess = createAction(
  CREATE_EMISSION_FACTOR_SUCCESS,
);
const createEmissionFactorFailure = createAction(
  CREATE_EMISSION_FACTOR_FAILURE,
);

const createEmissionItemGroupRequest = createAction(
  CREATE_EMISSION_ITEM_GROUP_REQUEST,
);
const createEmissionItemGroupSuccess = createAction(
  CREATE_EMISSION_ITEM_GROUP_SUCCESS,
);
const createEmissionItemGroupFailure = createAction(
  CREATE_EMISSION_ITEM_GROUP_FAILURE,
);

const createEmissionLimitItemRequest = createAction(
  CREATE_EMISSION_LIMIT_ITEM_REQUEST,
);
const createEmissionLimitItemSuccess = createAction(
  CREATE_EMISSION_LIMIT_ITEM_SUCCESS,
);
const createEmissionLimitItemFailure = createAction(
  CREATE_EMISSION_LIMIT_ITEM_FAILURE,
);

const createEmissionLoggedItemRequest = createAction(
  CREATE_EMISSION_LOGGED_ITEM_REQUEST,
);
const createEmissionLoggedItemSuccess = createAction(
  CREATE_EMISSION_LOGGED_ITEM_SUCCESS,
);
const createEmissionLoggedItemFailure = createAction(
  CREATE_EMISSION_LOGGED_ITEM_FAILURE,
);

const createEmissionLogTabsRequest = createAction(
  CREATE_EMISSION_LOG_TABS_REQUEST,
);
const createEmissionLogTabsSuccess = createAction(
  CREATE_EMISSION_LOG_TABS_SUCCESS,
);
const createEmissionLogTabsFailure = createAction(
  CREATE_EMISSION_LOG_TABS_FAILURE,
);

const batchUpdateEmissionLogsRequest = createAction(
  BATCH_UPDATE_EMISSION_LOGS_REQUEST,
);
const batchUpdateEmissionLogsSuccess = createAction(
  BATCH_UPDATE_EMISSION_LOGS_SUCCESS,
);
const batchUpdateEmissionLogsFailure = createAction(
  BATCH_UPDATE_EMISSION_LOGS_FAILURE,
);

const createEmissionRollingCalculationRequest = createAction(
  CREATE_EMISSION_ROLLING_CALCULATION_REQUEST,
);
const createEmissionRollingCalculationSuccess = createAction(
  CREATE_EMISSION_ROLLING_CALCULATION_SUCCESS,
);
const createEmissionRollingCalculationFailure = createAction(
  CREATE_EMISSION_ROLLING_CALCULATION_FAILURE,
);

const createEmissionUnitConversionRequest = createAction(
  CREATE_EMISSION_UNIT_CONVERSION_REQUEST,
);
const createEmissionUnitConversionSuccess = createAction(
  CREATE_EMISSION_UNIT_CONVERSION_SUCCESS,
);
const createEmissionUnitConversionFailure = createAction(
  CREATE_EMISSION_UNIT_CONVERSION_FAILURE,
);

const deleteEmissionCalculatedValueRequest = createAction(
  DELETE_EMISSION_CALCULATED_VALUE_REQUEST,
);
const deleteEmissionCalculatedValueSuccess = createAction(
  DELETE_EMISSION_CALCULATED_VALUE_SUCCESS,
);
const deleteEmissionCalculatedValueFailure = createAction(
  DELETE_EMISSION_CALCULATED_VALUE_FAILURE,
);

const deleteEmissionFactorRequest = createAction(
  DELETE_EMISSION_FACTOR_REQUEST,
);
const deleteEmissionFactorSuccess = createAction(
  DELETE_EMISSION_FACTOR_SUCCESS,
);
const deleteEmissionFactorFailure = createAction(
  DELETE_EMISSION_FACTOR_FAILURE,
);

const deleteEmissionItemGroupRequest = createAction(
  DELETE_EMISSION_ITEM_GROUP_REQUEST,
);
const deleteEmissionItemGroupSuccess = createAction(
  DELETE_EMISSION_ITEM_GROUP_SUCCESS,
);
const deleteEmissionItemGroupFailure = createAction(
  DELETE_EMISSION_ITEM_GROUP_FAILURE,
);

const deleteEmissionLimitItemRequest = createAction(
  DELETE_EMISSION_LIMIT_ITEM_REQUEST,
);
const deleteEmissionLimitItemSuccess = createAction(
  DELETE_EMISSION_LIMIT_ITEM_SUCCESS,
);
const deleteEmissionLimitItemFailure = createAction(
  DELETE_EMISSION_LIMIT_ITEM_FAILURE,
);

const deleteEmissionLoggedItemRequest = createAction(
  DELETE_EMISSION_LOGGED_ITEM_REQUEST,
);
const deleteEmissionLoggedItemSuccess = createAction(
  DELETE_EMISSION_LOGGED_ITEM_SUCCESS,
);
const deleteEmissionLoggedItemFailure = createAction(
  DELETE_EMISSION_LOGGED_ITEM_FAILURE,
);

const deleteEmissionLogTabsRequest = createAction(
  DELETE_EMISSION_LOG_TABS_REQUEST,
);
const deleteEmissionLogTabsSuccess = createAction(
  DELETE_EMISSION_LOG_TABS_SUCCESS,
);
const deleteEmissionLogTabsFailure = createAction(
  DELETE_EMISSION_LOG_TABS_FAILURE,
);

const deleteEmissionLogsRequest = createAction(DELETE_EMISSION_LOG_REQUEST);
const deleteEmissionLogsSuccess = createAction(DELETE_EMISSION_LOG_SUCCESS);
const deleteEmissionLogsFailure = createAction(DELETE_EMISSION_LOG_FAILURE);

const deleteEmissionRollingCalculationRequest = createAction(
  DELETE_EMISSION_ROLLING_CALCULATION_REQUEST,
);
const deleteEmissionRollingCalculationSuccess = createAction(
  DELETE_EMISSION_ROLLING_CALCULATION_SUCCESS,
);
const deleteEmissionRollingCalculationFailure = createAction(
  DELETE_EMISSION_ROLLING_CALCULATION_FAILURE,
);

const deleteEmissionUnitConversionRequest = createAction(
  DELETE_EMISSION_UNIT_CONVERSION_REQUEST,
);
const deleteEmissionUnitConversionSuccess = createAction(
  DELETE_EMISSION_UNIT_CONVERSION_SUCCESS,
);
const deleteEmissionUnitConversionFailure = createAction(
  DELETE_EMISSION_UNIT_CONVERSION_FAILURE,
);

const fetchEmissionCalculatedValuesRequest = createAction(
  FETCH_EMISSION_CALCULATED_VALUES_REQUEST,
);
const fetchEmissionCalculatedValuesSuccess = createAction(
  FETCH_EMISSION_CALCULATED_VALUES_SUCCESS,
);
const fetchEmissionCalculatedValuesFailure = createAction(
  FETCH_EMISSION_CALCULATED_VALUES_FAILURE,
);

const fetchEmissionFactorsRequest = createAction(
  FETCH_EMISSION_FACTORS_REQUEST,
);
const fetchEmissionFactorsSuccess = createAction(
  FETCH_EMISSION_FACTORS_SUCCESS,
);
const fetchEmissionFactorsFailure = createAction(
  FETCH_EMISSION_FACTORS_FAILURE,
);

const fetchEmissionItemGroupsRequest = createAction(
  FETCH_EMISSION_ITEM_GROUPS_REQUEST,
);
const fetchEmissionItemGroupsSuccess = createAction(
  FETCH_EMISSION_ITEM_GROUPS_SUCCESS,
);
const fetchEmissionItemGroupsFailure = createAction(
  FETCH_EMISSION_ITEM_GROUPS_FAILURE,
);

const fetchEmissionLoggedItemsRequest = createAction(
  FETCH_EMISSION_LOGGED_ITEMS_REQUEST,
);
const fetchEmissionLoggedItemsSuccess = createAction(
  FETCH_EMISSION_LOGGED_ITEMS_SUCCESS,
);
const fetchEmissionLoggedItemsFailure = createAction(
  FETCH_EMISSION_LOGGED_ITEMS_FAILURE,
);

const fetchEmissionLogsRequest = createAction(FETCH_EMISSION_LOGS_REQUEST);
const fetchEmissionLogsSuccess = createAction(FETCH_EMISSION_LOGS_SUCCESS);
const fetchEmissionLogsFailure = createAction(FETCH_EMISSION_LOGS_FAILURE);

const fetchEmissionLogRequest = createAction(FETCH_EMISSION_LOG_REQUEST);
const fetchEmissionLogSuccess = createAction(FETCH_EMISSION_LOG_SUCCESS);
const fetchEmissionLogFailure = createAction(FETCH_EMISSION_LOG_FAILURE);

const fetchEmissionLogParametersRequest = createAction(
  FETCH_EMISSION_LOG_PARAMETERS_REQUEST,
);
const fetchEmissionLogParametersSuccess = createAction(
  FETCH_EMISSION_LOG_PARAMETERS_SUCCESS,
);
const fetchEmissionLogParametersFailure = createAction(
  FETCH_EMISSION_LOG_PARAMETERS_FAILURE,
);

const fetchEmissionLogTabsRequest = createAction(
  FETCH_EMISSION_LOG_TABS_REQUEST,
);
const fetchEmissionLogTabsSuccess = createAction(
  FETCH_EMISSION_LOG_TABS_SUCCESS,
);
const fetchEmissionLogTabsFailure = createAction(
  FETCH_EMISSION_LOG_TABS_FAILURE,
);

const fetchEmissionLimitItemsRequest = createAction(
  FETCH_EMISSION_LIMIT_ITEMS_REQUEST,
);
const fetchEmissionLimitItemsSuccess = createAction(
  FETCH_EMISSION_LIMIT_ITEMS_SUCCESS,
);
const fetchEmissionLimitItemsFailure = createAction(
  FETCH_EMISSION_LIMIT_ITEMS_FAILURE,
);

const fetchEmissionRollingCalculationsRequest = createAction(
  FETCH_EMISSION_ROLLING_CALCULATIONS_REQUEST,
);
const fetchEmissionRollingCalculationsSuccess = createAction(
  FETCH_EMISSION_ROLLING_CALCULATIONS_SUCCESS,
);
const fetchEmissionRollingCalculationsFailure = createAction(
  FETCH_EMISSION_ROLLING_CALCULATIONS_FAILURE,
);

const fetchEmissionUnitConversionsRequest = createAction(
  FETCH_EMISSION_UNIT_CONVERSIONS_REQUEST,
);
const fetchEmissionUnitConversionsSuccess = createAction(
  FETCH_EMISSION_UNIT_CONVERSIONS_SUCCESS,
);
const fetchEmissionUnitConversionsFailure = createAction(
  FETCH_EMISSION_UNIT_CONVERSIONS_FAILURE,
);

const fetchEmissionStatisticsRequest = createAction(
  FETCH_EMISSION_STATISTICS_REQUEST,
);
const fetchEmissionStatisticsSuccess = createAction(
  FETCH_EMISSION_STATISTICS_SUCCESS,
  undefined,
  (_, req) => ({ req }),
);
const fetchEmissionStatisticsFailure = createAction(
  FETCH_EMISSION_STATISTICS_FAILURE,
);

const fetchEmissionMicroIntervalStatisticsRequest = createAction(
  FETCH_EMISSION_MICRO_INTERVAL_STATISTICS_REQUEST,
);
const fetchEmissionMicroIntervalStatisticsSuccess = createAction(
  FETCH_EMISSION_MICRO_INTERVAL_STATISTICS_SUCCESS,
  undefined,
  (_, req) => ({ req }),
);
const fetchEmissionMicroIntervalStatisticsFailure = createAction(
  FETCH_EMISSION_MICRO_INTERVAL_STATISTICS_FAILURE,
);

const updateEmissionCalculatedValueRequest = createAction(
  UPDATE_EMISSION_CALCULATED_VALUE_REQUEST,
);
const updateEmissionCalculatedValueSuccess = createAction(
  UPDATE_EMISSION_CALCULATED_VALUE_SUCCESS,
);
const updateEmissionCalculatedValueFailure = createAction(
  UPDATE_EMISSION_CALCULATED_VALUE_FAILURE,
);

const updateEmissionFactorRequest = createAction(
  UPDATE_EMISSION_FACTOR_REQUEST,
);
const updateEmissionFactorSuccess = createAction(
  UPDATE_EMISSION_FACTOR_SUCCESS,
);
const updateEmissionFactorFailure = createAction(
  UPDATE_EMISSION_FACTOR_FAILURE,
);

const updateEmissionLimitItemRequest = createAction(
  UPDATE_EMISSION_LIMIT_ITEM_REQUEST,
);
const updateEmissionLimitItemSuccess = createAction(
  UPDATE_EMISSION_LIMIT_ITEM_SUCCESS,
);
const updateEmissionLimitItemFailure = createAction(
  UPDATE_EMISSION_LIMIT_ITEM_FAILURE,
);

const updateEmissionItemGroupRequest = createAction(
  UPDATE_EMISSION_ITEM_GROUP_REQUEST,
);
const updateEmissionItemGroupSuccess = createAction(
  UPDATE_EMISSION_ITEM_GROUP_SUCCESS,
);
const updateEmissionItemGroupFailure = createAction(
  UPDATE_EMISSION_ITEM_GROUP_FAILURE,
);

const updateEmissionLoggedItemRequest = createAction(
  UPDATE_EMISSION_LOGGED_ITEM_REQUEST,
);
const updateEmissionLoggedItemSuccess = createAction(
  UPDATE_EMISSION_LOGGED_ITEM_SUCCESS,
);
const updateEmissionLoggedItemFailure = createAction(
  UPDATE_EMISSION_LOGGED_ITEM_FAILURE,
);

const updateEmissionLogTabsRequest = createAction(
  UPDATE_EMISSION_LOG_TABS_REQUEST,
);
const updateEmissionLogTabsSuccess = createAction(
  UPDATE_EMISSION_LOG_TABS_SUCCESS,
);
const updateEmissionLogTabsFailure = createAction(
  UPDATE_EMISSION_LOG_TABS_FAILURE,
);

const updateEmissionRollingCalculationRequest = createAction(
  UPDATE_EMISSION_ROLLING_CALCULATION_REQUEST,
);
const updateEmissionRollingCalculationSuccess = createAction(
  UPDATE_EMISSION_ROLLING_CALCULATION_SUCCESS,
);
const updateEmissionRollingCalculationFailure = createAction(
  UPDATE_EMISSION_ROLLING_CALCULATION_FAILURE,
);

const updateEmissionUnitConversionRequest = createAction(
  UPDATE_EMISSION_UNIT_CONVERSION_REQUEST,
);
const updateEmissionUnitConversionSuccess = createAction(
  UPDATE_EMISSION_UNIT_CONVERSION_SUCCESS,
);
const updateEmissionUnitConversionFailure = createAction(
  UPDATE_EMISSION_UNIT_CONVERSION_FAILURE,
);

export const resetEmissionLogsAction = createAction(RESET_EMISSION_LOGS);

export const updateEmissionTrackingCalendarsAction = createAction(
  UPDATE_EMISSION_TRACKING_CALENDARS,
);

export function fetchEmissionItemGroupsAction(projectId, logProjectId) {
  return {
    [CALL_API]: {
      endpoint: `/api/v2/${projectId}/generic-logs/${logProjectId}/item-groups`,
      lifecycleActions: [
        fetchEmissionItemGroupsRequest,
        fetchEmissionItemGroupsSuccess,
        fetchEmissionItemGroupsFailure,
      ],
      verb: HTTP.Types.GET,
    },
  };
}

export function fetchEmissionLoggedItemsAction(projectId, logProjectId) {
  return {
    [CALL_API]: {
      endpoint: `/api/v2/${projectId}/generic-logs/${logProjectId}/logged-items`,
      lifecycleActions: [
        fetchEmissionLoggedItemsRequest,
        fetchEmissionLoggedItemsSuccess,
        fetchEmissionLoggedItemsFailure,
      ],
      verb: HTTP.Types.GET,
    },
  };
}

export function fetchEmissionFactorsAction(projectId, logProjectId) {
  return {
    [CALL_API]: {
      endpoint: `/api/v2/${projectId}/generic-logs/${logProjectId}/emission-factors`,
      lifecycleActions: [
        fetchEmissionFactorsRequest,
        fetchEmissionFactorsSuccess,
        fetchEmissionFactorsFailure,
      ],
      verb: HTTP.Types.GET,
    },
  };
}

export function fetchEmissionLogAction(projectId, logDatetime, logProjectId) {
  return {
    [CALL_API]: {
      endpoint: `/api/v2/${projectId}/generic-logs/${logProjectId}/logs?logDatetime=${logDatetime}`,
      lifecycleActions: [
        fetchEmissionLogRequest,
        fetchEmissionLogSuccess,
        fetchEmissionLogFailure,
      ],
      verb: HTTP.Types.GET,
    },
  };
}

export function fetchEmissionLogsAction(
  projectId,
  logProjectId,
  parameters,
  interval,
) {
  return {
    [CALL_API]: {
      endpoint: `/api/v2/${projectId}/generic-logs/${logProjectId}/logs/list`,
      data: { parameters, interval },
      lifecycleActions: [
        fetchEmissionLogsRequest,
        fetchEmissionLogsSuccess,
        fetchEmissionLogsFailure,
      ],
      verb: HTTP.Types.POST,
    },
  };
}

export function fetchEmissionCalculatedValuesAction(projectId, logProjectId) {
  return {
    [CALL_API]: {
      endpoint: `/api/v2/${projectId}/generic-logs/${logProjectId}/calculated-values`,
      lifecycleActions: [
        fetchEmissionCalculatedValuesRequest,
        fetchEmissionCalculatedValuesSuccess,
        fetchEmissionCalculatedValuesFailure,
      ],
      verb: HTTP.Types.GET,
    },
  };
}

export function fetchEmissionLogParametersAction(projectId, logProjectId) {
  const url = `/api/v2/${projectId}/generic-logs/${logProjectId}/logs/parameters`;
  return {
    [CALL_API]: {
      endpoint: url,
      lifecycleActions: [
        fetchEmissionLogParametersRequest,
        fetchEmissionLogParametersSuccess,
        fetchEmissionLogParametersFailure,
      ],
      verb: HTTP.Types.GET,
    },
  };
}

export function fetchEmissionLogTabsAction(projectId, logProjectId) {
  const url = `/api/v2/${projectId}/generic-logs/${logProjectId}/log-tabs`;
  return {
    [CALL_API]: {
      endpoint: url,
      lifecycleActions: [
        fetchEmissionLogTabsRequest,
        fetchEmissionLogTabsSuccess,
        fetchEmissionLogTabsFailure,
      ],
      verb: HTTP.Types.GET,
    },
  };
}

export function fetchEmissionLimitItemsAction(projectId, logProjectId) {
  return {
    [CALL_API]: {
      endpoint: `/api/v2/${projectId}/generic-logs/${logProjectId}/limit-items`,
      lifecycleActions: [
        fetchEmissionLimitItemsRequest,
        fetchEmissionLimitItemsSuccess,
        fetchEmissionLimitItemsFailure,
      ],
      verb: HTTP.Types.GET,
    },
  };
}

export function fetchEmissionRollingCalculationsAction(
  projectId,
  logProjectId,
) {
  return {
    [CALL_API]: {
      endpoint: `/api/v2/${projectId}/generic-logs/${logProjectId}/rolling-calculations`,
      lifecycleActions: [
        fetchEmissionRollingCalculationsRequest,
        fetchEmissionRollingCalculationsSuccess,
        fetchEmissionRollingCalculationsFailure,
      ],
      verb: HTTP.Types.GET,
    },
  };
}

export function fetchEmissionUnitConversionsAction(projectId, logProjectId) {
  return {
    [CALL_API]: {
      endpoint: `/api/v2/${projectId}/generic-logs/${logProjectId}/unit-conversions`,
      lifecycleActions: [
        fetchEmissionUnitConversionsRequest,
        fetchEmissionUnitConversionsSuccess,
        fetchEmissionUnitConversionsFailure,
      ],
      verb: HTTP.Types.GET,
    },
  };
}

export function fetchEmissionStatisticsAction(
  projectId,
  parameters,
  logProjectId,
) {
  return {
    [CALL_API]: {
      endpoint: `/api/v2/${projectId}/generic-logs/${logProjectId}/logs/statistics`,
      data: parameters,
      lifecycleActions: [
        fetchEmissionStatisticsRequest,
        fetchEmissionStatisticsSuccess,
        fetchEmissionStatisticsFailure,
      ],
      verb: HTTP.Types.POST,
    },
  };
}

export function fetchEmissionMicroIntervalStatisticsAction(
  projectId,
  parameters,
  logProjectId,
) {
  return {
    [CALL_API]: {
      endpoint: `/api/v2/${projectId}/generic-logs/${logProjectId}/logs/micro-interval-statistics`,
      data: parameters,
      lifecycleActions: [
        fetchEmissionMicroIntervalStatisticsRequest,
        fetchEmissionMicroIntervalStatisticsSuccess,
        fetchEmissionMicroIntervalStatisticsFailure,
      ],
      verb: HTTP.Types.POST,
    },
  };
}

export function createEmissionFactorAction(projectId, emissionFactor) {
  return {
    [CALL_API]: {
      data: emissionFactor,
      endpoint: `/api/v2/${projectId}/generic-logs/${emissionFactor.logProjectId}/emission-factors`,
      lifecycleActions: [
        createEmissionFactorRequest,
        createEmissionFactorSuccess,
        createEmissionFactorFailure,
      ],
      verb: HTTP.Types.POST,
    },
  };
}

export function createEmissionItemGroupAction(projectId, group) {
  return {
    [CALL_API]: {
      data: group,
      endpoint: `/api/v2/${projectId}/generic-logs/${group.logProjectId}/item-groups`,
      lifecycleActions: [
        createEmissionItemGroupRequest,
        createEmissionItemGroupSuccess,
        createEmissionItemGroupFailure,
      ],
      verb: HTTP.Types.POST,
    },
  };
}

export function createEmissionLoggedItemAction(projectId, loggedItem) {
  return {
    [CALL_API]: {
      data: loggedItem,
      endpoint: `/api/v2/${projectId}/generic-logs/${loggedItem.logProjectId}/logged-items`,
      lifecycleActions: [
        createEmissionLoggedItemRequest,
        createEmissionLoggedItemSuccess,
        createEmissionLoggedItemFailure,
      ],
      verb: HTTP.Types.POST,
    },
  };
}

export function createEmissionCalculatedValueAction(
  projectId,
  calculatedValue,
) {
  return {
    [CALL_API]: {
      data: calculatedValue,
      endpoint: `/api/v2/${projectId}/generic-logs/${calculatedValue.logProjectId}/calculated-values`,
      lifecycleActions: [
        createEmissionCalculatedValueRequest,
        createEmissionCalculatedValueSuccess,
        createEmissionCalculatedValueFailure,
      ],
      verb: HTTP.Types.POST,
    },
  };
}

export function createEmissionLimitItemAction(projectId, limitItem) {
  return {
    [CALL_API]: {
      data: limitItem,
      endpoint: `/api/v2/${projectId}/generic-logs/${limitItem.logProjectId}/limit-items`,
      lifecycleActions: [
        createEmissionLimitItemRequest,
        createEmissionLimitItemSuccess,
        createEmissionLimitItemFailure,
      ],
      verb: HTTP.Types.POST,
    },
  };
}

export function createEmissionLogTabsAction(projectId, logTab) {
  return {
    [CALL_API]: {
      data: logTab,
      endpoint: `/api/v2/${projectId}/generic-logs/${logTab.logProjectId}/log-tabs`,
      lifecycleActions: [
        createEmissionLogTabsRequest,
        createEmissionLogTabsSuccess,
        createEmissionLogTabsFailure,
      ],
      verb: HTTP.Types.POST,
    },
  };
}

export function batchUpdateEmissionLogsAction(projectId, logProjectId, logs) {
  return {
    [CALL_API]: {
      data: logs,
      endpoint: `/api/v2/${projectId}/generic-logs/${logProjectId}/logs/batch`,
      lifecycleActions: [
        batchUpdateEmissionLogsRequest,
        batchUpdateEmissionLogsSuccess,
        batchUpdateEmissionLogsFailure,
      ],
      verb: HTTP.Types.PUT, // upsert allowed
    },
  };
}

export function createEmissionRollingCalculationAction(
  projectId,
  rollingCalculation,
) {
  return {
    [CALL_API]: {
      data: rollingCalculation,
      endpoint: `/api/v2/${projectId}/generic-logs/${rollingCalculation.logProjectId}/rolling-calculations`,
      lifecycleActions: [
        createEmissionRollingCalculationRequest,
        createEmissionRollingCalculationSuccess,
        createEmissionRollingCalculationFailure,
      ],
      verb: HTTP.Types.POST,
    },
  };
}

export function createEmissionUnitConversionAction(projectId, unitConversion) {
  return {
    [CALL_API]: {
      data: unitConversion,
      endpoint: `/api/v2/${projectId}/generic-logs/${unitConversion.logProjectId}/unit-conversions`,
      lifecycleActions: [
        createEmissionUnitConversionRequest,
        createEmissionUnitConversionSuccess,
        createEmissionUnitConversionFailure,
      ],
      verb: HTTP.Types.POST,
    },
  };
}

export const downloadEmissionTrackingLog = (projectId, logProjectId) => {
  window.open(
    `${APP.apiUrl}/api/v2/${projectId}/generic-logs/${logProjectId}/logs/download`,
  );
  return null;
};

export function updateEmissionLimitItemAction(projectId, limitItem) {
  return {
    [CALL_API]: {
      data: limitItem,
      endpoint: `/api/v2/${projectId}/generic-logs/${limitItem.logProjectId}/limit-items/${limitItem.id}`,
      lifecycleActions: [
        updateEmissionLimitItemRequest,
        updateEmissionLimitItemSuccess,
        updateEmissionLimitItemFailure,
      ],
      verb: HTTP.Types.PUT,
    },
  };
}

export function updateEmissionItemGroupAction(projectId, itemGroup) {
  return {
    [CALL_API]: {
      data: itemGroup,
      endpoint: `/api/v2/${projectId}/generic-logs/${itemGroup.logProjectId}/item-groups/${itemGroup.id}`,
      lifecycleActions: [
        updateEmissionItemGroupRequest,
        updateEmissionItemGroupSuccess,
        updateEmissionItemGroupFailure,
      ],
      verb: HTTP.Types.PUT,
    },
  };
}

export function updateEmissionLoggedItemAction(projectId, loggedItem) {
  return {
    [CALL_API]: {
      data: loggedItem,
      endpoint: `/api/v2/${projectId}/generic-logs/${loggedItem.logProjectId}/logged-items/${loggedItem.id}`,
      lifecycleActions: [
        updateEmissionLoggedItemRequest,
        updateEmissionLoggedItemSuccess,
        updateEmissionLoggedItemFailure,
      ],
      verb: HTTP.Types.PUT,
    },
  };
}

export function updateEmissionFactorAction(projectId, emissionFactor) {
  return {
    [CALL_API]: {
      data: emissionFactor,
      endpoint: `/api/v2/${projectId}/generic-logs/${emissionFactor.logProjectId}/emission-factors/${emissionFactor.id}`,
      lifecycleActions: [
        updateEmissionFactorRequest,
        updateEmissionFactorSuccess,
        updateEmissionFactorFailure,
      ],
      verb: HTTP.Types.PUT,
    },
  };
}

export function updateEmissionCalculatedValueAction(
  projectId,
  calculatedValue,
) {
  return {
    [CALL_API]: {
      data: calculatedValue,
      endpoint: `/api/v2/${projectId}/generic-logs/${calculatedValue.logProjectId}/calculated-values/${calculatedValue.id}`,
      lifecycleActions: [
        updateEmissionCalculatedValueRequest,
        updateEmissionCalculatedValueSuccess,
        updateEmissionCalculatedValueFailure,
      ],
      verb: HTTP.Types.PUT,
    },
  };
}

export function updateEmissionLogTabsAction(projectId, logTab) {
  return {
    [CALL_API]: {
      data: logTab,
      endpoint: `/api/v2/${projectId}/generic-logs/${logTab.logProjectId}/log-tabs/${logTab.id}`,
      lifecycleActions: [
        updateEmissionLogTabsRequest,
        updateEmissionLogTabsSuccess,
        updateEmissionLogTabsFailure,
      ],
      verb: HTTP.Types.PUT,
    },
  };
}

export function updateEmissionRollingCalculationAction(
  projectId,
  rollingCalculation,
) {
  return {
    [CALL_API]: {
      data: rollingCalculation,
      endpoint: `/api/v2/${projectId}/generic-logs/${rollingCalculation.logProjectId}/rolling-calculations/${rollingCalculation.id}`,
      lifecycleActions: [
        updateEmissionRollingCalculationRequest,
        updateEmissionRollingCalculationSuccess,
        updateEmissionRollingCalculationFailure,
      ],
      verb: HTTP.Types.PUT,
    },
  };
}

export function updateEmissionUnitConversionAction(projectId, unitConversion) {
  return {
    [CALL_API]: {
      data: unitConversion,
      endpoint: `/api/v2/${projectId}/generic-logs/${unitConversion.logProjectId}/unit-conversions/${unitConversion.id}`,
      lifecycleActions: [
        updateEmissionUnitConversionRequest,
        updateEmissionUnitConversionSuccess,
        updateEmissionUnitConversionFailure,
      ],
      verb: HTTP.Types.PUT,
    },
  };
}

export function deleteEmissionFactorAction(
  projectId,
  logProjectId,
  emissionFactorId,
) {
  return {
    [CALL_API]: {
      endpoint: `/api/v2/${projectId}/generic-logs/${logProjectId}/emission-factors/${emissionFactorId}`,
      lifecycleActions: [
        deleteEmissionFactorRequest,
        deleteEmissionFactorSuccess,
        deleteEmissionFactorFailure,
      ],
      verb: HTTP.Types.DELETE,
    },
  };
}

export function deleteEmissionItemGroupAction(
  projectId,
  logProjectId,
  itemGroupId,
) {
  return {
    [CALL_API]: {
      endpoint: `/api/v2/${projectId}/generic-logs/${logProjectId}/item-groups/${itemGroupId}`,
      lifecycleActions: [
        deleteEmissionItemGroupRequest,
        deleteEmissionItemGroupSuccess,
        deleteEmissionItemGroupFailure,
      ],
      verb: HTTP.Types.DELETE,
    },
  };
}

export function deleteEmissionLoggedItemAction(
  projectId,
  logProjectId,
  loggedItemId,
) {
  return {
    [CALL_API]: {
      endpoint: `/api/v2/${projectId}/generic-logs/${logProjectId}/logged-items/${loggedItemId}`,
      lifecycleActions: [
        deleteEmissionLoggedItemRequest,
        deleteEmissionLoggedItemSuccess,
        deleteEmissionLoggedItemFailure,
      ],
      verb: HTTP.Types.DELETE,
    },
  };
}

export function deleteEmissionCalculatedValueAction(
  projectId,
  logProjectId,
  calculatedValueId,
) {
  return {
    [CALL_API]: {
      endpoint: `/api/v2/${projectId}/generic-logs/${logProjectId}/calculated-values/${calculatedValueId}`,
      lifecycleActions: [
        deleteEmissionCalculatedValueRequest,
        deleteEmissionCalculatedValueSuccess,
        deleteEmissionCalculatedValueFailure,
      ],
      verb: HTTP.Types.DELETE,
    },
  };
}

export function deleteEmissionLimitItemAction(
  projectId,
  logProjectId,
  limitItemId,
) {
  return {
    [CALL_API]: {
      endpoint: `/api/v2/${projectId}/generic-logs/${logProjectId}/limit-items/${limitItemId}`,
      lifecycleActions: [
        deleteEmissionLimitItemRequest,
        deleteEmissionLimitItemSuccess,
        deleteEmissionLimitItemFailure,
      ],
      verb: HTTP.Types.DELETE,
    },
  };
}

export function deleteEmissionLogTabsAction(projectId, logProjectId, logTabId) {
  return {
    [CALL_API]: {
      endpoint: `/api/v2/${projectId}/generic-logs/${logProjectId}/log-tabs/${logTabId}`,
      lifecycleActions: [
        deleteEmissionLogTabsRequest,
        deleteEmissionLogTabsSuccess,
        deleteEmissionLogTabsFailure,
      ],
      verb: HTTP.Types.DELETE,
    },
  };
}

export function deleteEmissionLogAction(projectId, logProjectId, options) {
  const { logDatetime } = options;
  const url = `/api/v2/${projectId}/generic-logs/${logProjectId}/logs?datetime=${logDatetime}`;

  return {
    [CALL_API]: {
      endpoint: url,
      lifecycleActions: [
        deleteEmissionLogsRequest,
        deleteEmissionLogsSuccess,
        deleteEmissionLogsFailure,
      ],
      verb: HTTP.Types.DELETE,
    },
  };
}

export function deleteEmissionRollingCalculationAction(
  projectId,
  logProjectId,
  rollingCalculationId,
) {
  return {
    [CALL_API]: {
      endpoint: `/api/v2/${projectId}/generic-logs/${logProjectId}/rolling-calculations/${rollingCalculationId}`,
      lifecycleActions: [
        deleteEmissionRollingCalculationRequest,
        deleteEmissionRollingCalculationSuccess,
        deleteEmissionRollingCalculationFailure,
      ],
      verb: HTTP.Types.DELETE,
    },
  };
}

export function deleteEmissionUnitConversionAction(
  projectId,
  logProjectId,
  unitConversionId,
) {
  return {
    [CALL_API]: {
      endpoint: `/api/v2/${projectId}/generic-logs/${logProjectId}/unit-conversions/${unitConversionId}`,
      lifecycleActions: [
        deleteEmissionUnitConversionRequest,
        deleteEmissionUnitConversionSuccess,
        deleteEmissionUnitConversionFailure,
      ],
      verb: HTTP.Types.DELETE,
    },
  };
}
