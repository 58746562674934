import { createAction } from 'redux-actions';
import _get from 'lodash.get';
import { CALL_API } from '../middleware/api';
import * as HTTP from '../utils/HTTP';

import {
  FETCH_CURRENT_USER_SAVED_SETTING_REQUEST,
  FETCH_CURRENT_USER_SAVED_SETTING_SUCCESS,
  FETCH_CURRENT_USER_SAVED_SETTING_FAILURE,
  SET_CURRENT_USER_SAVED_SETTING_REQUEST,
  SET_CURRENT_USER_SAVED_SETTING_SUCCESS,
  SET_CURRENT_USER_SAVED_SETTING_FAILURE,
  FETCH_CURRENT_USER_WIDGET_SETTING_REQUEST,
  FETCH_CURRENT_USER_WIDGET_SETTING_SUCCESS,
  FETCH_CURRENT_USER_WIDGET_SETTING_FAILURE,
  SET_CURRENT_USER_WIDGET_SETTING_REQUEST,
  SET_CURRENT_USER_WIDGET_SETTING_SUCCESS,
  SET_CURRENT_USER_WIDGET_SETTING_FAILURE,
  SET_CURRENT_USER_SPECIFIC_WIDGET_SETTING_REQUEST,
  SET_CURRENT_USER_SPECIFIC_WIDGET_SETTING_SUCCESS,
  SET_CURRENT_USER_SPECIFIC_WIDGET_SETTING_FAILURE,
  DELETE_CURRENT_USER_WIDGET_SETTING_REQUEST,
  DELETE_CURRENT_USER_WIDGET_SETTING_SUCCESS,
  DELETE_CURRENT_USER_WIDGET_SETTING_FAILURE,
} from '.';

const fetchCurrentUserSavedSettingRequest = createAction(
  FETCH_CURRENT_USER_SAVED_SETTING_REQUEST,
);
const fetchCurrentUserSavedSettingSuccess = createAction(
  FETCH_CURRENT_USER_SAVED_SETTING_SUCCESS,
);
const fetchCurrentUserSavedSettingFailure = createAction(
  FETCH_CURRENT_USER_SAVED_SETTING_FAILURE,
);

const setCurrentUserSavedSettingRequest = createAction(
  SET_CURRENT_USER_SAVED_SETTING_REQUEST,
);
const setCurrentUserSavedSettingSuccess = createAction(
  SET_CURRENT_USER_SAVED_SETTING_SUCCESS,
);
const setCurrentUserSavedSettingFailure = createAction(
  SET_CURRENT_USER_SAVED_SETTING_FAILURE,
);

const setCurrentUserWidgetSettingRequest = createAction(
  SET_CURRENT_USER_WIDGET_SETTING_REQUEST,
);
const setCurrentUserWidgetSettingSuccess = createAction(
  SET_CURRENT_USER_WIDGET_SETTING_SUCCESS,
);
const setCurrentUserWidgetSettingFailure = createAction(
  SET_CURRENT_USER_WIDGET_SETTING_FAILURE,
);

const setCurrentUserSpecificWidgetSettingRequest = createAction(
  SET_CURRENT_USER_SPECIFIC_WIDGET_SETTING_REQUEST,
);
const setCurrentUserSpecificWidgetSettingSuccess = createAction(
  SET_CURRENT_USER_SPECIFIC_WIDGET_SETTING_SUCCESS,
  undefined,
  (_, req) => ({ req }),
);
const setCurrentUserSpecificWidgetSettingFailure = createAction(
  SET_CURRENT_USER_SPECIFIC_WIDGET_SETTING_FAILURE,
);

const deleteCurrentUserWidgetSettingRequest = createAction(
  DELETE_CURRENT_USER_WIDGET_SETTING_REQUEST,
);
const deleteCurrentUserWidgetSettingSuccess = createAction(
  DELETE_CURRENT_USER_WIDGET_SETTING_SUCCESS,
  undefined,
  (_, req) => ({ req }),
);
const deleteCurrentUserWidgetSettingFailure = createAction(
  DELETE_CURRENT_USER_WIDGET_SETTING_FAILURE,
);

const fetchCurrentUserWidgetSettingRequest = createAction(
  FETCH_CURRENT_USER_WIDGET_SETTING_REQUEST,
);
const fetchCurrentUserWidgetSettingSuccess = createAction(
  FETCH_CURRENT_USER_WIDGET_SETTING_SUCCESS,
);
const fetchCurrentUserWidgetSettingFailure = createAction(
  FETCH_CURRENT_USER_WIDGET_SETTING_FAILURE,
);

export function fetchCurrentUserSavedSettingAction(projectId, settingKey) {
  return {
    [CALL_API]: {
      endpoint: `/api/v2/users/user-settings?settingKey=${settingKey}&projectId=${projectId}`,
      lifecycleActions: [
        fetchCurrentUserSavedSettingRequest,
        fetchCurrentUserSavedSettingSuccess,
        fetchCurrentUserSavedSettingFailure,
      ],
      verb: HTTP.Types.GET,
    },
  };
}

export function setCurrentUserSavedSettingAction(projectId, userSetting) {
  return {
    [CALL_API]: {
      data: userSetting,
      endpoint: `/api/v2/users/user-settings?projectId=${projectId}`,
      lifecycleActions: [
        setCurrentUserSavedSettingRequest,
        setCurrentUserSavedSettingSuccess,
        setCurrentUserSavedSettingFailure,
      ],
      verb: HTTP.Types.POST,
    },
  };
}

export function fetchCurrentUserWidgetSettingAction(
  projectId,
  settingKey,
  type,
) {
  return {
    [CALL_API]: {
      endpoint: `/api/v2/users/user-settings/widgets?settingKey=${settingKey}&type=${type}&projectId=${projectId}`,
      lifecycleActions: [
        fetchCurrentUserWidgetSettingRequest,
        fetchCurrentUserWidgetSettingSuccess,
        fetchCurrentUserWidgetSettingFailure,
      ],
      verb: HTTP.Types.GET,
    },
    settingKey,
    widgetType: type,
  };
}

export function setCurrentUserWidgetSettingAction(projectId, widgetSetting) {
  return {
    [CALL_API]: {
      data: widgetSetting,
      endpoint: `/api/v2/users/user-settings/widgets?projectId=${projectId}`,
      lifecycleActions: [
        setCurrentUserWidgetSettingRequest,
        setCurrentUserWidgetSettingSuccess,
        setCurrentUserWidgetSettingFailure,
      ],
      verb: HTTP.Types.POST,
    },
  };
}

export function setCurrentUserSpecificWidgetSettingAction(
  projectId,
  settingKey,
  type,
  widgetSettingsId,
  widgetSettingsVersion,
  widgetSettingsValue,
) {
  return {
    [CALL_API]: (state) => {
      const prevWidgetSettings = _get(
        state.currentUser.userSettings,
        `${projectId}.${settingKey}.${type}`,
      );
      const data = {
        settingKey,
        settingValue: {
          type,
          widgetSettings: {
            ...prevWidgetSettings,
            [widgetSettingsId]: {
              widgetSettingsVersion,
              ...widgetSettingsValue,
            },
          },
        },
      };
      return {
        data,
        endpoint: `/api/v2/users/user-settings/widgets?projectId=${projectId}`,
        lifecycleActions: [
          setCurrentUserSpecificWidgetSettingRequest,
          setCurrentUserSpecificWidgetSettingSuccess,
          setCurrentUserSpecificWidgetSettingFailure,
        ],
        verb: HTTP.Types.POST,
        meta: {
          projectId,
          settingKey,
          type,
          widgetSettingsId,
          widgetSettingsValue,
        },
      };
    },
  };
}

export function deleteCurrentUserWidgetSettingAction(
  projectId,
  settingKey,
  type,
  widgetSettingsId,
) {
  return {
    [CALL_API]: {
      endpoint: `/api/v2/users/user-settings/widgets?settingKey=${settingKey}&type=${type}&widgetSettingsId=${widgetSettingsId}&projectId=${projectId}`,
      lifecycleActions: [
        deleteCurrentUserWidgetSettingRequest,
        deleteCurrentUserWidgetSettingSuccess,
        deleteCurrentUserWidgetSettingFailure,
      ],
      verb: HTTP.Types.DELETE,
      meta: { projectId, settingKey, type, widgetSettingsId },
    },
  };
}
