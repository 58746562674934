import qs from 'qs';
import {
  MapFeatureModel,
  MapFeatureResponse,
  MapWaterSampleType,
} from 'mapistry-shared';
import http from './httpClient';

export interface FetchMapFeaturesProps {
  projectId?: string;
  tags: string[];
}

export async function fetchMapFeatures({
  projectId,
  tags,
}: FetchMapFeaturesProps) {
  const res = await http.get<MapFeatureResponse[]>(
    `/api/v2/${projectId}/map-features?${qs.stringify({ layerTag: tags })}`,
  );
  return res.data;
}

// This endpoint is used from old map code that's why it returns snakecase model
export async function addDependantFeatures(
  projectId: string,
  featureId: string,
  sampleTypes: MapWaterSampleType[],
) {
  const res = await http.put<MapFeatureModel[]>(
    `/api/v2/${projectId}/map-features/${featureId}/sample-types`,
    sampleTypes,
  );
  return res.data;
}

export async function updateFeatureNameAliases(
  projectId: string,
  featureId: string,
  aliases: string[] | null,
) {
  const res = await http.put<MapFeatureModel[]>(
    `/api/v2/${projectId}/map-features/${featureId}/aliases`,
    aliases,
  );
  return res.data;
}
