import React from 'react';
import PropTypes from 'prop-types';
import { isThisWeek } from 'date-fns';
import {
  CalendarEventStatus,
  IntervalFrequencyEnum,
  IntervalFrequencyFactory,
  localEquivalentOfUTC,
} from 'mapistry-shared';
import { DatePicker, IconButton, MapistryTooltip } from '../../../elements';

const HighFrequencyEventCalendar = (props) => {
  const {
    currentDate,
    eventGroups,
    tooltipData,
    tooltipTitle,
    isEventClickable,
    onEventClick,
  } = props;

  const isTooMuchData = (frequency, customFrequency) => {
    const intervalFrequency = IntervalFrequencyFactory.For(
      frequency,
      customFrequency,
    );
    return intervalFrequency.isVeryHigh();
  };

  const colorClass = (events) => {
    if (isTooMuchData(events[0].frequency, events[0].customFrequency)) {
      return 'expand';
    }
    if (events.every((e) => e.status === CalendarEventStatus.COMPLETE)) {
      return 'success';
    }
    if (events.some((e) => e.status === CalendarEventStatus.OVERDUE)) {
      return 'failure';
    }
    if (events.some((e) => e.status === CalendarEventStatus.UPCOMING)) {
      return 'warning';
    }
    if (
      events.some(
        (e) =>
          (e.status === CalendarEventStatus.NOT_NEEDED ||
            e.status === CalendarEventStatus.EXTRA) &&
          localEquivalentOfUTC(e.startDate) < new Date(),
      )
    ) {
      return 'neutral';
    }
    return 'default';
  };

  const weekWrapperClass = (events, date) => {
    let className = '';
    const frequency = IntervalFrequencyFactory.For(
      events[0].frequency,
      events[0].customFrequency,
    );
    if (frequency.isWeeklyPermutation()) {
      className = `${colorClass(events)}-highlight`;
      if (date.getDay() === 0) {
        className += ' firstHighlight';
      } else if (date.getDay() === 6) {
        className += ' endHighlight';
      }
    }
    return className;
  };

  const renderAsNeededEvents = (events) =>
    events.reduce((acc, event) => {
      if (event.attachments.length && !event.attachments[0].isDefault) {
        const color =
          event.status === CalendarEventStatus.COMPLETE ? 'success' : 'warning';
        acc.push(
          <span
            key={`${event.eventDate}-${event.eventNumber}`}
            className={`m-event-calendar__daily__badge ${color}-highlight`}
          />,
        );
      }
      return acc;
    }, []);

  const renderEvents = (events) =>
    events.map((event) => (
      <span
        key={event.key}
        className={`m-event-calendar__daily__badge ${colorClass([
          event,
        ])}-highlight`}
      />
    ));

  const renderDots = (events) => {
    const sampleEvent = events[0];
    const frequency = IntervalFrequencyFactory.For(
      sampleEvent.frequency,
      sampleEvent.customFrequency,
    );
    if (sampleEvent.frequency === IntervalFrequencyEnum.HOUR) {
      return renderEvents([sampleEvent]);
    }
    if (!frequency.hasDueDate()) {
      return renderAsNeededEvents(events);
    }
    if (!frequency.isWeeklyPermutation()) {
      return renderEvents(events);
    }
    return [];
  };

  const emptyDay = (dayClassName, date) => (
    <div>
      <div className={dayClassName}>
        <span className="m-event-calendar__day">{date.getDate()}</span>
      </div>
    </div>
  );

  const getEvents = (dateToRender) => {
    const eventGroupKey = Object.keys(eventGroups).find((keyDate) => {
      const event = eventGroups[keyDate][0];
      if (event.dueDate) {
        return dateToRender <= event.dueDate && dateToRender >= event.startDate;
      }
      return dateToRender.getTime() === event.startDate.getTime();
    });
    return eventGroupKey ? eventGroups[eventGroupKey] : [];
  };

  const renderDay = (dateToRender, dayInCurrentMonth) => {
    const events = getEvents(dateToRender);
    const intervalFrequency =
      events.length &&
      IntervalFrequencyFactory.For(
        events[0].frequency,
        events[0].customFrequency,
      );
    const isWeekly =
      intervalFrequency && intervalFrequency.isWeeklyPermutation();
    if (!dayInCurrentMonth && !isWeekly) {
      return <div />;
    }
    const isInCurrentWeek = isThisWeek(dateToRender);
    const isClickable =
      isEventClickable &&
      (dateToRender <= new Date() || (isWeekly && isInCurrentWeek));
    let dayClassName = 'm-event-calendar__day-wrapper';
    dayClassName += isClickable ? '' : ' m-event-calendar__disabled-day';
    if (!events.length || (!isClickable && !intervalFrequency.hasDueDate())) {
      return emptyDay(dayClassName, dateToRender);
    }
    const tooMuchData = isTooMuchData(
      events[0].frequency,
      events[0].customFrequency,
    );
    return (
      <MapistryTooltip title={tooltipTitle(events, tooMuchData, tooltipData)}>
        <div className={weekWrapperClass(events, dateToRender)}>
          <IconButton
            className={dayClassName}
            disabled={!tooMuchData && !isClickable}
            onClick={(clickEvent) =>
              onEventClick(clickEvent, events, tooMuchData)
            }
          >
            <div>
              <span className="m-event-calendar__day">
                {dateToRender.getDate()}
              </span>
              <div className="m-event-calendar__dots">{renderDots(events)}</div>
            </div>
          </IconButton>
        </div>
      </MapistryTooltip>
    );
  };

  return (
    <div className="m-event-calendar__date-picker-wrapper">
      <DatePicker
        autoOk
        variant="static"
        renderDay={renderDay}
        value={currentDate}
      />
    </div>
  );
};

/*
  eventGroups: {
    Date: [
      {
        startDate: Date,
        dueDate: Date,
        frequency: string,
        status: string,
        attachments: [
          {
            date: Date
          }
        ]
      }
    ]
  }
*/

HighFrequencyEventCalendar.propTypes = {
  currentDate: PropTypes.instanceOf(Date).isRequired,
  eventGroups: PropTypes.shape({}).isRequired,
  isEventClickable: PropTypes.bool,
  onEventClick: PropTypes.func,
  tooltipData: PropTypes.any, // eslint-disable-line react/forbid-prop-types
  tooltipTitle: PropTypes.func.isRequired,
};

HighFrequencyEventCalendar.defaultProps = {
  isEventClickable: false,
  onEventClick: null,
  tooltipData: null,
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default HighFrequencyEventCalendar;
