import React from 'react';
import { Button, Tooltip } from '@monorepo/shared/components';
import { EditLogModalProps } from './EditLogModalTypes';

const OverWriteDataConfirmation = ({
  onOverwriteCancel,
  onOverwriteProceed,
}: Pick<EditLogModalProps, 'onOverwriteCancel' | 'onOverwriteProceed'>) => (
  <div className="air-emission-log-modal__conflict-container">
    <p className="air-emission-log-modal__conflict-text">
      You are about to overwrite existing data that was entered in the form on
      this date and time.
    </p>
    <p className="text-center">What you would you like to do?</p>
    <div>
      <Tooltip
        placement="top"
        title="This will overwrite the data that you just entered in the form"
      >
        <span>
          <Button
            className="air-emission-log-modal__conflict-button"
            color="danger"
            onClick={onOverwriteProceed}
          >
            Overwrite entered data
          </Button>
        </span>
      </Tooltip>
      <Tooltip
        placement="top"
        title="This will keep the data that you just entered in the form"
      >
        <span>
          <Button
            className="air-emission-log-modal__conflict-button"
            color="secondary"
            onClick={onOverwriteCancel}
          >
            Keep entered data
          </Button>
        </span>
      </Tooltip>
    </div>
  </div>
);

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default OverWriteDataConfirmation;
