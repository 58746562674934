import React from 'react';
import Alert from '@material-ui/lab/Alert';

export enum MessageBoxStatus {
  STATUS_ERROR = 'error',
  STATUS_INFO = 'info',
  STATUS_SUCCESS = 'success',
  STATUS_WARNING = 'warning',
}

type AlertProps = React.ComponentProps<typeof Alert>;

interface MessageBoxProps {
  message: AlertProps['children'];
  status: MessageBoxStatus;
}

export function MessageBox({ message, status }: MessageBoxProps) {
  return <Alert severity={status}>{message}</Alert>;
}
