import { SheetConstraint } from '@flatfile/api/api';
import { isNullOrUndefined } from '@monorepo/old-web/js/utils';
import _omitBy from 'lodash.omitby';
import { WorkbookConfig } from '../types/flatfileTypes';

export type WithoutNilValues<T> = Pick<
  T,
  {
    [Prop in keyof T]: T[Prop] extends null | undefined ? never : Prop;
  }[keyof T]
>;

/**
 * Flatfile's API complains at types not matching the required types
 * if they're submitted with undefined or null
 */
export function removeKeysWithNilValues<T extends Record<string, any>>(
  objectWithNilValues: T,
): WithoutNilValues<T> {
  return _omitBy(objectWithNilValues, isNullOrUndefined) as WithoutNilValues<T>;
}

/**
 * Undocumented Flatfile bug (March 2024), where invalid
 * constraint names break the composite uniqueness constraint.
 * TODO: according to Flatfile Customer support, this got fixed in June 2024,
 * so after validating that that's true, we could delete this check
 */
function checkThatUniqueConstraintNameIsValid(name: string) {
  const forbiddenCharacters = [',', '(', ')'];

  forbiddenCharacters.forEach((char) => {
    if (name.includes(char)) {
      throw new Error(
        `The constraint name ${name} includes the forbidden character "${char}"`,
      );
    }
  });
}

/**
 * Flatfile has some undocumented internal bugs that we're trying to protect against
 * with our own validation.
 */
export function checkWorkbookSettingsAgainstUndocumentedBugs(
  workbook: WorkbookConfig,
) {
  const firstSheet = workbook.sheets && workbook.sheets[0];
  if (firstSheet) {
    const sheetConstraints = firstSheet.constraints;
    sheetConstraints?.forEach((constraint) => {
      if (constraint.type === 'unique') {
        checkThatUniqueConstraintNameIsValid(constraint.name);
      }
    });
  }
}

/**
 * Flatfile adds  `Composite [...] is not unique` around the name
 */
export function uniqueConstraintSetting(name: string, fieldKeys: string[]) {
  const compositeUniqueConstraint: SheetConstraint = {
    name,
    type: 'unique',
    fields: fieldKeys,
    strategy: 'concat',
  };

  return compositeUniqueConstraint;
}
