import PropTypes from 'prop-types';
import React from 'react';

import DocumentService from '../../services/DocumentService';
import { Document } from './Document';
import MapistryTooltip from './MapistryTooltip';
import { Photo } from './Photo';
import PhotoEditor from './PhotoEditor';
import AddButton from './buttons/AddButton';

const ALL = 'All';
const IMAGE = 'Image';

const DOCUMENT_TYPES = {
  [ALL]:
    'image/*,video/*' +
    ',text/plain,text/csv,application/pdf' +
    ',application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document' +
    ',application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' +
    ',application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation' +
    ',application/zip',
  [IMAGE]: 'image/*',
};
const MAX_FILE_SIZE_BYTES = 20 * 1024 * 1024;

class DocumentUploader extends React.Component {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/state-in-constructor
  state = {
    loadingDocuments: [],
    loadedDocuments: [],
    editPhoto: false,
    selectedPhoto: null,
    copiedTemplate: false,
  };

  inputRef;

  documentService = new DocumentService();

  constructor(props) {
    super(props);

    this.handleAddDocument = this.handleAddDocument.bind(this);
    this.handleProgress = this.handleProgress.bind(this);
    this.handleDocumentLoaded = this.handleDocumentLoaded.bind(this);
    this.handleDocumentRemoved = this.handleDocumentRemoved.bind(this);
    this.handlePhotoChange = this.handlePhotoChange.bind(this);
    this.handlePhotoSave = this.handlePhotoSave.bind(this);
    this.handlePhotoEdit = this.handlePhotoEdit.bind(this);
    this.handleEditorSave = this.handleEditorSave.bind(this);
  }

  componentDidMount() {
    const { projectId, folderSlug, templateFolderSlug } = this.props;
    if (!folderSlug) {
      return;
    }

    Promise.all([
      templateFolderSlug
        ? this.documentService.getAll(projectId, templateFolderSlug, false)
        : [],
      this.documentService.getAll(projectId, folderSlug, false),
    ])
      .then((promiseResult) => {
        const [templateDocs, docs] = promiseResult;

        const allDocuments = templateDocs
          .map((doc) => ({ ...doc, folderSlug, fromTemplate: true }))
          .concat(docs);

        this.setState({ loadedDocuments: allDocuments, copiedTemplate: false });
      })
      .catch(() =>
        this.showError(
          'There was a problem retrieving your files, please try again.',
        ),
      );
  }

  setErrorState(errorDoc) {
    this.setState((state) => {
      const { loadedDocuments } = state;

      const updatedLoadedDocuments = loadedDocuments.map((doc) => ({
        ...doc,
        error: doc.fileId === errorDoc.fileId,
        processing: false,
      }));
      return { loadedDocuments: updatedLoadedDocuments };
    });
  }

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/sort-comp
  handleAddDocument(event) {
    const { projectId } = this.props;
    const { loadingDocuments } = this.state;

    const newDocuments = [];
    event.target.files.forEach((file) => {
      if (file.size > MAX_FILE_SIZE_BYTES) {
        this.showError(
          `Maximum file size is ${MAX_FILE_SIZE_BYTES / 1024 / 1024} MB`,
        );
        return;
      }

      const doc = {
        fileId: file.name,
        projectId,
        name: file.name,
        totalSize: file.size,
        loadedSize: 0,
        processing: true,
        mimeType: file.type,
        file,
      };
      newDocuments.push(doc);

      if (doc.mimeType.startsWith('image/')) {
        doc.fileReader = this.readAndUploadImage(file);
      } else {
        this.uploadDocument(doc);
      }
    });
    const allLoading = loadingDocuments.concat(newDocuments);
    this.setState({ loadingDocuments: allLoading });
  }

  readAndUploadImage(file) {
    const fileReader = new FileReader();
    fileReader.onprogress = this.handleProgress;
    fileReader.onload = this.handleDocumentLoaded;
    fileReader.readAsDataURL(file);
    return fileReader;
  }

  handleProgress(event) {
    const { loadingDocuments } = this.state;

    const newDocument = loadingDocuments.find(
      (d) => d.fileReader === event.target,
    );
    newDocument.loadedSize = event.loaded;
    this.setState({ loadingDocuments });
  }

  handleDocumentLoaded(event) {
    const fileReader = event.target;
    const newDocument = this.updateLoadingDocumentSrc(fileReader);

    return this.uploadDocument(newDocument);
  }

  updateLoadingDocumentSrc(fileReader) {
    const { loadingDocuments } = this.state;

    let newDocument;
    const updatedLoadingDocuments = loadingDocuments.map((doc) => {
      if (doc.fileReader === fileReader) {
        newDocument = { ...doc, src: fileReader.result };
        return newDocument;
      }
      return doc;
    });
    this.setState({ loadingDocuments: updatedLoadingDocuments });
    return newDocument;
  }

  copyDocumentsAndUpdateIds(documents) {
    const { projectId, folderSlug, templateFolderSlug } = this.props;

    const { loadedDocuments, copiedTemplate } = this.state;

    if (
      !templateFolderSlug ||
      copiedTemplate ||
      loadedDocuments.every((doc) => !doc.fromTemplate)
    ) {
      return Promise.resolve(documents);
    }

    return this.documentService
      .copy(projectId, templateFolderSlug, folderSlug)
      .then((copiedDocuments) => {
        this.setState((state) => {
          const newDocs = copiedDocuments.concat(
            state.loadedDocuments.filter((doc) => !doc.fromTemplate),
          );
          return { loadedDocuments: newDocs, copiedTemplate: true };
        });

        if (!documents) {
          return documents;
        }

        return documents.map((doc) => {
          if (!doc.fromTemplate) {
            return doc;
          }
          const original = copiedDocuments.find((d) => d.fileId === doc.fileId);
          return { ...doc, id: original.id };
        });
      });
  }

  uploadDocument(newDocument) {
    const { projectId, folderSlug, onChange } = this.props;

    return this.copyDocumentsAndUpdateIds()
      .then(() =>
        this.documentService.create(
          projectId,
          folderSlug,
          newDocument.file,
          false,
        ),
      )
      .then((uploadResult) => {
        this.setState((state) => {
          const { loadingDocuments, loadedDocuments } = state;

          const updatedLoadingDocuments = loadingDocuments.filter(
            (d) => d !== newDocument,
          );
          const updatedLoadedDocuments = [...loadedDocuments, uploadResult];
          if (onChange) {
            onChange({
              folderSlug,
              loadingDocuments: updatedLoadingDocuments,
              loadedDocuments: updatedLoadedDocuments,
            });
          }
          return {
            loadingDocuments: updatedLoadingDocuments,
            loadedDocuments: updatedLoadedDocuments,
          };
        });
      })
      .catch(() => {
        this.setState((state) => {
          const { loadingDocuments } = state;

          const updatedLoadingDocuments = loadingDocuments.filter(
            (d) => d !== newDocument,
          );
          return { loadingDocuments: updatedLoadingDocuments };
        });
        this.showError(
          'There was a problem uploading your file, please try again.',
        );
      });
  }

  handleDocumentRemoved(removedDoc) {
    const { onChange, folderSlug } = this.props;

    this.copyDocumentsAndUpdateIds([removedDoc])
      .then((removedDocs) => this.documentService.remove(removedDocs[0]))
      .then(() => {
        this.setState((state) => {
          const { loadedDocuments, loadingDocuments } = state;

          const updated = loadedDocuments.filter(
            (d) => d.fileId !== removedDoc.fileId,
          );
          if (onChange) {
            onChange({
              folderSlug,
              loadingDocuments,
              loadedDocuments: updated,
            });
          }

          return { loadedDocuments: updated };
        });
      })
      .catch(() => {
        this.setErrorState(removedDoc);
        this.showError(
          'There was a problem deleting your file, please try again.',
        );
      });
  }

  handlePhotoChange(changedPhoto) {
    const { loadedDocuments } = this.state;

    const updatedLoadedDocuments = loadedDocuments.map((doc) =>
      doc.fileId === changedPhoto.fileId ? changedPhoto : doc,
    );
    this.setState({ loadedDocuments: updatedLoadedDocuments });
  }

  handlePhotoSave(changedPhoto) {
    const { onChange, onSave, folderSlug } = this.props;

    onSave();
    this.copyDocumentsAndUpdateIds([changedPhoto])
      .then((updatedPhotos) =>
        this.documentService.update(updatedPhotos[0], false),
      )
      .then(() => {
        this.setState((state) => {
          const { loadedDocuments, loadingDocuments } = state;

          const updatedPhoto = { ...changedPhoto, error: false };
          const updatedLoadedDocuments = loadedDocuments.map((doc) =>
            doc.fileId === changedPhoto.fileId ? updatedPhoto : doc,
          );
          if (onChange) {
            onChange({
              folderSlug,
              loadingDocuments,
              loadedDocuments: updatedLoadedDocuments,
            });
          }
          return { loadedDocuments: updatedLoadedDocuments };
        });
      })
      .catch(() => {
        this.setErrorState(changedPhoto);
        this.showError(
          'There was a problem saving your file, please try again.',
        );
      });
  }

  handlePhotoEdit(selectedPhoto) {
    this.setState({ editPhoto: true, selectedPhoto });
  }

  handleEditorSave(updatedPhotos) {
    const { folderSlug, onChange, onSave } = this.props;
    const { loadedDocuments, loadingDocuments } = this.state;

    const updatedPhotoIds = updatedPhotos.map((p) => p.fileId);
    const updatedLoadedDocuments = loadedDocuments.map((doc) => {
      if (updatedPhotoIds.indexOf(doc.fileId) >= 0) {
        return { ...doc, processing: true };
      }
      return doc;
    });
    this.setState({
      editPhoto: false,
      loadedDocuments: updatedLoadedDocuments,
    });
    onSave();

    if (onChange) {
      onChange({
        folderSlug,
        loadingDocuments,
        loadedDocuments: updatedLoadedDocuments,
      });
    }

    return this.copyDocumentsAndUpdateIds(updatedPhotos).then((updated) =>
      Promise.all(this.processEdits(updated)),
    );
  }

  addPhotoFileSelection() {
    const { disabled, disabledMessage, documentType } = this.props;

    if (disabled) {
      return (
        <div>
          <MapistryTooltip
            title={
              disabledMessage || 'Photo upload is disabled for this feature.'
            }
          >
            <span>
              <AddButton
                label="Add photo"
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                onClick={() => {}}
                disabled={disabled}
              />
            </span>
          </MapistryTooltip>
        </div>
      );
    }
    return (
      <div>
        <input
          type="file"
          accept={DOCUMENT_TYPES[documentType]}
          className="hide"
          multiple
          onChange={this.handleAddDocument}
          value="" // allows upload of same image again (in case of deletion)
          /* eslint-disable-next-line no-return-assign */
          ref={(ref) => (this.inputRef = ref)}
        />
        <AddButton label="Add photo" onClick={() => this.inputRef.click()} />
      </div>
    );
  }

  addAttachmentSelection() {
    const {
      disabled,
      disabledMessage,
      documentType,
      labelBeforeAddAttachments,
    } = this.props;

    if (disabled) {
      return (
        <div className="header field-input">
          {labelBeforeAddAttachments && (
            <div className="section-header">Attachments</div>
          )}
          <MapistryTooltip
            title={
              disabledMessage ||
              'Attachment upload is disabled for this feature.'
            }
          >
            <span>
              <AddButton
                label="Add attachments"
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                onClick={() => {}}
                disabled={disabled}
              />
            </span>
          </MapistryTooltip>
        </div>
      );
    }
    return (
      <div className="header field-input">
        <input
          type="file"
          accept={DOCUMENT_TYPES[documentType]}
          className="hide"
          multiple
          onChange={this.handleAddDocument}
          value="" // allows upload of same image again (in case of deletion)
          /* eslint-disable-next-line no-return-assign */
          ref={(ref) => (this.inputRef = ref)}
        />
        {labelBeforeAddAttachments && (
          <div className="section-header">Attachments</div>
        )}
        <AddButton
          label="Add attachments"
          onClick={() => this.inputRef.click()}
        />
      </div>
    );
  }

  documentList() {
    const { disabled, documentType } = this.props;
    const { loadedDocuments, loadingDocuments } = this.state;

    const allDocuments = loadedDocuments
      .concat(loadingDocuments)
      .filter((doc) => !doc.mimeType || !doc.mimeType.startsWith('image/'));

    if (!allDocuments.length) {
      return null;
    }

    return (
      <>
        {documentType === ALL && (
          <span className="section-header">Documents</span>
        )}
        <div className="document-list field-input">
          {allDocuments.map((doc) => (
            <Document
              disabled={disabled}
              key={doc.id}
              document={doc}
              onDelete={this.handleDocumentRemoved}
            />
          ))}
        </div>
      </>
    );
  }

  photoList() {
    const { projectId, disabled, documentType } = this.props;
    const { loadedDocuments, loadingDocuments } = this.state;

    let allPhotos = loadedDocuments.concat(loadingDocuments);
    if (documentType !== IMAGE) {
      allPhotos = allPhotos.filter(
        (doc) => doc.mimeType && doc.mimeType.startsWith('image/'),
      );
    }

    if (!allPhotos.length) {
      return null;
    }

    return (
      <>
        {documentType === ALL && <span className="section-header">Images</span>}
        <div className="photo-list">
          {allPhotos.map((photo) => (
            <Photo
              disabled={disabled}
              key={photo.fileId}
              value={photo}
              projectId={projectId}
              error={photo.error}
              onRemove={this.handleDocumentRemoved}
              onEdit={this.handlePhotoEdit}
              onChange={this.handlePhotoChange}
              onSave={this.handlePhotoSave}
            />
          ))}
        </div>
      </>
    );
  }

  photoEditor() {
    const { documentType } = this.props;
    const { editPhoto, loadedDocuments, selectedPhoto } = this.state;

    let allPhotos = loadedDocuments;
    if (documentType !== IMAGE) {
      allPhotos = allPhotos.filter(
        (doc) => doc.mimeType && doc.mimeType.startsWith('image/'),
      );
    }

    return (
      <PhotoEditor
        open={editPhoto}
        photos={allPhotos}
        selectedPhoto={selectedPhoto}
        onClose={() => this.setState({ editPhoto: false })}
        onSave={this.handleEditorSave}
      />
    );
  }

  processEdits(updatedPhotos) {
    const { loadedDocuments } = this.state;
    return updatedPhotos.map((updatedPhoto) => {
      const original = loadedDocuments.find(
        (d) => d.fileId === updatedPhoto.fileId,
      );
      const updatePromise =
        original.src !== updatedPhoto.src
          ? this.documentService.updateResource(updatedPhoto, false)
          : this.documentService.update(updatedPhoto, false);

      return updatePromise
        .then(() => {
          this.setState((state) => {
            const updatedLoadedDocuments = state.loadedDocuments.map((doc) => {
              if (doc.fileId === updatedPhoto.fileId) {
                return { ...updatedPhoto, processing: false };
              }
              return doc;
            });
            return { loadedDocuments: updatedLoadedDocuments };
          });
        })
        .catch(() => {
          this.setErrorState(updatedPhoto);
          this.showError(
            'There was a problem saving your file, please try again.',
          );
        });
    });
  }

  showError(message) {
    const { onError } = this.props;

    if (onError) {
      onError(message);
    }
  }

  render() {
    const { documentType } = this.props;

    return (
      <div className="document-uploader">
        {documentType === ALL
          ? this.addAttachmentSelection()
          : this.addPhotoFileSelection()}
        {documentType === ALL && this.documentList()}
        {this.photoList()}
        {this.photoEditor()}
      </div>
    );
  }
}

DocumentUploader.propTypes = {
  disabled: PropTypes.bool,
  disabledMessage: PropTypes.string,
  documentType: PropTypes.oneOf([ALL, IMAGE]),
  folderSlug: PropTypes.string,
  labelBeforeAddAttachments: PropTypes.bool,
  onChange: PropTypes.func,
  onError: PropTypes.func,
  onSave: PropTypes.func,
  projectId: PropTypes.string.isRequired,
  templateFolderSlug: PropTypes.string,
};

DocumentUploader.defaultProps = {
  disabled: false,
  disabledMessage: null,
  documentType: ALL,
  folderSlug: null,
  labelBeforeAddAttachments: false,
  onChange: null,
  onError: null,
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onSave: () => {},
  templateFolderSlug: null,
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default DocumentUploader;
export { ALL, IMAGE };
