/* eslint-disable */
// when editing this file please uncomment the above and fix the linting errors
import React from 'react';
import PropTypes from 'prop-types';

import Button from './Button';

const ButtonLink = (props) => {
  const { href } = props;

  return <Button {...props} onClick={() => (window.location.href = href)} />;
};

ButtonLink.propTypes = {
  href: PropTypes.string.isRequired,
};

export default ButtonLink;
