import React from 'react';

import BlockButton, { SUPPORTED_BLOCKS } from './BlockButton';
import MarkButton, { SUPPORTED_MARKS } from './MarkButton';

const EditorToolbar = () => (
  <div className="text-editor__toolbar-wrapper">
    <div className="text-editor__toolbar-section-wrapper">
      <MarkButton type={SUPPORTED_MARKS.BOLD} />
      <MarkButton type={SUPPORTED_MARKS.ITALIC} />
      <MarkButton type={SUPPORTED_MARKS.UNDERLINE} />
      <MarkButton type={SUPPORTED_MARKS.CODE} />
    </div>
    <div className="text-editor__toolbar-section-wrapper">
      <BlockButton type={SUPPORTED_BLOCKS.HEADING_ONE} />
      <BlockButton type={SUPPORTED_BLOCKS.HEADING_TWO} />
      <BlockButton type={SUPPORTED_BLOCKS.BLOCK_QUOTE} />
      <BlockButton type={SUPPORTED_BLOCKS.ORDERED_LIST} />
      <BlockButton type={SUPPORTED_BLOCKS.UNORDERED_LIST} />
      <BlockButton type={SUPPORTED_BLOCKS.PARAGRAPH} />
    </div>
  </div>
);

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default EditorToolbar;
