import http from '../httpClient';
import { ItemGroup } from '../types';

export interface FetchItemGroupsProps {
  logProjectId?: string;
  projectId?: string;
}

interface FetchItemGroupsResponse {
  logProjectId: string;
  records: ItemGroup[];
}

export async function fetchItemGroups({
  logProjectId,
  projectId,
}: FetchItemGroupsProps): Promise<ItemGroup[]> {
  const res = await http.get<FetchItemGroupsResponse>(
    `/api/v2/${projectId}/generic-logs/${logProjectId}/item-groups`,
  );
  return res.data?.records;
}
