import { Divider, MenuItem, Menu as MuiMenu } from '@material-ui/core';
import { SvgIcon } from '@monorepo/shared/components/icons/SvgIcon';
import { ToastContext } from '@monorepo/shared/contexts/ToastContext';
import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { MenuOptionType } from '../propTypes';
import { MoreVert } from './MuiIcon';
import IconButton from './buttons/IconButton';

class Menu extends React.Component {
  constructor() {
    super();
    this.state = {
      menuAnchor: null,
    };
  }

  handleMenuButtonClicked(event) {
    const { options } = this.props;
    if (!options.length) {
      return;
    }
    this.setState({ menuAnchor: event.currentTarget });
  }

  handleMenuClosed() {
    this.setState({ menuAnchor: null });
  }

  showDisabledExplanationToast = (explanationText) => {
    const { warning } = this.context;
    warning(explanationText);
  };

  optionColorClass(option) {
    if (option.danger) {
      return ' danger';
    }
    if (option.warn) {
      return ' warn';
    }
    return '';
  }

  options() {
    const { options } = this.props;

    return options.map((option) =>
      option.isDivider ? (
        <div
          className="mapistry-select--divider"
          aria-hidden="true"
          key={option.key}
        >
          <Divider />
        </div>
      ) : (
        <MenuItem
          key={option.label}
          className={cn('menu-item', this.optionColorClass(option), {
            'disabled-with-explanation': !!option.disabledWithExplanation,
          })}
          disabled={option.disabled}
          onClick={
            option.disabledWithExplanation
              ? () =>
                  this.showDisabledExplanationToast(
                    option.disabledWithExplanation,
                  )
              : option.onClick
          }
          title={option.title}
        >
          {option.icon
            ? option.icon
            : option.iconIdentifier && (
                <SvgIcon identifier={option.iconIdentifier} />
              )}
          <span className="menu-item-text">{option.label}</span>
        </MenuItem>
      ),
    );
  }

  render() {
    const { buttonClassName, className, icon, options, title } = this.props;
    const { menuAnchor } = this.state;
    const componentClass = className ? ` ${className}` : '';
    const hideClass = !options.length ? ' mapistry-menu--hidden' : '';

    return (
      <div className={`mapistry-menu-container${componentClass}${hideClass}`}>
        <IconButton
          className={buttonClassName}
          disabled={!options.length}
          onClick={(e) => this.handleMenuButtonClicked(e)}
          title={title}
        >
          <div className="visually-hidden">More Actions</div>
          {icon || <MoreVert aria-hidden className="menu-button-icon" />}
        </IconButton>
        <MuiMenu
          className="mapistry-menu"
          onClick={() => this.handleMenuClosed()}
          transformOrigin={{ vertical: -20, horizontal: 40 }}
          anchorEl={menuAnchor}
          onClose={() => this.handleMenuClosed()}
          open={!!menuAnchor}
        >
          {this.options()}
        </MuiMenu>
      </div>
    );
  }
}

Menu.contextType = ToastContext;

Menu.propTypes = {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/require-default-props
  buttonClassName: PropTypes.string,
  className: PropTypes.string,
  icon: PropTypes.node,
  options: PropTypes.arrayOf(MenuOptionType),
  title: PropTypes.string,
};

Menu.defaultProps = {
  className: null,
  icon: null,
  options: [],
  title: undefined,
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default Menu;
