import _ from 'underscore';
import _s from 'underscore.string';
import APP from '../config';
import * as HTTP from '../utils/HTTP';

const API_ROOT = APP.apiUrl;
export const CALL_API = 'CALL_API';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default (store) => (next) => (action) => {
  if (!action) {
    return null;
  }
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/destructuring-assignment
  let callAPI = action[CALL_API];

  if (callAPI === undefined) {
    return next(action);
  }

  if (_.isFunction(callAPI)) {
    callAPI = callAPI(store.getState());
    if (!callAPI) {
      return null;
    }
  }

  const { endpoint, verb, lifecycleActions, data, headers, meta } = callAPI;

  const req = meta || data;

  if (!_.contains(HTTP.Types, verb)) {
    throw new Error('Invalid type', verb);
  }

  if (!_.isArray(lifecycleActions) || lifecycleActions.length !== 3) {
    throw new Error('Must provide 3 lifecycle action creators');
  }

  const actionWith = (otherAction) => {
    const finalAction = {
      ...action,
      ...otherAction,
    };
    delete finalAction[CALL_API];
    return finalAction;
  };

  const [requestActionCreator, successActionCreator, failureActionCreator] =
    lifecycleActions;
  next(actionWith(requestActionCreator(req)));

  const dispatchOrNext = (nextAction) => {
    if (_.has(nextAction, CALL_API)) {
      store.dispatch(nextAction);
    } else {
      next(actionWith(nextAction));
    }
  };

  const isInternalApiCall = !_s.startsWith(endpoint, 'http');
  const url = isInternalApiCall ? API_ROOT + endpoint : endpoint;

  return HTTP.makeCall(url, verb, data, isInternalApiCall, headers).then(
    ({ body }) => {
      dispatchOrNext(successActionCreator(body, req));
      return Promise.resolve(body);
    },
    ({ body, error }) => {
      dispatchOrNext(failureActionCreator(body, req, error));
      return Promise.reject(error);
    },
  );
};
