import {
  FrequencyRequirementResponse,
  SaveFrequencyRequirementRequest,
} from 'mapistry-shared';
import { http } from 'mapistry-shared/api';

export interface BasePathParams {
  organizationId: string;
  logId: string;
  projectId: string;
}

export type SingleFrequencyRequirementPathParams = BasePathParams & {
  frequencyRequirementId: string;
};

const getPath = ({
  organizationId,
  projectId,
  logId,
  frequencyRequirementId,
}: BasePathParams & { frequencyRequirementId?: string }) =>
  `/api/v2/organizations/${organizationId}/logs/${logId}/projects/${projectId}/frequencyRequirements${
    frequencyRequirementId ? `/${frequencyRequirementId}` : ''
  }`;

export type FetchFrequencyRequirementsParams = BasePathParams;
export type FetchFrequencyRequirementsResponse = FrequencyRequirementResponse[];

export async function fetchFrequencyRequirements(
  pathParams: FetchFrequencyRequirementsParams,
): Promise<FetchFrequencyRequirementsResponse> {
  const res = await http.get<FetchFrequencyRequirementsResponse>(
    getPath(pathParams),
  );
  return res.data;
}

export type CreateFrequencyRequirementParams = BasePathParams & {
  frequencyRequirement: SaveFrequencyRequirementRequest;
};

export async function createFrequencyRequirement({
  organizationId,
  logId,
  projectId,
  frequencyRequirement,
}: CreateFrequencyRequirementParams): Promise<FrequencyRequirementResponse> {
  const res = await http.post<FrequencyRequirementResponse>(
    getPath({ organizationId, logId, projectId }),
    frequencyRequirement,
  );
  return res.data;
}

export type UpdateFrequencyRequirementParams =
  SingleFrequencyRequirementPathParams & {
    frequencyRequirement: SaveFrequencyRequirementRequest;
  };

export async function updateFrequencyRequirement({
  organizationId,
  logId,
  projectId,
  frequencyRequirementId,
  frequencyRequirement,
}: UpdateFrequencyRequirementParams): Promise<FrequencyRequirementResponse> {
  const res = await http.put<FrequencyRequirementResponse>(
    getPath({
      organizationId,
      logId,
      projectId,
      frequencyRequirementId,
    }),
    frequencyRequirement,
  );
  return res.data;
}

export type DeleteFrequencyRequirementParams =
  SingleFrequencyRequirementPathParams;
export type DeleteFrequencyRequirementResponse = undefined;

export async function deleteFrequencyRequirement(
  pathParams: DeleteFrequencyRequirementParams,
): Promise<DeleteFrequencyRequirementResponse> {
  const res = await http.delete<DeleteFrequencyRequirementResponse>(
    getPath(pathParams),
  );
  return res.data;
}
