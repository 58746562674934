/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-var-requires */
/* global $ */
const BluebirdPromise = require('bluebird');
const APP = require('./config');

const apiCaller = {
  _pageSetupVarsPromise: null,
  _pageSetupVarsResults: null,

  getPageSetupVars() {
    if (this._pageSetupVarsResults) {
      return BluebirdPromise.resolve(this._pageSetupVarsResults);
    }

    if (this._pageSetupVarsPromise) {
      return this._pageSetupVarsPromise;
    }

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const _this = this;
    this._pageSetupVarsPromise = BluebirdPromise.resolve(
      $.get(`${APP.apiUrl}/api/page-setup-vars/stormwater`),
    ).tap((apiVars) => {
      APP.setApiVars(apiVars);
      _this._pageSetupVarsResults = apiVars;
    });

    return this._pageSetupVarsPromise;
  },

  tryLogin(email, password) {
    return BluebirdPromise.resolve(
      $.post(`${APP.apiUrl}/login?client=browser`, {
        email,
        password,
      }),
    );
  },

  trySignUp(data) {
    return BluebirdPromise.resolve(
      $.post(`${APP.apiUrl}/users`, {
        name: data.name,
        email: data.email,
        job_title: data.job_title,
        password: data.password,
        acceptLatestTos: data.acceptLatestTos,
        token: data.token,
      }),
    );
  },

  logout() {
    return BluebirdPromise.resolve($.get(`${APP.apiUrl}/logout`));
  },

  getLastProject() {
    return BluebirdPromise.resolve($.get(`${APP.apiUrl}/api/projects/last`));
  },

  geolocate(searchVal) {
    return BluebirdPromise.resolve(
      $.get(`${APP.apiUrl}/location`, { address: searchVal }),
    );
  },

  requestPasswordReset(email) {
    const url = `${APP.apiUrl}/api/users/password-reset-request`;
    return BluebirdPromise.resolve($.post(url, { email }));
  },

  setRecoveryQuestion(question, answer) {
    const url = `${APP.apiUrl}/api/users/set-recovery-question`;
    return BluebirdPromise.resolve(
      $.ajax({
        url,
        type: 'PUT',
        data: { question, answer },
      }),
    );
  },

  verifyPasswordResetToken(token) {
    const url = `${APP.apiUrl}/api/users/verify-password-reset-token`;
    return BluebirdPromise.resolve($.get(url, { token }));
  },

  resetPassword(password, passwordConfirmation, token, recoveryQuestionAnswer) {
    const url = `${APP.apiUrl}/api/users/password-reset`;
    return BluebirdPromise.resolve(
      $.ajax({
        url,
        type: 'PUT',
        data: {
          password,
          password_confirmation: passwordConfirmation,
          token,
          recovery_question_answer: recoveryQuestionAnswer,
        },
      }),
    );
  },

  /**
   * Sampling Events / Results
   */
  getSamplingEventsRootUrl(projectId) {
    return `${APP.apiUrl}/api/projects/${projectId}/sampling-events`;
  },

  getSwpppSubmissionId(projectId) {
    const url = `${APP.apiUrl}/api/v2/projects/${projectId}/swppp-submission-id`;
    return BluebirdPromise.resolve($.get(url));
  },

  downloadSwpppUpdateLogUrl(projectId, startDate, endDate) {
    const params = this._getStartDateEndDateQueryString(startDate, endDate);
    return `${APP.apiUrl}/api/projects/${projectId}/swppp-updates/download-swppp-update-log?${params}`;
  },

  downloadWastewaterEventsUrl(projectId) {
    return `${APP.apiUrl}/api/v2/${projectId}/wastewater-sampling-events/download`;
  },

  downloadWastewaterFlowLogUrl(projectId) {
    return `${APP.apiUrl}/api/v2/${projectId}/wastewater/flow-log/download`;
  },

  getSamplingResultsUrl(projectId) {
    return `${APP.apiUrl}/api/projects/${projectId}/sampling-results/`;
  },

  getSamplingEventUrl(projectId, samplingEventId, observedOnly) {
    return `${this.getSamplingEventsRootUrl(projectId)}/${samplingEventId}${
      observedOnly ? '?observedOnly' : ''
    }`;
  },

  downloadSamplingResultsUrl(projectId, samplingEventId) {
    return `${this.getSamplingEventUrl(projectId, samplingEventId)}/download`;
  },

  validateSamplingResultDownload(projectId, samplingEventId) {
    const url = `${this.downloadSamplingResultsUrl(
      projectId,
      samplingEventId,
    )}/validate`;
    return BluebirdPromise.resolve($.get(url));
  },

  uploadSamplingResultsFile(projectId, samplingEventId, fileContents) {
    const url = `${this.getSamplingEventUrl(
      projectId,
      samplingEventId,
    )}/upload`;
    const data = { file_contents: fileContents };
    return BluebirdPromise.resolve($.post(url, data));
  },

  getSamplingResultSummariesUrl(projectId, year) {
    return `${APP.apiUrl}/api/projects/${projectId}/sampling-summaries?year=${year}`;
  },

  getSamplingEventsIndexUrl(projectId, startDate, endDate) {
    const params = $.param({ startDate, endDate });
    return `${this.getSamplingEventsRootUrl(projectId)}?${params}`;
  },

  getSamplingEvent(projectId, samplingEventId, observedOnly) {
    const url = this.getSamplingEventUrl(
      projectId,
      samplingEventId,
      observedOnly,
    );
    return BluebirdPromise.resolve($.get(url));
  },

  getSamplingEventParameterUrl(projectId, samplingEventId) {
    return `${this.getSamplingEventUrl(
      projectId,
      samplingEventId,
    )}/sampling-parameters`;
  },

  createParameterForLocationsInSamplingEvent(
    projectId,
    samplingEventId,
    parameterSlug,
  ) {
    const url = this.getSamplingEventParameterUrl(projectId, samplingEventId);
    return BluebirdPromise.resolve($.post(url, { slug: parameterSlug }));
  },

  /**
   * Form Submission Urls
   */
  requestFormPdfUrl(submissionId) {
    return `${APP.apiUrl}/api/form-submissions/${submissionId}/email`;
  },

  /**
   * @deprecated
   */
  downloadPdfUrl(submissionId) {
    return `${APP.apiUrl}/api/form-submissions/${submissionId}/download`;
  },

  // used by swppp copy feature
  getRelatedFormSubmissions(projectId, templateSlug, submissionSlug) {
    const url = `${APP.apiUrl}/api/projects/${projectId}/form-templates/${templateSlug}/form-submissions/${submissionSlug}/related`;
    return BluebirdPromise.resolve($.get(url));
  },

  // used by exceedance report form
  getFormSubmission(projectId, templateSlug, submissionSlug) {
    const url = `${APP.apiUrl}/api/projects/${projectId}/form-templates/${templateSlug}/form-submissions/${submissionSlug}`;
    return BluebirdPromise.resolve($.get(url));
  },

  // used by swppp copy feature
  createFormSubmission(
    projectId,
    formTemplateSlug,
    submissionSlug,
    submissionIdToCopy,
  ) {
    const data = submissionIdToCopy
      ? { submission_id_to_copy: submissionIdToCopy }
      : {};
    const url = `${APP.apiUrl}/api/projects/${projectId}/form-templates/${formTemplateSlug}/form-submissions/${submissionSlug}`;
    return BluebirdPromise.resolve($.post(url, data));
  },

  getFormSubmissionUrl(
    projectId,
    templateSlug,
    submissionSlug,
    calendarName,
    eventDate,
    eventNumber,
  ) {
    const url = `/projects/${projectId}/form-submissions/${templateSlug}/${submissionSlug}`;
    if (eventDate) {
      const isoDate = new Date(
        eventDate.getTime() - eventDate.getTimezoneOffset() * 60000,
      );
      const formattedEventDate = isoDate.toISOString().substring(0, 10);
      return `${url}/${calendarName}/${formattedEventDate}/${eventNumber}/show-form`;
    }
    if (calendarName) {
      return `${url}/${calendarName}/show-form`;
    }
    return `${url}/show-form`;
  },

  getFormStencilUrl(
    projectId,
    templateSlug,
    stencilSubmissionSlug,
    calendarName,
  ) {
    return `/projects/${projectId}/form-submissions/${templateSlug}/${stencilSubmissionSlug}/${calendarName}/stencil`;
  },

  getScreenshotLinkUrl(projectId, mapId, orientation, mapSize) {
    const queryParams = $.param({
      orientation,
      map_size: mapSize,
    });
    return `${APP.apiUrl}/projects/${projectId}/maps/${mapId}/screenshot?${queryParams}`;
  },

  getDischargeLocationsUrl(projectId) {
    return `${APP.apiUrl}/api/projects/${projectId}/discharge-locations`;
  },

  getDischargeLocations(projectId) {
    return BluebirdPromise.resolve(
      $.get(this.getDischargeLocationsUrl(projectId)),
    );
  },

  getUsersRootUrl() {
    return `${APP.apiUrl}/users`;
  },

  getProjectUsersUrl(projectId) {
    return `${APP.apiUrl}/api/projects/${projectId}/users`;
  },

  getProjectListAttrs(projectId, key) {
    return `${APP.apiUrl}/projects/${projectId}/list-attrs/${key}`;
  },

  getProjectListAttrsDownloadCers(projectId, key) {
    return `${this.getProjectListAttrs(projectId, key)}/cers`;
  },

  getUserNotificationsUrl(userId) {
    return `${APP.apiUrl}/api/users/${userId}/notifications`;
  },

  enableUserNotification(userId, notification_slug) {
    const url = this.getUserNotificationsUrl(userId);
    const data = { notification_slug };
    return BluebirdPromise.resolve($.post(url, data));
  },

  disableUserNotification(userId, notificationId) {
    const url = `${this.getUserNotificationsUrl(userId)}/${notificationId}`;
    return BluebirdPromise.resolve($.ajax({ url, type: 'DELETE' }));
  },

  getProjectUserUrl(projectId, userId) {
    return `${APP.apiUrl}/api/projects/${projectId}/users/${userId}`;
  },

  getProjectInvitationUrl(projectId) {
    return `${APP.apiUrl}/api/projects/${projectId}/users/invite`;
  },

  getUpdateProjectUserPermissionsUrl(projectId, userId) {
    return `${APP.apiUrl}/api/projects/${projectId}/users/${userId}/permissions`;
  },

  getUserInfoSearchUrl(emails) {
    return `${APP.apiUrl}/api/user/search/${emails}`;
  },

  getSwpppUpdatesUrl(projectId) {
    return `${APP.apiUrl}/api/projects/${projectId}/swppp-updates`;
  },

  getSwpppUpdatesWithQueryParamsUrl(projectId, startDate, endDate) {
    const baseUrl = this.getSwpppUpdatesUrl(projectId);
    const queryParams = $.param({ start_date: startDate, end_date: endDate });
    return `${baseUrl}?${queryParams}`;
  },

  getSpccUpdatesUrl(projectId) {
    return `${APP.apiUrl}/api/projects/${projectId}/spcc-updates`;
  },

  getSpccUpdatesWithQueryParamsUrl(projectId, startDate, endDate) {
    const baseUrl = this.getSpccUpdatesUrl(projectId);
    const queryParams = $.param({ start_date: startDate, end_date: endDate });
    return `${baseUrl}?${queryParams}`;
  },

  getHmbpUpdatesUrl(projectId) {
    return `${APP.apiUrl}/api/projects/${projectId}/hmbp-updates`;
  },

  getHmbpUpdatesWithQueryParamsUrl(projectId, startDate, endDate) {
    const baseUrl = this.getHmbpUpdatesUrl(projectId);
    const queryParams = $.param({ start_date: startDate, end_date: endDate });
    return `${baseUrl}?${queryParams}`;
  },

  getProjectFeaturesUrl(projectId) {
    return `${APP.apiUrl}/projects/${projectId}/features`;
  },

  getProjectFeatureUrl(projectId, featureId) {
    return `${this.getProjectFeaturesUrl(projectId)}/${featureId}`;
  },

  deleteFeatures(projectId, featureIds) {
    const promise = $.ajax({
      url: this.getProjectFeaturesUrl(projectId),
      type: 'DELETE',
      data: { ids: featureIds },
    });
    return BluebirdPromise.resolve(promise);
  },

  updateFeature(projectId, featureId, data) {
    const promise = $.ajax({
      url: this.getProjectFeatureUrl(projectId, featureId),
      type: 'PUT',
      data,
    });
    return BluebirdPromise.resolve(promise);
  },

  getProjectFeaturesPhotosUrl(projectId, featureId) {
    return `${this.getProjectFeaturesUrl(projectId)}/${featureId}/photos`;
  },

  getProjectFeaturesPhotosStagingUrl(projectId, featureId) {
    return `${this.getProjectFeaturesPhotosUrl(projectId, featureId)}/stage`;
  },

  saveProjectSetting(projectId, { settingKey, settingValue }) {
    const promise = $.ajax({
      url: `${APP.apiUrl}/api/projects/${projectId}/settings/${settingKey}`,
      type: 'PUT',
      data: { settingValue },
    });
    return BluebirdPromise.resolve(promise);
  },

  getProjectSettings(projectId) {
    const promise = $.get(this.getProjectSettingsUrl(projectId));
    return BluebirdPromise.resolve(promise);
  },

  getProjectSettingsUrl(projectId) {
    return `${APP.apiUrl}/api/projects/${projectId}/settings`;
  },

  _getStartDateEndDateQueryString(startDate, endDate) {
    // eslint-disable-next-line global-require
    const moment = require('moment');
    return $.param({
      start_date: moment.isMoment(startDate)
        ? startDate.format(moment.ISO_8601())
        : startDate,
      end_date: moment.isMoment(endDate)
        ? endDate.format(moment.ISO_8601())
        : endDate,
    });
  },

  getNumSitesForOrganization(organizationId) {
    const url = `${APP.apiUrl}/api/organizations/${organizationId}/num-sites`;
    const numSitesPromise = fetch(url, { credentials: 'include' }).then(
      // eslint-disable-next-line consistent-return
      (result) => {
        if (result.ok) {
          return result.json();
        }
      },
    );
    return BluebirdPromise.resolve(numSitesPromise);
  },

  getBaseProjectDocumentUrl(projectId, folderSlug) {
    return `${APP.apiUrl}/api/v2/${projectId}/documents/${folderSlug}`;
  },

  getProjectRainEventUrl(projectId) {
    return `${APP.apiUrl}/api/projects/${projectId}/rain-events`;
  },

  getSamplingParametersUrl(projectId) {
    return `${APP.apiUrl}/api/projects/${projectId}/sampling-parameters`;
  },

  getDMRUrl(projectId) {
    return `${APP.apiUrl}/api/projects/${projectId}/dmrs`;
  },

  getOrganizationTagTypeUrl(organizationId) {
    return `${APP.apiUrl}/api/organizations/${organizationId}/tag-types/`;
  },

  getProjectTagsUrl(projectId) {
    return `${APP.apiUrl}/api/projects/${projectId}/tags/`;
  },

  getTypeTagsUrl(tagtypeId) {
    return `${APP.apiUrl}/api/projects/tags/${tagtypeId}`;
  },

  getTeslaTilesBaseUrl(projectId, bucket) {
    return `${APP.apiUrl}/project/${projectId}/buckets/${bucket}/key/`;
  },

  stopImpersonating() {
    return Promise.resolve(
      $.post(`${APP.apiUrl}/admin/users/stop-impersonating`),
    );
  },
};
module.exports = apiCaller;
