export const colors = {
  black: '#000',
  black30: 'rgba(0 0 0 / 30%)',

  blue: '#123D48',
  blue96: 'rgba(0 23 35 / 96%)',
  blue88: 'rgba(0 23 35 / 88%)',
  blue82: 'rgba(0 23 35 / 82%)',
  blue76: 'rgba(0 23 35 / 76%)',
  blue64: 'rgba(0 23 35 / 64%)',
  blue56: 'rgba(0 23 35 / 56%)',
  blue48: 'rgba(0 23 35 / 48%)',
  blue40: 'rgba(0 23 35 / 40%)',
  blue32: 'rgba(0 23 35 / 32%)',
  blue24: 'rgba(0 23 35 / 24%)',
  blue20: 'rgba(0 23 35 / 20%)',
  blue16: 'rgba(0 23 35 / 16%)',
  blue08: 'rgba(0 23 35 / 8%)',
  blue04: 'rgba(0 23 35 / 4%)',

  darkBlue: '#0097BC',
  darkerBlue: '#0080a9',

  darkGreen: '#2A9A40',
  darkerGreen: '#1b8230',

  darkRed: '#CE0C0C',
  darkerRed: '#BC0909',

  lightBlue: '#00A6CE',
  lightBlue96: 'rgba(0 166 206 / 96%)',
  lightBlue88: 'rgba(0 166 206 / 88%)',
  lightBlue82: 'rgba(0 166 206 / 82%)',
  lightBlue76: 'rgba(0 166 206 / 76%)',
  lightBlue64: 'rgba(0 166 206 / 64%)',
  lightBlue56: 'rgba(0 166 206 / 56%)',
  lightBlue48: 'rgba(0 166 206 / 48%)',
  lightBlue40: 'rgba(0 166 206 / 40%)',
  lightBlue32: 'rgba(0 166 206 / 32%)',
  lightBlue24: 'rgba(0 166 206 / 24%)',
  lightBlue16: 'rgba(0 166 206 / 16%)',
  lightBlue08: 'rgba(0 166 206 / 8%)',
  lightBlue04: 'rgba(0 166 206 / 4%)',

  gray123: '#123D48',
  gray333: '#333',
  gray666: '#666',
  gray999: '#999',
  graybbb: '#bbb',
  grayddd: '#ddd',
  grayeee: '#eee',

  green: '#31B14A',
  green96: 'rgba(49 177 74 / 96%)',
  green88: 'rgba(49 177 74 / 88%)',
  green82: 'rgba(49 177 74 / 82%)',
  green76: 'rgba(49 177 74 / 76%)',
  green64: 'rgba(49 177 74 / 64%)',
  green56: 'rgba(49 177 74 / 56%)',
  green48: 'rgba(49 177 74 / 48%)',
  green40: 'rgba(49 177 74 / 40%)',
  green32: 'rgba(49 177 74 / 32%)',
  green24: 'rgba(49 177 74 / 24%)',
  green16: 'rgba(49 177 74 / 16%)',
  green08: 'rgba(49 177 74 / 8%)',
  green04: 'rgba(49 177 74 / 4%)',

  // orange
  orange: '#FF851B',

  // red
  red: '#FF0000',
  red96: 'rgba(255 0 0 / 96%)',
  red88: 'rgba(255 0 0 / 88%)',
  red82: 'rgba(255 0 0 / 82%)',
  red76: 'rgba(255 0 0 / 76%)',
  red64: 'rgba(255 0 0 / 64%)',
  red56: 'rgba(255 0 0 / 56%)',
  red48: 'rgba(255 0 0 / 48%)',
  red40: 'rgba(255 0 0 / 40%)',
  red32: 'rgba(255 0 0 / 32%)',
  red24: 'rgba(255 0 0 / 24%)',
  red16: 'rgba(255 0 0 / 16%)',
  red08: 'rgba(255 0 0 / 8%)',
  red04: 'rgba(255 0 0 / 4%)',

  // white
  white: '#FFFFFF',
  white96: 'rgba(255 255 255 / 96%)',
  white88: 'rgba(255 255 255 / 88%)',
  white82: 'rgba(255 255 255 / 82%)',
  white76: 'rgba(255 255 255 / 76%)',
  white64: 'rgba(255 255 255 / 64%)',
  white56: 'rgba(255 255 255 / 56%)',
  white48: 'rgba(255 255 255 / 48%)',
  white40: 'rgba(255 255 255 / 40%)',
  white32: 'rgba(255 255 255 / 32%)',
  white24: 'rgba(255 255 255 / 24%)',
  white16: 'rgba(255 255 255 / 16%)',
  white08: 'rgba(255 255 255 / 8%)',
  white04: 'rgba(255 255 255 / 4%)',

  // yellow
  yellow: '#F2C407',
  yellow08: 'rgba(242 196 7 / 8%)',
} as const;
