import { useToast } from '@monorepo/shared/contexts/ToastContext';
import * as Sentry from '@sentry/browser';
import React, { useCallback } from 'react';
import {
  ErrorBoundary as ReactErrorBoundary,
  FallbackProps,
} from 'react-error-boundary';
import { useErrorResetBoundary } from 'react-query';

type ErrorBoundaryProps = {
  children: React.ReactNode;
  Fallback?: React.ComponentType<FallbackProps>;
  onError?: () => void;
};

function DefaultFallback() {
  return null;
}

/**
 * Use this class to throw an error with a user friendly message (to be shown by the error boundary), but that also
 * includes non-user-facing details that will be logged to Sentry
 */
export class UserFriendlyErrorWithExtraDetails extends Error {
  constructor(
    userFriendlyMessage: string,
    public additionalErrorDetails: Record<string, unknown>,
  ) {
    super(userFriendlyMessage);
  }
}

const reportErrorToSentry = (
  error: unknown,
  { componentStack }: { componentStack: string },
) => {
  if (process.env.NODE_ENV === 'test') return;
  const errorContext = {
    componentStack,
    ...(error instanceof UserFriendlyErrorWithExtraDetails
      ? error.additionalErrorDetails
      : {}),
  };

  Sentry.captureException(error, { extra: errorContext });
  // eslint-disable-next-line no-console
  console.error('Error caught by Error Boundary:', error, errorContext);
};

export function ErrorBoundary({
  children,
  Fallback,
  onError,
}: ErrorBoundaryProps) {
  const { showUserFriendlyErrorToast } = useToast();
  const { reset } = useErrorResetBoundary();

  const handleError = useCallback(
    (error: unknown, info: { componentStack: string }) => {
      reportErrorToSentry(error, info);

      if (onError) {
        onError();
      }

      // assuming we only want to show the toast when we do not have a fallback view defined
      if (!Fallback) {
        showUserFriendlyErrorToast(error, undefined, { dontAutoHide: true });
      }
    },
    [showUserFriendlyErrorToast, Fallback, onError],
  );

  return (
    <ReactErrorBoundary
      onReset={reset}
      FallbackComponent={Fallback || DefaultFallback}
      onError={handleError}
    >
      {children}
    </ReactErrorBoundary>
  );
}
