import { SvgIcon } from '@monorepo/shared/components/icons/SvgIcon';
import PropTypes from 'prop-types';
import React from 'react';
import MapistryTooltip from './MapistryTooltip';
import Select from './Select';
import SignatureEditor from './SignatureEditor';
import Button from './buttons/Button';

class SingleSignature extends React.Component {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/state-in-constructor
  state = {
    editSignature: false,
  };

  handleChange(selection) {
    const { users, onChange } = this.props;

    const selectedUser = users.find((u) => u.id === selection.value);
    onChange(selectedUser);
  }

  handleSavedSignature(updatedUser) {
    const { onSign, onUpdateSignature } = this.props;

    this.setState({ editSignature: false });
    onUpdateSignature(updatedUser);
    onSign();
  }

  removeSigner() {
    const { signed, allowSign, value, onRemove } = this.props;

    if (!signed && allowSign) {
      return <span className="remove-current-signer" />;
    }
    return (
      <MapistryTooltip placement="top" title="Remove Signer">
        <button
          type="button"
          className={value ? 'remove-current-signer' : 'remove-signer'}
          onClick={() => onRemove(value)}
        >
          <SvgIcon identifier="trash" />
        </button>
      </MapistryTooltip>
    );
  }

  signButton() {
    const { onSign } = this.props;

    return (
      <Button color="secondary" onClick={onSign} className="sign-button">
        Click here to sign
      </Button>
    );
  }

  errorElement(errorMessage) {
    return errorMessage ? <p className="error-text">{errorMessage}</p> : null;
  }

  formattedOptions() {
    const { users } = this.props;

    return users.map((option) => ({ label: option.name, value: option.id }));
  }

  signer(value, error) {
    const { allowSign } = this.props;

    if (value) {
      if (allowSign && !value.signature) {
        return this.createSignature();
      }
      return (
        <div className={this.inputClassName()}>
          <span className="signature-mark">X</span>
          {this.signature()}
        </div>
      );
    }
    return (
      <Select
        options={this.formattedOptions()}
        onChange={(selection) => this.handleChange(selection)}
        error={error}
      />
    );
  }

  createSignature() {
    const { value } = this.props;
    const { editSignature } = this.state;

    return (
      <div className={this.inputClassName()}>
        {/* TODO: Fix this the next time the file is edited. */}
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
        <span
          className="create-signature"
          onClick={() => this.setState({ editSignature: true })}
        >
          Click here to add a signature
        </span>
        <SignatureEditor
          user={value}
          open={editSignature}
          saveButtonText="Save and Sign"
          onSave={(user) => this.handleSavedSignature(user)}
          onClose={() => this.setState({ editSignature: false })}
        />
      </div>
    );
  }

  inputClassName() {
    const { errorMessage } = this.props;

    return `signer-input ${errorMessage ? 'signer-error' : ''}`;
  }

  signature() {
    const { value, signed, allowSign } = this.props;

    if (signed) {
      return (
        <img
          className="signature-image"
          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line react/prop-types
          src={value.signature}
          alt="signature"
        />
      );
    }
    return allowSign ? (
      this.signButton()
    ) : (
      <span className="signature-pending">Signature Pending</span>
    );
  }

  render() {
    const { value, errorMessage } = this.props;

    return (
      <div className="single-signature">
        <div className="signature">
          {this.signer(value, errorMessage)}
          {this.removeSigner()}
        </div>
        {/* TODO: Fix this the next time the file is edited. */}
        {/* eslint-disable-next-line react/prop-types */}
        <p className="signer-name">{value && value.name}</p>
        {this.errorElement(errorMessage)}
      </div>
    );
  }
}

const SignerOptionType = PropTypes.shape({
  user_id: PropTypes.string,
  user_name: PropTypes.string,
  signed: PropTypes.bool,
  signed_at: PropTypes.string,
});

const UserType = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  signature: PropTypes.string,
});

SingleSignature.propTypes = {
  value: SignerOptionType,
  users: PropTypes.arrayOf(UserType),
  signed: PropTypes.bool,
  allowSign: PropTypes.bool,
  errorMessage: PropTypes.string,
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
  onSign: PropTypes.func,
  onUpdateSignature: PropTypes.func,
};

SingleSignature.defaultProps = {
  value: null,
  users: [],
  errorMessage: null,
  signed: false,
  allowSign: false,
  onChange: null,
  onSign: null,
  onUpdateSignature: null,
  onRemove: null,
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default SingleSignature;
