import React from 'react';
import PropTypes from 'prop-types';
import { Popover } from '@material-ui/core';

const MapistryPopover = (props) => {
  const {
    anchorEl,
    anchorOrigin,
    disableAutoFocus,
    disableEnforceFocus,
    children,
    onClose,
    open,
  } = props;
  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={anchorOrigin}
      disableAutoFocus={disableAutoFocus}
      disableEnforceFocus={disableEnforceFocus}
      open={open}
      onClose={onClose}
    >
      {children}
    </Popover>
  );
};

MapistryPopover.propTypes = {
  anchorEl: PropTypes.instanceOf(Element),
  anchorOrigin: PropTypes.shape({
    horizontal: PropTypes.string,
    vertical: PropTypes.string,
  }),
  disableAutoFocus: PropTypes.bool,
  disableEnforceFocus: PropTypes.bool,
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

MapistryPopover.defaultProps = {
  anchorEl: null,
  anchorOrigin: undefined,
  disableAutoFocus: false,
  disableEnforceFocus: false,
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default MapistryPopover;
