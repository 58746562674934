import React from 'react';
import PropTypes from 'prop-types';
import { motion, AnimatePresence } from 'framer-motion';

/**
 * @deprecated Use packages/shared/components/AnimatedComponent
 */
export const ANIMATION_TYPES = {
  FADE_IN_OUT: 'fade-in-out',
  SLIDE_LEFT_RIGHT: 'slide-left-right',
  SLIDE_LEFT_RIGHT_PRESENCE: 'slide-left-right-presence',
  SLIDE_RIGHT_LEFT_PRESENCE: 'slide-right-left-presence',
  SLIDE_RIGHT_LEFT_SMOOTH_PRESENCE: 'slide_right_left_smooth_presence',
  SLIDE_DOWN_PRESENCE: 'slide-down-presence',
};

/**
 * @deprecated Use packages/shared/components/AnimatedComponent
 */
export const withAnimation = (
  Component,
  type = ANIMATION_TYPES.FADE_IN_OUT, // TODO: Fix this the next time the file is edited.
) => (
  // eslint-disable-next-line no-use-before-define
  <AnimatedComponent type={type}>{Component}</AnimatedComponent>
);

/**
 * @deprecated Use packages/shared/components/AnimatedComponent
 */
const AnimatedComponent = (props) => {
  const { children, className, initial, isVisible, type } = props;
  const childrenList = Array.isArray(children) ? children : [children];

  switch (type) {
    case ANIMATION_TYPES.FADE_IN_OUT:
      return (
        <AnimatePresence initial={initial}>
          {isVisible &&
            childrenList.map((child) => (
              <motion.div
                key={child.key}
                className="animated__wrapper w-100 h-100"
                animate={{ opacity: 1 }}
                initial={{ opacity: 0 }}
                exit={{ opacity: 0 }}
              >
                {child}
              </motion.div>
            ))}
        </AnimatePresence>
      );
    case ANIMATION_TYPES.SLIDE_LEFT_RIGHT:
      return (
        <motion.div
          animate={{ width: '100%' }}
          initial={{ width: '0%' }}
          className="animated__wrapper h-100"
          transition={{ type: 'spring' }}
        >
          {children}
        </motion.div>
      );
    case ANIMATION_TYPES.SLIDE_LEFT_RIGHT_PRESENCE:
      return (
        <AnimatePresence initial={initial}>
          {childrenList.map((child) => (
            <motion.div
              key={child.key}
              animate={{ transform: 'translateX(0%)' }}
              initial={{ transform: 'translateX(-100%)' }}
              exit={{ transform: 'translateX(-100%)' }}
              className={`animated__wrapper h-100 ${className || ''}`}
              transition={{ type: 'spring' }}
            >
              {child}
            </motion.div>
          ))}
        </AnimatePresence>
      );
    case ANIMATION_TYPES.SLIDE_RIGHT_LEFT_PRESENCE:
      return (
        <AnimatePresence initial={initial}>
          {childrenList.map((child) => (
            <motion.div
              key={child.key}
              animate={{ transform: 'translateX(0%)' }}
              initial={{ transform: 'translateX(100%)' }}
              exit={{ transform: 'translateX(100%)' }}
              className={`animated__wrapper h-100 ${className || ''}`}
              transition={{ type: 'spring' }}
            >
              {child}
            </motion.div>
          ))}
        </AnimatePresence>
      );
    case ANIMATION_TYPES.SLIDE_RIGHT_LEFT_SMOOTH_PRESENCE:
      return (
        <AnimatePresence initial={initial}>
          {isVisible &&
            childrenList.map((child) => (
              <motion.div
                key={child.key}
                animate={{ transform: 'translateX(0%)' }}
                initial={{ transform: 'translateX(100%)' }}
                exit={{ transform: 'translateX(100%)' }}
                className={`animated__wrapper h-100 ${className || ''}`}
                transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
              >
                {child}
              </motion.div>
            ))}
        </AnimatePresence>
      );
    case ANIMATION_TYPES.SLIDE_DOWN_PRESENCE:
      return (
        <AnimatePresence initial={initial}>
          {isVisible &&
            childrenList.map((child) => (
              <motion.div
                key={child.key}
                animate={{ opacity: 1, height: 'auto' }}
                initial={{ opacity: 0.3, height: 0 }}
                exit={{ opacity: 0.3, height: 0 }}
                className={`animated__wrapper animated__wrapper__slide-down ${
                  className || ''
                }`}
                transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
              >
                {child}
              </motion.div>
            ))}
        </AnimatePresence>
      );
    default:
      throw new Error(
        'You used AnimatedComponent without passing in a valid AnimatedComponent.type prop.',
      );
  }
};

AnimatedComponent.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
  className: PropTypes.string,
  initial: PropTypes.bool,
  isVisible: PropTypes.bool,
  type: PropTypes.oneOf(Object.values(ANIMATION_TYPES)).isRequired,
};

AnimatedComponent.defaultProps = {
  initial: true,
  className: null,
  isVisible: true,
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default AnimatedComponent;
