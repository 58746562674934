import isHotkey from 'is-hotkey';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { createEditor } from 'slate';
import { Editable, Slate, withReact } from 'slate-react';
import _ from 'underscore';

import EditorToolbar from './EditorToolbar';
import { toggleMark } from './MarkButton';

const DEFAULT_INITIAL_VALUE = [
  {
    type: 'paragraph',
    children: [{ text: 'Say something...' }],
  },
];

const HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  'mod+`': 'code',
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default class SlateEditor extends Component {
  constructor(props) {
    super(props);
    const initialValue = props.initialValue || DEFAULT_INITIAL_VALUE;
    this.state = {
      value: initialValue,
    };
    this.editorInstance = withReact(createEditor());
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.renderElement = this.renderElement.bind(this);
    this.renderLeaf = this.renderLeaf.bind(this);
  }

  handleKeyDown(event) {
    _.map(HOTKEYS, (mark, hotkey) => {
      if (isHotkey(hotkey, event)) {
        event.preventDefault();
        toggleMark(this.editorInstance, HOTKEYS[hotkey]);
      }
    });
  }

  renderElement(options) {
    const { attributes, children, element } = options;
    switch (element.type) {
      case 'block-quote':
        return <blockquote {...attributes}>{children}</blockquote>;
      case 'bulleted-list':
        return <ul {...attributes}>{children}</ul>;
      case 'heading-one':
        return <h1 {...attributes}>{children}</h1>;
      case 'heading-two':
        return <h2 {...attributes}>{children}</h2>;
      case 'list-item':
        return <li {...attributes}>{children}</li>;
      case 'numbered-list':
        return <ol {...attributes}>{children}</ol>;
      default:
        return <p {...attributes}>{children}</p>;
    }
  }

  renderLeaf(options) {
    const { attributes, children, leaf } = options;
    let nextChildren = children;

    if (leaf.bold) {
      nextChildren = <span className="text-bold">{nextChildren}</span>;
    }
    if (leaf.code) {
      nextChildren = <code>{nextChildren}</code>;
    }
    if (leaf.italic) {
      nextChildren = <span className="text-italic">{nextChildren}</span>;
    }
    if (leaf.underline) {
      nextChildren = <span className="text-underline">{nextChildren}</span>;
    }
    return <span {...attributes}>{nextChildren}</span>;
  }

  render() {
    const { value } = this.state;
    const { onChange } = this.props;
    return (
      <div className="rich-text__editor-container">
        <Slate
          editor={this.editorInstance}
          value={value}
          onChange={(nextValue) =>
            this.setState({ value: nextValue }, () => {
              onChange(nextValue);
            })
          }
        >
          <EditorToolbar />
          <div className="rich-text__content-container">
            <Editable
              // TODO: Fix this the next time the file is edited.
              // eslint-disable-next-line react/destructuring-assignment, react/prop-types
              aria-labelledby={this.props['aria-labelledby']}
              onKeyDown={this.handleKeyDown}
              renderElement={this.renderElement}
              renderLeaf={this.renderLeaf}
            />
          </div>
        </Slate>
      </div>
    );
  }
}

SlateEditor.propTypes = {
  initialValue: PropTypes.arrayOf(PropTypes.shape({})),
  onChange: PropTypes.func.isRequired,
};

SlateEditor.defaultProps = {
  initialValue: null,
};
