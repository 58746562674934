import {
  Organization,
  OrganizationTagTypeResponseLegacy,
} from 'mapistry-shared';
import http from './httpClient';

export async function fetchOrganizations(): Promise<Organization[]> {
  const res = await http.get<Organization[]>(`/api/v2/organizations`);
  return res.data;
}

export type FetchOrganizationProps = {
  organizationId: string;
};

export async function fetchOrganization({
  organizationId,
}: FetchOrganizationProps): Promise<Organization> {
  const res = await http.get<Organization>(
    `/api/v2/organizations/${organizationId}`,
  );
  return res.data;
}

export type FetchOrgTagTypesResponse = OrganizationTagTypeResponseLegacy[];

export type FetchOrganizationTagTypesProps = {
  organizationId: string;
};

export async function fetchOrganizationTagTypes(
  variables: FetchOrganizationTagTypesProps,
) {
  const { organizationId } = variables;
  const res = await http.get<FetchOrgTagTypesResponse>(
    `/api/organizations/${organizationId}/tag-types`,
  );
  return res.data;
}
