import { isValid, parse } from 'date-fns';
import { RecordValue } from '../../types/flatfileTypes';

export type ValidNumberString = string;

export function isValidNumber(value: RecordValue): value is ValidNumberString {
  if (
    !(typeof value === 'string' || typeof value === 'number') ||
    value === ''
  ) {
    return false;
  }
  return !Number.isNaN(Number(value));
}

export type ValidDateString = string;

const validTimeFormats = [
  'hh:mm a', // e.g. 9:45 am
  'HH:mm', // e.g. 16:42
  'HHmm', // e.g. 0912
  'HH:mm:ss', // e.g. 23:12:59
] as const;

export type ValidTimeFormat = typeof validTimeFormats[number];

export const determineTimeFormat = (
  value: string,
): ValidTimeFormat | undefined =>
  // Return the first time string fmt that parses correctly.
  validTimeFormats.find((fmt) => {
    const date = parse(value, fmt, new Date());
    return isValid(date);
  });

export function isValidDate(value: RecordValue): value is ValidDateString {
  // Flatfile RecordValue can be a number and parsing numbers with JS Date constructor will result in a valid date
  if (typeof value !== 'string') return false;

  const date = new Date(String(value));
  return isValid(date);
}

export type ValidTimeString = string;

export function isValidTime(value: RecordValue): value is ValidTimeString {
  if (typeof value !== 'string') return false;
  return !!determineTimeFormat(value);
}

export function isEmpty(value: RecordValue): boolean {
  if (typeof value === 'string' && value.trim() === '') return true;
  return value === null || value === undefined;
}

// Explicitly assigning "undefined" to empty values because Flatfile returns empty string,
// but in the DB we don't want two values (null and empty string) to mean absence of value.
export function valueOrUndefinedIfEmpty<R extends RecordValue>(
  value: R,
): typeof value | undefined {
  return isEmpty(value) ? undefined : value;
}

export function numericValueOrUndefinedIfEmpty<R extends RecordValue>(
  value: R,
): number | undefined {
  const valueOrUndefined = valueOrUndefinedIfEmpty(value);
  // be careful to parse 0 correctly as a number
  return valueOrUndefined === undefined ? undefined : Number(valueOrUndefined);
}

export function getLessThanNumberMatch(
  value: RecordValue,
): { hasMatch: false } | { hasMatch: true; number: string } {
  const lessThanNumberRegex = /^(<|< |&lt;|&lt; )([0-9]*\.?[0-9]*)$/;
  const matchRes = String(value).match(lessThanNumberRegex);
  if (!matchRes) return { hasMatch: false };
  return {
    hasMatch: true,
    /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
    number: matchRes[2], // 0 – whole match, 1 – first group ("<" or "&lt;"), 2 – second group (actual number)
  };
}

// JS Date constructor will presume that a string in "yyyy-mm-dd" format is in UTC, beginning of the day.
// For example, note the time:
//    new Date("2020-06-02").toISOString() => "2020-06-02T00:00:00.000Z"
//    new Date("6/2/2020").toISOString() => "2020-06-02T07:00:00.000Z" (if user's browser is in PDT)
// So, if the date is of any other formats we need to parse it to UTC equivalent of local
export function alreadyInUTC(dateString: ValidDateString) {
  // yyyy-mm-dd
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line prefer-regex-literals
  const isoDayRegex = new RegExp(
    /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/,
  );
  return isoDayRegex.test(dateString);
}
