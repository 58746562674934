import React from 'react';
import PropTypes from 'prop-types';
import { Card as MuiCard, CardHeader, CardContent } from '@material-ui/core';
import { CardContentErrorBoundary } from './CardContentErrorBoundary';
import Menu from '../Menu';
import { MenuOptionType } from '../../propTypes';

export const cardWidths = {
  SMALL: 'width-small',
  MEDIUM: 'width-medium',
  LARGE: 'width-large',
};

export const cardHeights = {
  FIXED: 'height-fixed',
  FLEX: 'height-flex',
};

export const Card = React.forwardRef((props, ref) => {
  const {
    actions,
    children,
    className,
    heightStyle,
    menuOptions,
    title,
    widthStyle,
  } = props;

  const titleActions = actions || null;
  const titleMenu = menuOptions.length ? <Menu options={menuOptions} /> : null;

  const getTitle = () => (
    <span>
      {titleActions}
      {titleMenu}
    </span>
  );

  const cardClassNames = [
    'mapistry-card',
    `mapistry-card--${widthStyle}`,
    `mapistry-card--${heightStyle}`,
  ];
  if (className.length) {
    cardClassNames.push(className);
  }

  return (
    <MuiCard ref={ref} className={cardClassNames.join(' ')}>
      <CardHeader
        action={getTitle()}
        className="mapistry-card__header"
        classes={{
          title: 'mapistry-card__header-title',
          action: 'mapistry-card__header-menu',
          content: 'mapistry-card__header-content',
        }}
        title={title}
      />

      <CardContent className="mapistry-card__content">
        <CardContentErrorBoundary>{children}</CardContentErrorBoundary>
      </CardContent>
    </MuiCard>
  );
});

Card.propTypes = {
  actions: PropTypes.element,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  heightStyle: PropTypes.oneOf(Object.values(cardHeights)),
  menuOptions: PropTypes.arrayOf(MenuOptionType),
  title: PropTypes.string.isRequired,
  widthStyle: PropTypes.oneOf(Object.values(cardWidths)),
};

Card.defaultProps = {
  actions: null,
  className: '',
  heightStyle: cardHeights.FIXED,
  menuOptions: [],
  widthStyle: cardWidths.SMALL,
};
