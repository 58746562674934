import { useEffect, useRef } from 'react';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Arguments = Array<any>;

export function useEffectCallEvent(
  event: (...args: Arguments) => void,
  deps: Arguments,
  condition = true,
) {
  const eventRef = useRef(event);
  eventRef.current = event;

  const conditionRef = useRef(condition);
  conditionRef.current = condition;

  useEffect(() => {
    if (conditionRef.current) {
      eventRef.current?.(...deps);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}
