import { Dialog } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { createContext } from 'react';
import { Clear } from './MuiIcon';
import IconButton from './buttons/IconButton';

export const ModalDialogContext = createContext({ isInModal: true });

function Modal({
  className,
  children,
  footer,
  fullWidth,
  header,
  hideBackdrop,
  maxWidth,
  onClose,
  open,
  paperClassName,
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/prop-types
  scrollPaperClassName,
}) {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/no-unstable-nested-components
  const CloseButton = () => (
    <IconButton className="close-icon" onClick={onClose}>
      <div className="visually-hidden">Close</div>
      <Clear aria-disabled />
    </IconButton>
  );

  const width = () => {
    if (maxWidth) {
      return maxWidth;
    }
    return fullWidth ? 'xl' : 'md';
  };

  const smallClass = maxWidth === 'xs' ? ' small-size' : '';

  return (
    <Dialog
      classes={{
        root: className,
        paper: paperClassName
          ? `content-wrapper ${paperClassName}`
          : 'content-wrapper',
        scrollPaper: scrollPaperClassName,
      }}
      disableBackdropClick
      disableEnforceFocus
      maxWidth={width()}
      fullWidth={fullWidth}
      hideBackdrop={hideBackdrop}
      open={open}
      onClose={onClose}
    >
      {/* TODO: Fix this the next time the file is edited. */}
      {/* eslint-disable-next-line react/jsx-no-constructed-context-values */}
      <ModalDialogContext.Provider value={{ isInModal: true }}>
        <div className="modal-header">
          {header}
          <CloseButton />
        </div>
        <div className="modal-main">
          <div className={`main-container${smallClass}`}>{children}</div>
        </div>
        {footer ? <div className="modal-footer">{footer}</div> : null}
      </ModalDialogContext.Provider>
    </Dialog>
  );
}

Modal.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  footer: PropTypes.node,
  fullWidth: PropTypes.bool,
  header: PropTypes.node,
  hideBackdrop: PropTypes.bool,
  maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
  onClose: PropTypes.func,
  open: PropTypes.bool,
  paperClassName: PropTypes.string,
};

Modal.defaultProps = {
  children: [],
  className: null,
  footer: null,
  fullWidth: true,
  header: null,
  hideBackdrop: false,
  maxWidth: null,
  onClose: null,
  open: false,
  paperClassName: null,
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default Modal;
