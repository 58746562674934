import { useReplaceSearchParams } from '@monorepo/shared/hooks/navigation/useReplaceSearchParams';
import { useSearchParams } from '@monorepo/shared/hooks/navigation/useSearchParams';
import { useCallback } from 'react';

type FilterValue = string | null;

export function useSearchParamState<V extends FilterValue = FilterValue>(
  key: string,
) {
  const allSearchParams = useSearchParams();
  const replaceSearchParams = useReplaceSearchParams();
  const filterValue = (allSearchParams[key] ?? null) as V;

  const setFilterValue = useCallback(
    (value: V) => {
      replaceSearchParams({ [key]: value });
    },
    [key, replaceSearchParams],
  );
  return [filterValue, setFilterValue];
}
