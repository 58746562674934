import React from 'react';
import { Button } from '@monorepo/shared/components/buttons/Button';
import { BaseDialog } from './BaseDialog';

interface AlertDialogProps {
  buttonText?: string;
  description: string;
  onDone: () => void;
  open: boolean;
  showCloseButton?: boolean;
  title: string;
}

export function AlertDialog(props: AlertDialogProps) {
  const { open, title, description, onDone, showCloseButton } = props;

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/destructuring-assignment
  const buttonText = props.buttonText || 'OK';
  return (
    <BaseDialog
      open={open}
      title={title}
      description={description}
      buttons={
        <Button onClick={onDone} color="primary">
          {buttonText}
        </Button>
      }
      onClose={onDone}
      showCloseButton={showCloseButton}
    />
  );
}
