/* eslint-disable */
// when editing this file please uncomment the above and fix the linting errors
import React from 'react';

const TBody = (props) => {
  const { children } = props;
  return <div className="mapistry-table__body">{children}</div>;
};

export default TBody;
