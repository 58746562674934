import React from 'react';

import FieldWrapper from '../FieldWrapper';

type TDProps = {
  smallScreenLabel?: string;
  width?: number;
  growth?: string;
};

const TD: React.FC<TDProps> = (props) => {
  const { children, smallScreenLabel, width, growth } = props;
  const widthClassName = width ? ` fb-${width}` : '';
  const growthClassName = growth ? ` fb-grow-${growth}` : '';

  return (
    <div className={`mapistry-table__cell${widthClassName}${growthClassName}`}>
      {smallScreenLabel && (
        <FieldWrapper label={smallScreenLabel}>{children}</FieldWrapper>
      )}
      {!smallScreenLabel && children}
    </div>
  );
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default TD;
