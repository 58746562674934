import cn from 'classnames';
import React, { useMemo } from 'react';

import { SelectableOption } from '../../types/SelectableOption';
import { ToggleButton } from './ToggleButton';

type ToggleButtonsProps<T> = {
  onChange: (value: T[]) => void;
  options: SelectableOption<T>[];
  size?: 'small' | 'medium';
  value: T[];
  className?: string;
};

export const ToggleButtons = <T extends string>(
  props: ToggleButtonsProps<T>,
) => {
  const { onChange, options, size, value, className } = props;

  const selectedValue = useMemo(
    () =>
      value.reduce(
        (selected, val) => ({ ...selected, [val.toString()]: true }),
        {},
      ),
    [value],
  );

  const handleChange = (toggle: SelectableOption<T>, selected: boolean) => {
    onChange(
      selected
        ? [...value, toggle.value]
        : value.filter((v) => v !== toggle.value),
    );
  };

  return (
    <div className={cn('mapistry-toggles', className)}>
      {options.map((toggle) => (
        <div key={toggle.value} className={cn('mapistry-toggle', size)}>
          <ToggleButton
            value={toggle}
            /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
            selected={selectedValue[toggle.value.toString()]}
            onChange={(selected) => handleChange(toggle, selected)}
          >
            {toggle.label}
          </ToggleButton>
        </div>
      ))}
    </div>
  );
};
