// Work around for TS not supporting "T extends enum" generic
// @see https://github.com/microsoft/TypeScript/issues/30611
type StandardEnum = {
  [id: string]: string | number;
};
type EnumValue<T extends StandardEnum> = T[keyof T];

interface Invalidator<CacheParams> {
  hookName: string; // for debugging purposes
  callback: (params: CacheParams) => void;
}

type InvalidatorByEvent<CacheEvents extends StandardEnum, CacheParams> = {
  [key in EnumValue<CacheEvents>]: Invalidator<CacheParams>[];
};

interface Output<CacheEvents extends StandardEnum, CacheParams> {
  invalidate: (cacheEvent: EnumValue<CacheEvents>, params: CacheParams) => void;
  register: (
    events: EnumValue<CacheEvents>[],
    invalidator: Invalidator<CacheParams>,
  ) => void;
}

export function initCacheInvalidatorRegister<
  CacheEvents extends StandardEnum,
  CacheParams,
>(cacheEvents: CacheEvents): Output<CacheEvents, CacheParams> {
  const invalidatorsByEvent = {} as InvalidatorByEvent<
    CacheEvents,
    CacheParams
  >;
  const eventValues: EnumValue<CacheEvents>[] = Object.values(
    cacheEvents,
  ) as EnumValue<CacheEvents>[];
  eventValues.forEach((evt) => {
    invalidatorsByEvent[evt] = [];
  });

  const invalidate = (
    cacheEvent: EnumValue<CacheEvents>,
    params: CacheParams,
  ) => {
    const invalidators = invalidatorsByEvent[cacheEvent];
    invalidators.forEach(({ callback }) => callback(params));
  };

  const register = (
    subscribeEvents: EnumValue<CacheEvents>[],
    invalidator: Invalidator<CacheParams>,
  ) => {
    subscribeEvents.forEach((evt) => {
      invalidatorsByEvent[evt].push(invalidator);
    });
  };

  return { invalidate, register };
}
