import {
  CreateProjectBulkRequest,
  CreateProjectBulkResponse,
  Permissions,
  Project,
  ProjectWithUsersResponse,
} from 'mapistry-shared';
import qs from 'qs';
import http from './httpClient';
import * as t from './types';
import { ProjectSnakeCase } from './types';

export type FetchProjectVariables = {
  projectId: string;
};

export type FetchProjectResponse = Project;

export async function fetchProject(variables: FetchProjectVariables) {
  const { projectId } = variables;
  const res = await http.get<FetchProjectResponse>(
    `/api/v2/projects/${projectId}`,
  );
  return res.data;
}

export type FetchLastProjectResponse = t.ProjectSnakeCase;

export async function fetchLastProject(): Promise<ProjectSnakeCase> {
  const res = await http.get<FetchLastProjectResponse>('/api/projects/last');
  return res.data;
}

export async function setLastProject(
  projectId: string,
): Promise<ProjectSnakeCase> {
  const res = await http.post('/api/projects/last', { projectId });
  return res.data;
}

export type FetchProjectsForUserResponse = t.ProjectSnakeCase[];

export async function fetchProjectsForUser() {
  const res = await http.get<FetchProjectsForUserResponse>('/api/projects');
  return res.data;
}

export interface FetchAllProjectsOnOrganizationProps {
  organizationId: string;
}

export type FetchAllProjectsOnOrganizationResponse = Project[];

export async function fetchAllProjectsOnOrganization({
  organizationId,
}: FetchAllProjectsOnOrganizationProps) {
  const res = await http.get<FetchAllProjectsOnOrganizationResponse>(
    `/api/v2/organizations/${organizationId}/projects`,
  );
  return res.data;
}

export interface FetchAllProjectsOnOrgWithUsersProps {
  organizationId: string;
  permission?: Permissions;
}

export async function fetchAllProjectsOnOrgWithUsers({
  organizationId,
  permission,
}: FetchAllProjectsOnOrgWithUsersProps) {
  const res = await http.get<ProjectWithUsersResponse[]>(
    `/api/v2/organizations/${organizationId}/projects/users?${qs.stringify({
      permission,
    })}`,
  );
  return res.data;
}

export async function saveProjectsBulk(
  projectDetails: CreateProjectBulkRequest[],
): Promise<CreateProjectBulkResponse> {
  const res = await http.post<CreateProjectBulkResponse>(
    `/api/v2/projects/bulk`,
    projectDetails,
  );
  return res.data;
}
