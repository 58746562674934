/* eslint-disable no-console */
import configHelper from './index';

class Logger {
  constructor() {
    this.isProduction = !configHelper.isDevelopment && !configHelper.isTest;
    this.isTest = configHelper.isTest;
    // This Logger used to log to Loggly, but we've since removed Loggly.
    // We might restore a logging service again in the future but for now, just log to console
  }

  debug(...params) {
    console.debug(...params);
  }

  error(...params) {
    console.error(...params);
  }

  info(...params) {
    console.info(...params);
  }

  warn(...params) {
    console.warn(...params);
  }
}

const logger = new Logger();
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default logger;
