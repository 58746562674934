import { QueryConfig, useQuery } from 'react-query';
import { OrganizationResponse } from 'mapistry-shared';
import { Api } from '../apiClient';

export const createKey = ({ organizationId }: Api.FetchOrganizationProps) =>
  ['organization', organizationId] as const;

type OrganizationFetcher = Api.DataHookQueryFn<
  typeof createKey,
  typeof Api.fetchOrganization
>;

const fetcher: OrganizationFetcher = (_, organizationId) =>
  Api.fetchOrganization({
    organizationId,
  });

type UseOrganizationUsersProps = Partial<Api.FetchOrganizationProps> & {
  config?: QueryConfig<OrganizationResponse, Api.ErrorResponse>;
};

export function useOrganization({
  config: inputConfig,
  organizationId,
}: UseOrganizationUsersProps) {
  const key = organizationId ? createKey({ organizationId }) : undefined;
  const config = {
    enabled: !!organizationId,
    ...inputConfig,
  };
  const { data, ...queryInfo } = useQuery(key, fetcher, config);

  return {
    ...queryInfo,
    organization: data,
  };
}
