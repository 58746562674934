import { SelectableOption } from '@monorepo/shared/types/SelectableOption';
import _isEqual from 'lodash.isequal';
import {
  CustomFrequency,
  Days,
  IntervalFrequencyEnum,
  Months,
} from 'mapistry-shared';
import React, { createContext, useCallback, useMemo, useReducer } from 'react';
import { SaveState } from '../../types/SaveState';
import { getDateOptions, getRepeatOptions } from '../../utils/frequencyUtils';
import { frequencyStatusReducer } from './reducers';
import {
  ActionType,
  CUSTOM_FREQUENCY,
  DateOptions,
  FrequencyOption,
  SavedFrequency,
} from './types';
import { useEffectCallEvent } from './useEffectCallEvent';

type FrequencyContextValue = {
  alwaysUseDatePicker?: boolean;
  customFrequency?: CustomFrequency;
  customPopperOpen?: boolean;
  dateOptions?: DateOptions | null;
  minStartDate?: Date | null;
  noRepeat?: boolean;
  noRepeatOn?: boolean;
  noStartDate?: boolean;
  onCustomPopperCancel: () => void;
  onCustomPopperSave: () => void;
  onFrequencySelect: (
    value?: FrequencyOption | null,
    custom?: CustomFrequency,
  ) => void;
  onStartDateSelect: (val: Date) => void;
  repeatOptions: SelectableOption<Days>[] | SelectableOption<Months>[];
  saveState: SaveState;
  selectedFrequency?: IntervalFrequencyEnum | null;
  selectedStartDate?: Date | null;
};

const noop = () => undefined;
const DefaultValue: FrequencyContextValue = {
  onCustomPopperCancel: noop,
  onCustomPopperSave: noop,
  onFrequencySelect: noop,
  onStartDateSelect: noop,
  repeatOptions: [],
  saveState: SaveState.CLEAN,
};

export const FrequencyContext = createContext(DefaultValue);

type OnFrequencyChange = (
  selectedFrequency?: IntervalFrequencyEnum | null,
  customFrequency?: CustomFrequency,
  selectedStartDate?: Date | null,
) => void;

type FrequencyProviderProps = {
  alwaysUseDatePicker?: boolean;
  children: NonNullable<React.ReactNode>;
  customFrequency?: CustomFrequency;
  forceCustomFrequency?: boolean;
  frequencies?: SavedFrequency[];
  minStartDate?: Date | null;
  noRepeat?: boolean;
  noRepeatOn?: boolean;
  noStartDate?: boolean;
  onFrequencyChange: OnFrequencyChange;
  onSave?: () => void;
  onCustomPopperOpen?: (isOpen: boolean) => void;
  saveState?: SaveState | null;
  selectedFrequency?: IntervalFrequencyEnum | null;
  selectedStartDate?: Date | null;
  isDueDate?: boolean;
};

export const FrequencyProvider: React.FC<FrequencyProviderProps> = (
  props: FrequencyProviderProps,
) => {
  const {
    alwaysUseDatePicker,
    children,
    forceCustomFrequency,
    frequencies,
    minStartDate,
    noRepeat,
    noRepeatOn,
    noStartDate,
    onSave,
    onFrequencyChange,
    onCustomPopperOpen,
    isDueDate,
    saveState,
  } = props;

  const [state, dispatch] = useReducer(frequencyStatusReducer, props, (p) => ({
    customFrequency: p.customFrequency,
    localSaveState: SaveState.CLEAN,
    selectedFrequency: p.selectedFrequency,
    selectedStartDate: p.selectedStartDate,
  }));

  const {
    customFrequency,
    customPopperOpen,
    localSaveState,
    selectedFrequency,
    selectedStartDate,
  } = state;

  const handleCustomPopperCancel = useCallback(() => {
    dispatch({ type: ActionType.UndoAndCloseCustomPopper });
    onCustomPopperOpen?.(false);
  }, [onCustomPopperOpen]);

  const handleCustomPopperSave = useCallback(() => {
    dispatch({ type: ActionType.CloseCustomPopper });
    onSave?.();
    onCustomPopperOpen?.(false);
  }, [onCustomPopperOpen, onSave]);

  const handleFrequencySelect = useCallback(
    (
      updatedFrequency?: FrequencyOption | null,
      updatedCustomFrequency?: CustomFrequency,
    ) => {
      if (!updatedFrequency) {
        dispatch({ type: ActionType.Reset });
      } else if (updatedFrequency === CUSTOM_FREQUENCY) {
        dispatch({
          type: ActionType.OpenCustomPopper,
          payload: { alwaysUseDatePicker, frequencies, noRepeatOn },
        });
        onCustomPopperOpen?.(true);
      } else {
        dispatch({
          type: ActionType.UpdateFrequency,
          payload: {
            alwaysUseDatePicker,
            customFrequency: updatedCustomFrequency,
            forceCustomFrequency,
            frequencies,
            noRepeatOn,
            selectedFrequency: updatedFrequency,
            isDueDate,
          },
        });
      }
    },
    [
      alwaysUseDatePicker,
      forceCustomFrequency,
      frequencies,
      noRepeatOn,
      onCustomPopperOpen,
      isDueDate,
    ],
  );

  const handleStartDateSelect = useCallback(
    (startDate: Date) => {
      dispatch({
        type: ActionType.UpdateStartDate,
        payload: { selectedStartDate: startDate, noRepeatOn, isDueDate },
      });
    },
    [noRepeatOn, isDueDate],
  );

  const frequencyChangeArgs = [
    selectedFrequency,
    customFrequency,
    selectedStartDate,
  ];

  const frequencyChangePropArgs = [
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/destructuring-assignment
    props.selectedFrequency,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/destructuring-assignment
    props.customFrequency,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/destructuring-assignment
    props.selectedStartDate,
  ];

  useEffectCallEvent(
    onFrequencyChange,
    frequencyChangeArgs,
    !_isEqual(frequencyChangeArgs, frequencyChangePropArgs),
  );

  const dateOptions = useMemo(
    () =>
      alwaysUseDatePicker
        ? null
        : getDateOptions(frequencies, selectedFrequency, customFrequency),
    [alwaysUseDatePicker, frequencies, selectedFrequency, customFrequency],
  );

  const repeatOptions = useMemo(
    () => getRepeatOptions(selectedFrequency, customFrequency),
    [selectedFrequency, customFrequency],
  );

  const value = useMemo<FrequencyContextValue>(
    () => ({
      alwaysUseDatePicker,
      customFrequency,
      customPopperOpen,
      dateOptions,
      minStartDate,
      noRepeat,
      noRepeatOn,
      noStartDate,
      onCustomPopperCancel: handleCustomPopperCancel,
      onCustomPopperSave: handleCustomPopperSave,
      onFrequencySelect: handleFrequencySelect,
      onStartDateSelect: handleStartDateSelect,
      repeatOptions,
      saveState:
        saveState && saveState !== SaveState.CLEAN
          ? saveState
          : localSaveState || SaveState.CLEAN,
      selectedFrequency,
      selectedStartDate,
    }),
    [
      alwaysUseDatePicker,
      customFrequency,
      customPopperOpen,
      dateOptions,
      minStartDate,
      noRepeat,
      noRepeatOn,
      noStartDate,
      handleCustomPopperCancel,
      handleCustomPopperSave,
      handleFrequencySelect,
      handleStartDateSelect,
      localSaveState,
      repeatOptions,
      saveState,
      selectedFrequency,
      selectedStartDate,
    ],
  );

  return (
    <FrequencyContext.Provider value={value}>
      {children}
    </FrequencyContext.Provider>
  );
};
