import { FrequencyContext } from '@monorepo/shared/contexts/FrequencyContext';
import { CustomFrequencyValidator } from '@monorepo/shared/utils/CustomFrequencyValidator';
import { CustomFrequencyMessage } from 'mapistry-shared';
import React, { useContext, useMemo } from 'react';
import CustomFrequencyPopper from './CustomFrequencyPopper';

type CustomFrequencyPopperContainerProps = {
  anchorEl?: Element | null;
  customFrequencyMessageStart?: string;
  header?: string;
  subHeader?: string;
};

const CustomFrequencyPopperContainer = (
  props: CustomFrequencyPopperContainerProps,
) => {
  const { anchorEl, customFrequencyMessageStart, header, subHeader } = props;

  const { customFrequency, selectedFrequency } = useContext(FrequencyContext);

  const helperText = useMemo(() => {
    if (!selectedFrequency || !customFrequency) {
      return '';
    }
    const message = new CustomFrequencyMessage(
      selectedFrequency,
      customFrequency,
    );
    return message.getMessage({ messageStart: customFrequencyMessageStart });
  }, [selectedFrequency, customFrequency, customFrequencyMessageStart]);

  const errors = useMemo(() => {
    if (!selectedFrequency || !customFrequency) {
      return null;
    }
    const validator = new CustomFrequencyValidator(
      selectedFrequency,
      customFrequency,
    );
    return validator.validate();
  }, [selectedFrequency, customFrequency]);

  return (
    <CustomFrequencyPopper
      anchorEl={anchorEl}
      errors={errors}
      header={header}
      helperText={helperText}
      subHeader={subHeader}
    />
  );
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default CustomFrequencyPopperContainer;
