/* eslint-disable */
import React, { useMemo, useCallback, useState, useRef } from 'react';
import { AlertDialog } from '../../components/dialogs/AlertDialog';
import { ConfirmDialog } from '../../components/dialogs/ConfirmDialog';
import { PromptDialog } from '../../components/dialogs/PromptDialog';
import { ModalContext } from './ModalContext';

type ModalType = 'alert' | 'confirm' | 'prompt';

type ModalOpts = { [key: string]: any };

type ModalState = {
  showModal: boolean;
  modalType: ModalType | undefined;
  modalOpts: ModalOpts | undefined;
  prevModalOpts: ModalOpts | undefined;
};

type ModalManagerProps = {
  children: NonNullable<React.ReactNode>;
};

export function ModalManager(props: ModalManagerProps) {
  const { children } = props;

  const [modalState, setModalState] = useState<ModalState>({
    showModal: false,
    modalType: undefined,
    modalOpts: undefined,
    prevModalOpts: undefined,
  });
  const { showModal, modalType, modalOpts, prevModalOpts } = modalState;

  const setModalOpen = useCallback((type, opts) => {
    setModalState((prevModalState) => ({
      ...prevModalState,
      showModal: true,
      modalType: type,
      modalOpts: opts,
    }));
  }, []);

  const setModalClosedAndReset = useCallback(() => {
    setModalState((prevModalState) => ({
      ...prevModalState,
      showModal: false,
      modalType: undefined,
      modalOpts: undefined,
      prevModalOpts: prevModalState.modalOpts,
    }));
  }, []);

  const promiseCallbacksRef = useRef<{
    resolve: (value?: any) => void;
    reject: (reason?: any) => void;
  }>({
    resolve: noop,
    reject: noop,
  });

  const openModal = useCallback(
    (type: ModalType, opts: ModalOpts) => {
      setModalOpen(type, opts);
      const p = new Promise<any>((resolve, reject) => {
        promiseCallbacksRef.current = { resolve, reject };
      }).finally(() => {
        promiseCallbacksRef.current = {
          resolve: noop,
          reject: noop,
        };
        setModalClosedAndReset();
      });
      return p;
    },
    [setModalOpen, setModalClosedAndReset],
  );

  const alert = useCallback((opts) => openModal('alert', opts), [openModal]);

  const confirm = useCallback(
    (opts) => openModal('confirm', opts),
    [openModal],
  );

  const prompt = useCallback((opts) => openModal('prompt', opts), [openModal]);

  const contextValue = useMemo(
    () => ({
      alert,
      confirm,
      prompt,
    }),
    [alert, confirm, prompt],
  );

  const { resolve } = promiseCallbacksRef.current;

  const modalElements = useMemo(() => {
    const getOptValue = (key: string) =>
      modalOpts ? modalOpts[key] : prevModalOpts?.[key];

    const baseDialogOptProps = {
      title: getOptValue('title'),
      description: getOptValue('description'),
    };
    switch (modalType) {
      case 'alert':
        return (
          <AlertDialog
            {...baseDialogOptProps}
            open={showModal}
            buttonText={getOptValue('buttonText')}
            onDone={() => resolve()}
          />
        );

      case 'confirm':
        return (
          <ConfirmDialog
            {...baseDialogOptProps}
            open={showModal}
            cancelButtonText={getOptValue('cancelButtonText')}
            confirmButtonText={getOptValue('confirmButtonText')}
            confirmChoices={getOptValue('confirmChoices')}
            danger={getOptValue('danger')}
            onConfirm={(value) => resolve(value)}
            onCancel={() => resolve(false)}
          />
        );

      case 'prompt':
        return (
          <PromptDialog
            {...baseDialogOptProps}
            open={showModal}
            cancelButtonText={getOptValue('cancelButtonText')}
            confirmButtonText={getOptValue('confirmButtonText')}
            initialInputValue={getOptValue('initialInputValue')}
            danger={getOptValue('danger')}
            multiline={getOptValue('multiline')}
            onConfirm={(inputValue) => resolve(inputValue)}
            onCancel={() => resolve()}
          />
        );
    }
  }, [modalType, modalOpts, prevModalOpts, showModal, resolve]);

  return (
    <ModalContext.Provider value={contextValue}>
      {modalElements}
      {children}
    </ModalContext.Provider>
  );
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {};
