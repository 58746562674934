export const ADD_WASTEWATER_SAMPLING_PARAMETER_LOCATION_REQUEST =
  'ADD_WASTEWATER_SAMPLING_PARAMETER_LOCATION_REQUEST';
export const ADD_WASTEWATER_SAMPLING_PARAMETER_LOCATION_SUCCESS =
  'ADD_WASTEWATER_SAMPLING_PARAMETER_LOCATION_SUCCESS';
export const ADD_WASTEWATER_SAMPLING_PARAMETER_LOCATION_FAILURE =
  'ADD_WASTEWATER_SAMPLING_PARAMETER_LOCATION_FAILURE';

export const DELETE_WASTEWATER_SAMPLING_PARAMETER_AT_LOCATION_REQUEST =
  'DELETE_WASTEWATER_SAMPLING_PARAMETER_AT_LOCATION_REQUEST';
export const DELETE_WASTEWATER_SAMPLING_PARAMETER_AT_LOCATION_SUCCESS =
  'DELETE_WASTEWATER_SAMPLING_PARAMETER_AT_LOCATION_SUCCESS';
export const DELETE_WASTEWATER_SAMPLING_PARAMETER_AT_LOCATION_FAILURE =
  'DELETE_WASTEWATER_SAMPLING_PARAMETER_AT_LOCATION_FAILURE';

export const EDIT_WASTEWATER_SAMPLING_PARAMETER_LOCATION_REQUEST =
  'EDIT_WASTEWATER_SAMPLING_PARAMETER_LOCATION_REQUEST';
export const EDIT_WASTEWATER_SAMPLING_PARAMETER_LOCATION_SUCCESS =
  'EDIT_WASTEWATER_SAMPLING_PARAMETER_LOCATION_SUCCESS';
export const EDIT_WASTEWATER_SAMPLING_PARAMETER_LOCATION_FAILURE =
  'EDIT_WASTEWATER_SAMPLING_PARAMETER_LOCATION_FAILURE';

export const ADD_WASTEWATER_SAMPLING_EVENT_REQUEST =
  'ADD_WASTEWATER_SAMPLING_EVENT_REQUEST';
export const ADD_WASTEWATER_SAMPLING_EVENT_SUCCESS =
  'ADD_WASTEWATER_SAMPLING_EVENT_SUCCESS';
export const ADD_WASTEWATER_SAMPLING_EVENT_FAILURE =
  'ADD_WASTEWATER_SAMPLING_EVENT_FAILURE';

export const ADD_WASTEWATER_SAMPLING_PARAMETER_REQUEST =
  'ADD_WASTEWATER_SAMPLING_PARAMETER_REQUEST';
export const ADD_WASTEWATER_SAMPLING_PARAMETER_SUCCESS =
  'ADD_WASTEWATER_SAMPLING_PARAMETER_SUCCESS';
export const ADD_WASTEWATER_SAMPLING_PARAMETER_FAILURE =
  'ADD_WASTEWATER_SAMPLING_PARAMETER_FAILURE';

export const CREATE_EMISSION_CALCULATED_VALUE_REQUEST =
  'CREATE_EMISSION_CALCULATED_VALUE_REQUEST';
export const CREATE_EMISSION_CALCULATED_VALUE_SUCCESS =
  'CREATE_EMISSION_CALCULATED_VALUE_SUCCESS';
export const CREATE_EMISSION_CALCULATED_VALUE_FAILURE =
  'CREATE_EMISSION_CALCULATED_VALUE_FAILURE';

export const CREATE_EMISSION_FACTOR_REQUEST = 'CREATE_EMISSION_FACTOR_REQUEST';
export const CREATE_EMISSION_FACTOR_SUCCESS = 'CREATE_EMISSION_FACTOR_SUCCESS';
export const CREATE_EMISSION_FACTOR_FAILURE = 'CREATE_EMISSION_FACTOR_FAILURE';

export const CREATE_EMISSION_ITEM_GROUP_REQUEST =
  'CREATE_EMISSION_ITEM_GROUP_REQUEST';
export const CREATE_EMISSION_ITEM_GROUP_SUCCESS =
  'CREATE_EMISSION_ITEM_GROUP_SUCCESS';
export const CREATE_EMISSION_ITEM_GROUP_FAILURE =
  'CREATE_EMISSION_ITEM_GROUP_FAILURE';

export const CREATE_EMISSION_LIMIT_ITEM_REQUEST =
  'CREATE_EMISSION_LIMIT_ITEM_REQUEST';
export const CREATE_EMISSION_LIMIT_ITEM_SUCCESS =
  'CREATE_EMISSION_LIMIT_ITEM_SUCCESS';
export const CREATE_EMISSION_LIMIT_ITEM_FAILURE =
  'CREATE_EMISSION_LIMIT_ITEM_FAILURE';

export const CREATE_EMISSION_LOGGED_ITEM_REQUEST =
  'CREATE_EMISSION_LOGGED_ITEM_REQUEST';
export const CREATE_EMISSION_LOGGED_ITEM_SUCCESS =
  'CREATE_EMISSION_LOGGED_ITEM_SUCCESS';
export const CREATE_EMISSION_LOGGED_ITEM_FAILURE =
  'CREATE_EMISSION_LOGGED_ITEM_FAILURE';

export const CREATE_EMISSION_LOG_TABS_REQUEST =
  'CREATE_EMISSION_LOG_TABS_REQUEST';
export const CREATE_EMISSION_LOG_TABS_SUCCESS =
  'CREATE_EMISSION_LOG_TABS_SUCCESS';
export const CREATE_EMISSION_LOG_TABS_FAILURE =
  'CREATE_EMISSION_LOG_TABS_FAILURE';

export const BATCH_UPDATE_EMISSION_LOGS_REQUEST =
  'BATCH_UPDATE_EMISSION_LOGS_REQUEST';
export const BATCH_UPDATE_EMISSION_LOGS_SUCCESS =
  'BATCH_UPDATE_EMISSION_LOGS_SUCCESS';
export const BATCH_UPDATE_EMISSION_LOGS_FAILURE =
  'BATCH_UPDATE_EMISSION_LOGS_FAILURE';

export const CREATE_EMISSION_ROLLING_CALCULATION_REQUEST =
  'CREATE_EMISSION_ROLLING_CALCULATION_REQUEST';
export const CREATE_EMISSION_ROLLING_CALCULATION_SUCCESS =
  'CREATE_EMISSION_ROLLING_CALCULATION_SUCCESS';
export const CREATE_EMISSION_ROLLING_CALCULATION_FAILURE =
  'CREATE_EMISSION_ROLLING_CALCULATION_FAILURE';

export const CREATE_EMISSION_UNIT_CONVERSION_REQUEST =
  'CREATE_EMISSION_UNIT_CONVERSION_REQUEST';
export const CREATE_EMISSION_UNIT_CONVERSION_SUCCESS =
  'CREATE_EMISSION_UNIT_CONVERSION_SUCCESS';
export const CREATE_EMISSION_UNIT_CONVERSION_FAILURE =
  'CREATE_EMISSION_UNIT_CONVERSION_FAILURE';

export const CREATE_PH_CALIBRATION_READING_REQUEST =
  'CREATE_PH_CALIBRATION_READING_REQUEST';
export const CREATE_PH_CALIBRATION_READING_SUCCESS =
  'CREATE_PH_CALIBRATION_READING_SUCCESS';
export const CREATE_PH_CALIBRATION_READING_FAILURE =
  'CREATE_PH_CALIBRATION_READING_FAILURE';

export const CREATE_PH_LOG_READING_REQUEST = 'CREATE_PH_LOG_READING_REQUEST';
export const CREATE_PH_LOG_READING_SUCCESS = 'CREATE_PH_LOG_READING_SUCCESS';
export const CREATE_PH_LOG_READING_FAILURE = 'CREATE_PH_LOG_READING_FAILURE';

export const DELETE_EMISSION_CALCULATED_VALUE_REQUEST =
  'DELETE_EMISSION_CALCULATED_VALUE_REQUEST';
export const DELETE_EMISSION_CALCULATED_VALUE_SUCCESS =
  'DELETE_EMISSION_CALCULATED_VALUE_SUCCESS';
export const DELETE_EMISSION_CALCULATED_VALUE_FAILURE =
  'DELETE_EMISSION_CALCULATED_VALUE_FAILURE';

export const DELETE_EMISSION_FACTOR_REQUEST = 'DELETE_EMISSION_FACTOR_REQUEST';
export const DELETE_EMISSION_FACTOR_SUCCESS = 'DELETE_EMISSION_FACTOR_SUCCESS';
export const DELETE_EMISSION_FACTOR_FAILURE = 'DELETE_EMISSION_FACTOR_FAILURE';

export const DELETE_EMISSION_ITEM_GROUP_REQUEST =
  'DELETE_EMISSION_ITEM_GROUP_REQUEST';
export const DELETE_EMISSION_ITEM_GROUP_SUCCESS =
  'DELETE_EMISSION_ITEM_GROUP_SUCCESS';
export const DELETE_EMISSION_ITEM_GROUP_FAILURE =
  'DELETE_EMISSION_ITEM_GROUP_FAILURE';

export const DELETE_EMISSION_LIMIT_ITEM_REQUEST =
  'DELETE_EMISSION_LIMIT_ITEM_REQUEST';
export const DELETE_EMISSION_LIMIT_ITEM_SUCCESS =
  'DELETE_EMISSION_LIMIT_ITEM_SUCCESS';
export const DELETE_EMISSION_LIMIT_ITEM_FAILURE =
  'DELETE_EMISSION_LIMIT_ITEM_FAILURE';

export const DELETE_EMISSION_LOGGED_ITEM_REQUEST =
  'DELETE_EMISSION_LOGGED_ITEM_REQUEST';
export const DELETE_EMISSION_LOGGED_ITEM_SUCCESS =
  'DELETE_EMISSION_LOGGED_ITEM_SUCCESS';
export const DELETE_EMISSION_LOGGED_ITEM_FAILURE =
  'DELETE_EMISSION_LOGGED_ITEM_FAILURE';

export const DELETE_EMISSION_LOG_TABS_REQUEST =
  'DELETE_EMISSION_LOG_TABS_REQUEST';
export const DELETE_EMISSION_LOG_TABS_SUCCESS =
  'DELETE_EMISSION_LOG_TABS_SUCCESS';
export const DELETE_EMISSION_LOG_TABS_FAILURE =
  'DELETE_EMISSION_LOG_TABS_FAILURE';

export const DELETE_EMISSION_LOG_REQUEST = 'DELETE_EMISSION_LOG_REQUEST';
export const DELETE_EMISSION_LOG_SUCCESS = 'DELETE_EMISSION_LOG_SUCCESS';
export const DELETE_EMISSION_LOG_FAILURE = 'DELETE_EMISSION_LOG_FAILURE';

export const DELETE_EMISSION_ROLLING_CALCULATION_REQUEST =
  'DELETE_EMISSION_ROLLING_CALCULATION_REQUEST';
export const DELETE_EMISSION_ROLLING_CALCULATION_SUCCESS =
  'DELETE_EMISSION_ROLLING_CALCULATION_SUCCESS';
export const DELETE_EMISSION_ROLLING_CALCULATION_FAILURE =
  'DELETE_EMISSION_ROLLING_CALCULATION_FAILURE';

export const DELETE_EMISSION_UNIT_CONVERSION_REQUEST =
  'DELETE_EMISSION_UNIT_CONVERSION_REQUEST';
export const DELETE_EMISSION_UNIT_CONVERSION_SUCCESS =
  'DELETE_EMISSION_UNIT_CONVERSION_SUCCESS';
export const DELETE_EMISSION_UNIT_CONVERSION_FAILURE =
  'DELETE_EMISSION_UNIT_CONVERSION_FAILURE';

export const DELETE_PH_CALIBRATION_READING_REQUEST =
  'DELETE_PH_CALIBRATION_READING_REQUEST';
export const DELETE_PH_CALIBRATION_READING_SUCCESS =
  'DELETE_PH_CALIBRATION_READING_SUCCESS';
export const DELETE_PH_CALIBRATION_READING_FAILURE =
  'DELETE_PH_CALIBRATION_READING_FAILURE';

export const DELETE_PH_LOG_READING_REQUEST = 'DELETE_PH_LOG_READING_REQUEST';
export const DELETE_PH_LOG_READING_SUCCESS = 'DELETE_PH_LOG_READING_SUCCESS';
export const DELETE_PH_LOG_READING_FAILURE = 'DELETE_PH_LOG_READING_FAILURE';

export const DELETE_WASTEWATER_SAMPLING_PARAMETER_REQUEST =
  'DELETE_WASTEWATER_SAMPLING_PARAMETER_REQUEST';
export const DELETE_WASTEWATER_SAMPLING_PARAMETER_SUCCESS =
  'DELETE_WASTEWATER_SAMPLING_PARAMETER_SUCCESS';
export const DELETE_WASTEWATER_SAMPLING_PARAMETER_FAILURE =
  'DELETE_WASTEWATER_SAMPLING_PARAMETER_FAILURE';

export const DOWNLOAD_WASTEWATER_EVENTS = 'DOWNLOAD_WASTEWATER_EVENTS';

export const DOWNLOAD_WASTEWATER_FLOW_LOG = 'DOWNLOAD_WASTEWATER_FLOW_LOG';

export const EDIT_PH_CALIBRATION_READING_REQUEST =
  'EDIT_PH_CALIBRATION_READING_REQUEST';
export const EDIT_PH_CALIBRATION_READING_SUCCESS =
  'EDIT_PH_CALIBRATION_READING_SUCCESS';
export const EDIT_PH_CALIBRATION_READING_FAILURE =
  'EDIT_PH_CALIBRATION_READING_FAILURE';

export const EDIT_PH_LOG_READING_REQUEST = 'EDIT_PH_LOG_READING_REQUEST';
export const EDIT_PH_LOG_READING_SUCCESS = 'EDIT_PH_LOG_READING_SUCCESS';
export const EDIT_PH_LOG_READING_FAILURE = 'EDIT_PH_LOG_READING_FAILURE';

export const EDIT_WASTEWATER_SAMPLING_EVENT_REQUEST =
  'EDIT_WASTEWATER_SAMPLING_EVENT_REQUEST';
export const EDIT_WASTEWATER_SAMPLING_EVENT_SUCCESS =
  'EDIT_WASTEWATER_SAMPLING_EVENT_SUCCESS';
export const EDIT_WASTEWATER_SAMPLING_EVENT_FAILURE =
  'EDIT_WASTEWATER_SAMPLING_EVENT_FAILURE';

export const FETCH_EMISSION_CALCULATED_VALUES_REQUEST =
  'FETCH_EMISSION_CALCULATED_VALUES_REQUEST';
export const FETCH_EMISSION_CALCULATED_VALUES_SUCCESS =
  'FETCH_EMISSION_CALCULATED_VALUES_SUCCESS';
export const FETCH_EMISSION_CALCULATED_VALUES_FAILURE =
  'FETCH_EMISSION_CALCULATED_VALUES_FAILURE';

export const FETCH_EMISSION_FACTORS_REQUEST = 'FETCH_EMISSION_FACTORS_REQUEST';
export const FETCH_EMISSION_FACTORS_SUCCESS = 'FETCH_EMISSION_FACTORS_SUCCESS';
export const FETCH_EMISSION_FACTORS_FAILURE = 'FETCH_EMISSION_FACTORS_FAILURE';

export const FETCH_FORM_SUBMISSIONS_BY_SLUG_REQUEST =
  'FETCH_FORM_SUBMISSIONS_BY_SLUG_REQUEST';
export const FETCH_FORM_SUBMISSIONS_BY_SLUG_SUCCESS =
  'FETCH_FORM_SUBMISSIONS_BY_SLUG_SUCCESS';
export const FETCH_FORM_SUBMISSIONS_BY_SLUG_FAILURE =
  'FETCH_FORM_SUBMISSIONS_BY_SLUG_FAILURE';

export const FETCH_EMISSION_ITEM_GROUPS_REQUEST =
  'FETCH_EMISSION_ITEM_GROUPS_REQUEST';
export const FETCH_EMISSION_ITEM_GROUPS_SUCCESS =
  'FETCH_EMISSION_ITEM_GROUPS_SUCCESS';
export const FETCH_EMISSION_ITEM_GROUPS_FAILURE =
  'FETCH_EMISSION_ITEM_GROUPS_FAILURE';

export const FETCH_EMISSION_LIMIT_ITEMS_REQUEST =
  'FETCH_EMISSION_LIMIT_ITEMS_REQUEST';
export const FETCH_EMISSION_LIMIT_ITEMS_SUCCESS =
  'FETCH_EMISSION_LIMIT_ITEMS_SUCCESS';
export const FETCH_EMISSION_LIMIT_ITEMS_FAILURE =
  'FETCH_EMISSION_LIMIT_ITEMS_FAILURE';

export const FETCH_EMISSION_LOGGED_ITEMS_REQUEST =
  'FETCH_EMISSION_LOGGED_ITEMS_REQUEST';
export const FETCH_EMISSION_LOGGED_ITEMS_SUCCESS =
  'FETCH_EMISSION_LOGGED_ITEMS_SUCCESS';
export const FETCH_EMISSION_LOGGED_ITEMS_FAILURE =
  'FETCH_EMISSION_LOGGED_ITEMS_FAILURE';

export const FETCH_EMISSION_LOG_REQUEST = 'FETCH_EMISSION_LOG_REQUEST';
export const FETCH_EMISSION_LOG_SUCCESS = 'FETCH_EMISSION_LOG_SUCCESS';
export const FETCH_EMISSION_LOG_FAILURE = 'FETCH_EMISSION_LOG_FAILURE';

export const FETCH_EMISSION_LOGS_REQUEST = 'FETCH_EMISSION_LOGS_REQUEST';
export const FETCH_EMISSION_LOGS_SUCCESS = 'FETCH_EMISSION_LOGS_SUCCESS';
export const FETCH_EMISSION_LOGS_FAILURE = 'FETCH_EMISSION_LOGS_FAILURE';

export const FETCH_EMISSION_LOG_PARAMETERS_REQUEST =
  'FETCH_EMISSION_LOG_PARAMETERS_REQUEST';
export const FETCH_EMISSION_LOG_PARAMETERS_SUCCESS =
  'FETCH_EMISSION_LOG_PARAMETERS_SUCCESS';
export const FETCH_EMISSION_LOG_PARAMETERS_FAILURE =
  'FETCH_EMISSION_LOG_PARAMETERS_FAILURE';

export const FETCH_EMISSION_LOG_TABS_REQUEST =
  'FETCH_EMISSION_LOG_TABS_REQUEST';
export const FETCH_EMISSION_LOG_TABS_SUCCESS =
  'FETCH_EMISSION_LOG_TABS_SUCCESS';
export const FETCH_EMISSION_LOG_TABS_FAILURE =
  'FETCH_EMISSION_LOG_TABS_FAILURE';

export const FETCH_EMISSION_ROLLING_CALCULATIONS_REQUEST =
  'FETCH_EMISSION_ROLLING_CALCULATIONS_REQUEST';
export const FETCH_EMISSION_ROLLING_CALCULATIONS_SUCCESS =
  'FETCH_EMISSION_ROLLING_CALCULATIONS_SUCCESS';
export const FETCH_EMISSION_ROLLING_CALCULATIONS_FAILURE =
  'FETCH_EMISSION_ROLLING_CALCULATIONS_FAILURE';

export const FETCH_EMISSION_UNIT_CONVERSIONS_REQUEST =
  'FETCH_EMISSION_UNIT_CONVERSIONS_REQUEST';
export const FETCH_EMISSION_UNIT_CONVERSIONS_SUCCESS =
  'FETCH_EMISSION_UNIT_CONVERSIONS_SUCCESS';
export const FETCH_EMISSION_UNIT_CONVERSIONS_FAILURE =
  'FETCH_EMISSION_UNIT_CONVERSIONS_FAILURE';

export const FETCH_EMISSION_STATISTICS_REQUEST =
  'FETCH_EMISSION_STATISTICS_REQUEST';
export const FETCH_EMISSION_STATISTICS_SUCCESS =
  'FETCH_EMISSION_STATISTICS_SUCCESS';
export const FETCH_EMISSION_STATISTICS_FAILURE =
  'FETCH_EMISSION_STATISTICS_FAILURE';

export const FETCH_EMISSION_MICRO_INTERVAL_STATISTICS_REQUEST =
  'FETCH_EMISSION_MICRO_INTERVAL_STATISTICS_REQUEST';
export const FETCH_EMISSION_MICRO_INTERVAL_STATISTICS_SUCCESS =
  'FETCH_EMISSION_MICRO_INTERVAL_STATISTICS_SUCCESS';
export const FETCH_EMISSION_MICRO_INTERVAL_STATISTICS_FAILURE =
  'FETCH_EMISSION_MICRO_INTERVAL_STATISTICS_FAILURE';

export const FETCH_CALCULATED_VALUES_REQUEST =
  'FETCH_CALCULATED_VALUES_REQUEST';
export const FETCH_CALCULATED_VALUES_SUCCESS =
  'FETCH_CALCULATED_VALUES_SUCCESS';
export const FETCH_CALCULATED_VALUES_FAILURE =
  'FETCH_CALCULATED_VALUES_FAILURE';

export const FETCH_FLOW_LOGS_FOR_CHART_REQUEST =
  'FETCH_FLOW_LOGS_FOR_CHART_REQUEST';
export const FETCH_FLOW_LOGS_FOR_CHART_SUCCESS =
  'FETCH_FLOW_LOGS_FOR_CHART_SUCCESS';
export const FETCH_FLOW_LOGS_FOR_CHART_FAILURE =
  'FETCH_FLOW_LOGS_FOR_CHART_FAILURE';

export const FETCH_PH_LOGS_FOR_CHART_REQUEST =
  'FETCH_PH_LOGS_FOR_CHART_REQUEST';
export const FETCH_PH_LOGS_FOR_CHART_SUCCESS =
  'FETCH_PH_LOGS_FOR_CHART_SUCCESS';
export const FETCH_PH_LOGS_FOR_CHART_FAILURE =
  'FETCH_PH_LOGS_FOR_CHART_FAILURE';

export const FETCH_SAMPLING_CALENDAR_EVENTS_REQUEST =
  'FETCH_SAMPLING_CALENDAR_EVENTS_REQUEST';
export const FETCH_SAMPLING_CALENDAR_EVENTS_SUCCESS =
  'FETCH_SAMPLING_CALENDAR_EVENTS_SUCCESS';
export const FETCH_SAMPLING_CALENDAR_EVENTS_FAILURE =
  'FETCH_SAMPLING_CALENDAR_EVENTS_FAILURE';

export const FETCH_CALENDAR_EVENTS_REQUEST = 'FETCH_CALENDAR_EVENTS_REQUEST';
export const FETCH_CALENDAR_EVENTS_SUCCESS = 'FETCH_CALENDAR_EVENTS_SUCCESS';
export const FETCH_CALENDAR_EVENTS_FAILURE = 'FETCH_CALENDAR_EVENTS_FAILURE';

export const FETCH_INSPECTION_CALENDAR_STENCILS_REQUEST =
  'FETCH_INSPECTION_CALENDAR_STENCILS_REQUEST';
export const FETCH_INSPECTION_CALENDAR_STENCILS_SUCCESS =
  'FETCH_INSPECTION_CALENDAR_STENCILS_SUCCESS';
export const FETCH_INSPECTION_CALENDAR_STENCILS_FAILURE =
  'FETCH_INSPECTION_CALENDAR_STENCILS_FAILURE';

export const FETCH_CALENDAR_ATTACHMENT_COUNT_REQUEST =
  'FETCH_CALENDAR_ATTACHMENT_COUNT_REQUEST';
export const FETCH_CALENDAR_ATTACHMENT_COUNT_SUCCESS =
  'FETCH_CALENDAR_ATTACHMENT_COUNT_SUCCESS';
export const FETCH_CALENDAR_ATTACHMENT_COUNT_FAILURE =
  'FETCH_CALENDAR_ATTACHMENT_COUNT_FAILURE';

export const FETCH_CALENDAR_SUMMARY_REQUEST = 'FETCH_CALENDAR_SUMMARY_REQUEST';
export const FETCH_CALENDAR_SUMMARY_SUCCESS = 'FETCH_CALENDAR_SUMMARY_SUCCESS';
export const FETCH_CALENDAR_SUMMARY_FAILURE = 'FETCH_CALENDAR_SUMMARY_FAILURE';

export const DOWNLOAD_CALENDAR_ATTACHMENTS_REQUEST =
  'DOWNLOAD_CALENDAR_ATTACHMENTS_REQUEST';
export const DOWNLOAD_CALENDAR_ATTACHMENTS_SUCCESS =
  'DOWNLOAD_CALENDAR_ATTACHMENTS_SUCCESS';
export const DOWNLOAD_CALENDAR_ATTACHMENTS_FAILURE =
  'DOWNLOAD_CALENDAR_ATTACHMENTS_FAILURE';

export const CLEAR_CALENDAR_FREQUENCIES = 'CLEAR_CALENDAR_FREQUENCIES';

export const CREATE_CALENDAR_FREQUENCY_REQUEST =
  'CREATE_CALENDAR_FREQUENCY_REQUEST';
export const CREATE_CALENDAR_FREQUENCY_SUCCESS =
  'CREATE_CALENDAR_FREQUENCY_SUCCESS';
export const CREATE_CALENDAR_FREQUENCY_FAILURE =
  'CREATE_CALENDAR_FREQUENCY_FAILURE';

export const DELETE_CALENDAR_FREQUENCY_REQUEST =
  'DELETE_CALENDAR_FREQUENCY_REQUEST';
export const DELETE_CALENDAR_FREQUENCY_SUCCESS =
  'DELETE_CALENDAR_FREQUENCY_SUCCESS';
export const DELETE_CALENDAR_FREQUENCY_FAILURE =
  'DELETE_CALENDAR_FREQUENCY_FAILURE';

export const FETCH_CALENDAR_FREQUENCIES_REQUEST =
  'FETCH_CALENDAR_FREQUENCIES_REQUEST';
export const FETCH_CALENDAR_FREQUENCIES_SUCCESS =
  'FETCH_CALENDAR_FREQUENCIES_SUCCESS';
export const FETCH_CALENDAR_FREQUENCIES_FAILURE =
  'FETCH_CALENDAR_FREQUENCIES_FAILURE';

export const FETCH_CALENDAR_MICRO_INTERVALS_REQUEST =
  'FETCH_CALENDAR_MICRO_INTERVALS_REQUEST';
export const FETCH_CALENDAR_MICRO_INTERVALS_SUCCESS =
  'FETCH_CALENDAR_MICRO_INTERVALS_SUCCESS';
export const FETCH_CALENDAR_MICRO_INTERVALS_FAILURE =
  'FETCH_CALENDAR_MICRO_INTERVALS_FAILURE';

export const FETCH_CALENDAR_PARENT_INTERVALS_REQUEST =
  'FETCH_CALENDAR_PARENT_INTERVALS_REQUEST';
export const FETCH_CALENDAR_PARENT_INTERVALS_SUCCESS =
  'FETCH_CALENDAR_PARENT_INTERVALS_SUCCESS';
export const FETCH_CALENDAR_PARENT_INTERVALS_FAILURE =
  'FETCH_CALENDAR_PARENT_INTERVALS_FAILURE';

export const CREATE_CALENDAR_ASSIGNEES_REQUEST =
  'CREATE_CALENDAR_ASSIGNEES_REQUEST';
export const CREATE_CALENDAR_ASSIGNEES_SUCCESS =
  'CREATE_CALENDAR_ASSIGNEES_SUCCESS';
export const CREATE_CALENDAR_ASSIGNEES_FAILURE =
  'CREATE_CALENDAR_ASSIGNEES_FAILURE';

export const REMOVE_CALENDAR_ASSIGNEES_REQUEST =
  'REMOVE_CALENDAR_ASSIGNEES_REQUEST';
export const REMOVE_CALENDAR_ASSIGNEES_SUCCESS =
  'REMOVE_CALENDAR_ASSIGNEES_SUCCESS';
export const REMOVE_CALENDAR_ASSIGNEES_FAILURE =
  'REMOVE_CALENDAR_ASSIGNEES_FAILURE';

export const RESET_CALENDAR_ERROR_STATE = 'RESET_CALENDAR_ERROR_STATE';

export const INIT_SAMPLING_EVENT = 'INIT_SAMPLING_EVENT';

export const INIT_WASTEWATER_LIMIT_CALCULATION =
  'INIT_WASTEWATER_LIMIT_CALCULATION';

export const ADD_FLOW_LOG_LIMIT_REQUEST = 'ADD_FLOW_LOG_LIMIT_REQUEST';
export const ADD_FLOW_LOG_LIMIT_SUCCESS = 'ADD_FLOW_LOG_LIMIT_SUCCESS';
export const ADD_FLOW_LOG_LIMIT_FAILURE = 'ADD_FLOW_LOG_LIMIT_FAILURE';

export const DELETE_FLOW_LOG_LIMIT_REQUEST = 'DELETE_FLOW_LOG_LIMIT_REQUEST';
export const DELETE_FLOW_LOG_LIMIT_SUCCESS = 'DELETE_FLOW_LOG_LIMIT_SUCCESS';
export const DELETE_FLOW_LOG_LIMIT_FAILURE = 'DELETE_FLOW_LOG_LIMIT_FAILURE';

export const DELETE_WASTEWATER_SAMPLING_RESULT_REQUEST =
  'DELETE_WASTEWATER_SAMPLING_RESULT_REQUEST';
export const DELETE_WASTEWATER_SAMPLING_RESULT_SUCCESS =
  'DELETE_WASTEWATER_SAMPLING_RESULT_SUCCESS';
export const DELETE_WASTEWATER_SAMPLING_RESULT_FAILURE =
  'DELETE_WASTEWATER_SAMPLING_RESULT_FAILURE';

export const EDIT_FLOW_LOG_LIMIT_REQUEST = 'EDIT_FLOW_LOG_LIMIT_REQUEST';
export const EDIT_FLOW_LOG_LIMIT_SUCCESS = 'EDIT_FLOW_LOG_LIMIT_SUCCESS';
export const EDIT_FLOW_LOG_LIMIT_FAILURE = 'EDIT_FLOW_LOG_LIMIT_FAILURE';

export const FETCH_FLOW_LOG_LIMITS_REQUEST = 'FETCH_FLOW_LOG_LIMITS_REQUEST';
export const FETCH_FLOW_LOG_LIMITS_SUCCESS = 'FETCH_FLOW_LOG_LIMITS_SUCCESS';
export const FETCH_FLOW_LOG_LIMITS_FAILURE = 'FETCH_FLOW_LOG_LIMITS_FAILURE';

export const ADD_FLOW_LOG_READING_REQUEST = 'ADD_FLOW_LOG_READING_REQUEST';
export const ADD_FLOW_LOG_READING_SUCCESS = 'ADD_FLOW_LOG_READING_SUCCESS';
export const ADD_FLOW_LOG_READING_FAILURE = 'ADD_FLOW_LOG_READING_FAILURE';

export const DELETE_FLOW_LOG_READING_REQUEST =
  'DELETE_FLOW_LOG_READING_REQUEST';
export const DELETE_FLOW_LOG_READING_SUCCESS =
  'DELETE_FLOW_LOG_READING_SUCCESS';
export const DELETE_FLOW_LOG_READING_FAILURE =
  'DELETE_FLOW_LOG_READING_FAILURE';

export const EDIT_FLOW_LOG_READING_REQUEST = 'EDIT_FLOW_LOG_READING_REQUEST';
export const EDIT_FLOW_LOG_READING_SUCCESS = 'EDIT_FLOW_LOG_READING_SUCCESS';
export const EDIT_FLOW_LOG_READING_FAILURE = 'EDIT_FLOW_LOG_READING_FAILURE';

export const FETCH_FLOW_LOG_READINGS_REQUEST =
  'FETCH_FLOW_LOG_READINGS_REQUEST';
export const FETCH_FLOW_LOG_READINGS_SUCCESS =
  'FETCH_FLOW_LOG_READINGS_SUCCESS';
export const FETCH_FLOW_LOG_READINGS_FAILURE =
  'FETCH_FLOW_LOG_READINGS_FAILURE';

export const FETCH_FLOW_LOG_STATS_REQUEST = 'FETCH_FLOW_LOG_STATS_REQUEST';
export const FETCH_FLOW_LOG_STATS_SUCCESS = 'FETCH_FLOW_LOG_STATS_SUCCESS';
export const FETCH_FLOW_LOG_STATS_FAILURE = 'FETCH_FLOW_LOG_STATS_FAILURE';

export const FETCH_CURRENT_USER_SAVED_SETTING_REQUEST =
  'FETCH_CURRENT_USER_SAVED_SETTING_REQUEST';
export const FETCH_CURRENT_USER_SAVED_SETTING_SUCCESS =
  'FETCH_CURRENT_USER_SAVED_SETTING_SUCCESS';
export const FETCH_CURRENT_USER_SAVED_SETTING_FAILURE =
  'FETCH_CURRENT_USER_SAVED_SETTING_FAILURE';

export const FETCH_WASTEWATER_PARAMETER_LIMIT_CALCULATIONS_REQUEST =
  'FETCH_WASTEWATER_PARAMETER_LIMIT_CALCULATIONS_REQUEST';
export const FETCH_WASTEWATER_PARAMETER_LIMIT_CALCULATIONS_SUCCESS =
  'FETCH_WASTEWATER_PARAMETER_LIMIT_CALCULATIONS_SUCCESS';
export const FETCH_WASTEWATER_PARAMETER_LIMIT_CALCULATIONS_FAILURE =
  'FETCH_WASTEWATER_PARAMETER_LIMIT_CALCULATIONS_FAILURE';

export const FETCH_WASTEWATER_PARAMETERS_REQUEST =
  'FETCH_WASTEWATER_PARAMETERS_REQUEST';
export const FETCH_WASTEWATER_PARAMETERS_SUCCESS =
  'FETCH_WASTEWATER_PARAMETERS_SUCCESS';
export const FETCH_WASTEWATER_PARAMETERS_FAILURE =
  'FETCH_WASTEWATER_PARAMETERS_FAILURE';

export const FETCH_WASTEWATER_MONITORING_LOCATIONS_REQUEST =
  'FETCH_WASTEWATER_MONITORING_LOCATIONS_REQUEST';
export const FETCH_WASTEWATER_MONITORING_LOCATIONS_SUCCESS =
  'FETCH_WASTEWATER_MONITORING_LOCATIONS_SUCCESS';
export const FETCH_WASTEWATER_MONITORING_LOCATIONS_FAILURE =
  'FETCH_WASTEWATER_MONITORING_LOCATIONS_FAILURE';

export const FETCH_WASTEWATER_SAMPLING_EVENT_REQUEST =
  'FETCH_WASTEWATER_SAMPLING_EVENT_REQUEST';
export const FETCH_WASTEWATER_SAMPLING_EVENT_SUCCESS =
  'FETCH_WASTEWATER_SAMPLING_EVENT_SUCCESS';
export const FETCH_WASTEWATER_SAMPLING_EVENT_FAILURE =
  'FETCH_WASTEWATER_SAMPLING_EVENT_FAILURE';

export const FETCH_PROJECT_REQUEST = 'FETCH_PROJECT_REQUEST';
export const FETCH_PROJECT_SUCCESS = 'FETCH_PROJECT_SUCCESS';
export const FETCH_PROJECT_FAILURE = 'FETCH_PROJECT_FAILURE';

export const FETCH_PROJECT_USERS_REQUEST = 'FETCH_PROJECT_USERS_REQUEST';
export const FETCH_PROJECT_USERS_SUCCESS = 'FETCH_PROJECT_USERS_SUCCESS';
export const FETCH_PROJECT_USERS_FAILURE = 'FETCH_PROJECT_USERS_FAILURE';

export const RESET_WASTEWATER_ERROR_STATE = 'RESET_WASTEWATER_ERROR_STATE';
export const RESET_EMISSION_LOGS = 'RESET_EMISSION_LOGS';

export const SET_CURRENT_USER_SAVED_SETTING_REQUEST =
  'SET_CURRENT_USER_SAVED_SETTING_REQUEST';
export const SET_CURRENT_USER_SAVED_SETTING_SUCCESS =
  'SET_CURRENT_USER_SAVED_SETTING_SUCCESS';
export const SET_CURRENT_USER_SAVED_SETTING_FAILURE =
  'SET_CURRENT_USER_SAVED_SETTING_FAILURE';

export const UPDATE_EMISSION_CALCULATED_VALUE_REQUEST =
  'UPDATE_EMISSION_CALCULATED_VALUE_REQUEST';
export const UPDATE_EMISSION_CALCULATED_VALUE_SUCCESS =
  'UPDATE_EMISSION_CALCULATED_VALUE_SUCCESS';
export const UPDATE_EMISSION_CALCULATED_VALUE_FAILURE =
  'UPDATE_EMISSION_CALCULATED_VALUE_FAILURE';

export const UPDATE_EMISSION_FACTOR_REQUEST = 'UPDATE_EMISSION_FACTOR_REQUEST';
export const UPDATE_EMISSION_FACTOR_SUCCESS = 'UPDATE_EMISSION_FACTOR_SUCCESS';
export const UPDATE_EMISSION_FACTOR_FAILURE = 'UPDATE_EMISSION_FACTOR_FAILURE';

export const UPDATE_EMISSION_LIMIT_ITEM_REQUEST =
  'UPDATE_EMISSION_LIMIT_ITEM_REQUEST';
export const UPDATE_EMISSION_LIMIT_ITEM_SUCCESS =
  'UPDATE_EMISSION_LIMIT_ITEM_SUCCESS';
export const UPDATE_EMISSION_LIMIT_ITEM_FAILURE =
  'UPDATE_EMISSION_LIMIT_ITEM_FAILURE';

export const UPDATE_EMISSION_ITEM_GROUP_REQUEST =
  'UPDATE_EMISSION_ITEM_GROUP_REQUEST';
export const UPDATE_EMISSION_ITEM_GROUP_SUCCESS =
  'UPDATE_EMISSION_ITEM_GROUP_SUCCESS';
export const UPDATE_EMISSION_ITEM_GROUP_FAILURE =
  'UPDATE_EMISSION_ITEM_GROUP_FAILURE';

export const UPDATE_EMISSION_LOGGED_ITEM_REQUEST =
  'UPDATE_EMISSION_LOGGED_ITEM_REQUEST';
export const UPDATE_EMISSION_LOGGED_ITEM_SUCCESS =
  'UPDATE_EMISSION_LOGGED_ITEM_SUCCESS';
export const UPDATE_EMISSION_LOGGED_ITEM_FAILURE =
  'UPDATE_EMISSION_LOGGED_ITEM_FAILURE';

export const UPDATE_EMISSION_LOG_TABS_REQUEST =
  'UPDATE_EMISSION_LOG_TABS_REQUEST';
export const UPDATE_EMISSION_LOG_TABS_SUCCESS =
  'UPDATE_EMISSION_LOG_TABS_SUCCESS';
export const UPDATE_EMISSION_LOG_TABS_FAILURE =
  'UPDATE_EMISSION_LOG_TABS_FAILURE';

export const UPDATE_EMISSION_ROLLING_CALCULATION_REQUEST =
  'UPDATE_EMISSION_ROLLING_CALCULATION_REQUEST';
export const UPDATE_EMISSION_ROLLING_CALCULATION_SUCCESS =
  'UPDATE_EMISSION_ROLLING_CALCULATION_SUCCESS';
export const UPDATE_EMISSION_ROLLING_CALCULATION_FAILURE =
  'UPDATE_EMISSION_ROLLING_CALCULATION_FAILURE';

export const UPDATE_EMISSION_TRACKING_CALENDARS =
  'UPDATE_EMISSION_TRACKING_CALENDARS';

export const UPDATE_EMISSION_UNIT_CONVERSION_REQUEST =
  'UPDATE_EMISSION_UNIT_CONVERSION_REQUEST';
export const UPDATE_EMISSION_UNIT_CONVERSION_SUCCESS =
  'UPDATE_EMISSION_UNIT_CONVERSION_SUCCESS';
export const UPDATE_EMISSION_UNIT_CONVERSION_FAILURE =
  'UPDATE_EMISSION_UNIT_CONVERSION_FAILURE';

export const FETCH_CURRENT_USER_WIDGET_SETTING_REQUEST =
  'FETCH_CURRENT_USER_WIDGET_SETTING_REQUEST';
export const FETCH_CURRENT_USER_WIDGET_SETTING_SUCCESS =
  'FETCH_CURRENT_USER_WIDGET_SETTING_SUCCESS';
export const FETCH_CURRENT_USER_WIDGET_SETTING_FAILURE =
  'FETCH_CURRENT_USER_WIDGET_SETTING_FAILURE';

export const SET_CURRENT_USER_WIDGET_SETTING_REQUEST =
  'SET_CURRENT_USER_WIDGET_SETTING_REQUEST';
export const SET_CURRENT_USER_WIDGET_SETTING_SUCCESS =
  'SET_CURRENT_USER_WIDGET_SETTING_SUCCESS';
export const SET_CURRENT_USER_WIDGET_SETTING_FAILURE =
  'SET_CURRENT_USER_WIDGET_SETTING_FAILURE';

export const SET_CURRENT_USER_SPECIFIC_WIDGET_SETTING_REQUEST =
  'SET_CURRENT_USER_SPECIFIC_WIDGET_SETTING_REQUEST';
export const SET_CURRENT_USER_SPECIFIC_WIDGET_SETTING_SUCCESS =
  'SET_CURRENT_USER_SPECIFIC_WIDGET_SETTING_SUCCESS';
export const SET_CURRENT_USER_SPECIFIC_WIDGET_SETTING_FAILURE =
  'SET_CURRENT_USER_SPECIFIC_WIDGET_SETTING_FAILURE';

export const DELETE_CURRENT_USER_WIDGET_SETTING_REQUEST =
  'DELETE_CURRENT_USER_WIDGET_SETTING_REQUEST';
export const DELETE_CURRENT_USER_WIDGET_SETTING_SUCCESS =
  'DELETE_CURRENT_USER_WIDGET_SETTING_SUCCESS';
export const DELETE_CURRENT_USER_WIDGET_SETTING_FAILURE =
  'DELETE_CURRENT_USER_WIDGET_SETTING_FAILURE';

export const FETCH_TASKS_REQUEST = 'FETCH_TASKS_REQUEST';
export const FETCH_TASKS_SUCCESS = 'FETCH_TASKS_SUCCESS';
export const FETCH_TASKS_FAILURE = 'FETCH_TASKS_FAILURE';

export const MARK_TASK_COMPLETE_REQUEST = 'MARK_TASK_COMPLETE_REQUEST';
export const MARK_TASK_COMPLETE_SUCCESS = 'MARK_TASK_COMPLETE_SUCCESS';
export const MARK_TASK_COMPLETE_FAILURE = 'MARK_TASK_COMPLETE_FAILURE';

export const DELETE_TASK_REQUEST = 'DELETE_TASK_REQUEST';
export const DELETE_TASK_SUCCESS = 'DELETE_TASK_SUCCESS';
export const DELETE_TASK_FAILURE = 'DELETE_TASK_FAILURE';

export const FETCH_USERS_REQUEST = 'FETCH_USERS_REQUEST';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE';
