import styled from 'styled-components';
import { TextButton } from './TextButton';

export const TextPrimaryButton = styled(TextButton)`
  color: ${({ theme }) => theme.colors.lightBlue};

  & svg {
    fill: ${({ theme }) => theme.colors.lightBlue};
  }

  &:hover {
    /* Need to explicitly set color for buttons that are rendered with <a> element,
       e.g. when href prop is passed */
    color: ${({ theme }) => theme.colors.lightBlue};
    background-color: ${({ theme }) => theme.colors.lightBlue08};
  }

  &:focus {
    /* Need to explicitly set color for buttons that are rendered with <a> element,
       e.g. when href prop is passed */
    color: ${({ theme }) => theme.colors.lightBlue};
  }

  &:not(.Mui-disabled) {
    font-weight: 400;
    -webkit-text-stroke: 0.5px ${({ theme }) => theme.colors.darkerBlue};

    &:active,
    &:focus-visible {
      background-color: ${({ theme }) => theme.colors.lightBlue16};
    }
  }
`;
