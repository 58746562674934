import { Records } from '@flatfile/api/api';
import {
  isIndexedErrorResponse,
  isMultiErrorResponse,
  isStringErrorResponse,
} from '../../../apiClient/httpClient';
import { IndexedErrorResponse } from '../../../apiClient/types';
import { SubmitErrorCallback } from '../types/flatfileTypes';
import {
  announceError,
  announceFinish,
  FlatfileApiRecord,
  updateRecords,
} from './flatfileApiHelpers';

async function handleFlatfileTimeout(
  error: any,
  jobId: string,
): Promise<boolean> {
  if (error?.status === 504) {
    const message =
      'The file you have uploaded is quite large! We are processing your data in the background, and it should be available for you to peruse within 15 minutes. Thanks for your patience.';

    await announceFinish(jobId, 'This might take a moment', message);
    return true;
  }

  return false;
}

function composeRejectedRecord(
  record: FlatfileApiRecord,
  error: Record<string, string>,
): FlatfileApiRecord {
  const valuesWithErrors = record.values;
  Object.entries(error).forEach(([fieldKey, message]) => {
    valuesWithErrors[fieldKey] = {
      ...record.values[fieldKey],
      valid: false,
      messages: [
        ...(record.values[fieldKey]?.messages || []),
        {
          type: 'error',
          message,
        },
      ],
    };
  });

  return {
    ...record,
    valid: false,
    values: valuesWithErrors,
  };
}

function formatIndexedErrorsAsRejectedRecords(
  indexedErrors: IndexedErrorResponse['data']['errors'],
  records: Records,
): FlatfileApiRecord[] {
  const rejectedRecords: FlatfileApiRecord[] = [];
  // for indexed errors, errors should always be as long as records list
  indexedErrors.forEach((error, index) => {
    const record = records[index] as FlatfileApiRecord;
    if (error) {
      rejectedRecords.push(composeRejectedRecord(record, error));
    }
  });
  return rejectedRecords;
}

async function handleIndexedError(
  indexedError: IndexedErrorResponse,
  records: Records,
  { sheetId }: { sheetId: string },
) {
  const { errors } = indexedError.data;
  const rejectedRecords = formatIndexedErrorsAsRejectedRecords(errors, records);
  await updateRecords(sheetId, rejectedRecords);
}

export async function handleDataSubmitError(
  error: unknown,
  {
    jobId,
    sheetId,
    records,
  }: {
    jobId: string;
    sheetId: string;
    records: Records;
  },
  onSubmitError?: SubmitErrorCallback,
  customErrorMessage?: string,
) {
  const timeoutHappened = await handleFlatfileTimeout(error, jobId);
  if (timeoutHappened) {
    return;
  }

  let errorMessage = "Couldn't save your data, please try again later.";
  if (isIndexedErrorResponse(error)) {
    try {
      await handleIndexedError(error, records, { sheetId });
      errorMessage = error.data.message;
    } catch {
      // If we fail to show indexed errors in the UI, use generic error message
    }
  } else if (isMultiErrorResponse(error)) {
    const errorsStrings = error.data.errors.map(
      // @ts-expect-error exception looking for e.detail && e.message for public API response error for log 1 upload (which should become indexed errors)
      (e) => e.detail || e.message || e,
    );
    errorMessage = `${error.data.message}: ${errorsStrings.join('; ')}`;
  } else if (isStringErrorResponse(error)) {
    errorMessage = error.data;
  } else if (error instanceof Error) {
    // Errors thrown within the frontend
    errorMessage = error.message;
  }

  await announceError(
    jobId,
    "Couldn't save your data.",
    `${customErrorMessage || ''} ${errorMessage}`,
  );

  if (onSubmitError) {
    onSubmitError(error);
  }
}
