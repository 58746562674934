/* eslint-disable */
var env = require('./environmentVars');

var stormpathConfig = {
  getApiId: function () {
    return env.get('STORMPATH_API_ID');
  },

  getApiSecret: function () {
    return env.get('STORMPATH_API_SECRET');
  },

  getAppId: function () {
    return env.get('STORMPATH_APPLICATION_ID');
  },

  getUrl: function () {
    return 'https://api.stormpath.com/v1/';
  },
};

module.exports = stormpathConfig;
