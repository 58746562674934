import {
  TasksCacheEvents,
  invalidateTasksCache,
} from '@monorepo/shared/cacheRegisters/complianceCalendar/tasksCacheRegister';
import update from 'immutability-helper';
import { handleActions } from 'redux-actions';

import {
  DELETE_TASK_FAILURE,
  DELETE_TASK_REQUEST,
  DELETE_TASK_SUCCESS,
  FETCH_TASKS_FAILURE,
  FETCH_TASKS_REQUEST,
  FETCH_TASKS_SUCCESS,
  MARK_TASK_COMPLETE_FAILURE,
  MARK_TASK_COMPLETE_REQUEST,
  MARK_TASK_COMPLETE_SUCCESS,
} from '../actions';

import { failed, fetching, notFetching } from '.'; // eslint-disable-line import/no-cycle

const INITIAL_STATE = {
  isFetching: {
    completingTask: false,
    deletingTask: false,
    projectTasks: false,
    taskWithSubtasks: false,
    updatingTask: false,
  },
  tasks: {
    currentTasks: [],
    futureTasks: [],
    completedTasks: [],
    filteredTasks: null,
  },
  taskWithSubtasks: null,
};

function updateTasks(state, taskId, newValues) {
  function updateTaskGroup(group) {
    return !group?.length
      ? group
      : group.map((task) =>
          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line prefer-object-spread
          task.id === taskId ? Object.assign({}, task, newValues) : task,
        );
  }

  return update(state, {
    tasks: (tasks) =>
      Object.keys(tasks).reduce(
        (updatedTasks, key) =>
          update(updatedTasks, {
            $merge: { [key]: updateTaskGroup(tasks[key]) },
          }),
        {},
      ),
  });
}

const reducers = handleActions(
  {
    [FETCH_TASKS_REQUEST]: (state) => fetching(state, 'projectTasks'),
    [FETCH_TASKS_SUCCESS]: (state, action) =>
      notFetching(
        update(state, {
          tasks: {
            currentTasks: { $set: action.payload.currentTasks },
            futureTasks: { $set: action.payload.futureTasks },
            completedTasks: { $set: action.payload.completedTasks },
            filteredTasks: { $set: action.payload.filteredTasks },
          },
        }),
        'projectTasks',
      ),
    [FETCH_TASKS_FAILURE]: (state, action) =>
      failed(notFetching(state, 'projectTasks'), action.payload),

    [MARK_TASK_COMPLETE_REQUEST]: (state, action) => {
      const newState = updateTasks(state, action.taskId, {
        isMarkedCompleted: true,
      });
      return fetching(newState, 'completingTask');
    },
    [MARK_TASK_COMPLETE_SUCCESS]: (state, action) => {
      invalidateTasksCache(TasksCacheEvents.DELETE, {
        projectId: action.payload.projectId,
      });

      return notFetching(state, 'completingTask');
    },
    [MARK_TASK_COMPLETE_FAILURE]: (state, action) => {
      const newState = updateTasks(state, action.taskId, {
        isMarkedCompleted: false,
      });
      return failed(
        notFetching(newState, 'completingTask'),
        null,
        'There was a problem completing the task. Please try again later.',
      );
    },

    [DELETE_TASK_REQUEST]: (state) => fetching(state, 'deletingTask'),
    [DELETE_TASK_SUCCESS]: (state) => {
      invalidateTasksCache(TasksCacheEvents.DELETE, {}); // I wish we knew the project id here
      return notFetching(state, 'deletingTask');
    },
    [DELETE_TASK_FAILURE]: (state, action) =>
      failed(notFetching(state, 'deletingTask'), action.payload),
  },
  INITIAL_STATE,
);

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default reducers;
