import MuiButton from '@material-ui/core/Button';
import styled from 'styled-components';
import { focusOutline } from '../../styles/focus';

export const BaseButton = styled(MuiButton).attrs({
  disableElevation: true,
  disableRipple: true,
})`
  height: 2.125rem;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1rem;
  text-transform: none;
  border-radius: 0.25rem;

  &:focus-visible {
    ${focusOutline()}
  }

  & svg {
    /* make the svg sized based on the font-size of its parent 
      so making a bigger / smaller button scales any icon with it */
    width: 1em;
    height: 1em;
    margin-right: 0.5rem;
  }

  & .MuiSvgIcon-root {
    /* MUI icons (anything imported from @material-ui/core/icons) have font size of 1.5rem by default, 
      but we want our svg size to be relative to the font-size of the button */
    font-size: inherit;
  }
`;
