import React from 'react';
import {
  Dialog,
  Typography,
  DialogActions,
  DialogContent,
  DialogTitle,
  Input,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Button from './buttons/Button';
import { Clear } from './MuiIcon';
import IconButton from './buttons/IconButton';

/**
 * @deprecated Use packages/shared/components/dialogs/ConfirmDialog.tsx
 */
class ConfirmDialog extends React.Component {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/state-in-constructor
  state = {
    inputValue: '',
  };

  handleConfirm(event, button) {
    const { onConfirmed, requireInputToConfirm } = this.props;
    const { inputValue } = this.state;

    if (!requireInputToConfirm || inputValue === requireInputToConfirm) {
      onConfirmed(event, button);
    }
  }

  handleChange(event) {
    this.setState({ inputValue: event.target.value || '' });
  }

  handleCancel(event) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/destructuring-assignment
    this.setState({ inputValue: '' }, () => this.props.onCancelled(event));
  }

  description() {
    const { description } = this.props;

    return description ? (
      <Typography paragraph>{description}</Typography>
    ) : null;
  }

  confirmationInput() {
    const { requireInputToConfirm } = this.props;
    const { inputValue } = this.state;

    return requireInputToConfirm ? (
      <Input
        type="text"
        placeholder={`Type ${requireInputToConfirm} to confirm`}
        value={inputValue}
        onChange={(e) => this.handleChange(e)}
      />
    ) : null;
  }

  cancelButton() {
    const { cancelButtonText, hideCancelButton } = this.props;

    return (
      !hideCancelButton && (
        <Button onClick={(e) => this.handleCancel(e)} color="secondary">
          {cancelButtonText || 'Cancel'}
        </Button>
      )
    );
  }

  confirmButton() {
    const { requireInputToConfirm, danger, confirmButtonText, confirmButtons } =
      this.props;
    const { inputValue } = this.state;

    const isDisabled =
      requireInputToConfirm && inputValue !== requireInputToConfirm;

    return confirmButtons && Array.isArray(confirmButtons) ? (
      confirmButtons.map((button) => {
        const buttonDanger =
          typeof button.danger === 'boolean' ? button.danger : danger;
        return (
          <Button
            key={button.id}
            color={buttonDanger ? 'danger' : 'primary'}
            onClick={(e) => this.handleConfirm(e, button)}
            disabled={isDisabled}
          >
            {button.text}
          </Button>
        );
      })
    ) : (
      <Button
        color={danger ? 'danger' : 'primary'}
        onClick={(e) => this.handleConfirm(e)}
        disabled={isDisabled}
      >
        {confirmButtonText || 'OK'}
      </Button>
    );
  }

  closeButton() {
    const { showCloseButton } = this.props;

    return (
      showCloseButton && (
        <IconButton
          className="close-icon"
          onClick={(e) => this.handleCancel(e)}
        >
          <Clear />
        </IconButton>
      )
    );
  }

  render() {
    const { open, onCancelled, title, showCloseButton } = this.props;
    // Wrapping the <Dialog> component in a div that prevents the click event
    // from bubbling up prevents clicks on the dialog from going through the
    // dialog into the elements below it. This happens if the dialog is fired
    // from another dialog below it.

    const description = this.description();
    const confirmationInput = this.confirmationInput();
    const hasContent = !!description || !!confirmationInput;

    return (
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
      <div onClick={(e) => e.stopPropagation()}>
        <Dialog
          className="confirm-dialog"
          disableBackdropClick
          open={open}
          onClose={onCancelled}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle
            id="form-dialog-title"
            className={cn({ 'with-close-button': showCloseButton })}
          >
            {title}
          </DialogTitle>
          {this.closeButton()}
          {hasContent && (
            <DialogContent>
              {description}
              {confirmationInput}
            </DialogContent>
          )}
          <DialogActions
            classes={{
              spacing: 'confirm-dialog__actions',
            }}
          >
            {this.cancelButton()}
            {this.confirmButton()}
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

ConfirmDialog.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  confirmButtonText: PropTypes.string,
  confirmButtons: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      danger: PropTypes.bool,
    }),
  ),
  cancelButtonText: PropTypes.string,
  hideCancelButton: PropTypes.bool,
  showCloseButton: PropTypes.bool,
  danger: PropTypes.bool,
  requireInputToConfirm: PropTypes.string,
  onConfirmed: PropTypes.func.isRequired,
  onCancelled: PropTypes.func.isRequired,
};

ConfirmDialog.defaultProps = {
  open: false,
  description: null,
  confirmButtonText: null,
  confirmButtons: null,
  cancelButtonText: null,
  requireInputToConfirm: null,
  danger: false,
  hideCancelButton: false,
  showCloseButton: false,
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default ConfirmDialog;
