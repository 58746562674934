import qs from 'qs';
import http from './httpClient';

export type FetchAnalyticsEmbedUrlResponse = { url: string };

export async function fetchSigmaEmbedUrl(
  dashboardId?: string,
): Promise<FetchAnalyticsEmbedUrlResponse> {
  const res = await http.get(
    `/api/v2/analytics/embed?${qs.stringify({ dashboardId })}`,
  );
  return res.data;
}

export async function updateLastViewedDashboard(
  dashboardId: string,
): Promise<void> {
  await http.put(`/api/v2/analytics/dashboards/last`, { dashboardId });
}
