import { FlatfileRecord } from '@flatfile/plugin-record-hook';
import { format } from 'date-fns';
import { isValidEmailAddress } from 'mapistry-shared';
import { isNullOrUndefined } from '../../../../../old-web/js/utils';
import { RecordValue } from '../../types/flatfileTypes';
import {
  dateFormatExamples,
  dateTimeFormatExamples,
  timeFormatExamples,
} from '../columnSettingHelpers';
import { setWithMessage } from '../flatfileRecordHelpers';
import {
  parseDateString,
  parseDateTimeString,
  parseTimeFromDateString,
  parseTimeString,
} from './dateParsingHelpers';
import {
  ValidDateString,
  ValidTimeFormat,
  determineTimeFormat,
  isEmpty,
  isValidDate,
  isValidTime,
} from './valueValidationHelpers';

/**
 * This only catches validation that got added via record hooks, but not field level constraints.
 * E.g., if there is also a `required` constraint on the field, you have to manually check first that the field has a value.
 *
 * This is also sketchily done.
 * It's unreasonably hard just to know whether a field on a record is valid, so I'm looking at the non-public `_info`.
 * We could start adding our own metadata on a field whenever we validate it, but that is also error prone.
 */
export function didValidationSucceed(fieldKey: string, record: FlatfileRecord) {
  type RecordInfo = { field: string; level: string };
  // @ts-expect-error record._info is a private property, which we're "hacking" into to get field status
  // eslint-disable-next-line no-underscore-dangle
  const sketchilyGottenLogInfo: RecordInfo[] = record._info || [];
  const infoAboutField = sketchilyGottenLogInfo.filter(
    (recordInfo) => recordInfo.field === fieldKey,
  );
  if (infoAboutField.some((i) => i.level === 'error')) {
    return false;
  }
  return true;
}

export function validateThatPrerequisiteFieldNotEmpty(
  fieldKey: string,
  prerequisiteFieldKey: string,
  record: FlatfileRecord,
  errorMessage: string,
): void {
  record.validate(
    fieldKey,
    (value) => {
      if (!value) {
        return true;
      }
      const prerequisiteValue = record.get(prerequisiteFieldKey);
      return !!prerequisiteValue;
    },
    errorMessage,
  );
}

export function validatePositiveNumber(
  numberFieldKey: string,
  record: FlatfileRecord,
): void {
  const value = record.get(numberFieldKey);
  if (isNullOrUndefined(value)) {
    return;
  }

  const parsedNumber = Number(value);
  const isNumber = !Number.isNaN(parsedNumber);
  const isPositiveNumber = isNumber && parsedNumber >= 0;
  if (!isPositiveNumber) {
    record.addError(numberFieldKey, 'Needs to be a positive number.');
  }
}

interface dayValidationOptions {
  canBeInTheFuture?: boolean;
  withTime?: boolean;
}

export function validateDayField(
  dayFieldKey: string,
  record: FlatfileRecord,
  options: dayValidationOptions = {},
): void {
  const value = record.get(dayFieldKey);

  // This is a generic validator and it doesn't check whether a field is required
  if (value == null || value === '') {
    return;
  }

  if (!isValidDate(value)) {
    const formatExamples = options.withTime
      ? dateTimeFormatExamples
      : dateFormatExamples;
    record.addError(
      dayFieldKey,
      `Needs to be a valid date, e.g. in format ${formatExamples}.`,
    );
    return;
  }

  const logDate = options.withTime
    ? parseDateTimeString(value)
    : parseDateString(value);
  const dateFormat = options.withTime ? 'MM/dd/yyyy HH:mm' : 'MM/dd/yyyy';
  const formattedDateString = format(logDate, dateFormat);

  if (!options.canBeInTheFuture) {
    if (logDate > new Date()) {
      record.addError(
        dayFieldKey,
        'Cannot submit a date that is in the future.',
      );
    }
  }

  record.set(dayFieldKey, formattedDateString);
}

export function validateTimeField(
  timeFieldKey: string,
  record: FlatfileRecord,
): void {
  const value = record.get(timeFieldKey);

  // This is a generic validator and it doesn't check whether a field is required
  if (value == null || value === '') {
    return;
  }

  if (!isValidTime(value)) {
    record.addError(
      timeFieldKey,
      `Needs to be a valid time, e.g. in format ${timeFormatExamples}`,
    );
    return;
  }

  const timeFormat = determineTimeFormat(value) as ValidTimeFormat;
  record.set(timeFieldKey, parseTimeString(value, timeFormat));
}

export function validateDateTimeFields(
  dateFieldKey: string,
  timeFieldKey: string,
  record: FlatfileRecord,
  options = { canBeInTheFuture: false },
): void {
  const [dateValue, timeValue] = [
    record.get(dateFieldKey),
    record.get(timeFieldKey),
  ];
  const hasTimeValue = !isEmpty(timeValue);
  const hasDateValue = !isEmpty(dateValue);

  if (hasTimeValue) {
    validateTimeField(timeFieldKey, record);
  }

  if (hasDateValue) {
    validateDayField(dateFieldKey, record, options);

    if (!hasTimeValue) {
      const timeFromDateString = parseTimeFromDateString(
        dateValue as ValidDateString,
      );
      setWithMessage(
        timeFieldKey,
        record,
        timeFromDateString,
        'The time value was taken from your date column.',
      );
    }
  }
}

export function validateDateFieldsOrder(
  fieldToShowErrorOnKey: string,
  record: FlatfileRecord,
  dates: { before: RecordValue; after: RecordValue },
  errorMessage?: string,
): void {
  const { before, after } = dates;
  if (!before || !after || !isValidDate(before) || !isValidDate(after)) {
    return;
  }

  if (new Date(before) > new Date(after)) {
    record.addError(
      fieldToShowErrorOnKey,
      errorMessage || `${before} must be before ${after} date.`,
    );
  }
}

export function validateValueOrNull(
  fieldKey: string,
  record: FlatfileRecord,
): void {
  const value = record.get(fieldKey);

  if (value === '') {
    // DB date, number, json columns don't accept empty string, "null" is not valid Flatfile value but
    // it will assign null to the field anyway.
    // Flatfile considers "undefined" valid value, but still can't pass it,
    // it results in Flatfile ignoring it and keeping empty string.
    record.set(fieldKey, null);
  }
}

export function validateEmailAddress(
  emailFieldKey: string,
  record: FlatfileRecord,
): void {
  const value = record.get(emailFieldKey);

  // This is a generic validator and it doesn't check whether a field is required
  if (value == null || value === '') {
    return;
  }

  if (typeof value !== 'string') {
    record.addError(emailFieldKey, 'Invalid Email.');
    return;
  }

  const parsedEmail = value.toLowerCase().trim();
  if (!isValidEmailAddress(parsedEmail)) {
    record.addError(emailFieldKey, 'Invalid Email.');
    return;
  }

  record.set(emailFieldKey, parsedEmail);
}
