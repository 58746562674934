import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';

export enum AnimationType {
  fadeInOut = 'fade-in-out',
  slideLeftRight = 'slide-left-right',
  slideLeftRightPresence = 'slide-left-right-presence',
  slideRightLeftPresence = 'slide-right-left-presence',
  slideRightLeftSmoothPresence = 'slide_right_left_smooth_presence',
  slideDownPresence = 'slide-down-presence',
}

interface AnimatedComponentProps {
  children: React.ReactNode;
  className?: string;
  initial?: boolean;
  isVisible?: boolean;
  type: AnimationType;
}

interface WithKey {
  key: React.Key;
}

const hasKey = (item: React.ReactNode): item is WithKey =>
  typeof item === 'object' &&
  typeof item !== 'function' &&
  item != null &&
  'key' in item;
const getKey = (item: React.ReactNode) =>
  hasKey(item) ? item.key : item?.toString();

/**
 * HOC for building animated components
 */
export const withAnimation = (Component: React.ReactNode) => (
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  <AnimatedComponent initial isVisible type={AnimationType.fadeInOut}>
    {Component}
  </AnimatedComponent>
);

export const AnimatedComponent = (props: AnimatedComponentProps) => {
  const { children, className, initial = true, isVisible = true, type } = props;
  const childrenList = Array.isArray(children) ? children : [children];

  switch (type) {
    case AnimationType.fadeInOut:
      return (
        <AnimatePresence initial={initial}>
          {isVisible &&
            childrenList.map(
              (child) =>
                child && (
                  <motion.div
                    key={getKey(child)}
                    className="animated__wrapper w-100 h-100"
                    animate={{ opacity: 1 }}
                    initial={{ opacity: 0 }}
                    exit={{ opacity: 0 }}
                  >
                    {child}
                  </motion.div>
                ),
            )}
        </AnimatePresence>
      );
    case AnimationType.slideLeftRight:
      return (
        <motion.div
          animate={{ width: '100%' }}
          initial={{ width: '0%' }}
          className="animated__wrapper h-100"
          transition={{ type: 'spring' }}
        >
          {children}
        </motion.div>
      );
    case AnimationType.slideLeftRightPresence:
      return (
        <AnimatePresence initial={initial}>
          {childrenList.map((child) => (
            <motion.div
              key={getKey(child)}
              animate={{ transform: 'translateX(0%)' }}
              initial={{ transform: 'translateX(-100%)' }}
              exit={{ transform: 'translateX(-100%)' }}
              className={`animated__wrapper h-100 ${className || ''}`}
              transition={{ type: 'spring' }}
            >
              {child}
            </motion.div>
          ))}
        </AnimatePresence>
      );
    case AnimationType.slideRightLeftPresence:
      return (
        <AnimatePresence initial={initial}>
          {childrenList.map((child) => (
            <motion.div
              key={getKey(child)}
              animate={{ transform: 'translateX(0%)' }}
              initial={{ transform: 'translateX(100%)' }}
              exit={{ transform: 'translateX(100%)' }}
              className={`animated__wrapper h-100 ${className || ''}`}
              transition={{ type: 'spring' }}
            >
              {child}
            </motion.div>
          ))}
        </AnimatePresence>
      );
    case AnimationType.slideRightLeftSmoothPresence:
      return (
        <AnimatePresence initial={initial}>
          {isVisible &&
            childrenList.map((child) => (
              <motion.div
                key={getKey(child)}
                animate={{ transform: 'translateX(0%)' }}
                initial={{ transform: 'translateX(100%)' }}
                exit={{ transform: 'translateX(100%)' }}
                className={`animated__wrapper h-100 ${className || ''}`}
                transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
              >
                {child}
              </motion.div>
            ))}
        </AnimatePresence>
      );
    case AnimationType.slideDownPresence:
      return (
        <AnimatePresence initial={initial}>
          {isVisible &&
            childrenList.map((child) => (
              <motion.div
                key={getKey(child)}
                animate={{ opacity: 1, height: 'auto' }}
                initial={{ opacity: 0.3, height: 0 }}
                exit={{ opacity: 0.3, height: 0 }}
                className={`animated__wrapper animated__wrapper__slide-down ${
                  className || ''
                }`}
                transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
              >
                {child}
              </motion.div>
            ))}
        </AnimatePresence>
      );
    default:
      throw new Error(
        'You used AnimatedComponent without passing in a valid AnimatedComponent.type prop.',
      );
  }
};
