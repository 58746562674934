/* eslint-disable */
// when editing this file please uncomment the above and fix the linting errors
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';

import { ExpandMore as ExpandMoreIcon } from './MuiIcon';

export default class ExpandablePanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: props.expanded,
    };
  }

  render() {
    const successOrErrorClass = (() => {
      if (this.props.shouldShowSuccessClass) {
        return 'animate__success';
      }
      if (this.props.shouldShowErrorClass) {
        return 'animate__error';
      }
    })();

    const classes = {
      root: this.props.classes.root || 'mapistry-accordion-root',
      title: this.props.classes.title || 'mapistry-accordion-panel-title',
      details: this.props.classes.details || 'mapistry-accordion-panel-details',
    };
    return (
      <Accordion
        classes={{ root: classes.root }}
        expanded={this.state.expanded}
        onChange={() =>
          this.setState((prevState) => ({ expanded: !prevState.expanded }))
        }
      >
        <AccordionSummary
          classes={{
            root: `${classes.title} ${successOrErrorClass || ''}`,
          }}
          expandIcon={<ExpandMoreIcon />}
        >
          {this.props.title}
        </AccordionSummary>
        <AccordionDetails classes={{ root: classes.details }}>
          {this.state.expanded && this.props.body}
        </AccordionDetails>
      </Accordion>
    );
  }
}

ExpandablePanel.propTypes = {
  body: PropTypes.element.isRequired,
  classes: PropTypes.shape({
    details: PropTypes.string,
    root: PropTypes.string,
    title: PropTypes.string,
  }),
  shouldShowErrorClass: PropTypes.bool,
  shouldShowSuccessClass: PropTypes.bool,
  title: PropTypes.element.isRequired,
};

ExpandablePanel.defaultProps = {
  classes: {},
  expanded: false,
  shouldShowErrorClass: false,
  shouldShowSuccessClass: false,
};
