import { FieldsOrderResponse, UpdateFieldsOrderRequest } from 'mapistry-shared';
import http from '../httpClient';

export type UpdateLogFieldOrderParams = {
  logFieldOrder: UpdateFieldsOrderRequest;
  logId: string;
  organizationId: string;
};

export type UpdateLogFieldsOrderResponse = FieldsOrderResponse;

export async function updateLogFieldOrder({
  logFieldOrder,
  logId,
  organizationId,
}: UpdateLogFieldOrderParams) {
  const res = await http.put<UpdateLogFieldsOrderResponse>(
    `/api/v2/organizations/${organizationId}/logs/${logId}/field-order`,
    logFieldOrder,
  );
  return res.data;
}
