import { LimitItem } from 'mapistry-shared';
import http from '../httpClient';

export interface FetchLimitItemsProps {
  logProjectId?: string;
  projectId?: string;
}

interface FetchLimitItemsResponse {
  logProjectId: string;
  records: LimitItem[];
}

export async function fetchLimitItems({
  logProjectId,
  projectId,
}: FetchLimitItemsProps): Promise<LimitItem[]> {
  const res = await http.get<FetchLimitItemsResponse>(
    `/api/v2/${projectId}/generic-logs/${logProjectId}/limit-items`,
  );
  return res.data?.records;
}

export type UpdateLimitItemResponse = LimitItem;

export async function updateLimitItem(limitItem: LimitItem) {
  const res = await http.put<UpdateLimitItemResponse>(
    `/api/v2/${limitItem.projectId}/generic-logs/${limitItem.logProjectId}/limit-items/${limitItem.id}`,
    limitItem,
  );
  return res.data;
}
