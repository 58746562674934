import { GenericLogType, LogStatistic } from 'mapistry-shared';
import http from '../httpClient';

interface ResourceKey {
  resourceId: string;
  resourceType: Extract<
    GenericLogType,
    | GenericLogType.CALCULATED_VALUE
    | GenericLogType.LOGGED_ITEM
    | GenericLogType.ROLLING_CALCULATION
  >;
}

export interface FetchStatisticsProps {
  logProjectId?: string;
  projectId?: string;
  resources?: ResourceKey[];
}

interface FetchStatisticsResponse {
  logProjectId: string;
  records: LogStatistic[];
}

export async function fetchStatistics({
  logProjectId,
  projectId,
  resources,
}: FetchStatisticsProps): Promise<LogStatistic[]> {
  const res = await http.post<FetchStatisticsResponse>(
    `/api/v2/${projectId}/generic-logs/${logProjectId}/logs/statistics`,
    resources,
  );
  return res.data?.records;
}
