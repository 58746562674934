import { useQuery } from 'react-query';
import { Api } from '../../apiClient';

function createRecoveryQuestionSetKey() {
  return ['recoverQuestionSet'] as const;
}

type RecoveryQuestionSetFetcher = Api.DataHookQueryFn<
  typeof createRecoveryQuestionSetKey,
  typeof Api.fetchIsRecoveryQuestionSet
>;

const fetcher: RecoveryQuestionSetFetcher = () =>
  Api.fetchIsRecoveryQuestionSet();

export function useIsRecoveryQuestionSet() {
  const key = createRecoveryQuestionSetKey();
  const config = {
    cacheTime: Infinity,
    staleTime: Infinity,
  };

  const { data, ...queryInfo } = useQuery(key, fetcher, config);

  return {
    ...queryInfo,
    ...data,
  };
}
