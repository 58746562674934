import React from 'react';
import PropTypes from 'prop-types';

const UserName = (props) => {
  const { className, name } = props;
  const namePartials = name.split(' ');
  let initials = namePartials[0].substring(0, 1);
  if (namePartials.length > 1) {
    initials += namePartials[namePartials.length - 1].substring(0, 1);
  }

  const containerClass = className ? ` ${className}` : '';

  return (
    <div className={`user-name${containerClass}`}>
      <div className="user-name__icon">{initials}</div>
      <div>{name}</div>
    </div>
  );
};

UserName.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
};

UserName.defaultProps = {
  className: null,
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default UserName;
