import { Records } from '@flatfile/api/api';
import { StoreFlatfileUploadResultsResponse } from 'mapistry-shared';
import { FlatfileLoggingContext } from '../components/InteractiveFileUpload/types/flatfileTypes';
import http from './httpClient';

type FlatfileLogInfo = {
  loggingContext: FlatfileLoggingContext;
  sheetInfo: {
    id: string;
    workbookId: string;
    name: string;
    createdAt: Date;
    updatedAt: Date;
    config: { slug: string | undefined; name: string };
  };
  records: Records;
};

export async function storeFlatfileUploadResults(logInfo: FlatfileLogInfo) {
  const res = await http.post<StoreFlatfileUploadResultsResponse>(
    `/api/v2/flatfile/store-upload-results`,
    logInfo,
  );
  return res.data;
}
