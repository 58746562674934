import { handleActions } from 'redux-actions';
import update from 'immutability-helper';
import {
  FETCH_PROJECT_FAILURE,
  FETCH_PROJECT_REQUEST,
  FETCH_PROJECT_SUCCESS,
  FETCH_PROJECT_USERS_FAILURE,
  FETCH_PROJECT_USERS_REQUEST,
  FETCH_PROJECT_USERS_SUCCESS,
} from '../actions';
// eslint-disable-next-line import/no-cycle
import { failed, fetching, notFetching } from '.';

const reducers = handleActions(
  {
    [FETCH_PROJECT_REQUEST]: (state) => fetching(state),
    [FETCH_PROJECT_SUCCESS]: (state, action) =>
      notFetching(
        update(state, {
          id: { $set: action.payload.id },
          organizationId: { $set: action.payload.organizationId },
          state: { $set: action.payload.state },
        }),
      ),
    [FETCH_PROJECT_FAILURE]: (state, action) =>
      failed(
        notFetching(state, { errorMessage: action.payload }),
        action.payload,
      ),
    [FETCH_PROJECT_USERS_REQUEST]: (state) => fetching(state),
    [FETCH_PROJECT_USERS_SUCCESS]: (state, action) =>
      notFetching(update(state, { users: { $set: action.payload } })),
    [FETCH_PROJECT_USERS_FAILURE]: (state, action) =>
      failed(
        notFetching(state, { errorMessage: action.payload }),
        action.payload,
      ),
  },
  {
    isFetching: false,
    errorMessage: null,
    id: null,
    organizationId: null,
    state: null,
    users: [],
  },
);

export const selectProject = (state) => state.project;

export const selectIsFetching = (state) => state.project.isFetching;

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default reducers;
