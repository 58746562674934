import { KeyboardTimePicker } from '@material-ui/pickers';
import PropTypes from 'prop-types';
import React from 'react';
import { AccessTime } from './MuiIcon';

class TimePicker extends React.Component {
  handleChange(date, textValue) {
    const { onChange } = this.props;

    onChange(date, textValue);
  }

  render() {
    const { ampm, autoOk, className, clearable, disabled, error, value } =
      this.props;

    const propClass = className ? ` ${className}` : '';

    return (
      <KeyboardTimePicker
        ampm={ampm}
        autoOk={autoOk}
        className={`mapistry-time-picker${propClass}`}
        clearable={clearable}
        disabled={disabled}
        {...(error != null ? { error } : {})}
        format="HHmm"
        InputAdornmentProps={{ position: 'end' }}
        keyboardIcon={<AccessTime />}
        onChange={(date, textValue) => this.handleChange(date, textValue)}
        value={value}
      />
    );
  }
}

TimePicker.propTypes = {
  ampm: PropTypes.bool,
  autoOk: PropTypes.bool,
  className: PropTypes.string,
  clearable: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
};

TimePicker.defaultProps = {
  ampm: false,
  autoOk: false,
  className: null,
  clearable: false,
  disabled: false,
  error: false,
  onChange: null,
  value: null,
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default TimePicker;
