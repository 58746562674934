/* eslint-disable */
// when editing this file please uncomment the above and fix the linting errors
import { handleActions } from 'redux-actions';
import update from 'immutability-helper';

import {
  FETCH_CURRENT_USER_SAVED_SETTING_REQUEST,
  FETCH_CURRENT_USER_SAVED_SETTING_SUCCESS,
  FETCH_CURRENT_USER_SAVED_SETTING_FAILURE,
  SET_CURRENT_USER_SAVED_SETTING_REQUEST,
  SET_CURRENT_USER_SAVED_SETTING_SUCCESS,
  SET_CURRENT_USER_SAVED_SETTING_FAILURE,
  FETCH_CURRENT_USER_WIDGET_SETTING_REQUEST,
  FETCH_CURRENT_USER_WIDGET_SETTING_SUCCESS,
  FETCH_CURRENT_USER_WIDGET_SETTING_FAILURE,
  SET_CURRENT_USER_WIDGET_SETTING_REQUEST,
  SET_CURRENT_USER_WIDGET_SETTING_SUCCESS,
  SET_CURRENT_USER_WIDGET_SETTING_FAILURE,
  SET_CURRENT_USER_SPECIFIC_WIDGET_SETTING_REQUEST,
  SET_CURRENT_USER_SPECIFIC_WIDGET_SETTING_SUCCESS,
  SET_CURRENT_USER_SPECIFIC_WIDGET_SETTING_FAILURE,
  DELETE_CURRENT_USER_WIDGET_SETTING_REQUEST,
  DELETE_CURRENT_USER_WIDGET_SETTING_SUCCESS,
  DELETE_CURRENT_USER_WIDGET_SETTING_FAILURE,
} from '../actions';

import { failed, fetching, notFetching } from '.';

const reducers = handleActions(
  {
    [FETCH_CURRENT_USER_SAVED_SETTING_REQUEST]: (state) =>
      fetching(state, 'userSettings'),
    [FETCH_CURRENT_USER_SAVED_SETTING_SUCCESS]: (state, action) =>
      notFetching(
        update(state, {
          userSettings: {
            [action.payload.settingKey]: {
              $set: action.payload.settingValue,
            },
          },
        }),
        'userSettings',
      ),
    [FETCH_CURRENT_USER_SAVED_SETTING_FAILURE]: (state, action) =>
      failed(notFetching(state, 'userSettings'), action.payload),
    [SET_CURRENT_USER_SAVED_SETTING_REQUEST]: (state) =>
      fetching(state, 'userSettings'),
    [SET_CURRENT_USER_SAVED_SETTING_SUCCESS]: (state, action) =>
      notFetching(
        update(state, {
          userSettings: {
            [action.payload.settingKey]: {
              $set: action.payload.settingValue,
            },
          },
        }),
        'userSettings',
      ),
    [SET_CURRENT_USER_SAVED_SETTING_FAILURE]: (state, action) =>
      failed(notFetching(state, 'userSettings'), action.payload),
    [FETCH_CURRENT_USER_WIDGET_SETTING_REQUEST]: (state, action) =>
      fetching(state, `userSettings-${action.settingKey}-${action.widgetType}`),
    [FETCH_CURRENT_USER_WIDGET_SETTING_SUCCESS]: (state, action) => {
      const { projectId, settingKey, settingValue } = action.payload;
      if (!projectId || !settingKey) {
        return notFetching(
          state,
          `userSettings-${action.settingKey}-${action.widgetType}`,
        );
      }
      return notFetching(
        update(state, {
          userSettings: (userSettings) =>
            update(userSettings || {}, {
              [projectId]: (projectSettings) =>
                update(projectSettings || {}, {
                  [settingKey]: (settings) =>
                    update(settings || {}, { $merge: settingValue }),
                }),
            }),
        }),
        `userSettings-${action.settingKey}-${action.widgetType}`,
      );
    },
    [FETCH_CURRENT_USER_WIDGET_SETTING_FAILURE]: (state, action) =>
      failed(
        notFetching(
          state,
          `userSettings-${action.settingKey}-${action.widgetType}`,
        ),
        action.payload,
      ),
    [SET_CURRENT_USER_WIDGET_SETTING_REQUEST]: (state) =>
      fetching(state, 'userSettings'),
    [SET_CURRENT_USER_WIDGET_SETTING_SUCCESS]: (state, action) => {
      const { projectId, settingKey, settingValue } = action.payload;
      if (!projectId || !settingKey) {
        return notFetching(state);
      }
      return notFetching(
        update(state, {
          userSettings: (userSettings) =>
            update(userSettings || {}, {
              [projectId]: (projectSettings) =>
                update(projectSettings || {}, {
                  [settingKey]: (settings) =>
                    update(settings || {}, {
                      $merge: settingValue,
                    }),
                }),
            }),
        }),
        'userSettings',
      );
    },
    [SET_CURRENT_USER_WIDGET_SETTING_FAILURE]: (state, action) =>
      failed(notFetching(state, 'userSettings'), action.payload),
    [SET_CURRENT_USER_SPECIFIC_WIDGET_SETTING_REQUEST]: (state) =>
      fetching(state, 'userSettings'),
    [SET_CURRENT_USER_SPECIFIC_WIDGET_SETTING_SUCCESS]: (state, action) => {
      const { projectId, settingKey, type, widgetSettingsId } = action.meta.req;
      const widgetSettingsValue =
        action.payload.settingValue[type][widgetSettingsId];

      return notFetching(
        update(state, {
          userSettings: (userSettings) =>
            update(userSettings || {}, {
              [projectId]: (projectSettings) =>
                update(projectSettings || {}, {
                  [settingKey]: (settings) =>
                    update(settings || {}, {
                      [type]: (widgetTypeSettings) =>
                        update(widgetTypeSettings || {}, {
                          $merge: {
                            [widgetSettingsId]: widgetSettingsValue,
                          },
                        }),
                    }),
                }),
            }),
        }),
        'userSettings',
      );
    },
    [SET_CURRENT_USER_SPECIFIC_WIDGET_SETTING_FAILURE]: (state, action) =>
      failed(notFetching(state, 'userSettings'), action.payload),
    [DELETE_CURRENT_USER_WIDGET_SETTING_REQUEST]: (state) =>
      fetching(state, 'userSettings'),
    [DELETE_CURRENT_USER_WIDGET_SETTING_SUCCESS]: (state, action) => {
      const { projectId, settingKey, type, widgetSettingsId } = action.meta.req;
      if (!projectId || !settingKey) {
        return notFetching(state);
      }
      return notFetching(
        update(state, {
          userSettings: {
            [projectId]: {
              [settingKey]: {
                [type]: {
                  $unset: [widgetSettingsId],
                },
              },
            },
          },
        }),
        'userSettings',
      );
    },
    [DELETE_CURRENT_USER_WIDGET_SETTING_FAILURE]: (state, action) =>
      failed(notFetching(state, 'userSettings'), action.payload),
  },
  {
    userSettings: {},
  },
);

export default reducers;
