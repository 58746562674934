import { Api } from '@monorepo/shared/apiClient';
import { useQuery } from 'react-query';

/**
 * @deprecated Use packages/genericLogs/src/hooks
 */
/* @ts-expect-error - TODO: Fix this the next time the file is edited. */
export const logProjectQueryKey = (logProjectId) =>
  `LOG_PROJECT_${logProjectId}`;

/**
 * @deprecated Use packages/genericLogs/src/hooks
 */
function useLogProject(logProjectId: string) {
  const config = {
    cacheTime: Infinity,
    staleTime: Infinity,
  };
  const fetcher = () => Api.fetchLogProject({ id: logProjectId });
  const queryInfo = useQuery(logProjectQueryKey(logProjectId), fetcher, config);
  return {
    logProject: queryInfo.data,
    ...queryInfo,
  };
}

export { useLogProject };
