import { Box, TextField, TextFieldProps } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { ClassNameMap } from '@material-ui/styles';
import cn from 'classnames';
import React from 'react';
import { VisuallyHidden } from '../../componentsV2/VisuallyHidden';

export interface SearchBarProps {
  placeholder?: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: ClassNameMap;
  textFieldProps?: TextFieldProps;
}

const SearchBar: React.FC<SearchBarProps> = (props) => {
  const {
    textFieldProps,
    value,
    placeholder = 'Search',
    onChange,
    className,
  } = props;
  const id = `searchBar-${placeholder}`;

  return (
    <Box className={cn('search-bar', className)}>
      <VisuallyHidden>
        <label htmlFor={id}>{placeholder}</label>
      </VisuallyHidden>
      <TextField
        id={id}
        className="text-field"
        variant="outlined"
        type="search"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        InputProps={{
          startAdornment: <SearchIcon className="search-icon" />,
          ...textFieldProps?.InputProps,
        }}
        {...textFieldProps}
      />
    </Box>
  );
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default SearchBar;
