/* eslint-disable */
// when editing this file please uncomment the above and fix the linting errors
import { Tooltip } from '@monorepo/shared/components/Tooltip';
import ArrowLeft from '@svg/m-arrow-left.svg';
import ArrowRight from '@svg/m-arrow-right.svg';
import RotateLeft from '@svg/m-rotate-left.svg';
import RotateRight from '@svg/m-rotate-right.svg';
import ZoomInIcon from '@svg/m-zoom-in.svg';
import ZoomOutIcon from '@svg/m-zoom-out.svg';
import PropTypes from 'prop-types';
import React from 'react';
import Button from './buttons/Button';
import FieldWrapper from './FieldWrapper';
import MapistryTooltip from './MapistryTooltip';
import Modal from './Modal';
import Select from './Select';
import TextField from './TextField';

class PhotoEditor extends React.Component {
  state = {
    fullSizeZoomValue: 0,
    showImage: true,
    selectedPhoto: null,
    selectedPhotoRotation: 0,
    selectedPhotoScale: 1,
  };

  FULL_SIZE_ZOOM = 'Fit to screen';

  imageRef = React.createRef();

  imageContainerRef = React.createRef();

  defaultZoomOptions = [
    { value: 3, label: '300%' },
    { value: 2, label: '200%' },
    { value: 1.5, label: '150%' },
    { value: 1, label: '100%' },
    { value: 0.75, label: '75%' },
    { value: 0.5, label: '50%' },
    { value: 0.25, label: '25%' },
  ];

  scaleStep = 0.1;

  maxScale = 3;

  isInverted = false;

  cachedEdits = [];

  componentDidUpdate(prevProps) {
    const { open } = this.props;

    if (prevProps.open && !open) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        selectedPhoto: null,
        selectedPhotoRotation: 0,
        selectedPhotoScale: 1,
        showImage: false,
      });
    }
  }

  getNextPhoto(goForward) {
    const { photos } = this.props;

    const nextIndex = this.getNextIndex(goForward);
    const nextPhoto = photos[nextIndex];
    const editedVersion = this.cachedEdits.find(
      (p) => p.fileId === nextPhoto.fileId,
    );
    return editedVersion || nextPhoto;
  }

  getNextIndex(goForward) {
    const { photos } = this.props;

    const selectedPhoto = this.selectedPhoto();
    const currentIndex = photos.findIndex(
      (p) => p.fileId === selectedPhoto.fileId,
    );

    if (goForward) {
      return currentIndex + 1 < photos.length ? currentIndex + 1 : 0;
    }

    return currentIndex - 1 >= 0 ? currentIndex - 1 : photos.length - 1;
  }

  getZoomOptions() {
    const { fullSizeZoomValue, selectedPhotoScale } = this.state;

    const fullSizeOption = {
      label: this.FULL_SIZE_ZOOM,
      value: fullSizeZoomValue,
    };
    const zoomOptions = [fullSizeOption, ...this.defaultZoomOptions];
    const selectedOption = zoomOptions.find(
      (o) => o.value === selectedPhotoScale,
    );

    if (!selectedOption) {
      const text = Math.round(selectedPhotoScale * 100 * 10) / 10;
      zoomOptions.unshift({
        value: selectedPhotoScale,
        label: `${text}%`,
      });
    }

    return zoomOptions;
  }

  handleImageLoaded() {
    this.isInverted = false;
    this.setState({
      fullSizeZoomValue: this.fullSizeScale(),
      showImage: true,
    });
  }

  handleZoomSelect(selection) {
    this.handleZoom(selection.value);
  }

  handleZoomIn() {
    const { selectedPhotoScale } = this.state;

    if (selectedPhotoScale < this.maxScale) {
      const scaleValue =
        Math.round((selectedPhotoScale + this.scaleStep) * 10) / 10;
      this.handleZoom(scaleValue);
    }
  }

  handleZoomOut() {
    const { selectedPhotoScale } = this.state;

    const scaleValue =
      Math.round((selectedPhotoScale - this.scaleStep) * 10) / 10;
    if (scaleValue >= this.scaleStep) {
      this.handleZoom(scaleValue);
    }
  }

  handleZoom(scale) {
    this.setState({ selectedPhotoScale: scale, showImage: true });
  }

  handleRotateLeft() {
    const { selectedPhotoRotation } = this.state;

    this.isInverted = !this.isInverted;
    this.setState({ fullSizeZoomValue: this.fullSizeScale() });
    this.handleRotate(selectedPhotoRotation - 90);
  }

  handleRotateRight() {
    const { selectedPhotoRotation } = this.state;

    this.isInverted = !this.isInverted;
    this.setState({ fullSizeZoomValue: this.fullSizeScale() });
    this.handleRotate(selectedPhotoRotation + 90);
  }

  handleRotate(rotation) {
    this.setState({ selectedPhotoRotation: rotation, showImage: true });
  }

  handleChangeName(event) {
    const selectedPhoto = this.selectedPhoto();
    const updatedPhoto = { ...selectedPhoto, name: event.target.value };
    this.setState({ selectedPhoto: updatedPhoto });
  }

  handleChangeDescription(event) {
    const selectedPhoto = this.selectedPhoto();
    const updatedPhoto = { ...selectedPhoto, description: event.target.value };
    this.setState({ selectedPhoto: updatedPhoto });
  }

  handleNextPhoto() {
    this.changeSelectedPhoto();
  }

  handlePreviousPhoto() {
    this.changeSelectedPhoto(false);
  }

  handleSave() {
    const { onSave } = this.props;

    const selectedPhoto = this.selectedPhoto();
    this.updateCachedEdits(selectedPhoto);
    onSave(this.cachedEdits);
  }

  selectedPhoto() {
    // eslint-disable-next-line react/destructuring-assignment
    return this.state.selectedPhoto || this.props.selectedPhoto || {};
  }

  sidebar() {
    const selectedPhoto = this.selectedPhoto();

    return (
      <div className="photo-editor-sidebar">
        <MapistryTooltip placement="top" title={selectedPhoto.name}>
          <h2 className="sidebar-header truncate">{selectedPhoto.name}</h2>
        </MapistryTooltip>
        <div className="sidebar-body">
          <FieldWrapper label="Image Title" className="field">
            <TextField
              value={selectedPhoto.name}
              onChange={(e) => this.handleChangeName(e)}
            />
          </FieldWrapper>
          <FieldWrapper label="Caption" className="field">
            <TextField
              multiline
              value={selectedPhoto.description}
              onChange={(e) => this.handleChangeDescription(e)}
            />
          </FieldWrapper>
        </div>
      </div>
    );
  }

  editbar() {
    const { selectedPhotoScale } = this.state;

    const zoomOptions = this.getZoomOptions();

    return (
      <div className="edit-bar">
        <div className="edit-tool-group">
          <span onClick={() => this.handleRotateLeft()}>
            <Tooltip
              classes={{
                tooltip: 'tooltip-label',
                popper: 'mapistry-tooltip',
              }}
              placement="top"
              title="Rotate Left"
            >
              <div>
                <RotateLeft className="m-icon tool-icon" />
              </div>
            </Tooltip>
          </span>
          <span onClick={() => this.handleRotateRight()}>
            <Tooltip
              classes={{
                tooltip: 'tooltip-label',
                popper: 'mapistry-tooltip',
              }}
              placement="top"
              title="Rotate Right"
            >
              <div>
                <RotateRight className="m-icon tool-icon" />
              </div>
            </Tooltip>
          </span>
        </div>
        <div className="edit-tool-group">
          <span onClick={() => this.handleZoomOut()}>
            <Tooltip
              classes={{
                tooltip: 'tooltip-label',
                popper: 'mapistry-tooltip',
              }}
              placement="top"
              title="Zoom Out"
            >
              <div>
                <ZoomOutIcon
                  className={`m-icon tool-icon ${
                    selectedPhotoScale <= this.scaleStep ? 'disabled' : ''
                  }`}
                />
              </div>
            </Tooltip>
          </span>
          <span onClick={() => this.handleZoomIn()}>
            <Tooltip
              classes={{
                tooltip: 'tooltip-label',
                popper: 'mapistry-tooltip',
              }}
              placement="top"
              title="Zoom In"
            >
              <div>
                <ZoomInIcon
                  className={`m-icon tool-icon ${
                    selectedPhotoScale >= this.maxScale ? 'disabled' : ''
                  }`}
                />
              </div>
            </Tooltip>
          </span>
          <Select
            value={this.zoomValue(zoomOptions)}
            options={zoomOptions}
            onChange={(selection) => this.handleZoomSelect(selection)}
            isClearable={false}
          />
        </div>
      </div>
    );
  }

  mainContent() {
    const { photos } = this.props;

    const selectedPhoto = this.selectedPhoto();
    const showNavigation = photos.length > 1;
    return (
      <div className="main-content">
        <div ref={this.imageContainerRef} className="image-container">
          <img
            crossOrigin="anonymous"
            ref={this.imageRef}
            style={this.imageStyle()}
            src={selectedPhoto.src}
            onLoad={() => this.handleImageLoaded()}
            alt={selectedPhoto.name}
          />
        </div>
        {showNavigation && (
          <div
            className="previous-photo"
            onClick={() => this.handlePreviousPhoto()}
          >
            <ArrowLeft className="m-icon navigate-photo-icon" />
          </div>
        )}
        {showNavigation && (
          <div className="next-photo" onClick={() => this.handleNextPhoto()}>
            <ArrowRight className="m-icon navigate-photo-icon" />
          </div>
        )}
      </div>
    );
  }

  actions() {
    const { onClose } = this.props;

    return (
      <div className="photo-edit-actions">
        <Button color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={() => this.handleSave()}>Save</Button>
      </div>
    );
  }

  imageStyle() {
    const { showImage, selectedPhotoRotation, selectedPhotoScale } = this.state;

    return {
      transition: showImage ? 'transform 0.3s ease 0s' : 'none',
      opacity: showImage ? 1 : 0,
      transform: `rotate(${selectedPhotoRotation}deg) scale(${selectedPhotoScale})`,
    };
  }

  zoomValue(zoomOptions) {
    const { selectedPhotoScale } = this.state;

    return zoomOptions.find((option) => option.value === selectedPhotoScale);
  }

  fullSizeScale() {
    const image = this.imageRef.current;
    if (image == null) {
      return 0;
    }

    const container = this.imageContainerRef.current;
    const imageLongSize = Math.max(image.naturalHeight, image.naturalWidth);
    let containerSize;
    if (this.isInverted) {
      containerSize =
        imageLongSize === image.naturalHeight
          ? container.clientWidth
          : container.clientHeight;
    } else {
      containerSize =
        imageLongSize === image.naturalHeight
          ? container.clientHeight
          : container.clientWidth;
    }
    const scale = containerSize / imageLongSize;
    return Math.round((scale - 0.02) * 100) / 100;
  }

  changeSelectedPhoto(goForward = true) {
    const selectedPhoto = this.selectedPhoto();
    this.updateCachedEdits(selectedPhoto);

    this.setState({
      showImage: false,
      selectedPhoto: this.getNextPhoto(goForward),
      selectedPhotoRotation: 0,
      selectedPhotoScale: 1,
    });
  }

  updateCachedEdits(selectedPhoto) {
    const { selectedPhotoRotation } = this.state;

    const updatedPhoto =
      selectedPhotoRotation === 0
        ? selectedPhoto
        : { ...selectedPhoto, src: this.exportImage() };
    const otherCachedEdits = this.cachedEdits.filter(
      (p) => p.fileId !== updatedPhoto.fileId,
    );
    this.cachedEdits = [...otherCachedEdits, updatedPhoto];
  }

  exportImage() {
    const { selectedPhotoRotation } = this.state;

    const canvas = document.createElement('canvas');
    const image = this.imageRef.current;
    canvas.width = this.isInverted ? image.height : image.width;
    canvas.height = this.isInverted ? image.width : image.height;
    const context = canvas.getContext('2d');

    context.clearRect(0, 0, canvas.width, canvas.height);
    context.save();
    const rotationWidthTranslation = canvas.width / 2;
    const rotationHeightTranslation = canvas.height / 2;
    context.translate(rotationWidthTranslation, rotationHeightTranslation);
    context.rotate((selectedPhotoRotation * Math.PI) / 180);
    context.translate(-rotationWidthTranslation, -rotationHeightTranslation);

    let xPosition = 0;
    let yPosition = 0;

    if (this.isInverted && image.width > image.height) {
      xPosition = -(canvas.height - canvas.width) / 2;
      yPosition = (canvas.height - canvas.width) / 2;
    } else if (this.isInverted && image.height > image.width) {
      xPosition = (canvas.width - canvas.height) / 2;
      yPosition = -(canvas.width - canvas.height) / 2;
    }
    context.drawImage(image, xPosition, yPosition);

    context.restore();
    return canvas.toDataURL('image/png');
  }

  render() {
    const { open, onClose } = this.props;

    return (
      <Modal
        className="photo-editor"
        header={this.editbar()}
        footer={this.actions()}
        open={open}
        onClose={onClose}
      >
        <div className="photo-editor-main-wrapper">
          {this.sidebar()}
          <div className="photo-editor-main-content">{this.mainContent()}</div>
        </div>
      </Modal>
    );
  }
}

const PhotoType = PropTypes.shape({
  fileId: PropTypes.string,
  file: PropTypes.object,
  src: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  totalSize: PropTypes.number,
  loadedSize: PropTypes.number,
  fileReader: PropTypes.object,
  processing: PropTypes.bool,
});

PhotoEditor.propTypes = {
  open: PropTypes.bool.isRequired,
  photos: PropTypes.arrayOf(PhotoType),
  selectedPhoto: PhotoType,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func,
};

PhotoEditor.defaultProps = {
  photos: [],
  selectedPhoto: null,
  onClose: null,
};

export default PhotoEditor;
