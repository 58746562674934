import { QueryConfig, useQuery } from 'react-query';
import { Api } from '../apiClient';

export const createKey = ({
  projectId,
  includeArchived,
}: Api.FetchAllUsersOnProjectProps) =>
  ['project-users', projectId, includeArchived] as const;

type Fetcher = Api.DataHookQueryFn<
  typeof createKey,
  typeof Api.fetchAllUsersOnProject
>;

const fetcher: Fetcher = (_, projectId, includeArchived) =>
  Api.fetchAllUsersOnProject({ projectId, includeArchived });

type UseProjectUsersProps = Api.FetchAllUsersOnProjectProps & {
  config?: QueryConfig<Api.FetchUsersResponse, Api.ErrorResponse>;
};

export function useProjectUsers({
  config: inputConfig,
  projectId,
  includeArchived = false,
}: UseProjectUsersProps) {
  const key = createKey({ projectId, includeArchived });
  const config = {
    enabled: !!projectId,
    ...inputConfig,
  };
  const { data, ...queryInfo } = useQuery(key, fetcher, config);
  return {
    ...queryInfo,
    users: data,
  };
}
