import { useCallback, useState } from 'react';

type OpenCloseReturnType = [
  isOpen: boolean,
  open: () => void,
  close: () => void,
];

/**
 *
 * Helper hook to manage open/closed state of modals, toasts, etc
 */
export function useOpenClose(startsOpen = false): OpenCloseReturnType {
  const [isOpen, setIsOpen] = useState(startsOpen);

  const open = useCallback(() => setIsOpen(true), [setIsOpen]);
  const close = useCallback(() => setIsOpen(false), [setIsOpen]);

  return [isOpen, open, close];
}
