import styled from 'styled-components';
import { ContainedButton } from './ContainedButton';

export const ContainedSecondaryButton = styled(ContainedButton)`
  background-color: ${({ theme }) => theme.colors.darkRed};

  &:hover {
    /* Need to explicitly set color for buttons that are rendered with <a> element,
       e.g. when href prop is passed */
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.darkerRed};
  }

  &:focus {
    /* Need to explicitly set color for buttons that are rendered with <a> element,
       e.g. when href prop is passed */
    color: ${({ theme }) => theme.colors.white};
  }

  &:active {
    background-color: ${({ theme }) => theme.colors.red};
  }

  &.Mui-disabled {
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.red};
    opacity: 0.3;

    & svg {
      fill: ${({ theme }) => theme.colors.white};
    }
  }
`;
