import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button, CardOverlay, FieldWrapper, Select } from '../../../elements';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default class DatePickerOverlay extends Component {
  constructor(props) {
    super(props);
    const { currentDate } = props;
    this.state = {
      year: currentDate.getFullYear(),
      month: currentDate.getMonth(),
      day: currentDate.getDate(),
    };
    this.handleConfirm = this.handleConfirm.bind(this);
    this.handleDayChange = this.handleDayChange.bind(this);
    this.handleMonthChange = this.handleMonthChange.bind(this);
    this.handleYearChange = this.handleYearChange.bind(this);
  }

  handleDayChange(dayOption) {
    this.setState({ day: dayOption.value });
  }

  handleMonthChange(monthOption) {
    this.setState({ month: monthOption.value });
  }

  handleYearChange(yearOption) {
    this.setState({ year: yearOption.value });
  }

  handleConfirm() {
    const { onConfirm } = this.props;
    const { day, month, year } = this.state;
    const selectedDate = new Date(year, month, day);
    onConfirm(selectedDate);
  }

  years() {
    const { currentDate } = this.props;
    const currentYear = currentDate.getFullYear();
    const min = currentYear - 5;
    const max = currentYear + 5;
    const options = [];
    for (let i = min; i <= max; i += 1) {
      options.push({ label: i.toString(), value: i });
    }
    return options;
  }

  months() {
    return [
      { label: 'January', value: 0 },
      { label: 'February', value: 1 },
      { label: 'March', value: 2 },
      { label: 'April', value: 3 },
      { label: 'May', value: 4 },
      { label: 'June', value: 5 },
      { label: 'July', value: 6 },
      { label: 'August', value: 7 },
      { label: 'September', value: 8 },
      { label: 'October', value: 9 },
      { label: 'November', value: 10 },
      { label: 'December', value: 11 },
    ];
  }

  days() {
    const { month, year } = this.state;
    const min = 1;
    // last day of current month
    const max = new Date(year, month + 1, 0).getDate();
    const options = [];
    for (let i = min; i <= max; i += 1) {
      options.push({ label: i.toString(), value: i });
    }
    return options;
  }

  footer() {
    const { onCancel } = this.props;
    return (
      <>
        <Button color="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button onClick={this.handleConfirm}>Apply</Button>
      </>
    );
  }

  render() {
    const { onCancel } = this.props;
    const { day, month, year } = this.state;
    const months = this.months();

    return (
      <CardOverlay
        footer={this.footer()}
        onCancel={onCancel}
        title="Select a date"
      >
        <FieldWrapper label="Select a year">
          <Select
            isClearable={false}
            isFixed
            options={this.years()}
            value={{ label: year.toString(), value: year }}
            onChange={this.handleYearChange}
          />
        </FieldWrapper>
        <FieldWrapper label="Select a month">
          <Select
            isClearable={false}
            isFixed
            options={months}
            value={months[month]}
            onChange={this.handleMonthChange}
          />
        </FieldWrapper>
        <FieldWrapper label="Select a day">
          <Select
            isClearable={false}
            isFixed
            options={this.days()}
            value={{ label: day.toString(), value: day }}
            onChange={this.handleDayChange}
          />
        </FieldWrapper>
      </CardOverlay>
    );
  }
}

DatePickerOverlay.propTypes = {
  currentDate: PropTypes.instanceOf(Date),
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

DatePickerOverlay.defaultProps = {
  currentDate: new Date(),
};
