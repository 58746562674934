import PropTypes from 'prop-types';
import React from 'react';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import api from '../middleware/api';
import app from '../reducers';

const middleware = [thunk, api];

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  app,
  composeEnhancers(applyMiddleware(...middleware)),
);

const StoreProps = {
  children: PropTypes.node.isRequired,
};

const Store = (props) => {
  const { children } = props;
  return <Provider store={store}>{children}</Provider>;
};

Store.propTypes = StoreProps;

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default Store;
