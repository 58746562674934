import React from 'react';
import PropTypes from 'prop-types';
import { SvgIcon } from '@monorepo/shared/components/icons/SvgIcon';
import IconButton from './IconButton';
import MapistryTooltip from '../MapistryTooltip';

const LabelledIconButtonProps = {
  children: PropTypes.node.isRequired,
  iconIdentifier: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

const LabelledIconButton = (props) => {
  const { children, iconIdentifier, onClick } = props;
  return (
    <IconButton onClick={onClick}>
      <MapistryTooltip placement="top" title={children}>
        <div className="flex items-center">
          <SvgIcon identifier={iconIdentifier} />
          <span className="filter__text">{children}</span>
        </div>
      </MapistryTooltip>
    </IconButton>
  );
};

LabelledIconButton.propTypes = LabelledIconButtonProps;

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default LabelledIconButton;
