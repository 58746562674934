import { FrequencyContext } from '@monorepo/shared/contexts/FrequencyContext';
import { CustomFrequencyMessage } from 'mapistry-shared';
import React, { useContext, useMemo, useRef } from 'react';
import { FieldWrapper } from '../elements';
import FrequencyDateSelector from './FrequencyDateSelector';
import FrequencySelect, { FrequencySelectProps } from './FrequencySelect';

type FrequencyProps = {
  frequencyErrorMessage?: string;
  frequencyIsClearable?: boolean;
  frequencyIsRequired?: boolean;
  frequencyLabel: string;
  showDateField?: boolean;
  startDateErrorMessage?: string;
  startDateFooterText?: React.ReactNode;
  startDateIsRequired?: boolean;
  startDateLabel: string;
} & Pick<
  FrequencySelectProps,
  | 'customFrequencyMessageStart'
  | 'customPopperHeader'
  | 'customPopperSubHeader'
  | 'disabled'
  | 'frequencyOptions'
>;

const defaultProps = {
  showDateField: true,
};

const FrequencyForm = (props: FrequencyProps) => {
  const {
    disabled,
    customFrequencyMessageStart,
    frequencyErrorMessage,
    frequencyIsClearable,
    frequencyIsRequired,
    frequencyLabel,
    showDateField,
    startDateErrorMessage,
    startDateFooterText,
    startDateIsRequired,
    startDateLabel,
    ...frequencySelectPassthrough
  } = props;

  const { customFrequency, customPopperOpen, selectedFrequency } =
    useContext(FrequencyContext);

  const frequencyHeadingRef = useRef<HTMLElement>(null);

  const helperText = useMemo(() => {
    if (
      !selectedFrequency ||
      !customFrequency ||
      customFrequency.nonCustomFrequency
    ) {
      return '';
    }
    const message = new CustomFrequencyMessage(
      selectedFrequency,
      customFrequency,
    );
    return message.getMessage({ messageStart: customFrequencyMessageStart });
  }, [customFrequency, customFrequencyMessageStart, selectedFrequency]);

  return (
    <>
      <FieldWrapper
        error={!!frequencyErrorMessage}
        errorMessage={frequencyErrorMessage}
        headingRef={frequencyHeadingRef}
        isRequired={frequencyIsRequired}
        label={frequencyLabel}
        footerText={helperText}
      >
        <FrequencySelect
          {...frequencySelectPassthrough}
          isClearable={frequencyIsClearable}
          isFixed
          customFrequencyMessageStart={customFrequencyMessageStart}
          customPopperAnchor={frequencyHeadingRef}
          disabled={disabled}
          error={!!frequencyErrorMessage}
        />
      </FieldWrapper>
      {showDateField && (
        <FieldWrapper
          error={!!startDateErrorMessage}
          errorMessage={startDateErrorMessage}
          footerText={startDateFooterText}
          isRequired={startDateIsRequired}
          label={startDateLabel}
        >
          <FrequencyDateSelector
            disabled={customPopperOpen || disabled}
            error={!!startDateErrorMessage}
            isFixed
          />
        </FieldWrapper>
      )}
    </>
  );
};

FrequencyForm.defaultProps = defaultProps;

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default FrequencyForm;
