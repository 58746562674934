import {
  isSingleOption,
  isSubOptions,
  Option,
  SingleOption,
} from '../types/ts-types';

export function findSelectedOption<T>(
  options: Option<T>[],
  selected?: T | null,
): SingleOption<T> | null {
  for (let i = 0; i < options.length; i += 1) {
    const option = options[i];

    /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
    if (isSingleOption(option) && option.value === selected) {
      return option;
    }
    /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
    if (isSubOptions(option)) {
      const subOption = findSelectedOption(option.options, selected);
      if (subOption) {
        return subOption;
      }
    }
  }

  return null;
}
