import React from 'react';
import { IconButton as MuiIconButton } from '@material-ui/core';
import cn from 'classnames';
import { Tooltip } from '../Tooltip';

type MuiIconButtonProps = React.ComponentProps<typeof MuiIconButton>;

interface IconButtonProps extends MuiIconButtonProps {
  tooltipText?: string;
}

export const IconButton = ({
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/prop-types
  className,
  tooltipText,
  ...restProps
}: IconButtonProps) => {
  const button = (
    <MuiIconButton
      {...restProps}
      className={cn('mapistry-icon-button', className)}
      disableRipple
    />
  );

  if (tooltipText) {
    return (
      <Tooltip placement="top" title={tooltipText}>
        <span>{button}</span>
      </Tooltip>
    );
  }

  return button;
};
