import React from 'react';

const CalendarCardSkeleton = () => (
  <div className="m-skeleton-card mapistry-card">
    <div className="m-skeleton-card__header shimmer" />
    <div className="m-skeleton-card__container">
      <div className="m-skeleton-card__time shimmer" />
      <div className="m-skeleton-card__date shimmer" />
      <div className="m-skeleton-card__content">
        <ul className="m-skeleton-card__multi-group__list">
          <li className="m-skeleton-card__month shimmer" />
          <li className="m-skeleton-card__month shimmer" />
          <li className="m-skeleton-card__month shimmer" />
          <li className="m-skeleton-card__month shimmer" />
          <li className="m-skeleton-card__month shimmer" />
          <li className="m-skeleton-card__month shimmer" />
          <li className="m-skeleton-card__month shimmer" />
          <li className="m-skeleton-card__month shimmer" />
          <li className="m-skeleton-card__month shimmer" />
          <li className="m-skeleton-card__month shimmer" />
          <li className="m-skeleton-card__month shimmer" />
          <li className="m-skeleton-card__month shimmer" />
        </ul>
      </div>
    </div>
  </div>
);

CalendarCardSkeleton.propTypes = {};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default CalendarCardSkeleton;
