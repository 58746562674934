import React from 'react';
import { useField, UseFieldConfig } from 'react-final-form';
import { OptionTypeBase, ValueType } from 'react-select';
import { Select } from '../Select';

export type SelectFieldProps<FieldType> = Omit<
  React.ComponentProps<typeof Select>,
  'onChange'
> & {
  label?: string;
  name: string;
  validate?: UseFieldConfig<FieldType>['validate'];
};

export const SelectField = <FieldType,>({
  label,
  name,
  options,
  validate,
  ...rest
}: SelectFieldProps<FieldType>) => {
  const { input, meta } = useField<FieldType>(name, { validate });

  return (
    <Select
      {...rest}
      aria-labelledby={`select-${name}-label`}
      error={!!meta.error}
      errorMessage={meta.error}
      id={`select-${name}`}
      inputId={`select-${name}-input`}
      label={label}
      name={name}
      onChange={(option: ValueType<OptionTypeBase, false>) =>
        input.onChange(option?.value)
      }
      options={options}
      /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
      value={options?.find((o) => o.value === input.value)}
    />
  );
};
