import { CustomFrequency, IntervalFrequencyEnum } from 'mapistry-shared';

export const Rules = {
  SupportedEvery: {
    [IntervalFrequencyEnum.DAY]: [1],
    [IntervalFrequencyEnum.WEEK]: [1],
    [IntervalFrequencyEnum.MONTH]: [1, 2, 3, 4, 6],
    [IntervalFrequencyEnum.YEAR]: [1],
  } as { [key in IntervalFrequencyEnum]: number[] },
  MaxEvery: {
    [IntervalFrequencyEnum.DAY]: 364,
    [IntervalFrequencyEnum.WEEK]: 51,
    [IntervalFrequencyEnum.MONTH]: 11,
  } as { [key in IntervalFrequencyEnum]: number },
  MaxStartDay: {
    [IntervalFrequencyEnum.WEEK]: 7,
    [IntervalFrequencyEnum.MONTH]: 31,
    [IntervalFrequencyEnum.YEAR]: 31,
  } as { [key in IntervalFrequencyEnum]: number },
};

export type CustomFrequencyErrors = {
  numberOfEventsError?: boolean;
  everyError?: boolean;
  messages?: string[];
};

const NO_ERROR = '';

export class CustomFrequencyValidator {
  frequency: IntervalFrequencyEnum;

  customFrequency: CustomFrequency;

  constructor(
    frequency: IntervalFrequencyEnum,
    customFrequency: CustomFrequency,
  ) {
    this.frequency = frequency;
    this.customFrequency = customFrequency;
  }

  validate(): CustomFrequencyErrors {
    const repeatError = this.validateNumberOfEvents();
    const everyError = this.validateEvery();

    return {
      numberOfEventsError: !!repeatError,
      everyError: !!everyError,
      messages: [repeatError, everyError].filter(
        (error) => !!error,
      ) as string[],
    };
  }

  private validateNumberOfEvents() {
    const { numberOfEvents } = this.customFrequency;
    if (Number.isNaN(numberOfEvents) || numberOfEvents < 1) {
      return this.getErrorMessage('numberOfEvents');
    }
    return NO_ERROR;
  }

  private validateEvery() {
    const { every } = this.customFrequency;
    if (Number.isNaN(every) || every < 1) {
      return this.getErrorMessage('every');
    }
    const maxRule = Rules.MaxEvery[this.frequency];
    if (maxRule && every > maxRule) {
      return this.getErrorMessage('every');
    }
    const supportedEvery = Rules.SupportedEvery[this.frequency];
    if (supportedEvery && supportedEvery.indexOf(every) < 0) {
      return this.getNotSupportedMessage('every');
    }
    return NO_ERROR;
  }

  private getErrorMessage(key: keyof CustomFrequency) {
    const { numberOfEvents, every } = this.customFrequency;
    switch (key) {
      case 'numberOfEvents':
        return Number.isNaN(numberOfEvents)
          ? 'You must enter a number for repeat value'
          : `${numberOfEvents} is not a valid repeat value`;
      case 'every': {
        const frequencyName = this.getFrequencyName();
        return Number.isNaN(every)
          ? `You must enter a number for ${frequencyName} frequency`
          : `${every} is not a valid value for ${frequencyName} frequency`;
      }
      default:
        return NO_ERROR;
    }
  }

  private getNotSupportedMessage(key: keyof CustomFrequency) {
    const { every } = this.customFrequency;
    switch (key) {
      case 'every':
        return `${every} is not a supported value for ${this.getFrequencyName()} frequency`;
      default:
        return NO_ERROR;
    }
  }

  private getFrequencyName() {
    switch (this.frequency) {
      case IntervalFrequencyEnum.DAY:
        return 'daily';
      case IntervalFrequencyEnum.WEEK:
        return 'weekly';
      case IntervalFrequencyEnum.MONTH:
        return 'monthly';
      case IntervalFrequencyEnum.YEAR:
        return 'yearly';
      default:
        return 'unexpected frequency';
    }
  }
}
