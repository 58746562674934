import { DateLike, EdpLogCalendarEvent } from 'mapistry-shared';
import http from '../httpClient';

export interface FetchLogEventsParams {
  endDate: DateLike;
  logId: string;
  projectId: string;
  startDate: DateLike;
}
export type FetchLogEventsResponse = EdpLogCalendarEvent[];

export async function fetchLogEvents({
  endDate,
  logId,
  projectId,
  startDate,
}: FetchLogEventsParams): Promise<FetchLogEventsResponse> {
  const res = await http.get<FetchLogEventsResponse>(
    `/api/v2/projects/${projectId}/compliance-calendar/events/logs/${logId}`,
    { params: { startDate, endDate } },
  );
  return res.data;
}
