import React from 'react';

type ChartCardSettingsActionsProps = {
  children: NonNullable<React.ReactNode>;
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function ChartCardSettingsActions(props: ChartCardSettingsActionsProps) {
  const { children } = props;
  return <div className="chart-card--settings-actions">{children}</div>;
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default ChartCardSettingsActions;
