import { SimpleUnits } from 'mapistry-shared';

export enum FactorType {
  constant = 'Constant',
  factor = 'Factor',
  other = 'Other',
}

export type Factor = {
  createdAt: Date;
  groupId: string;
  id: string;
  itemType: FactorType;
  itemValue: number; // This is a string in the DB, but we seem to treat it as a number everywhere
  logId: string;
  logProjectId: string;
  name: string;
  notes: unknown;
  projectId: string;
  units: SimpleUnits;
  updatedAt: Date;
};
