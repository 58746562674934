import { format, parse } from 'date-fns';
import { localEquivalentOfUTC } from 'mapistry-shared';
import {
  ValidDateString,
  ValidTimeFormat,
  ValidTimeString,
  alreadyInUTC,
} from './valueValidationHelpers';

export function parseDateString(dateString: ValidDateString): Date {
  const date = new Date(dateString);
  if (alreadyInUTC(dateString)) {
    return localEquivalentOfUTC(date);
  }
  date.setHours(0, 0, 0, 0);
  return date;
}

export function parseDateTimeString(dateString: ValidDateString): Date {
  const date = new Date(dateString);
  return alreadyInUTC(dateString) ? localEquivalentOfUTC(date) : date;
}

export function parseTimeFromDateString(dateString: ValidDateString) {
  let date = new Date(dateString);
  if (alreadyInUTC(dateString)) {
    date = localEquivalentOfUTC(date);
  }
  return format(date, 'HH:mm');
}

export function parseTimeString(
  timeString: ValidTimeString,
  fmt: ValidTimeFormat,
) {
  const date = parse(timeString, fmt, new Date());
  return format(date, 'HH:mm');
}
