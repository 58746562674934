import qs from 'qs';
import { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSearchParams } from './useSearchParams';

export function useReplaceSearchParams() {
  const history = useHistory();
  const location = useLocation();
  const currentSearchParams = useSearchParams();

  const replaceSearchParams = useCallback(
    (searchParams: { [key: string]: string | null }) => {
      const updatedSearchParams = { ...currentSearchParams, ...searchParams };
      Object.entries(updatedSearchParams).forEach(([key, value]) => {
        if (value == null) {
          delete updatedSearchParams[key];
        }
      });
      const search = qs.stringify(updatedSearchParams, {
        addQueryPrefix: true,
      });
      const updatedLocation = { ...location, search };
      history.replace(updatedLocation);
    },
    [history, location, currentSearchParams],
  );
  return replaceSearchParams;
}
