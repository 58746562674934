import ApexCharts from 'apexcharts';
import _get from 'lodash.get';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { delimitNumbers } from '../../utils';

import AnimatedComponent, { ANIMATION_TYPES } from './AnimatedComponent';

const SERIES_LENGTH_PERFORMANCE_THRESHOLD = 60;

class Chart extends Component {
  constructor(props) {
    super(props);
    this.chartTarget = React.createRef();
    this.chart = null;
  }

  componentDidMount() {
    const chartOptions = this.buildOptions();
    if (this.chartTarget.current) {
      this.chart = new ApexCharts(this.chartTarget.current, chartOptions);
      this.chart.render();
    }
  }

  componentDidUpdate(prevProps) {
    const { series } = this.props;
    if (prevProps.series !== series) {
      if (this.chart) {
        this.chart.destroy();
      }
      const nextOptions = this.buildOptions();
      this.chart = new ApexCharts(this.chartTarget.current, nextOptions);
      this.chart.render();
    }
  }

  buildOptions() {
    const {
      axes,
      categories,
      colors,
      fill,
      height,
      markers,
      plotOptions,
      series,
      title,
      tooltip,
      yaxis,
    } = this.props;

    const seriesAsArray = Array.isArray(series) ? series : [series];
    const anySeriesAreTooLong = seriesAsArray.some(
      (s) => s.data.length > SERIES_LENGTH_PERFORMANCE_THRESHOLD,
    );

    const options = {
      chart: {
        height: height || 300,
        fontFamily: 'Roboto, sans-serif',
        toolbar: {
          show: true,
          tools: {
            pan: false,
            reset: false,
          },
        },
        type: 'line',
      },
      colors,
      fill: {
        ...fill,
      },
      legend: {
        show: false,
      },
      markers: {
        ...markers,
      },
      plotOptions: {
        ...plotOptions,
      },
      series: seriesAsArray,
      stroke: {
        width: 2,
      },
      title: {
        text: title,
        offsetX: -7,
      },
      tooltip: {
        ...tooltip,
      },
      xaxis: {
        categories,
        title: {
          style: {
            cssClass: 'mapistry-chart__axis-label',
          },
          text: _get(axes, 'x'),
        },
      },
      yaxis: {
        labels: {
          formatter: (value) => delimitNumbers(value),
        },
        title: {
          style: {
            cssClass: 'mapistry-chart__axis-label',
          },
          text: _get(axes, 'y'),
        },
        ...yaxis,
      },
    };

    if (anySeriesAreTooLong) {
      // Adjust the chart for better performance when there are larger datasets
      options.chart = {
        ...options.chart,
        animations: {
          enabled: false,
        },
      };
    }
    return options;
  }

  render() {
    const { categories, isLoading } = this.props;

    const chartHasNoData = !isLoading && categories && categories.length === 0;
    if (chartHasNoData) {
      return (
        <div ref={this.chartTarget} className="red">
          There are no records in this period. Try adjusting the date range for
          the chart.
        </div>
      );
    }
    return (
      <>
        {isLoading && (
          <img
            className="w-100 h-100 shimmer"
            alt="Chart loading"
            src="https://s3.amazonaws.com/mapistryAssets/chartDefault1.png"
          />
        )}
        {!isLoading && (
          <AnimatedComponent type={ANIMATION_TYPES.FADE_IN_OUT}>
            <div
              className="h-100 w-100 mapistry-chart__container"
              ref={this.chartTarget}
            />
          </AnimatedComponent>
        )}
      </>
    );
  }
}

Chart.propTypes = {
  axes: PropTypes.shape({
    x: PropTypes.string,
    y: PropTypes.string,
  }),
  colors: PropTypes.arrayOf(PropTypes.string),
  fill: PropTypes.shape({}),
  height: PropTypes.number,
  markers: PropTypes.shape({
    size: PropTypes.arrayOf(PropTypes.number),
  }),
  plotOptions: PropTypes.shape({}),
  categories: PropTypes.arrayOf(PropTypes.string),
  isLoading: PropTypes.bool.isRequired,
  series: PropTypes.arrayOf(PropTypes.shape({})),
  title: PropTypes.string,
  tooltip: PropTypes.shape({}),
  yaxis: PropTypes.shape({}),
};

Chart.defaultProps = {
  axes: undefined,
  categories: undefined,
  colors: undefined,
  fill: {},
  height: null,
  markers: {},
  plotOptions: {},
  series: [],
  title: undefined,
  tooltip: {},
  yaxis: {},
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default Chart;
