import { Checkbox } from '@monorepo/shared/components/Checkbox';
import { Tooltip } from '@monorepo/shared/components/Tooltip';
import { VisuallyHidden } from '@monorepo/shared/componentsV2/VisuallyHidden';
import { KeyService } from '@monorepo/shared/KeyService';
import TrashIcon from '@svg/trash.svg';
import PropTypes from 'prop-types';
import React from 'react';
import { PlayArrow } from '../elements/MuiIcon';
import {
  NO_COMPLETE_PERMISSION_MSG,
  NO_DELETE_PERMISSION_MSG,
} from './shared_messaging';

const TaskHeader = (props) => {
  const {
    canBeCompleted,
    defaultTitle,
    title,
    disabled,
    isCollapsed,
    isCompleted,
    onCollapse,
    onComplete,
    onDelete,
    taskPermissions,
  } = props;

  let tooltipMessage = '';
  if (isCompleted) {
    tooltipMessage = 'Completed task cannot be deleted';
  }
  if (!taskPermissions.isAllowedToDelete) {
    tooltipMessage = NO_DELETE_PERMISSION_MSG;
  }

  const disableDelete =
    disabled || !taskPermissions.isAllowedToDelete || isCompleted;

  const hideDeleteClass = disableDelete
    ? ' task-header__remove-icon--hidden'
    : '';
  const expandedClass = isCollapsed ? '' : ' rotate-ninety';
  const handleCollapse = () => onCollapse(!isCollapsed);

  return (
    <div className="task-header">
      <PlayArrow
        className={`task-header__toggle-icon${expandedClass}`}
        onClick={handleCollapse}
        onKeyDown={(e) => {
          if (KeyService.triggersButtonClick(e.keyCode)) {
            handleCollapse(e);
          }
        }}
        role="button"
        aria-label="Collapse task"
        tabIndex={0}
      />
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <div className="task-header__title" onClick={handleCollapse}>
        {title || defaultTitle}
      </div>
      {canBeCompleted && (
        <Tooltip
          title={
            taskPermissions.isAllowedToComplete
              ? ''
              : NO_COMPLETE_PERMISSION_MSG
          }
        >
          <div className="task-header__mark-complete">
            <Checkbox
              isChecked={isCompleted}
              label="Mark task complete"
              onChange={onComplete}
              disabled={!taskPermissions.isAllowedToComplete}
              focusableWhenDisabled
            />
          </div>
        </Tooltip>
      )}
      <Tooltip title={tooltipMessage}>
        <span
          role="button"
          className={`task-header__remove-icon${hideDeleteClass}`}
          onClick={!disableDelete ? onDelete : undefined}
          tabIndex={0}
          onKeyDown={(e) => {
            if (disableDelete) return;
            if (KeyService.triggersButtonClick(e.keyCode)) {
              onDelete(e);
            }
          }}
        >
          <TrashIcon className="m-icon" aria-hidden />
          <VisuallyHidden>Delete Task</VisuallyHidden>
        </span>
      </Tooltip>
    </div>
  );
};

TaskHeader.propTypes = {
  canBeCompleted: PropTypes.bool,
  defaultTitle: PropTypes.string.isRequired,
  title: PropTypes.string,
  disabled: PropTypes.bool,
  isCollapsed: PropTypes.bool,
  isCompleted: PropTypes.bool,
  onCollapse: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  taskPermissions: PropTypes.shape({
    isAllowedToDelete: PropTypes.bool,
    isAllowedToEdit: PropTypes.bool,
    isAllowedToComplete: PropTypes.bool,
  }).isRequired,
};

TaskHeader.defaultProps = {
  canBeCompleted: true,
  title: null,
  disabled: false,
  isCollapsed: false,
  isCompleted: false,
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default TaskHeader;
