import * as Sentry from '@sentry/browser';
import React, { ComponentProps, useCallback, useRef } from 'react';
import styled from 'styled-components';
import { Button } from './Button';
import { VisuallyHidden, visuallyHidden } from './VisuallyHidden';

export const StyledSkipLink = styled(Button)`
  &:not(:focus) {
    ${visuallyHidden()}
  }

  position: absolute;
  z-index: 1600; /* above mui z-index for drawer at 1200 */
  transition: unset;
`;

export const SkipLinkPositioner = styled.div`
  position: relative;
`;

/**
 * Creates a pair of components - the skip link itself, and the skip link target.
 * Allows focussing the target component without changing the url.
 * Use this if you don't have anything meaningful to focus on (e.g. a header component, but just need to allow skipping past a widget).
 *
 * The more common way to do this is to use an a tag with href="#target"
 * but that adds #target to the url - which is fine for navigating to main, for example, but get a bit
 * tedious for skipping widgets
 */
export const useSkipWidgetLink = () => {
  const targetRef = useRef<HTMLDivElement>(null);

  const focusTarget = useCallback(() => {
    if (!targetRef.current) {
      Sentry.captureException(
        `Skip link target not found on ${window.location.href}. Did you forget to render the SkipLinkTarget?`,
      );
      return;
    }

    targetRef.current.focus();
  }, []);

  const SkipLink = useCallback(
    ({
      linkText,
      ...rest
    }: ComponentProps<typeof StyledSkipLink> & { linkText: string }) => (
      <SkipLinkPositioner className="skip-link">
        <StyledSkipLink onClick={focusTarget} {...rest}>
          {linkText}
        </StyledSkipLink>
      </SkipLinkPositioner>
    ),
    [focusTarget],
  );

  const SkipLinkTarget = useCallback(
    () => (
      <VisuallyHidden
        className="skip-link-target"
        ref={targetRef}
        tabIndex={-1}
      />
    ),
    [],
  );

  return {
    SkipLink,
    SkipLinkTarget,
  };
};
