import SearchBar from '@monorepo/shared/components/SearchBar';
import React, { useState } from 'react';
import { AccordionContainer } from '../../elements';
import { FormError, LoggedItemRecord } from './EditLogModalTypes';
import LogRow from './LogRow';

interface EditLogItemGroupProps {
  formErrors: FormError;
  groupId: string;
  groupName: string;
  items: Array<LoggedItemRecord & { itemId: string }>;
  onEdit: (key: string, value: unknown, id?: string) => void;
}

const EditLogModalLoggedItemGroup = ({
  groupId,
  groupName,
  items,
  formErrors,
  onEdit,
}: EditLogItemGroupProps) => {
  const [filter, setFilter] = useState('');
  const filteredItems = items.filter((i) =>
    i.name.toLowerCase().includes(filter),
  );
  return (
    <div className="accordion-container">
      <AccordionContainer
        className="group__container"
        title={
          <div className="group-name__content">
            <span>{groupName}</span>
          </div>
        }
      >
        <>
          <div className="air-emission-log-modal__items-header-searchbar">
            <SearchBar
              value={filter}
              placeholder="Search for an item"
              onChange={(e) => setFilter(e.target.value)}
              textFieldProps={{ variant: 'standard' }}
            />
          </div>
          <div className="air-emission-log-modal__items-header">
            <div className="air-emission-log-modal__items-header-label">
              Item
            </div>
            <div className="air-emission-log-modal__items-header-label">
              Item Value
            </div>
            <div className="air-emission-log-modal__items-header-label">
              Units
            </div>
          </div>
          {filteredItems.length ? (
            filteredItems.map((item) => (
              <LogRow
                key={`${item.name}.${groupId}`}
                /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
                error={!!formErrors?.items?.[item.itemId].value}
                logItem={item}
                onChange={(val) => onEdit('value', val, item.itemId)}
                onUnitChange={(val) => onEdit('units', val, item.itemId)}
              />
            ))
          ) : (
            <div className="air-emission-log-modal__no-items">
              <div className="strong">No items match your search</div>
              <div className="weak">
                Double check your search or try another keyword
              </div>
            </div>
          )}
        </>
      </AccordionContainer>
    </div>
  );
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default EditLogModalLoggedItemGroup;
