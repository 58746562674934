import styled, { css } from 'styled-components';

export const visuallyHidden = () => css`
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  white-space: nowrap;
`;

export const VisuallyHidden = styled.span`
  ${visuallyHidden()}
`;
