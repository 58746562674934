import {
  FormControl,
  FormControlLabel,
  Checkbox as MuiCheckbox,
} from '@material-ui/core';
import cn from 'classnames';
import React, { ChangeEvent } from 'react';

export type CheckboxProps = {
  className?: string;
  disabled?: boolean;
  focusableWhenDisabled?: boolean;
  isChecked: boolean;
  label: string | React.ReactNode;
  onChange: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
};

export const Checkbox = (props: CheckboxProps) => {
  const {
    className,
    disabled,
    focusableWhenDisabled,
    isChecked,
    label,
    onChange,
  } = props;

  return (
    <FormControl className={cn(['mapistry-checkbox', className])}>
      <FormControlLabel
        classes={{
          label: `mapistry-checkbox__label ${disabled ? 'Mui-disabled' : ''}`,
        }}
        control={
          <MuiCheckbox
            checked={isChecked}
            classes={{
              root: 'mapistry-checkbox__root',
              colorPrimary: 'mapistry-green',
            }}
            color="primary"
            disableRipple
            onChange={disabled ? () => null : onChange}
            disabled={focusableWhenDisabled ? false : disabled}
            aria-disabled={disabled}
          />
        }
        label={label}
      />
    </FormControl>
  );
};
