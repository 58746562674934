import {
  CustomFrequency,
  IntervalFrequencyEnum,
  IntervalFrequencyFactory,
} from 'mapistry-shared';
import { SaveState } from '../../types/SaveState';

export type SavedFrequency = {
  frequency: IntervalFrequencyEnum;
  customFrequency: CustomFrequency;
  numberOfInspections: number;
  startDate: string;
};
export type State = {
  customFrequency?: CustomFrequency;
  customPopperOpen?: boolean;
  frequencyChanged?: boolean;
  prevCustomFrequency?: CustomFrequency;
  prevFrequency?: IntervalFrequencyEnum | null;
  prevStartDate?: Date | null;
  localSaveState: SaveState;
  selectedFrequency?: IntervalFrequencyEnum | null;
  selectedStartDate?: Date | null;
};

export const DefaultCustomFrequency: CustomFrequency = {
  numberOfEvents: 1,
  canAddMore: false,
  every: 1,
  repeatOn: [],
};

export enum ActionType {
  Reset = 'reset',
  CloseCustomPopper = 'closeCustomPopper',
  UndoAndCloseCustomPopper = 'undoAndCloseCustomPopper',
  OpenCustomPopper = 'openCustomPopper',
  UpdateFrequency = 'updateFrequency',
  UpdateStartDate = 'updateStartDate',
}

export type PayloadType = Partial<
  {
    alwaysUseDatePicker?: boolean;
    forceCustomFrequency?: boolean;
    frequencies: SavedFrequency[];
    noRepeatOn?: boolean;
    isDueDate?: boolean;
  } & State
>;

export const CUSTOM_FREQUENCY = 'custom_frequency' as const;

export type FrequencyOption = typeof CUSTOM_FREQUENCY | IntervalFrequencyEnum;

export type FrequencyOptions = (FrequencyOption | FrequencyOption[])[];

export type DateOptions = {
  dates: Date[];
  intervalFrequency: ReturnType<typeof IntervalFrequencyFactory.For>;
};
