/* eslint-disable @typescript-eslint/no-var-requires */
const env = require('./environmentVars');

const configHelpers = {
  // Modules
  // eslint-disable-next-line global-require
  stormpath: require('./stormpath'),
  // Methods
  getEnv() {
    return env.get('NODE_ENV');
  },
  getSupportEmailAddress() {
    return 'help@mapistry.com';
  },

  getAssetsRoot() {
    return env.get('ASSETS_ROOT');
  },
  getAppUrl() {
    return env.get('APP_URL');
  },
  getApiUrl() {
    return env.get('API_URL');
  },
  getAdminAppUrl() {
    return env.get('ADMIN_APP_URL');
  },
  getSentryAuthToken() {
    return env.get('SENTRY_AUTH_TOKEN');
  },
};

const currentNodeEnv = configHelpers.getEnv();
configHelpers.isDevelopment =
  !currentNodeEnv ||
  (currentNodeEnv.toLowerCase() !== 'staging' &&
    currentNodeEnv.toLowerCase() !== 'production');
configHelpers.isTest =
  !currentNodeEnv ||
  (currentNodeEnv.toLowerCase() !== 'staging' &&
    currentNodeEnv.toLowerCase() !== 'production' &&
    currentNodeEnv.toLowerCase() !== 'development');

module.exports = configHelpers;
