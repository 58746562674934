import styled from 'styled-components';
import { ContainedButton } from './ContainedButton';

export const ContainedDefaultButton = styled(ContainedButton)`
  text-shadow: ${({ theme }) => `${theme.colors.gray333} 0 0 2px`};
  background-color: ${({ theme }) => theme.colors.green};

  &:hover {
    /* Need to explicitly set color for buttons that are rendered with <a> element,
       e.g. when href prop is passed */
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.darkGreen};
  }

  &:focus {
    /* Need to explicitly set color for buttons that are rendered with <a> element,
       e.g. when href prop is passed */
    color: ${({ theme }) => theme.colors.white};
  }

  &:active {
    background-color: ${({ theme }) => theme.colors.green88};
  }

  &.Mui-disabled {
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.green};
    opacity: 0.3;

    & svg {
      fill: ${({ theme }) => theme.colors.white};
    }
  }
`;
