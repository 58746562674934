/* eslint-disable */
// when editing this file please uncomment the above and fix the linting errors
import React from 'react';
import PropTypes from 'prop-types';

import Button from './buttons/Button';

export default function NoLocationsDefined({ goToMapsURL, message }) {
  return (
    <div className="no-locations-defined-container">
      <div className="no-locations-defined-content">
        <img
          alt=""
          src="https://s3.amazonaws.com/mapistryAssets/discharge-location.svg"
        />
        <div className="helper-text">{message}</div>
        <Button color="secondary" onClick={goToMapsURL}>
          Navigate to Maps
        </Button>
      </div>
    </div>
  );
}

NoLocationsDefined.defaultProps = {
  message: '',
};
NoLocationsDefined.propTypes = {
  goToMapsURL: PropTypes.func.isRequired,
  message: PropTypes.string,
};
