/* eslint-disable */
let errors = require('./errors');
let CurrentUser = Backbone.Model.extend({
  isLoggedIn() {
    this._configurePendo();
    return !!this.id;
  },

  urlRoot() {
    let apiCaller = require('./apiCaller');
    return apiCaller.getUsersRootUrl();
  },

  fetch() {
    throw new errors.GenericError(
      'CurrentUser should not be fetched. It should be set using api setup vars or the result from logging in.',
    );
  },

  destroy() {
    throw new errors.GenericError('CurrentUser should not be destroyed.');
  },

  _configurePendo() {
    if (!!this.id && typeof pendo !== 'undefined') {
      const PendoManager = require('./managers/pendoManager');
      PendoManager.configurePendo(this.toJSON());
    }
  },
});

module.exports = new CurrentUser();
