/* eslint-disable */
// when editing this file please uncomment the above and fix the linting errors
import _get from 'lodash.get';
import { handleActions } from 'redux-actions';
import update from 'immutability-helper';

import {
  FETCH_FORM_SUBMISSIONS_BY_SLUG_REQUEST,
  FETCH_FORM_SUBMISSIONS_BY_SLUG_SUCCESS,
  FETCH_FORM_SUBMISSIONS_BY_SLUG_FAILURE,
} from '../actions';

import { failed, fetching, notFetching } from '.'; // eslint-disable-line import/no-cycle

const reducers = handleActions(
  {
    [FETCH_FORM_SUBMISSIONS_BY_SLUG_REQUEST]: (state) =>
      fetching(state, 'formSubmissionsFetchBySlug'),
    [FETCH_FORM_SUBMISSIONS_BY_SLUG_SUCCESS]: (state, action) =>
      notFetching(
        update(state, {
          formSubmissions: {
            [action.payload.slug]: {
              $set: action.payload,
            },
          },
        }),
        'formSubmissionsFetchBySlug',
      ),
    [FETCH_FORM_SUBMISSIONS_BY_SLUG_FAILURE]: (state, action) =>
      failed(notFetching(state, 'formSubmissionsFetchBySlug')),
  },
  {
    formSubmissions: {},
    isFetching: {},
  },
);

export default reducers;
