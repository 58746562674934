/* eslint-disable */
// when editing this file please uncomment the above and fix the linting errors
import React from 'react';
import ReactSelectCreatable from 'react-select/creatable';
import PropTypes from 'prop-types';

function SelectCreatable(props) {
  const {
    error,
    isClearable,
    isFixed,
    isMulti,
    menuIsOpen,
    onBlur,
    onChange,
    onInputChange,
    options,
    placeholder,
    value,
  } = props;

  const errorClass = error ? ' error' : '';
  const menuOpenOnlyOnFocusClass = menuIsOpen ? ' menuOpenOnlyOnFocus' : '';
  return (
    <ReactSelectCreatable
      className={`mapistry-select--multi mapistry-select${errorClass}${menuOpenOnlyOnFocusClass}`}
      classNamePrefix="mapistry-select"
      isClearable={isClearable}
      isMulti={isMulti}
      menuIsOpen={menuIsOpen}
      menuPosition={isFixed ? 'fixed' : 'absolute'}
      onBlur={onBlur}
      onChange={onChange}
      onInputChange={onInputChange}
      options={options}
      placeholder={placeholder}
      value={value}
    />
  );
}

const optionPropType = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
});

SelectCreatable.propTypes = {
  error: PropTypes.bool,
  isClearable: PropTypes.bool,
  isFixed: PropTypes.bool,
  isMulti: PropTypes.bool,
  menuIsOpen: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onInputChange: PropTypes.func,
  options: PropTypes.arrayOf(optionPropType).isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([
    optionPropType,
    PropTypes.arrayOf(optionPropType),
  ]),
};

SelectCreatable.defaultProps = {
  error: false,
  isClearable: true,
  isFixed: false,
  isMulti: false,
  menuIsOpen: undefined,
  placeholder: null,
  onBlur: null,
  onChange: null,
  onInputChange: null,
  value: null,
};

export default SelectCreatable;
