/* eslint-disable */
import _get from 'lodash.get';
import { isNullOrUndefined } from '../../../../utils';

import BaseValidator from './BaseValidator';

class TasksValidator extends BaseValidator {
  validate() {
    const tasks = _get(this.getValue(), 'tasks', []);

    return this.validateTasks(null, tasks);
  }

  validateTasks(parentTask, tasks) {
    return (tasks || []).reduce((res, task) => {
      const validation = this.validateTask(parentTask, task);
      if (validation) res.push(validation);
      return res;
    }, []);
  }

  validateTask(parentTask, task) {
    const {
      assignees,
      completedDate,
      deleted,
      dueDate,
      endDate,
      frequency,
      id,
      isRecurring,
      markedAsCompletedDate,
      startDate,
      subTasks,
      title,
    } = task;
    if (deleted) {
      return null;
    }
    const error = {
      taskId: id,
      fieldErrors: {
        titleError: this.requiredValidation(title),
        assigneesError: this.assigneesValidation(assignees),
        dueDateError: !isRecurring && this.requiredValidation(dueDate),
        frequencyError:
          isRecurring && !parentTask && this.requiredValidation(frequency),
        startDateError:
          isRecurring && !parentTask && this.requiredValidation(startDate),
        endDateError:
          isRecurring &&
          !parentTask &&
          this.endDateValidation(startDate, endDate),
        completedDateError: this.completedDateValidation(
          completedDate,
          markedAsCompletedDate,
        ),
      },
      subTaskErrors: this.validateTasks(task, subTasks),
    };

    return this.hasErrors(error) ? error : null;
  }

  hasErrors(error) {
    return (
      Object.values(error.fieldErrors).some((v) => v) ||
      (error.subTaskErrors && error.subTaskErrors.length)
    );
  }

  requiredValidation(value) {
    if (
      isNullOrUndefined(value) ||
      (typeof value === 'string' && value.length === 0)
    ) {
      return 'This field must have a value.';
    }

    if (value instanceof Date && Number.isNaN(value.getTime())) {
      return 'Invalid Date';
    }

    return null;
  }

  assigneesValidation(assignees) {
    const nonEmpty = (assignees || []).filter(
      (user) => !!user && !user.deleted,
    );
    if (nonEmpty.length) {
      return null;
    }
    return 'This field must have a value.';
  }

  endDateValidation(startDate, endDate) {
    if (isNullOrUndefined(startDate) || isNullOrUndefined(endDate)) {
      return null;
    }

    const start = new Date(startDate);
    const end = new Date(endDate);

    if (Number.isNaN(end.getTime())) {
      return 'Invalid Date';
    }

    return start >= end ? 'End date must be after start date.' : null;
  }

  completedDateValidation(completedDate, markedAsCompletedDate) {
    if (!markedAsCompletedDate || !!completedDate) {
      return null;
    }
    return 'This field must have a value.';
  }
}

export default TasksValidator;
