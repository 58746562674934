import React from 'react';
import PropTypes from 'prop-types';

const FormErrors = ({ errors, label }) => (
  <div className="form-errors__container">
    <div className="form-errors__header">{label}</div>
    <ul className="form-errors__list">
      {errors.map((e) => (
        <li key={e} className="form-errors__list-item">
          {e}
        </li>
      ))}
    </ul>
  </div>
);

FormErrors.defaultProps = {
  label: 'Errors:',
};

FormErrors.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string).isRequired,
  label: PropTypes.string,
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default FormErrors;
