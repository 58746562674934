import http from '../httpClient';

export interface FetchFormSubmissionParams {
  calendarId: string;
  projectId: string;
  submissionSlug: string;
  templateSlug: string;
}

// TODO replace with actual form submission response DTO when we implement one
export type FetchFormSubmissionResponse = {
  id: string;
  isComplete: boolean;
};

export async function fetchFormSubmission({
  calendarId,
  projectId,
  submissionSlug,
  templateSlug,
}: FetchFormSubmissionParams): Promise<FetchFormSubmissionResponse> {
  const res = await http.get<FetchFormSubmissionResponse>(
    `/api/v2/${projectId}/form-submissions/${submissionSlug}/${templateSlug}/${calendarId}`,
  );
  return res.data;
}
