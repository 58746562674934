import { useCurrentUser } from '@monorepo/shared/hooks/useCurrentUser';
import * as Sentry from '@sentry/browser';
import { ExceptionOriginTag } from 'mapistry-shared';
import { useEffect } from 'react';
import { useVortexSetupVars } from './useVortexSetupVars';

export function setupSentry(
  sentryDSN: string,
  email: string,
  environment?: string,
  apiUrl?: string,
) {
  const userEmail = email || 'nonLoggedInUser';
  Sentry.init({
    dsn: sentryDSN,
    environment,
    ignoreErrors: [
      // This error is coming from password manager browser extensions, not from Mapistry app
      'Non-Error promise rejection captured with value: Object Not Found Matching Id',
    ],
    initialScope: {
      tags: {
        origin: ExceptionOriginTag.WEB,
      },
      // If both user id and email are set, only id will be visible in Sentry issue
      user: { email: userEmail },
    },
    tunnel: `${apiUrl}/api/v2/sentry-tunnel`,
  });

  window.addEventListener('unhandledrejection', (err) => {
    const customEvent = err as unknown as CustomEvent; // a PromiseRejectionEvent by default, but Sentry logs show that it sometimes has CustomEvent properties
    const nonErrorMessage =
      customEvent?.detail?.reason?.message ||
      customEvent?.detail?.reason?.name ||
      JSON.stringify(customEvent?.detail || err);
    const toCapture = err instanceof Error ? err : nonErrorMessage;
    Sentry.captureException(toCapture);
  });
}

export function useSetupSentry() {
  const { setupVars } = useVortexSetupVars();
  const { currentUser } = useCurrentUser();

  useEffect(() => {
    const sentryDSN = setupVars?.sentryDSN;
    const userEmail = currentUser?.email;

    if (sentryDSN && userEmail) {
      setupSentry(
        sentryDSN,
        userEmail,
        process.env.NODE_ENV,
        process.env.API_URL,
      );
    }
  }, [setupVars?.sentryDSN, currentUser?.email]);
}
