import { CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import UserService from '../../services/UserService';
import FieldWrapper from './FieldWrapper';
import Modal from './Modal';
import Button from './buttons/Button';

class SignatureEditor extends React.Component {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/state-in-constructor
  state = {
    saving: false,
    errorMessage: null,
  };

  constructor(props) {
    super(props);

    this.setupSignature = this.setupSignature.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { open } = this.props;

    if (!open && prevProps.open) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ errorMessage: null });
    }
  }

  // this would normally go in componentDidMount but the modal causes problems with refs
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/sort-comp
  setupSignature(canvas) {
    /* eslint-disable no-param-reassign */
    const { user } = this.props;

    if (!canvas) {
      return;
    }
    canvas.width = 700; // drawing is offset when this is in css
    canvas.height = 200;
    // eslint-disable-next-line no-undef
    this.signaturePad = new SignaturePad(canvas);
    if (user && user.signature) {
      this.signaturePad.fromDataURL(user.signature);
    }
  }

  handleClear() {
    this.signaturePad.clear();
  }

  handleSave() {
    const { user, onSave } = this.props;

    if (!this.signaturePad.points.length) {
      this.setState({ errorMessage: 'Cannot sign with a blank signature' });
      return Promise.resolve();
    }

    this.setState({ saving: true, errorMessage: null });
    const updatedUser = {
      id: user.id,
      signature: this.signaturePad.toDataURL(),
    };

    const userService = new UserService();
    return userService
      .update(updatedUser)
      .then((savedUser) => {
        this.setState({ saving: false });
        onSave(savedUser);
      })
      .catch(() => {
        this.setState({
          saving: false,
          errorMessage: 'Failed to save signature, please try again',
        });
      });
  }

  header() {
    return (
      <div>
        <div className="title">Save your signature</div>
        <div className="subtitle">
          Confirm your name, initials and signature
        </div>
      </div>
    );
  }

  userDetail() {
    const { user } = this.props;

    return (
      <div className="user-detail">
        <FieldWrapper label="Full Name" isRequired>
          <span className="field-detail">{user.name}</span>
        </FieldWrapper>
        <FieldWrapper label="Initials" isRequired>
          <span className="field-detail">{this.userInitials(user.name)}</span>
        </FieldWrapper>
      </div>
    );
  }

  userInitials(fullname) {
    const names = fullname.split(' ');
    const firstName = names[0];
    const lastName = names.length > 1 ? names[1] : '';
    return `${firstName.charAt(0)}${lastName.charAt(0)}`;
  }

  signature() {
    return (
      <div className="signature-container">
        <canvas ref={this.setupSignature} />
      </div>
    );
  }

  legal() {
    return (
      <div className="legal">
        <p>
          By clicking Sign, I agree that signature and initials will be the
          electronic representation of my signature and initials for all
          purposes when I (or my agent) use them on documents, including legally
          binding contracts - just the same as a pen-and-paper signature or
          initial.
        </p>
      </div>
    );
  }

  error() {
    const { errorMessage } = this.state;

    return errorMessage ? (
      <div className="error-message">
        <p>{errorMessage}</p>
      </div>
    ) : null;
  }

  actions() {
    const { saveButtonText } = this.props;
    const { saving } = this.state;

    return (
      <div className="actions">
        <Button
          color="secondary"
          onClick={() => this.handleClear()}
          disabled={saving}
        >
          Clear Signature
        </Button>
        <Button onClick={() => this.handleSave()} disabled={saving}>
          {saveButtonText || 'Save Signature'}
        </Button>
        {saving && <CircularProgress />}
      </div>
    );
  }

  render() {
    const { open, onClose } = this.props;

    return (
      <Modal
        className="signature-editor"
        header={this.header()}
        fullWidth={false}
        open={open}
        onClose={onClose}
      >
        {this.userDetail()}
        {this.signature()}
        {this.legal()}
        {this.error()}
        {this.actions()}
      </Modal>
    );
  }
}

const UserType = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  signature: PropTypes.string,
});

SignatureEditor.propTypes = {
  open: PropTypes.bool,
  user: UserType.isRequired,
  saveButtonText: PropTypes.string,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

SignatureEditor.defaultProps = {
  open: false,
  saveButtonText: null,
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default SignatureEditor;
