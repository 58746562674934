/* eslint-disable */
import { localEquivalentOfUTC } from 'mapistry-shared';
import React from 'react';
import { DatePicker, FieldWrapper, TimePicker } from '../../elements';

const DateTimeFields = ({
  /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
  formDraft,
  /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
  formErrors,
  /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
  logProjectStart,
  /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
  onDateChange,
  /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
  onTimeChange,
}) => {
  // logProjectStart will be undefined when isLoading is true
  const minDate = logProjectStart
    ? localEquivalentOfUTC(logProjectStart)
    : undefined;

  return (
    <div className="air-emission-log-modal__date-time-fields">
      <FieldWrapper label="Log date" isRequired>
        <DatePicker
          autoOk
          className="date-field"
          disableFuture
          error={formErrors?.logDate}
          maxDateMessage="Can't create a log in the future"
          minDate={minDate}
          minDateMessage="Date can't be before log calendar start date"
          onChange={onDateChange}
          value={formDraft.logDate}
        />
      </FieldWrapper>
      <FieldWrapper label="Log time" isRequired>
        <TimePicker
          autoOk
          className="time-field"
          error={formErrors?.logTime}
          onChange={onTimeChange}
          value={formDraft.logTime}
        />
      </FieldWrapper>
    </div>
  );
};

export default DateTimeFields;
