// Types which are not exported from flatfile package

import {
  CreateWorkbookConfig,
  EnumPropertyOption,
  SheetConfig,
} from '@flatfile/api/api';
import { initializeFlatfile } from '@flatfile/javascript';
import { FlatfileRecord, RecordHook } from '@flatfile/plugin-record-hook';

export const SUBMIT_ACTION_NAME = 'submitAction' as const;

export type FlatfileOptions = Parameters<typeof initializeFlatfile>[0]; // what Flatfile gets initialized with
export type WorkbookConfig = Omit<
  CreateWorkbookConfig,
  'actions' | 'settings'
> & { sheets: [SheetConfig] }; // the workbook for Flatfile settings - currently we assume that there always be only one sheet

// options for a single select option within flatfile
export type FlatfileSingleSelectOption = EnumPropertyOption & {
  label: string; // marking these as required
  value: string; // marking these as required
};

export type RecordValue = ReturnType<FlatfileRecord['get']> | undefined; // the types record.get() might return
export type UploadRecord = Record<string, RecordValue>; // the shape of data after we pulled it out of Flatfile, before uploading

export type RecordHookCallback = Parameters<typeof RecordHook>[1];

export type SuccessMessage = string;
export type SubmitErrorCallback = (error: unknown) => void;
export type SubmitDataCallback = (
  data: any, // this could be more precise, like T extends UploadRecord, but I'm running into some type problems
  jobId: string,
) => Promise<SuccessMessage | undefined>;

export type FlatfileLoggingContext = Record<
  string,
  string | number | undefined
>;
