import React from 'react';
import { SvgIcon } from '../SvgIcon';

type SvgIconProps = React.ComponentProps<typeof SvgIcon>;

interface HelpIconProps {
  placement?: SvgIconProps['tooltipPlacement'];
  text: string;
}

export const HelpIcon = ({ text, placement = 'top' }: HelpIconProps) => (
  <span className="help-icon-wrapper">
    <SvgIcon
      className="help-icon"
      identifier="help"
      tooltipPlacement={placement}
      tooltipTitle={text}
    />
  </span>
);
