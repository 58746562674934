import styled from 'styled-components';
import { TextButton } from './TextButton';

export const TextDefaultButton = styled(TextButton)`
  color: ${({ theme }) => theme.colors.gray333};

  & svg {
    fill: ${({ theme }) => theme.colors.gray333};
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.grayeee};
  }

  &:not(.Mui-disabled) {
    &:active,
    &:focus-visible {
      background-color: ${({ theme }) => theme.colors.grayddd};
    }
  }
`;
