import { SvgIcon } from '@monorepo/shared/components/icons/SvgIcon';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ConfirmDialog from './ConfirmDialog';
import EditableText from './EditableText';
import LoadingIndicator from './LoadingIndicator';
import IconButton from './buttons/IconButton';

/**
 * @deprecated Use packages/shared/components/Tabs
 */
class Tabs extends Component {
  constructor(props) {
    super(props);
    this.state = { isEditing: false, tabToDelete: null };
  }

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/sort-comp
  createButton() {
    const { onCreateTab } = this.props;
    return onCreateTab ? (
      <IconButton onClick={onCreateTab}>
        <SvgIcon identifier="add" />
      </IconButton>
    ) : null;
  }

  deleteButton(option, isSelected) {
    const { onDeleteTab } = this.props;
    const { isEditing } = this.state;
    return isSelected && onDeleteTab && !isEditing ? (
      <IconButton onClick={() => this.setState({ tabToDelete: option })}>
        <SvgIcon identifier="close" />
      </IconButton>
    ) : null;
  }

  deleteConfirmation() {
    const { onDeleteTab } = this.props;
    const { tabToDelete } = this.state;
    return tabToDelete ? (
      <ConfirmDialog
        danger
        confirmButtonText="Delete"
        description="You will lose the column filters associated with this tab"
        onCancelled={() => this.setState({ tabToDelete: null })}
        onConfirmed={() =>
          this.setState({ tabToDelete: null }, () => onDeleteTab(tabToDelete))
        }
        open
        title={`Are you sure you want to delete ${tabToDelete.label}?`}
      />
    ) : null;
  }

  handleChangeName(option, newLabel) {
    const { onRenameTab } = this.props;
    this.setState({ isEditing: false }, () => onRenameTab(option, newLabel));
  }

  tabText(option, isSelected) {
    const { onRenameTab } = this.props;

    return isSelected && onRenameTab ? (
      <EditableText
        onEdit={(newLabel) => this.handleChangeName(option, newLabel)}
        onEditStart={() => this.setState({ isEditing: true })}
        text={option.label}
      />
    ) : (
      option.label
    );
  }

  tabs() {
    const { onSelectTab, options, selected } = this.props;

    return options.map((option) => {
      const isSelected = selected === option;
      return (
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        <div
          key={option.id || option.label}
          className={`tabs-item${isSelected ? ' selected' : ''}`}
          onClick={() => onSelectTab(option)}
        >
          {this.tabText(option, isSelected)}
          {this.deleteButton(option, isSelected)}
        </div>
      );
    });
  }

  render() {
    const { isLoading } = this.props;
    return (
      <>
        {this.deleteConfirmation()}
        <div className="mapistry-tabs-wrapper">
          {this.tabs()}
          {isLoading && <LoadingIndicator isAbsolute={false} size={22} />}
          {this.createButton()}
        </div>
      </>
    );
  }
}

const OptionType = PropTypes.shape({
  label: PropTypes.string,
  path: PropTypes.string,
});

Tabs.propTypes = {
  isLoading: PropTypes.bool,
  onCreateTab: PropTypes.func,
  onDeleteTab: PropTypes.func,
  onSelectTab: PropTypes.func.isRequired,
  onRenameTab: PropTypes.func,
  options: PropTypes.arrayOf(OptionType),
  selected: OptionType,
};

Tabs.defaultProps = {
  isLoading: false,
  onCreateTab: null,
  onDeleteTab: null,
  onRenameTab: null,
  options: [],
  selected: null,
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default Tabs;
