import http from '../httpClient';
import { LogProject } from '../types';

export interface FetchLogProjectProps {
  id?: string;
}

export type FetchLogProjectResponse = LogProject;

export async function fetchLogProject({
  id,
}: FetchLogProjectProps): Promise<FetchLogProjectResponse> {
  if (!id) {
    return Promise.resolve({} as FetchLogProjectResponse);
  }
  const res = await http.get<FetchLogProjectResponse>(
    `/api/v2/log-projects/${id}`,
  );
  return res.data;
}

type UpdateLogProjectProps = {
  id: string;
  name: string;
  informationalText: string;
  intervalStartMonth?: number;
};

export async function updateLogProject(
  props: UpdateLogProjectProps,
): Promise<FetchLogProjectResponse> {
  const { id, ...rest } = props;
  const res = await http.put<FetchLogProjectResponse>(
    `/api/v2/log-projects/${id}`,
    rest,
  );
  return res.data;
}
