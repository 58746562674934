import qs from 'qs';

/* @ts-expect-error - TODO: Fix this the next time the file is edited. */
const alphabeticalSort = (a, b) => {
  if (a === b) return 0;
  return a > b ? 1 : -1;
};

/* @ts-expect-error - TODO: Fix this the next time the file is edited. */
export const createKey = (params) =>
  qs.stringify(params, { sort: alphabeticalSort });
