import { createMuiTheme } from '@material-ui/core/styles';

const mainPalette = {
  primary: {
    light: '#75e15c',
    main: '#31b14a',
    dark: '#007d00',
    contrastText: '#FBFBFB',
  },
  secondary: {
    light: '#ffffff',
    main: '#FDFDFD',
    dark: '#EDEDED',
    contrastText: '#123D4B',
  },
};

const themeOptions = {
  palette: mainPalette,
  overrides: {
    MuiInput: {
      underline: {
        '&:before': {
          borderBottom: `2px solid #bbbbbb`,
          opacity: 0.72,
        },
        '&:after': {
          borderBottom: `2px solid #bbbbbb`,
        },
        '&:hover': {
          '&:before': {
            borderBottom: `2px solid #bbbbbb !important`,
          },
        },
      },
    },
  },
};

export const InspectionFormTheme = createMuiTheme(themeOptions);
