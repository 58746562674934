import { FetchFormTemplateParams } from '@monorepo/formTemplates/src/components/EditFormTemplatePage/types';
import {
  FormTemplateFieldOptions,
  FormTemplateResponse,
  NullableString,
} from 'mapistry-shared';
import http from '../httpClient';

// will move to mapistry-shared
export type UpdateFormFieldsOrder = {
  fieldGroupId: string;
  fieldOrder: string[];
};

// will move to mapistry-shared
type UpdateFormTemplateRequest = {
  sectionOrder?: string[];
  fieldOrder?: UpdateFormFieldsOrder[];
};

// will move to mapistry-shared
type UpdateFormTemplateGroupRequest = {
  name: string;
};

interface UpdateFormTemplateParams extends UpdateFormTemplateRequest {
  id: string;
  organizationId: string;
}

interface UpdateFormTemplateGroupParams extends UpdateFormTemplateGroupRequest {
  id: string;
  organizationId: string;
  groupId: string;
}

interface UpdateFormTemplateFieldParams extends UpdateFormTemplateGroupParams {
  fieldId: string;
  helpText: NullableString;
  isRequired: boolean;
  nameSubtext: NullableString;
  defaultOption: NullableString;
  options: FormTemplateFieldOptions;
  isOptionsAlphabetized: boolean;
}

export async function fetchFormTemplate({
  slug,
  organizationId,
}: FetchFormTemplateParams) {
  const res = await http.get<FormTemplateResponse>(
    `/api/v2/organizations/${organizationId}/form-templates/${slug}`,
  );
  return res.data;
}

export async function updateFormTemplate({
  id,
  organizationId,
  sectionOrder,
  fieldOrder,
}: UpdateFormTemplateParams): Promise<FormTemplateResponse> {
  const res = await http.put<FormTemplateResponse>(
    `/api/v2/organizations/${organizationId}/form-templates/${id}`,
    { sectionOrder, fieldOrder },
  );
  return res.data;
}

export async function updateFormTemplateGroup({
  id,
  organizationId,
  name,
  groupId,
}: UpdateFormTemplateGroupParams): Promise<FormTemplateResponse> {
  const res = await http.put<FormTemplateResponse>(
    `/api/v2/organizations/${organizationId}/form-templates/${id}/form-groups/${groupId}`,
    { name },
  );
  return res.data;
}

export async function updateFormTemplateField({
  id,
  organizationId,
  name,
  groupId,
  fieldId,
  helpText,
  isRequired,
  nameSubtext,
  defaultOption,
  options,
  isOptionsAlphabetized,
}: UpdateFormTemplateFieldParams): Promise<FormTemplateResponse> {
  const res = await http.put<FormTemplateResponse>(
    `/api/v2/organizations/${organizationId}/form-templates/${id}/form-groups/${groupId}/form-fields/${fieldId}`,
    {
      name,
      isRequired,
      helpText,
      nameSubtext,
      defaultOption,
      options,
      isOptionsAlphabetized,
    },
  );
  return res.data;
}
