import http from '../httpClient';
import { Factor } from '../types';

export interface FetchFactorsProps {
  logProjectId?: string;
  projectId?: string;
}

interface FetchFactorsResponse {
  logProjectId: string;
  records: Factor[];
}

export async function fetchFactors({
  logProjectId,
  projectId,
}: FetchFactorsProps): Promise<Factor[]> {
  const res = await http.get<FetchFactorsResponse>(
    `/api/v2/${projectId}/generic-logs/${logProjectId}/emission-factors`,
  );
  return res.data?.records;
}

export type UpdateFactorResponse = Factor;

export async function updateFactor(factor: Factor) {
  const res = await http.put<UpdateFactorResponse>(
    `/api/v2/${factor.projectId}/generic-logs/${factor.logProjectId}/emission-factors/${factor.id}`,
    factor,
  );
  return res.data;
}
