import cn from 'classnames';
import React, { useMemo } from 'react';

import AnimatedComponent, { ANIMATION_TYPES } from '../../AnimatedComponent';
import { Card } from '../Card';

type ChartCardProps = {
  hasChartData: boolean;
  isLoading: boolean;
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/ban-types
  menuOptions: {}[];
  renderGraphCard: () => NonNullable<React.ReactNode>;
  renderLoadingCard: () => NonNullable<React.ReactNode>;
  renderSplashCard: () => NonNullable<React.ReactNode>;
  title: string;
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function ChartCard(props: ChartCardProps) {
  const {
    hasChartData,
    isLoading,
    menuOptions,
    renderSplashCard,
    renderLoadingCard,
    renderGraphCard,
    title,
    ...rest
  } = props;

  const showSplashCard = !hasChartData && !isLoading;
  const showLoadingCard = !hasChartData && isLoading;

  const content = useMemo(() => {
    if (showSplashCard) {
      return renderSplashCard();
    }
    if (showLoadingCard) {
      return renderLoadingCard();
    }
    return renderGraphCard();
  }, [
    showSplashCard,
    showLoadingCard,
    renderSplashCard,
    renderLoadingCard,
    renderGraphCard,
  ]);

  return (
    <section className="min-w-400 margin-16 dashboard-react-card">
      {/* @ts-expect-error - TODO: Fix this the next time the file is edited. */}
      <Card {...rest} title={title} menuOptions={menuOptions}>
        <AnimatedComponent type={ANIMATION_TYPES.FADE_IN_OUT}>
          <div
            className={cn({
              'chart-container': !showSplashCard,
            })}
          >
            {content}
          </div>
        </AnimatedComponent>
      </Card>
    </section>
  );
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default ChartCard;
