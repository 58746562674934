import api from '@flatfile/api';
import { Records, Sheet } from '@flatfile/api/api';
import { WorkbookConfig } from '../types/flatfileTypes';

export type FlatfileApiRecord = Records[0];

export async function announceProgress(
  jobId: string,
  progressPercentage: number,
  infoText: string,
) {
  await api.jobs.ack(jobId, {
    info: infoText,
    progress: progressPercentage,
  });
}

export async function announceFinish(
  jobId: string,
  heading: string,
  message: string,
) {
  await api.jobs.complete(jobId, {
    outcome: {
      acknowledge: true,
      buttonText: `Close`,
      heading,
      message,
      next: {
        type: 'wait',
      },
    },
  });
}

export async function announceError(
  jobId: string,
  heading: string,
  message: string,
) {
  await api.jobs.fail(jobId, {
    outcome: {
      acknowledge: true,
      buttonText: 'Go back to your data.',
      heading,
      message,
    },
  });
}

export async function fetchRecordsForFirstSheetFromFlatfile({
  jobId,
  workbookId,
}: {
  jobId: string;
  workbookId: string;
}): Promise<{ sheetId: string; sheet: Sheet; records: Records }> {
  const { data: workbookSheets } = await api.sheets.list({ workbookId });
  // for now, we only ever handle one sheet
  const [sheet] = workbookSheets;
  if (!sheet) {
    await announceError(
      jobId,
      'Hmmm, something is not right',
      "We can't find the sheets in your workbook, please contact support.",
    );
    throw new Error('Oops! No sheets in your Workbook for some reason');
  }

  const {
    data: { records },
  } = await api.records.get(sheet.id);

  return { sheetId: sheet.id, sheet, records };
}

/**
 * When an enum has a lot of options, we ran into issues where the payload
 * for the `initializeFlatfile` call is too large. Submitting the workbook options
 * via api request after init avoids that limit.
 * The suggestion is to use reference fields, but those skip the matching step for file uploads.
 */
export async function uploadWorkbookAvoidingSizeLimitForEnumOptions(
  spaceId: string,
  workbookWithSharedSettings: WorkbookConfig,
) {
  const {
    data: [workbook],
  } = await api.workbooks.list({ spaceId });

  if (!workbook || !workbook.sheets || !workbook.sheets[0]) {
    throw new Error(
      'Flatfile workbook needs to be submitted before it can be changed',
    );
  }

  await api.workbooks.update(workbook.id, {
    sheets: [
      {
        ...workbook.sheets[0],
        config: {
          ...workbook.sheets[0].config,
          fields: workbookWithSharedSettings.sheets[0].fields,
        },
      },
    ],
  });
}

export async function updateRecords(
  sheetId: string,
  records: FlatfileApiRecord[],
) {
  await api.records.update(sheetId, records);
}
