/* eslint-disable */
// when editing this file please uncomment the above and fix the linting errors
import React from 'react';
import { Link } from 'react-router-dom';

import AnimatedComponent from './AnimatedComponent';
import { ANIMATION_TYPES } from './AnimatedComponent';

/**
 * @deprecated Use packages/shared/components/Page
 */
const Page = (props) => {
  const { children } = props;
  return (
    <AnimatedComponent type={ANIMATION_TYPES.FADE_IN_OUT}>
      <div className="page-wrapper">{children}</div>
    </AnimatedComponent>
  );
};

export default Page;
