import { LinearProgress, Paper } from '@material-ui/core';
import { VisuallyHidden } from '@monorepo/shared/componentsV2/VisuallyHidden';
import DownloadIcon from '@svg/download.svg';
import EditIcon from '@svg/edit.svg';
import TrashIcon from '@svg/trash.svg';
import PropTypes from 'prop-types';
import React from 'react';
import ConfirmDialog from './ConfirmDialog';
import Menu from './Menu';
import TextField from './TextField';
import Button from './buttons/Button';

export class Photo extends React.Component {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/state-in-constructor
  state = {
    editName: false,
    editDescription: false,
    showConfirmation: false,
  };

  handleEditPhoto() {
    const { value, onEdit } = this.props;

    onEdit(value);
  }

  handleDeletePhoto() {
    this.setState({ showConfirmation: true });
  }

  handleDeleteConfirmation() {
    const { value, onRemove } = this.props;

    this.setState({ showConfirmation: false }, onRemove(value));
  }

  handleEnableEditName() {
    this.setState({ editName: true });
  }

  handleEnableEditDescription() {
    this.setState({ editDescription: true });
  }

  handleBlurName() {
    const { value, onSave } = this.props;

    this.setState({ editName: false });
    onSave(value);
  }

  handleBlurDescription() {
    const { value, onSave } = this.props;

    this.setState({ editDescription: false });
    onSave(value);
  }

  handleChangeName(event) {
    const { value, onChange } = this.props;

    const updatedValue = { ...value, name: event.target.value };
    onChange(updatedValue);
  }

  handleChangeDescription(event) {
    const { value, onChange } = this.props;

    const updatedValue = { ...value, description: event.target.value };
    onChange(updatedValue);
  }

  loadingImage() {
    const { value } = this.props;

    return (
      <div className="photo-image-container">
        {`Loading... ${value.loadedSize} of ${value.totalSize}`}
      </div>
    );
  }

  loadedImage() {
    const { disabled, value } = this.props;

    const source = value && value.src;
    const overlay =
      value && value.processing
        ? this.waitingOverlay()
        : this.editImageOverlay();

    return (
      <div className="photo-image-container">
        {!disabled && overlay}
        <img className="photo-image" src={source} alt={value && value.name} />
      </div>
    );
  }

  waitingOverlay() {
    return (
      <div className="photo-waiting-overlay">
        <LinearProgress style={{ width: '100%' }} />
      </div>
    );
  }

  editImageOverlay() {
    return (
      <div className="photo-edit-overlay transition">
        <Menu className="photo-menu" options={this.menuOptions()} />
        <Button
          className="photo-edit-button"
          onClick={() => this.handleEditPhoto()}
          color="secondary"
        >
          Edit Image
        </Button>
      </div>
    );
  }

  menuOptions() {
    const { value } = this.props;
    const options = [
      {
        label: 'Edit',
        icon: <EditIcon className="m-icon" />,
        onClick: () => this.handleEditPhoto(),
      },
      {
        label: 'Download',
        icon: <DownloadIcon className="m-icon" />,
        onClick: () => window.open(value.src),
      },
    ];
    if (value.permissions.hasDeletePermission) {
      options.push({
        label: 'Delete',
        icon: <TrashIcon className="m-icon" />,
        onClick: () => this.handleDeletePhoto(),
        danger: true,
      });
    }
    return options;
  }

  imageDetails() {
    const { disabled, value } = this.props;
    const descriptionElement = value.description
      ? this.descriptionDetail()
      : null;
    return (
      <div className="photo-details">
        <div className="photo-detail-item">
          {this.fileNameElement()}
          {!disabled && (
            <button
              type="button"
              className="icon-button"
              onClick={() => this.handleEnableEditName()}
            >
              <EditIcon className="m-icon edit-icon transition" />
              <VisuallyHidden>Edit Image Name</VisuallyHidden>
            </button>
          )}
        </div>
        {descriptionElement}
      </div>
    );
  }

  descriptionDetail() {
    const { disabled } = this.props;

    return (
      <div className="photo-detail-item">
        {this.descriptionElement()}
        {!disabled && (
          <button
            type="button"
            className="icon-button"
            onClick={() => this.handleEnableEditDescription()}
          >
            <EditIcon className="m-icon edit-icon transition" />
            <VisuallyHidden>Edit Image Description</VisuallyHidden>
          </button>
        )}
      </div>
    );
  }

  fileNameElement() {
    const { value } = this.props;
    const { editName } = this.state;

    return editName ? (
      <TextField
        value={value.name}
        onBlur={() => this.handleBlurName()}
        autoFocus
        onChange={(e) => this.handleChangeName(e)}
      />
    ) : (
      <span className="photo-filename">{value.name}</span>
    );
  }

  descriptionElement() {
    const { value } = this.props;
    const { editDescription } = this.state;

    return editDescription ? (
      <TextField
        value={value.description}
        multiline
        onBlur={() => this.handleBlurDescription()}
        autoFocus
        onChange={(e) => this.handleChangeDescription(e)}
      />
    ) : (
      <span className="photo-description">{value.description}</span>
    );
  }

  render() {
    const { value, error } = this.props;
    const { showConfirmation } = this.state;

    const imageContent =
      value.loadedSize < value.totalSize
        ? this.loadingImage()
        : this.loadedImage();
    const errorClass = error ? ' error' : '';

    return (
      <>
        <Paper className={`photo-container${errorClass}`} elevation={1}>
          {imageContent}
          {this.imageDetails()}
        </Paper>
        <ConfirmDialog
          open={showConfirmation}
          title="Are you sure you want to delete this photo?"
          onConfirmed={() => this.handleDeleteConfirmation()}
          onCancelled={() => this.setState({ showConfirmation: false })}
          confirmButtonText="Delete"
          danger
        />
      </>
    );
  }
}

const PhotoType = PropTypes.shape({
  fileId: PropTypes.string,
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/forbid-prop-types
  file: PropTypes.object,
  permissions: PropTypes.shape({
    hasDeletePermission: PropTypes.bool,
  }),
  src: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  totalSize: PropTypes.number,
  loadedSize: PropTypes.number,
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/forbid-prop-types
  fileReader: PropTypes.object,
  processing: PropTypes.bool,
});

Photo.propTypes = {
  disabled: PropTypes.bool,
  value: PhotoType.isRequired,
  error: PropTypes.bool,
  onEdit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

Photo.defaultProps = {
  disabled: false,
  error: false,
};
