import { css } from 'styled-components';

// this double function seems to allow passing the prop
// from a component like <Link $outlineOffset ...
// as well as from within another style with ${focusOutline({ outlineOffset: true })}
interface FocusOutlineProps {
  $outlineOffset?: string;
}

export const focusOutline = (
  { $outlineOffset }: FocusOutlineProps = { $outlineOffset: '0' },
) => css`
  outline: 0.125rem auto ${({ theme }) => theme.colors.gray333};
  outline-offset: ${$outlineOffset};
`;
