import { CircularProgress } from '@material-ui/core';
import cn from 'classnames';
import React from 'react';
import { VisuallyHidden } from '../../componentsV2/VisuallyHidden';

export interface LoadingProps {
  size?: 'small' | 'medium' | 'large';
  className?: string;
}

const sizes = {
  small: 20,
  medium: 40,
  large: 80,
};

export const Loading = (props: LoadingProps) => {
  const { className, size = 'large' } = props;

  return (
    <div className={cn('LoadingComponent', className)}>
      <CircularProgress
        className="mapistry-loading-indicator"
        classes={{ colorPrimary: 'mapistry-green' }}
        size={sizes[size] || sizes.large}
      />
      <VisuallyHidden>Loading...</VisuallyHidden>
    </div>
  );
};
