import { Chip, InputLabel } from '@material-ui/core';
import React, { useState } from 'react';
import { useField } from 'react-final-form';
import { IconButton } from '../../buttons/IconButton';
import { SvgIcon } from '../../icons/SvgIcon';
import { TextInput } from '../../TextInput';

interface OptionCreationFieldProps {
  label?: string;
  name: string;
  placeholder?: string;
}

export const OptionCreationField = <
  FieldType extends { label: string; value: unknown }[],
>({
  label,
  name,
  placeholder,
}: OptionCreationFieldProps) => {
  const { input } = useField<FieldType>(name);
  const [value, setValue] = useState('');

  const onAdd = () => {
    if (!value || input.value.find((option) => option.value === value)) {
      return;
    }
    input.onChange([...input.value, { label: value, value }]);
    setValue('');
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setValue(e.target.value);

  const onDelete = (v: unknown) => {
    const updated = input.value.filter((option) => option.value !== v);
    input.onChange(updated);
  };

  const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onAdd();
    }
  };

  return (
    <div className="option-creation-field">
      {label && (
        <InputLabel htmlFor={`option-creation-field-${name}`}>
          {label}
        </InputLabel>
      )}
      <TextInput
        endAdornment={
          <IconButton aria-label="add" edge="end" onClick={onAdd}>
            <SvgIcon identifier="add" />
          </IconButton>
        }
        onChange={onChange}
        onKeyPress={onKeyPress}
        placeholder={placeholder || 'Create option'}
        value={value}
      />
      <ul className="option-creation-field__container">
        {input.value?.map((option) => (
          <li key={option.label}>
            <Chip
              label={option.label}
              onDelete={() => onDelete(option.value)}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};
