export enum CustomLogType {
  custom = 'custom',
  stormwater = 'stormwater',
  water = 'water',
}

export type LogProject = {
  id: string;
  projectId: string;
  logId: string;
  name: string;
  informationalText: string;
  intervalStartMonth: number;
  startDate: Date;
  type: CustomLogType;
};
