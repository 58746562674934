import qs from 'querystring';
import { createAction } from 'redux-actions';
import { CALL_API } from '../middleware/api';
import * as HTTP from '../utils/HTTP';

import {
  CLEAR_CALENDAR_FREQUENCIES,
  CREATE_CALENDAR_ASSIGNEES_FAILURE,
  CREATE_CALENDAR_ASSIGNEES_REQUEST,
  CREATE_CALENDAR_ASSIGNEES_SUCCESS,
  CREATE_CALENDAR_FREQUENCY_FAILURE,
  CREATE_CALENDAR_FREQUENCY_REQUEST,
  CREATE_CALENDAR_FREQUENCY_SUCCESS,
  DELETE_CALENDAR_FREQUENCY_FAILURE,
  DELETE_CALENDAR_FREQUENCY_REQUEST,
  DELETE_CALENDAR_FREQUENCY_SUCCESS,
  DOWNLOAD_CALENDAR_ATTACHMENTS_FAILURE,
  DOWNLOAD_CALENDAR_ATTACHMENTS_REQUEST,
  DOWNLOAD_CALENDAR_ATTACHMENTS_SUCCESS,
  FETCH_CALENDAR_ATTACHMENT_COUNT_FAILURE,
  FETCH_CALENDAR_ATTACHMENT_COUNT_REQUEST,
  FETCH_CALENDAR_ATTACHMENT_COUNT_SUCCESS,
  FETCH_CALENDAR_EVENTS_FAILURE,
  FETCH_CALENDAR_EVENTS_REQUEST,
  FETCH_CALENDAR_EVENTS_SUCCESS,
  FETCH_CALENDAR_FREQUENCIES_FAILURE,
  FETCH_CALENDAR_FREQUENCIES_REQUEST,
  FETCH_CALENDAR_FREQUENCIES_SUCCESS,
  FETCH_CALENDAR_MICRO_INTERVALS_FAILURE,
  FETCH_CALENDAR_MICRO_INTERVALS_REQUEST,
  FETCH_CALENDAR_MICRO_INTERVALS_SUCCESS,
  FETCH_CALENDAR_PARENT_INTERVALS_FAILURE,
  FETCH_CALENDAR_PARENT_INTERVALS_REQUEST,
  FETCH_CALENDAR_PARENT_INTERVALS_SUCCESS,
  FETCH_CALENDAR_SUMMARY_FAILURE,
  FETCH_CALENDAR_SUMMARY_REQUEST,
  FETCH_CALENDAR_SUMMARY_SUCCESS,
  FETCH_INSPECTION_CALENDAR_STENCILS_FAILURE,
  FETCH_INSPECTION_CALENDAR_STENCILS_REQUEST,
  FETCH_INSPECTION_CALENDAR_STENCILS_SUCCESS,
  FETCH_SAMPLING_CALENDAR_EVENTS_FAILURE,
  FETCH_SAMPLING_CALENDAR_EVENTS_REQUEST,
  FETCH_SAMPLING_CALENDAR_EVENTS_SUCCESS,
  REMOVE_CALENDAR_ASSIGNEES_FAILURE,
  REMOVE_CALENDAR_ASSIGNEES_REQUEST,
  REMOVE_CALENDAR_ASSIGNEES_SUCCESS,
  RESET_CALENDAR_ERROR_STATE,
} from '.';

const fetchSamplingCalendarEventsRequest = createAction(
  FETCH_SAMPLING_CALENDAR_EVENTS_REQUEST,
);
const fetchSamplingCalendarEventsSuccess = createAction(
  FETCH_SAMPLING_CALENDAR_EVENTS_SUCCESS,
);
const fetchSamplingCalendarEventsFailure = createAction(
  FETCH_SAMPLING_CALENDAR_EVENTS_FAILURE,
);

const fetchCalendarEventsRequest = createAction(FETCH_CALENDAR_EVENTS_REQUEST);
const fetchCalendarEventsSuccess = createAction(FETCH_CALENDAR_EVENTS_SUCCESS);
const fetchCalendarEventsFailure = createAction(FETCH_CALENDAR_EVENTS_FAILURE);

const fetchInspectionCalendarStencilsRequest = createAction(
  FETCH_INSPECTION_CALENDAR_STENCILS_REQUEST,
);
const fetchInspectionCalendarStencilsSuccess = createAction(
  FETCH_INSPECTION_CALENDAR_STENCILS_SUCCESS,
);
const fetchInspectionCalendarStencilsFailure = createAction(
  FETCH_INSPECTION_CALENDAR_STENCILS_FAILURE,
);

const fetchCalendarAttachmentCountRequest = createAction(
  FETCH_CALENDAR_ATTACHMENT_COUNT_REQUEST,
);
const fetchCalendarAttachmentCountSuccess = createAction(
  FETCH_CALENDAR_ATTACHMENT_COUNT_SUCCESS,
);
const fetchCalendarAttachmentCountFailure = createAction(
  FETCH_CALENDAR_ATTACHMENT_COUNT_FAILURE,
);

const fetchCalendarSummaryRequest = createAction(
  FETCH_CALENDAR_SUMMARY_REQUEST,
);
const fetchCalendarSummarySuccess = createAction(
  FETCH_CALENDAR_SUMMARY_SUCCESS,
);
const fetchCalendarSummaryFailure = createAction(
  FETCH_CALENDAR_SUMMARY_FAILURE,
);

const downloadCalendarAttachmentsRequest = createAction(
  DOWNLOAD_CALENDAR_ATTACHMENTS_REQUEST,
);
const downloadCalendarAttachmentsSuccess = createAction(
  DOWNLOAD_CALENDAR_ATTACHMENTS_SUCCESS,
);
const downloadCalendarAttachmentsFailure = createAction(
  DOWNLOAD_CALENDAR_ATTACHMENTS_FAILURE,
);

const createCalendarFrequencyRequest = createAction(
  CREATE_CALENDAR_FREQUENCY_REQUEST,
);
const createCalendarFrequencySuccess = createAction(
  CREATE_CALENDAR_FREQUENCY_SUCCESS,
);
const createCalendarFrequencyFailure = createAction(
  CREATE_CALENDAR_FREQUENCY_FAILURE,
);

const deleteCalendarFrequencyRequest = createAction(
  DELETE_CALENDAR_FREQUENCY_REQUEST,
);
const deleteCalendarFrequencySuccess = createAction(
  DELETE_CALENDAR_FREQUENCY_SUCCESS,
);
const deleteCalendarFrequencyFailure = createAction(
  DELETE_CALENDAR_FREQUENCY_FAILURE,
);

const fetchCalendarFrequenciesRequest = createAction(
  FETCH_CALENDAR_FREQUENCIES_REQUEST,
);
const fetchCalendarFrequenciesSuccess = createAction(
  FETCH_CALENDAR_FREQUENCIES_SUCCESS,
);
const fetchCalendarFrequenciesFailure = createAction(
  FETCH_CALENDAR_FREQUENCIES_FAILURE,
);

const fetchMicroIntervalsRequest = createAction(
  FETCH_CALENDAR_MICRO_INTERVALS_REQUEST,
);
const fetchMicroIntervalsSuccess = createAction(
  FETCH_CALENDAR_MICRO_INTERVALS_SUCCESS,
);
const fetchMicroIntervalsFailure = createAction(
  FETCH_CALENDAR_MICRO_INTERVALS_FAILURE,
);

const fetchParentIntervalsRequest = createAction(
  FETCH_CALENDAR_PARENT_INTERVALS_REQUEST,
);
const fetchParentIntervalsSuccess = createAction(
  FETCH_CALENDAR_PARENT_INTERVALS_SUCCESS,
);
const fetchParentIntervalsFailure = createAction(
  FETCH_CALENDAR_PARENT_INTERVALS_FAILURE,
);

const createCalendarAssigneesRequest = createAction(
  CREATE_CALENDAR_ASSIGNEES_REQUEST,
);
const createCalendarAssigneesSuccess = createAction(
  CREATE_CALENDAR_ASSIGNEES_SUCCESS,
);
const createCalendarAssigneesFailure = createAction(
  CREATE_CALENDAR_ASSIGNEES_FAILURE,
);

const removeCalendarAssigneesRequest = createAction(
  REMOVE_CALENDAR_ASSIGNEES_REQUEST,
);
const removeCalendarAssigneesSuccess = createAction(
  REMOVE_CALENDAR_ASSIGNEES_SUCCESS,
);
const removeCalendarAssigneesFailure = createAction(
  REMOVE_CALENDAR_ASSIGNEES_FAILURE,
);

export const resetCalendarErrorStateAction = createAction(
  RESET_CALENDAR_ERROR_STATE,
);

export function fetchSamplingCalendarEvents({
  calendarName,
  end,
  parameterSlug,
  projectId,
  start,
}) {
  const withParameter = parameterSlug ? `&parameters=${parameterSlug}` : '';
  return {
    [CALL_API]: {
      endpoint: `/api/v2/${projectId}/calendar-events/${calendarName}?startDate=${start}&endDate=${end}${withParameter}`,
      lifecycleActions: [
        fetchSamplingCalendarEventsRequest,
        fetchSamplingCalendarEventsSuccess,
        fetchSamplingCalendarEventsFailure,
      ],
      verb: HTTP.Types.GET,
    },
    // If no parameter was passed, assuming "ph", since it's the only param with special treatment.
    // Ugly, but old Wastewater Suite will be soon obsolete and
    // this is the easiest way to make our Redux store more organized without major refactor.
    parameterSlug: parameterSlug || 'ph',
  };
}

export function fetchCalendarEvents(options) {
  const { calendarName, date, parameters, projectId, widgetId } = options;
  const withParameters = parameters ? `&parameters=${parameters}` : '';
  const withWidgetId = widgetId ? `&widgetId=${widgetId}` : '';
  return {
    [CALL_API]: {
      endpoint: `/api/v2/${projectId}/calendar-events/${calendarName}?date=${date.toISOString()}${withParameters}${withWidgetId}`,
      lifecycleActions: [
        fetchCalendarEventsRequest,
        fetchCalendarEventsSuccess,
        fetchCalendarEventsFailure,
      ],
      verb: HTTP.Types.GET,
    },
  };
}

export function fetchInspectionCalendarStencils(options) {
  const { calendarName, projectId } = options;
  return {
    [CALL_API]: {
      endpoint: `/api/v2/${projectId}/calendar-events/${calendarName}/stencils`,
      lifecycleActions: [
        fetchInspectionCalendarStencilsRequest,
        fetchInspectionCalendarStencilsSuccess,
        fetchInspectionCalendarStencilsFailure,
      ],
      verb: HTTP.Types.GET,
    },
  };
}

export function fetchCalendarAttachmentCountAction(options) {
  const { projectId, calendarId, startDate, endDate } = options;
  return {
    [CALL_API]: {
      endpoint: `/api/v2/${projectId}/calendar-events/${calendarId}/attachments/count?startDate=${startDate}&endDate=${endDate}`,
      lifecycleActions: [
        fetchCalendarAttachmentCountRequest,
        fetchCalendarAttachmentCountSuccess,
        fetchCalendarAttachmentCountFailure,
      ],
      verb: HTTP.Types.GET,
    },
  };
}

export function fetchCalendarSummaryAction(options) {
  const { projectId, calendarName, date } = options;
  return {
    [CALL_API]: {
      endpoint: `/api/v2/${projectId}/calendar-events/${calendarName}/summary?date=${date.toISOString()}`,
      lifecycleActions: [
        fetchCalendarSummaryRequest,
        fetchCalendarSummarySuccess,
        fetchCalendarSummaryFailure,
      ],
      verb: HTTP.Types.GET,
    },
  };
}

function getPdfOptionsQueryParams(pdfOptions) {
  const queryStringOptions = {
    includeTasks: pdfOptions.includeTasksOption,
    includeTaskAttachments: !!pdfOptions.includeTaskAttachments,
    includePhotosAndAttachments: pdfOptions.includePhotosAndAttachmentsOption,
    includeRegularFormContent: !!pdfOptions.includeRegularFormContent,
  };

  return qs.stringify(queryStringOptions);
}

export function emailCalendarAttachmentsAction(options) {
  const { projectId, calendarId, startDate, endDate, pdfOptions } = options;
  const dateQueryParams = `startDate=${startDate}&endDate=${endDate}`;
  const pdfOptionsQueryParams = getPdfOptionsQueryParams(pdfOptions);
  const queryParams = `?${dateQueryParams}&${pdfOptionsQueryParams}`;

  return {
    [CALL_API]: {
      endpoint: `/api/v2/${projectId}/calendar-events/${calendarId}/attachments/email${queryParams}`,
      lifecycleActions: [
        downloadCalendarAttachmentsRequest,
        downloadCalendarAttachmentsSuccess,
        downloadCalendarAttachmentsFailure,
      ],
      verb: HTTP.Types.GET,
    },
  };
}

export function fetchCalendarFrequenciesAction(projectId, calendarName) {
  return {
    [CALL_API]: {
      endpoint: `/api/v2/${projectId}/calendar-events/${calendarName}/frequencies`,
      lifecycleActions: [
        fetchCalendarFrequenciesRequest,
        fetchCalendarFrequenciesSuccess,
        fetchCalendarFrequenciesFailure,
      ],
      verb: HTTP.Types.GET,
    },
  };
}

export const clearCalendarFrequenciesAction = createAction(
  CLEAR_CALENDAR_FREQUENCIES,
);

export function createCalendarFrequencyAction(
  projectId,
  calendarName,
  frequency,
  startDate,
  customFrequency,
) {
  return {
    [CALL_API]: {
      data: {
        frequency,
        startDate,
        customFrequency,
      },
      endpoint: `/api/v2/${projectId}/calendar-events/${calendarName}/frequencies`,
      lifecycleActions: [
        createCalendarFrequencyRequest,
        createCalendarFrequencySuccess,
        createCalendarFrequencyFailure,
      ],
      verb: HTTP.Types.POST,
    },
  };
}

export function deleteCalendarFrequencyAction(
  projectId,
  calendarName,
  frequencyId,
) {
  return {
    [CALL_API]: {
      endpoint: `/api/v2/${projectId}/calendar-events/${calendarName}/frequencies/${frequencyId}`,
      lifecycleActions: [
        deleteCalendarFrequencyRequest,
        deleteCalendarFrequencySuccess,
        deleteCalendarFrequencyFailure,
      ],
      verb: HTTP.Types.DELETE,
    },
  };
}

export function fetchParentIntervalsAction(projectId, calendarName) {
  return {
    [CALL_API]: {
      endpoint: `/api/v2/${projectId}/calendar-events/${calendarName}/parent-intervals`,
      lifecycleActions: [
        fetchParentIntervalsRequest,
        fetchParentIntervalsSuccess,
        fetchParentIntervalsFailure,
      ],
      verb: HTTP.Types.GET,
    },
  };
}

export function fetchMicroIntervalsAction({
  projectId,
  start,
  end,
  frequency,
  calendarName,
  customFrequency = null,
}) {
  let endpoint = `/api/v2/${projectId}/calendar-events/${calendarName}/micro-intervals?frequency=${frequency}`;
  if (start) {
    endpoint += `&startDate=${start}`;
  }
  if (end) {
    endpoint += `&endDate=${end}`;
  }
  if (customFrequency) {
    if (customFrequency.numberOfEvents) {
      endpoint += `&numberOfEvents${customFrequency.numberOfEvents}`;
    }
    if (customFrequency.every) {
      endpoint += `&every${customFrequency.every}`;
    }
    if (customFrequency.frequency) {
      endpoint += `&customFrequency${customFrequency.frequency}`;
    }
    if (customFrequency.repeatOn && customFrequency.repeatOn.length) {
      endpoint += `&${customFrequency.repeatOn
        .map((r) => `repeatOn=${r}`)
        .join('&')}`;
    }
  }
  return {
    [CALL_API]: {
      endpoint,
      lifecycleActions: [
        fetchMicroIntervalsRequest,
        fetchMicroIntervalsSuccess,
        fetchMicroIntervalsFailure,
      ],
      verb: HTTP.Types.GET,
    },
  };
}

export function createCalendarAssignmentsAction(
  projectId,
  calendarId,
  users,
  interval,
) {
  return {
    [CALL_API]: {
      data: {
        ...interval,
        users,
      },
      endpoint: `/api/v2/${projectId}/calendar-events/${calendarId}/assignments`,
      lifecycleActions: [
        createCalendarAssigneesRequest,
        createCalendarAssigneesSuccess,
        createCalendarAssigneesFailure,
      ],
      verb: HTTP.Types.POST,
    },
  };
}

export function removeCalendarAssignmentsAction(
  projectId,
  calendarId,
  assignments,
  interval,
) {
  return {
    [CALL_API]: {
      data: {
        ...interval,
        assignments,
      },
      endpoint: `/api/v2/${projectId}/calendar-events/${calendarId}/assignments`,
      headers: {
        'Content-Type': 'application/json',
      },
      lifecycleActions: [
        removeCalendarAssigneesRequest,
        removeCalendarAssigneesSuccess,
        removeCalendarAssigneesFailure,
      ],
      verb: HTTP.Types.DELETE,
    },
  };
}
