import { GenericLogType } from 'mapistry-shared';
import { createCachedSelector } from 're-reselect';
/* @ts-expect-error - TODO: Fix this the next time the file is edited. */
import _ from 'underscore';

/* @ts-expect-error - TODO: Fix this the next time the file is edited. */
const selectIsFetching = (state, fetchingSubpath) =>
  state.air.isFetching[fetchingSubpath];
/* @ts-expect-error - TODO: Fix this the next time the file is edited. */
const selectAirEmissions = (state, logProjectId) =>
  state.air.airEmissions[logProjectId];

export const getIsFetching = createCachedSelector(
  [selectIsFetching],
  (isFetching) => Boolean(isFetching),
)((_state_, fetchingSubpath: string) => fetchingSubpath);

export const getCalculatedValues = createCachedSelector(
  [selectAirEmissions],
  (airEmissions) => airEmissions?.calculatedValues || [],
)((_state_, logProjectId: string) => logProjectId);

export const getEmissionFactors = createCachedSelector(
  [selectAirEmissions],
  (airEmissions) => airEmissions?.emissionFactors || [],
)((_state_, logProjectId: string) => logProjectId);

export const getLimitItems = createCachedSelector(
  [selectAirEmissions],
  (airEmissions) => airEmissions?.limitItems || [],
)((_state_, logProjectId: string) => logProjectId);

export const getLog = createCachedSelector(
  [selectAirEmissions],
  (airEmissions) => airEmissions?.log || {},
)((_state_, logProjectId: string) => logProjectId);

export const getLoggedItems = createCachedSelector(
  [selectAirEmissions],
  (airEmissions) => airEmissions?.loggedItems || [],
)((_state_, logProjectId: string) => logProjectId);

export const getLogSaved = createCachedSelector(
  [selectAirEmissions],
  (airEmissions) => airEmissions?.logSaved || null,
)((_state_, logProjectId: string) => logProjectId);

export const getLogParameters = createCachedSelector(
  [selectAirEmissions],
  (airEmissions) => airEmissions?.logParameters || [],
)((_state_, logProjectId: string) => logProjectId);

export const getLogs = createCachedSelector(
  [selectAirEmissions],
  (airEmissions) => airEmissions?.logs || [],
)((_state_, logProjectId: string) => logProjectId);

export const getLogTabs = createCachedSelector(
  [selectAirEmissions],
  (airEmissions) => airEmissions?.logTabs || [],
)((_state_, logProjectId: string) => logProjectId);

export const getProjectHasLogs = createCachedSelector(
  [selectAirEmissions],
  (airEmissions) => airEmissions?.projectHasLogs || false,
)((_state_, logProjectId: string) => logProjectId);

export const getMicroIntervalStatistics = createCachedSelector(
  [selectAirEmissions],
  (airEmissions) => airEmissions?.microIntervalStatistics || null,
)((_state_, logProjectId: string) => logProjectId);

export const getRollingCalculations = createCachedSelector(
  [selectAirEmissions],
  (airEmissions) => airEmissions?.rollingCalculations || [],
)((_state_, logProjectId: string) => logProjectId);

export const getStatistics = createCachedSelector(
  [selectAirEmissions],
  (airEmissions) => airEmissions?.statistics || null,
)((_state_, logProjectId: string) => logProjectId);

export const getUnitConversions = createCachedSelector(
  [selectAirEmissions],
  (airEmissions) => airEmissions?.unitConversions || [],
)((_state_, logProjectId: string) => logProjectId);

export const getCalculatedValueGroups = createCachedSelector(
  [selectAirEmissions],
  (airEmissions) =>
    _.pick(
      airEmissions?.itemGroups || {},
      /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
      (group) => group.groupType === GenericLogType.CALCULATED_VALUE,
    ),
)((_state_, logProjectId: string) => logProjectId);

export const getEmissionFactorGroups = createCachedSelector(
  [selectAirEmissions],
  (airEmissions) =>
    _.pick(
      airEmissions?.itemGroups || {},
      /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
      (group) => group.groupType === GenericLogType.EMISSION_FACTOR,
    ),
)((_state_, logProjectId: string) => logProjectId);

export const getLimitItemGroups = createCachedSelector(
  [selectAirEmissions],
  (airEmissions) =>
    _.pick(
      airEmissions?.itemGroups || {},
      /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
      (group) => group.groupType === GenericLogType.LIMIT_ITEM,
    ),
)((_state_, logProjectId: string) => logProjectId);

export const getLoggedItemGroups = createCachedSelector(
  [selectAirEmissions],
  (airEmissions) =>
    _.pick(
      airEmissions?.itemGroups || {},
      /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
      (group) => group.groupType === GenericLogType.LOGGED_ITEM,
    ),
)((_state_, logProjectId: string) => logProjectId);

export const getRollingCalculationGroups = createCachedSelector(
  [selectAirEmissions],
  (airEmissions) =>
    _.pick(
      airEmissions?.itemGroups || {},
      /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
      (group) => group.groupType === GenericLogType.ROLLING_CALCULATION,
    ),
)((_state_, logProjectId: string) => logProjectId);

export const getUnitConversionGroups = createCachedSelector(
  [selectAirEmissions],
  (airEmissions) =>
    _.pick(
      airEmissions?.itemGroups || {},
      /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
      (group) => group.groupType === GenericLogType.UNIT_CONVERSION,
    ),
)((_state_, logProjectId: string) => logProjectId);
