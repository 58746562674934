import React from 'react';
import { IconButton as MuiIconButton } from '@material-ui/core';
import cn from 'classnames';
import MapistryTooltip from '../MapistryTooltip';

type MuiIconButtonProps = React.ComponentProps<typeof MuiIconButton>;

interface IconButtonProps extends MuiIconButtonProps {
  tooltipText?: string;
}

/**
 * @deprecated Use packages/shared/components/buttons/IconButton.tsx
 */
function IconButton(props: IconButtonProps) {
  const { tooltipText, ...restProps } = props;
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/destructuring-assignment, react/prop-types
  const className = cn('mapistry-icon-button', props.className);

  const button = (
    <MuiIconButton {...restProps} className={className} disableRipple />
  );

  if (tooltipText) {
    return (
      <MapistryTooltip placement="top" title={tooltipText}>
        <span>{button}</span>
      </MapistryTooltip>
    );
  }

  return button;
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default IconButton;
