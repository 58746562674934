import update from 'immutability-helper';
import React from 'react';
import EditLogModalLoggedItemGroup from './EditLogModalLoggedItemGroup';
import { EditLogModalProps } from './EditLogModalTypes';

const LoggedItems = ({
  formDraft,
  loggedItemGroups,
  formErrors,
  onEdit,
}: Pick<
  EditLogModalProps,
  'formDraft' | 'formErrors' | 'loggedItemGroups' | 'onEdit'
>) => {
  const { items } = formDraft;
  const itemsGroupedByGroupId = Object.keys(items)?.reduce((acc, itemId) => {
    const item = items[itemId];
    return update(acc, {
      // @ts-expect-error: groupId can be null and automatically gets converted to 'null' string here
      [item.groupId]: (group) =>
        update(group || {}, {
          /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
          items: (arr) =>
            update(arr || [], {
              $push: [
                {
                  ...item,
                  itemId,
                },
              ],
            }),
        }),
    });
  }, {});

  return (
    <div className="air-emission-log-modal__items">
      {Object.keys(itemsGroupedByGroupId)
        ?.sort((a, b) => {
          const toSortByA = loggedItemGroups[a]?.name;
          const toSortByB = loggedItemGroups[b]?.name;

          if (toSortByA && toSortByB) {
            return toSortByA.localeCompare(toSortByB);
          }
          return toSortByA ? -1 : 1;
        })
        .map((groupId) => {
          const groupName = loggedItemGroups[groupId]?.name || 'Ungrouped';
          /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
          const group = itemsGroupedByGroupId[groupId];
          return (
            <EditLogModalLoggedItemGroup
              key={groupId}
              groupId={groupId}
              groupName={groupName}
              items={group.items}
              onEdit={onEdit}
              formErrors={formErrors}
            />
          );
        })}
    </div>
  );
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default LoggedItems;
