import styled from 'styled-components';
import { BaseButton } from '../BaseButton';

export const ContainedButton = styled(BaseButton)`
  color: ${({ theme }) => theme.colors.white};

  &:hover {
    box-shadow: 0 4px 8px ${({ theme }) => theme.colors.black30};
  }

  & svg {
    fill: ${({ theme }) => theme.colors.white};
  }
`;
