/* eslint-disable */
// when editing this file please uncomment the above and fix the linting errors
import { combineReducers } from 'redux';

import air from './air';
import calendar from './calendar';
import currentUser from './currentUser';
import formSubmissions from './formSubmissions';
import project from './project';
import task from './task';
import user from './user';
import wastewater from './wastewater';

export function fetching(state, fetchingSubpath) {
  return Object.assign({}, state, {
    errorMessage: null,
    isFetching: fetchingSubpath
      ? {
          ...state.isFetching,
          [fetchingSubpath]: true,
        }
      : true,
  });
}

export function notFetching(state, fetchingSubpath) {
  return Object.assign({}, state, {
    isFetching: fetchingSubpath
      ? {
          ...state.isFetching,
          [fetchingSubpath]: false,
        }
      : false,
  });
}
export function failed(state, error, defaultMessage = 'Request has failed') {
  return Object.assign({}, state, {
    errorMessage: error || { message: defaultMessage },
  });
}

export default combineReducers({
  air,
  calendar,
  project,
  currentUser,
  formSubmissions,
  task,
  user,
  wastewater,
});
