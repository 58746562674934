import { format } from 'date-fns';
import { GenericLogLoggedItemType } from 'mapistry-shared';
import React, { FunctionComponent, useMemo } from 'react';

import { localEquivalentOfUTC } from '../../../utils';
import Units from '../../../utils/units';
import { FieldWrapper, HelpIcon, Select } from '../../elements';
import EditLogFieldFactory from './EditLogFieldFactory';
import { LoggedItemRecord } from './EditLogModalTypes';

type LogRowProps = {
  logItem: LoggedItemRecord;
  error: boolean;
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (param: any) => void;
  onUnitChange: (newUnit: string) => void;
};

const LogRow: FunctionComponent<LogRowProps> = (props) => {
  const { error, logItem, onChange, onUnitChange } = props;
  const unitOptions = useMemo(
    () => Units.getRelatedUnits(logItem.units),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  const LogItemIcon = useMemo(() => {
    const { existingLogDatetime } = logItem;
    if (!existingLogDatetime) {
      return null;
    }
    const localDatetime = localEquivalentOfUTC(existingLogDatetime);
    const helpText = `There is already a log for this time period on
      ${format(
        /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
        localDatetime,
        'MM/dd/yyyy',
      )} at ${format(
      /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
      localDatetime,
      'HH:mm',
    )}`;
    return <HelpIcon text={helpText} />;
  }, [logItem]);

  return (
    <div className="air-emission-log-modal__items-row">
      <div>
        {logItem.name}
        {LogItemIcon}
      </div>
      <FieldWrapper label="Item Value">
        <EditLogFieldFactory
          disabled={!!logItem.existingLogDatetime}
          error={error}
          value={logItem.value}
          onChange={onChange}
          options={logItem.selectTypeOptions}
          type={logItem.itemType}
        />
      </FieldWrapper>
      {logItem.itemType === GenericLogLoggedItemType.NUMBER ? (
        <FieldWrapper label="Units">
          <Select
            isClearable={false}
            isDisabled={!!logItem.existingLogDatetime}
            isFixed
            options={unitOptions}
            onChange={(option) => onUnitChange(option.value)}
            value={unitOptions.find(
              (unitOption) => unitOption.value === logItem.units,
            )}
          />
        </FieldWrapper>
      ) : (
        <div />
      )}
    </div>
  );
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default LogRow;
