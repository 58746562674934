import CloseIcon from '@svg/close.svg';
import { func, node, string } from 'prop-types';
import React from 'react';
import IconButton from '../../buttons/IconButton';
import { Card } from '../Card';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default function CardOverlay(props) {
  const { children, footer, onCancel, title, ...cardProps } = props;

  const closeButton = onCancel ? (
    <IconButton className="card-overlay__close" onClick={onCancel}>
      <div className="visually-hidden">Close</div>
      <CloseIcon className="m-icon" />
    </IconButton>
  ) : null;

  return (
    <Card title={title} actions={closeButton} {...cardProps}>
      <div className="card-overlay__content">
        <div className="card-overlay__body">{children}</div>
        {footer && <div className="card-overlay__footer">{footer}</div>}
      </div>
    </Card>
  );
}

CardOverlay.propTypes = {
  children: node.isRequired,
  footer: node,
  onCancel: func,
  title: string.isRequired,
};

CardOverlay.defaultProps = {
  footer: null,
  onCancel: null,
};
