import { QueryConfig, useQuery } from 'react-query';
import { Api } from '../apiClient';

export const createKey = ({
  organizationId,
}: Api.FetchAllProjectsOnOrganizationProps) =>
  ['org-projects', organizationId] as const;

type Fetcher = Api.DataHookQueryFn<
  typeof createKey,
  typeof Api.fetchAllProjectsOnOrganization
>;

const fetcher: Fetcher = (_, organizationId) =>
  Api.fetchAllProjectsOnOrganization({ organizationId });

type UseOrganizationProjectsProps =
  Partial<Api.FetchAllProjectsOnOrganizationProps> & {
    config?: QueryConfig<
      Api.FetchAllProjectsOnOrganizationResponse,
      Api.ErrorResponse
    >;
  };

export function useOrganizationProjects({
  config: inputConfig,
  organizationId,
}: UseOrganizationProjectsProps) {
  const key = organizationId ? createKey({ organizationId }) : undefined;
  const config = {
    enabled: !!organizationId,
    ...inputConfig,
  };
  const { data, ...queryInfo } = useQuery(key, fetcher, config);
  return {
    ...queryInfo,
    projects: data,
  };
}
