import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { StyledSkipLink } from './SkipWidgetLink';

interface MainProps {
  children: ReactNode;
  className?: string;
  mainRef?: React.Ref<HTMLDivElement>;
}

export const SkipToMainLink = () => (
  <StyledSkipLink href="#main">Skip to main content</StyledSkipLink>
);

const StyledMain = styled.main`
  &:focus {
    outline: none;
  }
`;

/**
 * This will be the "Skip to main content" skip link target
 */
export const Main = ({ children, className, mainRef }: MainProps) => (
  <StyledMain id="main" tabIndex={-1} className={className} ref={mainRef}>
    {children}
  </StyledMain>
);
