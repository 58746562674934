import { createContext } from 'react';
import { AlertDialog } from '../../components/dialogs/AlertDialog';
import {
  MultiChoiceConfirmDialogProps,
  SingleChoiceConfirmDialogProps,
} from '../../components/dialogs/ConfirmDialog';
import { PromptDialog } from '../../components/dialogs/PromptDialog';

type AlertDialogProps = React.ComponentProps<typeof AlertDialog>;

type PromptDialogProps = React.ComponentProps<typeof PromptDialog>;

export type AlertDialogOpts = Pick<
  AlertDialogProps,
  'title' | 'description' | 'buttonText'
>;

export type SingleChoiceConfirmDialogOpts = Pick<
  SingleChoiceConfirmDialogProps,
  'title' | 'description' | 'cancelButtonText' | 'confirmButtonText' | 'danger'
>;

export type MultiChoiceConfirmDialogOpts = Pick<
  MultiChoiceConfirmDialogProps,
  'title' | 'description' | 'cancelButtonText' | 'confirmChoices'
>;

export type ConfirmDialogOpts =
  | SingleChoiceConfirmDialogOpts
  | MultiChoiceConfirmDialogOpts;

interface ConfirmFn {
  (opts: SingleChoiceConfirmDialogOpts): Promise<boolean>;
  (opts: MultiChoiceConfirmDialogOpts): Promise<false | string>;
}

export type PromptDialogOpts = Pick<
  PromptDialogProps,
  | 'title'
  | 'description'
  | 'cancelButtonText'
  | 'confirmButtonText'
  | 'initialInputValue'
  | 'danger'
  | 'multiline'
>;

interface ModalContextValue {
  alert: (opts: AlertDialogOpts) => Promise<void>;
  confirm: ConfirmFn;
  prompt: (opts: PromptDialogOpts) => Promise<string | void>;
}

const defaultValue: ModalContextValue = {
  alert: () => Promise.resolve(),
  confirm: (() => Promise.resolve(false)) as ConfirmFn,
  prompt: () => Promise.resolve(),
};

export const ModalContext = createContext(defaultValue);
