import React from 'react';
import PropTypes from 'prop-types';

import { widthsAreValid } from './utils';

const TR = (props) => {
  const { children } = props;

  const childrenWidths = (() => {
    if (children instanceof Array) {
      return children.reduce((acc, c) => {
        if (c.props.width) {
          acc.push(c.props.width);
        }
        return acc;
      }, []);
    }
    return [children.props.width];
  })();

  if (!widthsAreValid(childrenWidths)) {
    throw new Error('Widths provided must not be greater than 100');
  }

  return <div className="mapistry-table__row">{children}</div>;
};

TR.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default TR;
