import update from 'immutability-helper';
import { handleActions } from 'redux-actions';

import {
  ADD_FLOW_LOG_LIMIT_FAILURE,
  ADD_FLOW_LOG_LIMIT_REQUEST,
  ADD_FLOW_LOG_LIMIT_SUCCESS,
  ADD_FLOW_LOG_READING_FAILURE,
  ADD_FLOW_LOG_READING_REQUEST,
  ADD_FLOW_LOG_READING_SUCCESS,
  ADD_WASTEWATER_SAMPLING_EVENT_FAILURE,
  ADD_WASTEWATER_SAMPLING_EVENT_REQUEST,
  ADD_WASTEWATER_SAMPLING_EVENT_SUCCESS,
  ADD_WASTEWATER_SAMPLING_PARAMETER_FAILURE,
  ADD_WASTEWATER_SAMPLING_PARAMETER_LOCATION_FAILURE,
  ADD_WASTEWATER_SAMPLING_PARAMETER_LOCATION_REQUEST,
  ADD_WASTEWATER_SAMPLING_PARAMETER_LOCATION_SUCCESS,
  ADD_WASTEWATER_SAMPLING_PARAMETER_REQUEST,
  ADD_WASTEWATER_SAMPLING_PARAMETER_SUCCESS,
  CREATE_PH_LOG_READING_FAILURE,
  CREATE_PH_LOG_READING_REQUEST,
  CREATE_PH_LOG_READING_SUCCESS,
  DELETE_FLOW_LOG_LIMIT_FAILURE,
  DELETE_FLOW_LOG_LIMIT_REQUEST,
  DELETE_FLOW_LOG_LIMIT_SUCCESS,
  DELETE_FLOW_LOG_READING_FAILURE,
  DELETE_FLOW_LOG_READING_REQUEST,
  DELETE_FLOW_LOG_READING_SUCCESS,
  DELETE_WASTEWATER_SAMPLING_PARAMETER_AT_LOCATION_FAILURE,
  DELETE_WASTEWATER_SAMPLING_PARAMETER_AT_LOCATION_REQUEST,
  DELETE_WASTEWATER_SAMPLING_PARAMETER_AT_LOCATION_SUCCESS,
  DELETE_WASTEWATER_SAMPLING_PARAMETER_FAILURE,
  DELETE_WASTEWATER_SAMPLING_PARAMETER_REQUEST,
  DELETE_WASTEWATER_SAMPLING_PARAMETER_SUCCESS,
  EDIT_FLOW_LOG_LIMIT_FAILURE,
  EDIT_FLOW_LOG_LIMIT_REQUEST,
  EDIT_FLOW_LOG_LIMIT_SUCCESS,
  EDIT_FLOW_LOG_READING_FAILURE,
  EDIT_FLOW_LOG_READING_REQUEST,
  EDIT_FLOW_LOG_READING_SUCCESS,
  EDIT_WASTEWATER_SAMPLING_EVENT_FAILURE,
  EDIT_WASTEWATER_SAMPLING_EVENT_REQUEST,
  EDIT_WASTEWATER_SAMPLING_EVENT_SUCCESS,
  EDIT_WASTEWATER_SAMPLING_PARAMETER_LOCATION_FAILURE,
  EDIT_WASTEWATER_SAMPLING_PARAMETER_LOCATION_REQUEST,
  EDIT_WASTEWATER_SAMPLING_PARAMETER_LOCATION_SUCCESS,
  FETCH_FLOW_LOGS_FOR_CHART_FAILURE,
  FETCH_FLOW_LOGS_FOR_CHART_REQUEST,
  FETCH_FLOW_LOGS_FOR_CHART_SUCCESS,
  FETCH_FLOW_LOG_LIMITS_FAILURE,
  FETCH_FLOW_LOG_LIMITS_REQUEST,
  FETCH_FLOW_LOG_LIMITS_SUCCESS,
  FETCH_FLOW_LOG_READINGS_FAILURE,
  FETCH_FLOW_LOG_READINGS_REQUEST,
  FETCH_FLOW_LOG_READINGS_SUCCESS,
  FETCH_FLOW_LOG_STATS_FAILURE,
  FETCH_FLOW_LOG_STATS_REQUEST,
  FETCH_FLOW_LOG_STATS_SUCCESS,
  FETCH_PH_LOGS_FOR_CHART_FAILURE,
  FETCH_PH_LOGS_FOR_CHART_REQUEST,
  FETCH_PH_LOGS_FOR_CHART_SUCCESS,
  FETCH_WASTEWATER_MONITORING_LOCATIONS_FAILURE,
  FETCH_WASTEWATER_MONITORING_LOCATIONS_REQUEST,
  FETCH_WASTEWATER_MONITORING_LOCATIONS_SUCCESS,
  FETCH_WASTEWATER_PARAMETERS_FAILURE,
  FETCH_WASTEWATER_PARAMETERS_REQUEST,
  FETCH_WASTEWATER_PARAMETERS_SUCCESS,
  FETCH_WASTEWATER_PARAMETER_LIMIT_CALCULATIONS_FAILURE,
  FETCH_WASTEWATER_PARAMETER_LIMIT_CALCULATIONS_REQUEST,
  FETCH_WASTEWATER_PARAMETER_LIMIT_CALCULATIONS_SUCCESS,
  FETCH_WASTEWATER_SAMPLING_EVENT_FAILURE,
  FETCH_WASTEWATER_SAMPLING_EVENT_REQUEST,
  FETCH_WASTEWATER_SAMPLING_EVENT_SUCCESS,
  INIT_SAMPLING_EVENT,
  INIT_WASTEWATER_LIMIT_CALCULATION,
  RESET_WASTEWATER_ERROR_STATE,
} from '../actions';

import { isNullOrUndefined, localEquivalentOfUTC } from '../utils';
// FIX THIS AT SOME POINT
// eslint-disable-next-line import/no-cycle
import { failed, fetching, notFetching } from '.';

const eventWithDateObjects = (event) => {
  const { id, eventDate, eventTime, samplingResults } = event;
  if (!id) {
    // the event got entirely deleted
    return {};
  }

  const newEventDate = localEquivalentOfUTC(new Date(eventDate));
  const newEventTime = new Date();
  const [hours, minutes, seconds] = eventTime.split(':');
  newEventTime.setHours(hours, minutes, seconds);
  const newSamplingResults = samplingResults.map((result) => ({
    ...result,
    analysisDate:
      result.analysisDate == null
        ? null
        : localEquivalentOfUTC(new Date(result.analysisDate)),
  }));
  return {
    ...event,
    eventDate: newEventDate,
    eventTime: newEventTime,
    samplingResults: newSamplingResults,
  };
};

const reducers = handleActions(
  {
    [ADD_FLOW_LOG_LIMIT_REQUEST]: (state) => fetching(state, 'flowLogLimits'),
    [ADD_FLOW_LOG_LIMIT_SUCCESS]: (state, action) =>
      notFetching(
        update(state, { flowLogLimits: { $push: [action.payload] } }),
        'flowLogLimits',
      ),
    [ADD_FLOW_LOG_LIMIT_FAILURE]: (state, action) =>
      failed(notFetching(state, 'flowLogLimits'), action.payload),

    [ADD_FLOW_LOG_READING_REQUEST]: (state) =>
      fetching(state, 'flowLogReadings'),
    [ADD_FLOW_LOG_READING_SUCCESS]: (state, action) => {
      const { flowLogReadings } = state;
      const nextFlowLogReadings = flowLogReadings
        .concat(action.payload)
        .sort((reading1, reading2) => {
          if (reading1.dateOfDischarge < reading2.dateOfDischarge) {
            return -1;
          }
          if (reading1.dateOfDischarge === reading2.dateOfDischarge) {
            return 0;
          }
          return 1;
        });
      return notFetching(
        update(state, { flowLogReadings: { $set: nextFlowLogReadings } }),
        'flowLogReadings',
      );
    },
    [ADD_FLOW_LOG_READING_FAILURE]: (state, action) =>
      failed(notFetching(state, 'flowLogReadings'), action.payload),

    [ADD_WASTEWATER_SAMPLING_EVENT_REQUEST]: (state) =>
      fetching(state, 'addSamplingEvent'),
    [ADD_WASTEWATER_SAMPLING_EVENT_SUCCESS]: (state, action) => {
      const newEvent = eventWithDateObjects(action.payload);
      return notFetching(
        update(state, {
          samplingEvent: { $set: newEvent },
        }),
        'addSamplingEvent',
      );
    },
    [ADD_WASTEWATER_SAMPLING_EVENT_FAILURE]: (state, action) =>
      failed(notFetching(state, 'addSamplingEvent'), action.payload),

    [ADD_WASTEWATER_SAMPLING_PARAMETER_REQUEST]: (state) =>
      fetching(state, 'addSamplingParameter'),
    [ADD_WASTEWATER_SAMPLING_PARAMETER_SUCCESS]: (state, action) =>
      notFetching(
        update(state, {
          parameters: { [action.payload.id]: { $set: action.payload } },
        }),
        'addSamplingParameter',
      ),
    [ADD_WASTEWATER_SAMPLING_PARAMETER_FAILURE]: (state, action) =>
      failed(notFetching(state, 'addSamplingParameter'), action.payload),

    [ADD_WASTEWATER_SAMPLING_PARAMETER_LOCATION_REQUEST]: (state) =>
      fetching(state, 'addParameterLocation'),
    [ADD_WASTEWATER_SAMPLING_PARAMETER_LOCATION_SUCCESS]: (state, action) =>
      notFetching(
        update(state, {
          parameters: {
            [action.payload.wastewater_sampling_parameter_id]: {
              sp_monitoring_locations: { $push: [action.payload] },
            },
          },
        }),
        'addParameterLocation',
      ),
    [ADD_WASTEWATER_SAMPLING_PARAMETER_LOCATION_FAILURE]: (state, action) =>
      failed(notFetching(state, 'addParameterLocation'), action.payload),

    [CREATE_PH_LOG_READING_REQUEST]: (state) =>
      fetching(state, 'createPhLogReading'),
    [CREATE_PH_LOG_READING_SUCCESS]: (state) =>
      notFetching(state, 'createPhLogReading'),
    [CREATE_PH_LOG_READING_FAILURE]: (state, action) =>
      failed(notFetching(state, 'createPhLogReading'), action.payload),

    [DELETE_FLOW_LOG_LIMIT_REQUEST]: (state) =>
      fetching(state, 'flowLogLimits'),
    [DELETE_FLOW_LOG_LIMIT_SUCCESS]: (state, action) => {
      // On a successful delete, the server will return the ID of the parameter that
      // was deleted. Take this ID and update the frontend store to keep in sync.
      const { id } = action.payload;
      const deletedLimitIdx = state.flowLogLimits.findIndex((i) => i.id === id);
      return notFetching(
        update(state, { flowLogLimits: { $splice: [[deletedLimitIdx, 1]] } }),
        'flowLogLimits',
      );
    },
    [DELETE_FLOW_LOG_LIMIT_FAILURE]: (state, action) =>
      failed(notFetching(state, 'flowLogLimits'), action.payload),

    [DELETE_FLOW_LOG_READING_REQUEST]: (state) =>
      fetching(state, 'flowLogReadings'),
    [DELETE_FLOW_LOG_READING_SUCCESS]: (state, action) => {
      // On a successful delete, the server will return the ID of the parameter that
      // was deleted. Take this ID and update the frontend store to keep in sync.
      const { id } = action.payload;
      const deletedIdx = state.flowLogReadings.findIndex((i) => i.id === id);
      return notFetching(
        update(state, { flowLogReadings: { $splice: [[deletedIdx, 1]] } }),
        'flowLogReadings',
      );
    },
    [DELETE_FLOW_LOG_READING_FAILURE]: (state, action) =>
      failed(notFetching(state, 'flowLogReadings'), action.payload),

    [DELETE_WASTEWATER_SAMPLING_PARAMETER_REQUEST]: (state) =>
      fetching(state, 'deleteParameter'),
    [DELETE_WASTEWATER_SAMPLING_PARAMETER_SUCCESS]: (state, action) =>
      // On a successful delete, the server will return the ID of the parameter that
      // was deleted. Take this ID and update the frontend store to keep in sync.
      notFetching(
        update(state, { parameters: { $unset: [action.payload.id] } }),
        'deleteParameter',
      ),
    [DELETE_WASTEWATER_SAMPLING_PARAMETER_FAILURE]: (state, action) =>
      failed(notFetching(state, 'deleteParameter'), action.payload),

    [DELETE_WASTEWATER_SAMPLING_PARAMETER_AT_LOCATION_REQUEST]: (state) =>
      fetching(state, 'deleteParameterAtLocation'),
    [DELETE_WASTEWATER_SAMPLING_PARAMETER_AT_LOCATION_SUCCESS]: (
      state,
      action,
    ) => {
      // On a successful delete, the server will return the ID of the wastewater_sampling_parameter_monitoring_location that
      // was deleted. Take this ID and the returned samplingParameterId and update the frontend store to keep in sync.
      const { id, samplingParameterId } = action.payload;
      const samplingParameterAtLocationIdx = state.parameters[
        samplingParameterId
      ].sp_monitoring_locations.findIndex((i) => i.id === id);
      return notFetching(
        update(state, {
          parameters: {
            [samplingParameterId]: {
              sp_monitoring_locations: {
                $splice: [[samplingParameterAtLocationIdx, 1]],
              },
            },
          },
        }),
        'deleteParameterAtLocation',
      );
    },
    [DELETE_WASTEWATER_SAMPLING_PARAMETER_AT_LOCATION_FAILURE]: (
      state,
      action,
    ) =>
      failed(notFetching(state, 'deleteParameterAtLocation'), action.payload),

    [EDIT_FLOW_LOG_LIMIT_REQUEST]: (state) => fetching(state, 'flowLogLimits'),
    [EDIT_FLOW_LOG_LIMIT_SUCCESS]: (state, action) => {
      const updatedFlowLogLimit = action.payload;
      const updatedLimitIdx = state.flowLogLimits.findIndex(
        (i) => i.id === updatedFlowLogLimit.id,
      );
      return notFetching(
        update(state, {
          flowLogLimits: {
            [updatedLimitIdx]: { $set: updatedFlowLogLimit },
          },
        }),
        'flowLogLimits',
      );
    },
    [EDIT_FLOW_LOG_LIMIT_FAILURE]: (state, action) =>
      failed(notFetching(state, 'flowLogLimits'), action.payload),

    [EDIT_FLOW_LOG_READING_REQUEST]: (state) =>
      fetching(state, 'flowLogReadings'),
    [EDIT_FLOW_LOG_READING_SUCCESS]: (state, action) => {
      const updatedFlowLogReading = action.payload;
      const updatedIdx = state.flowLogReadings.findIndex(
        (i) => i.id === updatedFlowLogReading.id,
      );
      return notFetching(
        update(state, {
          flowLogReadings: {
            [updatedIdx]: { $set: updatedFlowLogReading },
          },
        }),
        'flowLogReadings',
      );
    },
    [EDIT_FLOW_LOG_READING_FAILURE]: (state, action) =>
      failed(notFetching(state, 'flowLogReadings'), action.payload),

    [EDIT_WASTEWATER_SAMPLING_EVENT_REQUEST]: (state) =>
      fetching(state, 'editSamplingEvent'),
    [EDIT_WASTEWATER_SAMPLING_EVENT_SUCCESS]: (state, action) => {
      const newEvent = eventWithDateObjects(action.payload);
      return notFetching(
        update(state, {
          samplingEvent: { $set: newEvent },
        }),
        'editSamplingEvent',
      );
    },
    [EDIT_WASTEWATER_SAMPLING_EVENT_FAILURE]: (state, action) =>
      failed(notFetching(state, 'editSamplingEvent'), action.payload),

    [EDIT_WASTEWATER_SAMPLING_PARAMETER_LOCATION_REQUEST]: (state) =>
      fetching(state, 'samplingParameterLocation'),
    [EDIT_WASTEWATER_SAMPLING_PARAMETER_LOCATION_SUCCESS]: (state, action) => {
      const nextParameterAtLocation = action.payload;
      const parameterId =
        nextParameterAtLocation.wastewater_sampling_parameter_id;
      const paramAtLocationIdx = state.parameters[
        parameterId
      ].sp_monitoring_locations.findIndex(
        (i) => i.id === nextParameterAtLocation.id,
      );
      return notFetching(
        update(state, {
          parameters: {
            [parameterId]: {
              sp_monitoring_locations: {
                [paramAtLocationIdx]: { $set: action.payload },
              },
            },
          },
        }),
        'samplingParameterLocation',
      );
    },
    [EDIT_WASTEWATER_SAMPLING_PARAMETER_LOCATION_FAILURE]: (state, action) =>
      failed(notFetching(state, 'samplingParameterLocation'), action.payload),

    [FETCH_FLOW_LOG_LIMITS_REQUEST]: (state) =>
      fetching(state, 'flowLogLimits'),
    [FETCH_FLOW_LOG_LIMITS_SUCCESS]: (state, action) =>
      notFetching(
        update(state, { flowLogLimits: { $set: action.payload } }),
        'flowLogLimits',
      ),
    [FETCH_FLOW_LOG_LIMITS_FAILURE]: (state, action) =>
      failed(notFetching(state, 'flowLogLimits'), action.payload),

    [FETCH_FLOW_LOG_READINGS_REQUEST]: (state) =>
      fetching(state, 'flowLogReadings'),
    [FETCH_FLOW_LOG_READINGS_SUCCESS]: (state, action) => {
      const isNewReading = (readingToCheck) =>
        state.flowLogReadings.filter(
          (stateReading) => stateReading.id === readingToCheck.id,
        ).length === 0;
      const readingsToAdd = action.payload.filter(isNewReading);
      const readingsToUpdate = action.payload.filter(
        (reading) => !isNewReading(reading),
      );

      const currentReadingsWithUpdates = state.flowLogReadings.map(
        (readingToCheck) => {
          const updatedReading = readingsToUpdate.find(
            ({ id }) => id === readingToCheck.id,
          );
          if (isNullOrUndefined(updatedReading)) {
            return readingToCheck;
          }
          return updatedReading;
        },
      );

      const nextFlowLogReadings = [
        ...currentReadingsWithUpdates,
        ...readingsToAdd,
      ].sort((reading1, reading2) => {
        if (reading1.dateOfDischarge < reading2.dateOfDischarge) {
          return -1;
        }
        if (reading1.dateOfDischarge === reading2.dateOfDischarge) {
          return 0;
        }
        return 1;
      });
      return notFetching(
        update(state, {
          flowLogReadings: {
            $set: nextFlowLogReadings,
          },
        }),
        'flowLogReadings',
      );
    },
    [FETCH_FLOW_LOG_READINGS_FAILURE]: (state, action) =>
      failed(notFetching(state, 'flowLogReadings'), action.payload),

    [FETCH_FLOW_LOG_STATS_REQUEST]: (state) =>
      fetching(state, 'flowLogStatsForRecords'),
    /**
     * action.payload: {
     *    frequency: year / month
     *    stats: {
     *      [locationId]: [ locationStats ]
     *    }
     * }
     *
     * flowLogStats: {
     *    [locationId]: {
     *      [year]: [ locationStats ],
     *      [month]: [ locationStats ],
     *      ...
     *    }
     *  }
     */
    [FETCH_FLOW_LOG_STATS_SUCCESS]: (state, action) => {
      const { endDate, frequency, startDate } = action.payload;
      const locationIdsToUpdate = Object.keys(action.payload.stats);
      const nextFlowLogStats = locationIdsToUpdate.reduce(
        (accum, locationIdToUpdate) => {
          const statsForLocationToUpdate =
            action.payload.stats[locationIdToUpdate];
          const currentLocationStats = state.flowLogStats[locationIdToUpdate];

          // Adding a location's stats for the first time.
          if (isNullOrUndefined(currentLocationStats)) {
            return {
              ...accum,
              [locationIdToUpdate]: {
                [frequency]: statsForLocationToUpdate,
              },
            };
          }

          // Location has stats, but this is the first for the frequency.
          const currentLocationStatsForFrequency =
            currentLocationStats[frequency];
          if (isNullOrUndefined(currentLocationStatsForFrequency)) {
            return {
              ...accum,
              [locationIdToUpdate]: {
                ...currentLocationStats,
                [frequency]: statsForLocationToUpdate,
              },
            };
          }

          // Location already has stats for this frequency.
          // The payload contains the interval used to calculate the new stats -
          //  keep any stats outside the interval and replace those inside it
          //  with the new stats.
          const isOutsideIntervalBeingReplaced = (stats) =>
            !(stats.startDate >= startDate && stats.startDate < endDate);
          const newStatsForLocationToUpdate = currentLocationStatsForFrequency
            .filter(isOutsideIntervalBeingReplaced)
            .concat(statsForLocationToUpdate)
            .sort((stats1, stats2) => {
              if (stats1.startDate < stats2.startDate) {
                return -1;
              }
              if (stats1.startDate === stats2.startDate) {
                return 0;
              }
              return 1;
            });
          return {
            ...accum,
            [locationIdToUpdate]: {
              ...currentLocationStats,
              [frequency]: newStatsForLocationToUpdate,
            },
          };
        },
        state.flowLogStats,
      );

      return notFetching(
        update(state, { flowLogStats: { $set: nextFlowLogStats } }),
        'flowLogStatsForRecords',
      );
    },
    [FETCH_FLOW_LOG_STATS_FAILURE]: (state, action) =>
      failed(notFetching(state, 'flowLogStatsForRecords'), action.payload),

    [FETCH_FLOW_LOGS_FOR_CHART_REQUEST]: (state) =>
      fetching(state, 'flowLogsForChart'),
    [FETCH_FLOW_LOGS_FOR_CHART_SUCCESS]: (state, action) => {
      const nextState = state;
      return notFetching(
        update(nextState, {
          flowLogsForChart: {
            $set: action.payload,
          },
        }),
        'flowLogsForChart',
      );
    },
    [FETCH_FLOW_LOGS_FOR_CHART_FAILURE]: (state, action) =>
      failed(notFetching(state, 'flowLogsForChart'), action.payload),

    [FETCH_PH_LOGS_FOR_CHART_REQUEST]: (state) =>
      fetching(state, 'phLogsForChart'),
    [FETCH_PH_LOGS_FOR_CHART_SUCCESS]: (state, action) => {
      const nextState = state;
      return notFetching(
        update(nextState, {
          phLogsForChart: {
            $set: action.payload,
          },
        }),
        'phLogsForChart',
      );
    },
    [FETCH_PH_LOGS_FOR_CHART_FAILURE]: (state, action) =>
      failed(notFetching(state, 'phLogsForChart'), action.payload),

    [FETCH_WASTEWATER_PARAMETER_LIMIT_CALCULATIONS_REQUEST]: (state, action) =>
      fetching(
        state,
        `limitCalculation-${action.samplingEventId}-${action.parameterSlug}`,
      ),
    [FETCH_WASTEWATER_PARAMETER_LIMIT_CALCULATIONS_SUCCESS]: (
      state,
      action,
    ) => {
      const nextLimitCalculation = {
        samplingEventId: action.samplingEventId,
        ...action.payload,
      };
      const idx = state.limitCalculations.findIndex(
        (i) => i.parameterSlug === nextLimitCalculation.parameterSlug,
      );
      if (idx !== -1) {
        return notFetching(
          update(state, {
            limitCalculations: { [idx]: { $set: nextLimitCalculation } },
          }),
          `limitCalculation-${action.samplingEventId}-${action.parameterSlug}`,
        );
      }

      return notFetching(
        update(state, { limitCalculations: { $push: [nextLimitCalculation] } }),
        `limitCalculation-${action.samplingEventId}-${action.parameterSlug}`,
      );
    },
    [FETCH_WASTEWATER_PARAMETER_LIMIT_CALCULATIONS_FAILURE]: (state, action) =>
      failed(
        notFetching(
          state,
          `limitCalculation-${action.samplingEventId}-${action.parameterSlug}`,
        ),
        action.payload,
      ),

    [FETCH_WASTEWATER_PARAMETERS_REQUEST]: (state) =>
      fetching(state, 'samplingParameters'),
    [FETCH_WASTEWATER_PARAMETERS_SUCCESS]: (state, action) => {
      const arrayToMap = (() => {
        const map = {};
        action.payload.forEach((parameter) => {
          map[parameter.id] = parameter;
        });
        return map;
      })();
      return notFetching(
        update(state, { parameters: { $set: arrayToMap } }),
        'samplingParameters',
      );
    },
    [FETCH_WASTEWATER_PARAMETERS_FAILURE]: (state, action) =>
      failed(notFetching(state, 'samplingParameters'), action.payload),

    [FETCH_WASTEWATER_MONITORING_LOCATIONS_REQUEST]: (state) =>
      fetching(state, 'monitoringLocations'),
    [FETCH_WASTEWATER_MONITORING_LOCATIONS_SUCCESS]: (state, action) =>
      notFetching(
        update(state, { monitoringLocations: { $set: action.payload } }),
        'monitoringLocations',
      ),
    [FETCH_WASTEWATER_MONITORING_LOCATIONS_FAILURE]: (state, action) =>
      failed(notFetching(state, 'monitoringLocations'), action.payload),

    [FETCH_WASTEWATER_SAMPLING_EVENT_REQUEST]: (state) =>
      fetching(state, 'samplingEvent'),
    [FETCH_WASTEWATER_SAMPLING_EVENT_SUCCESS]: (state, action) => {
      const newEvent = eventWithDateObjects(action.payload);
      return notFetching(
        update(state, {
          samplingEvent: { $set: newEvent },
        }),
        'samplingEvent',
      );
    },
    [FETCH_WASTEWATER_SAMPLING_EVENT_FAILURE]: (state, action) =>
      failed(notFetching(state, 'samplingEvent'), action.payload),

    [RESET_WASTEWATER_ERROR_STATE]: (state) =>
      update(state, {
        errorMessage: { $set: null },
      }),
    [INIT_SAMPLING_EVENT]: (state) =>
      update(state, {
        samplingEvent: { $set: null },
      }),
    [INIT_WASTEWATER_LIMIT_CALCULATION]: (state) =>
      update(state, {
        limitCalculations: { $set: [] },
      }),
  },
  {
    flowLogLimits: [],
    flowLogReadings: [],
    flowLogStats: {},
    isFetching: false,
    limitCalculations: [],
    monitoringLocations: [],
    parameters: {},
    samplingEvent: null,
    flowLogsForChart: {},
    phLogsForChart: {},
  },
);

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default reducers;
