import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import React, { SyntheticEvent } from 'react';

export enum ToastStatus {
  STATUS_ERROR = 'error',
  STATUS_INFO = 'info',
  STATUS_SUCCESS = 'success',
  STATUS_WARNING = 'warning',
}

const defaultAutoHideDurationByStatus: Record<ToastStatus, number> = {
  [ToastStatus.STATUS_ERROR]: 5 * 1000,
  [ToastStatus.STATUS_INFO]: 10 * 1000,
  [ToastStatus.STATUS_SUCCESS]: 3 * 1000,
  [ToastStatus.STATUS_WARNING]: 10 * 1000,
};

export interface ToastProps {
  autoHideDuration?: number;
  dontAutoHide?: boolean;
  isOpen: boolean;
  message: string | JSX.Element;
  setIsOpen: (isOpen: boolean) => void;
  status: ToastStatus;
}

export const Toast = ({
  autoHideDuration: autoHideDurationProp,
  dontAutoHide = false,
  isOpen = false,
  message,
  setIsOpen,
  status = ToastStatus.STATUS_INFO,
}: ToastProps) => {
  const handleClose = (_?: SyntheticEvent, reason?: string) =>
    reason !== 'clickaway' && setIsOpen(false);

  const autoHideDuration = dontAutoHide
    ? null
    : autoHideDurationProp ?? defaultAutoHideDurationByStatus[status];

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
      open={isOpen}
    >
      <Alert onClose={handleClose} severity={status}>
        {message}
      </Alert>
    </Snackbar>
  );
};
