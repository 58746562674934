import { KeyboardDatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import moment, { Moment } from 'moment';
import React, { ReactElement, useCallback, useMemo } from 'react';

type KeyboardDatePickerProps = React.ComponentProps<typeof KeyboardDatePicker>;

type ExposedKeyboardDatePickerProps = Pick<
  KeyboardDatePickerProps,
  | 'autoOk'
  | 'clearable'
  | 'disabled'
  | 'disableFuture'
  | 'maxDate'
  | 'maxDateMessage'
  | 'minDate'
  | 'minDateMessage'
  | 'value'
  | 'variant'
>;

type DatePickerProps = ExposedKeyboardDatePickerProps & {
  className?: string;
  error?: boolean;
  /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
  onChange?: (Date, string) => void;
  /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
  renderDay?: (Date, boolean) => ReactElement;
  showHelperText?: boolean;
} & typeof defaultProps;

const defaultProps = {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/default-props-match-prop-types
  autoOk: false,
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/default-props-match-prop-types
  clearable: false,
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/default-props-match-prop-types
  disabled: false,
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/default-props-match-prop-types
  disableFuture: false,
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/default-props-match-prop-types
  showHelperText: true,
};

const DatePicker = (props: DatePickerProps): ReactElement => {
  const {
    className,
    error,
    onChange,
    renderDay,
    showHelperText,
    ...keyboardDatePickerProps
  } = props;

  const handleChange = useCallback(
    (date: MaterialUiPickersDate | null, textValue?: string | null): void => {
      if (!onChange) return;
      onChange(date, textValue);
    },
    [onChange],
  );

  const handleRenderDay = useCallback(
    (
      date: MaterialUiPickersDate,
      selectedDate: MaterialUiPickersDate,
      dayInCurrentMonth: boolean,
    ): ReactElement =>
      // handleRenderDay will be passed only if props.renderDay is defined
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      renderDay!(date, dayInCurrentMonth),

    [renderDay],
  );

  const propClass = useMemo(
    () => (className ? ` ${className}` : ''),
    [className],
  );

  const optionalProps: { error?: boolean; helperText?: '' } = {};
  // If "error" prop is passed to KeyboardDatePicker (even as undefined),
  // its error state becomes controllable by us
  // and error styles won't be applied when min/max date validation fails,
  // so passing it only if "error" is actually true
  if (error) {
    optionalProps.error = true;
  }
  // If "helperText" prop is passed to KeyboardDatePicker (even as undefined),
  // none of validation error messages will be shown,
  // so setting it to empty string only when validation help text is disabled with "showHelperText = false"
  if (!showHelperText) {
    optionalProps.helperText = '';
  }

  return (
    <KeyboardDatePicker
      {...keyboardDatePickerProps}
      {...optionalProps}
      className={`mapistry-date-picker${propClass}`}
      format="MM/dd/yyyy"
      InputAdornmentProps={{ position: 'end' }}
      onChange={handleChange}
      renderDay={renderDay && handleRenderDay}
    />
  );
};

DatePicker.defaultProps = defaultProps;

type ParsableDateForDateFnsUtils = undefined | null | string | number | Date;
type ParsableDateForMomentUtils = ParsableDateForDateFnsUtils | Moment;
type MomentDatePickerProps = Omit<
  DatePickerProps,
  'value' | 'minDate' | 'maxDate'
> & {
  value: ParsableDateForMomentUtils;
  minDate?: ParsableDateForMomentUtils;
  maxDate?: ParsableDateForMomentUtils;
};

// existing usage of the DatePicker component assumed that our date management library
//   for our MUI pickers components was moment
// we have since transitioned to only using date-fns for our MUI pickers components,
//  so this component does any conversion of props that might be Moment objects
const MomentDatePicker = (props: MomentDatePickerProps): ReactElement => {
  const { value, minDate, maxDate, ...rest } = props;

  // convert incoming properties from Moment object to plain Date if needed
  const convertIfNeeded = (
    inputDate: ParsableDateForMomentUtils,
  ): ParsableDateForDateFnsUtils =>
    moment.isMoment(inputDate) ? inputDate.toDate() : inputDate;

  return (
    <DatePicker
      value={convertIfNeeded(value)}
      minDate={convertIfNeeded(minDate)}
      maxDate={convertIfNeeded(maxDate)}
      {...rest}
    />
  );
};

MomentDatePicker.defaultProps = defaultProps;

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default MomentDatePicker;
