import React from 'react';
import Button from '../../buttons/Button';

type ButtonProps = React.ComponentProps<typeof Button>;

type ChartCardSplashOverlayProps = {
  children: NonNullable<React.ReactNode>;
  ctaText: string;
  onEngage: ButtonProps['onClick'];
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function ChartCardSplashOverlay(props: ChartCardSplashOverlayProps) {
  const { children, ctaText, onEngage } = props;

  return (
    <div className="chart-default__container">
      <div className="chart-overlay__container">
        <div className="chart-overlay__content">{children}</div>
        <Button onClick={onEngage} color="secondary">
          {ctaText}
        </Button>
      </div>
      <img
        className="w-100 h-100"
        alt="Default chart"
        src="https://s3.amazonaws.com/mapistryAssets/chartDefault1.png"
      />
    </div>
  );
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default ChartCardSplashOverlay;
