import { RecordWithLinks, Records, Sheet } from '@flatfile/api/api';
import { storeFlatfileUploadResults } from '../../../apiClient/flatfile';
import {
  FlatfileLoggingContext,
  SubmitDataCallback,
  SubmitErrorCallback,
  UploadRecord,
} from '../types/flatfileTypes';
import {
  announceFinish,
  announceProgress,
  fetchRecordsForFirstSheetFromFlatfile,
} from './flatfileApiHelpers';
import { handleDataSubmitError } from './flatfileErrorHelpers';

function formatRecordAsFieldIdToValueMap(
  record: RecordWithLinks,
): UploadRecord {
  return Object.entries(record.values).reduce(
    (acc, [fieldKey, fieldInfo]) => ({
      ...acc,
      [fieldKey]: fieldInfo.value,
    }),
    {},
  );
}

async function trySubmittingDataAndHandleError(
  { jobId, sheetId }: { jobId: string; sheetId: string },
  records: Records,
  onSubmitData: SubmitDataCallback,
  onSubmitError?: SubmitErrorCallback,
  customErrorMessage?: string,
) {
  const uploadRecords: UploadRecord[] = records.map(
    formatRecordAsFieldIdToValueMap,
  );

  await announceProgress(jobId, 25, 'Sending your data');

  let successMessage: string | undefined;
  try {
    successMessage = await onSubmitData(uploadRecords, jobId);
  } catch (error) {
    await handleDataSubmitError(
      error,
      {
        jobId,
        sheetId,
        records,
      },
      onSubmitError,
      customErrorMessage,
    );
    return;
  }

  // if `onSubmitData` handler didn't return a success message,
  // a parent component for uploading modal will take care of showing success or error popups itself.
  if (successMessage) {
    await announceFinish(jobId, 'Success!', successMessage);
  }
}

function storeFlatfileResults(
  sheet: Sheet,
  records: Records,
  loggingContext: FlatfileLoggingContext,
) {
  const {
    id,
    workbookId,
    name,
    createdAt,
    updatedAt,
    config: { slug },
  } = sheet;

  // purposefully not waiting for these to be stored to let the user continue
  void storeFlatfileUploadResults({
    loggingContext,
    sheetInfo: {
      id,
      workbookId,
      name,
      createdAt,
      updatedAt,
      config: { slug, name },
    },
    records,
  });
}

export async function validateAndSubmitData(
  { jobId, workbookId }: { jobId: string; workbookId: string },
  onSubmitData: SubmitDataCallback,
  loggingContext: FlatfileLoggingContext,
  onSubmitError?: SubmitErrorCallback,
  customErrorMessage?: string,
) {
  await announceProgress(jobId, 10, 'Reading in your data');
  const { records, sheetId, sheet } =
    await fetchRecordsForFirstSheetFromFlatfile({
      jobId,
      workbookId,
    });
  await announceProgress(jobId, 20, 'Preparing your data');

  storeFlatfileResults(sheet, records, loggingContext);

  await trySubmittingDataAndHandleError(
    { jobId, sheetId },
    records,
    onSubmitData,
    onSubmitError,
    customErrorMessage,
  );
}
