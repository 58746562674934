import http from '../httpClient';
import { LoggedItem } from '../types';

export interface FetchLoggedItemsProps {
  logProjectId?: string;
  projectId?: string;
}

interface FetchLoggedItemsResponse {
  logProjectId: string;
  records: LoggedItem[];
}

export async function fetchLoggedItems({
  logProjectId,
  projectId,
}: FetchLoggedItemsProps): Promise<LoggedItem[]> {
  const res = await http.get<FetchLoggedItemsResponse>(
    `/api/v2/${projectId}/generic-logs/${logProjectId}/logged-items`,
  );
  return res.data?.records;
}

export type UpdateLoggedItemResponse = LoggedItem;

export async function updateLoggedItem(loggedItem: LoggedItem) {
  const res = await http.put<UpdateLoggedItemResponse>(
    `/api/v2/${loggedItem.projectId}/generic-logs/${loggedItem.logProjectId}/logged-items/${loggedItem.id}`,
    loggedItem,
  );
  return res.data;
}
