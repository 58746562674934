import React from 'react';

import HelpIcon from '../HelpIcon';

type THProps = {
  label: string;
  tooltipText?: string;
  width?: number;
  growth?: string;
  style: { width: string };
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function TH(props: THProps) {
  const { label, tooltipText, width, growth, style } = props;
  const widthClassName = width ? ` fb-${width}` : '';
  const growthClassName = growth ? ` fb-grow-${growth}` : '';

  return (
    <div
      className={`mapistry-table__header-cell${widthClassName}${growthClassName}`}
      style={style}
    >
      <span>{label}</span>
      {tooltipText && <HelpIcon text={tooltipText} />}
    </div>
  );
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default TH;
