import { Tooltip as MuiTooltip } from '@material-ui/core';
import { KeyService } from '@monorepo/shared/KeyService';
import { useOpenClose } from '@monorepo/shared/hooks/useOpenClose';
import React, { useCallback, useEffect } from 'react';

type MuiTooltipProps = React.ComponentProps<typeof MuiTooltip>;

type TooltipProps = Pick<MuiTooltipProps, 'children' | 'placement' | 'title'>;

/**
 * Wrapping function components with the MaterialUi Tooltip causes errors - see
 * https://github.com/mui-org/material-ui/issues/15663. Possible solutions:
 *  1. Wrap component with React.forwardRef: https://next--material-ui.netlify.com/guides/composition/#caveat-with-refs
 *  2. If that doesn't work, wrap the component with a plain div as mentioned in
 *    https://github.com/mui-org/material-ui/issues/15663#issuecomment-520778909 :
 *      <Tooltip>
 *        <div>
 *          <MyFunctionComponent />
 *        </div>
 *      </Tooltip
 */
export const Tooltip = ({
  children,
  placement = 'bottom',
  title,
}: TooltipProps) => {
  const [isOpen, open, close] = useOpenClose();

  const closeOnEscape = useCallback(
    (event) => {
      if (KeyService.isEscapeKey(event.keyCode)) {
        close();
      }
    },
    [close],
  );

  useEffect(() => {
    document.addEventListener('keydown', closeOnEscape);

    return () => {
      document.removeEventListener('keydown', closeOnEscape);
    };
  }, [closeOnEscape]);

  return (
    <MuiTooltip
      classes={{
        tooltip: 'tooltip-label',
        popper: 'mapistry-tooltip',
      }}
      interactive
      placement={placement}
      title={title}
      open={isOpen}
      onClose={close}
      onOpen={open}
    >
      {children}
    </MuiTooltip>
  );
};
