import axios, { AxiosError } from 'axios';
import { getDefaultErrorForStatus } from './ServerErrorHandler';
import {
  ErrorResponse,
  IndexedErrorResponse,
  MultiErrorResponse,
  StringErrorResponse,
} from './types';

export function isStringErrorResponse(
  error?: ErrorResponse | null | unknown,
): error is StringErrorResponse {
  if (!error) return false;
  // We are already inside a type-narrowing function
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return typeof (error as any)?.data === 'string';
}

export function isMultiErrorResponse(
  error?: ErrorResponse | null | unknown,
): error is MultiErrorResponse {
  if (!error) return false;
  // We are already inside a type-narrowing function
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const anyError: any = error as any;
  return (
    !!anyError?.data &&
    typeof anyError?.data === 'object' &&
    typeof anyError?.data !== null &&
    'message' in anyError.data &&
    'errors' in anyError.data &&
    !!anyError.data.errors?.length
  );
}

export function isIndexedErrorResponse(
  error?: ErrorResponse | null | unknown,
): error is IndexedErrorResponse {
  if (!error) return false;
  // We are already inside a type-narrowing function
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const anyError: any = error as any;
  return (
    anyError.status === 422 &&
    anyError.data?.isIndexed === true && // the isIndexed communicates that the errors are indexed to specific pieces of data
    typeof anyError.data?.message === 'string' &&
    isMultiErrorResponse(anyError)
  );
}

export const apiUrl = process.env.API_URL;

const http = axios.create({
  baseURL: apiUrl,
  timeout: 30 * 1000 - 100, // Heroku timeout is 30 seconds. Timeout a little early to catch it here.
  withCredentials: true,
});

http.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    // we don't know what errorData might be
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let errorData: any;
    let status: number | undefined;
    const requestUrl = error.config?.url || error.request?.responseUrl;

    if (error.response) {
      errorData = error.response.data;
      status = error.response.status;
    } else if (error.request) {
      // The request was made but no response was received
      if (error.code === 'ECONNABORTED' && /timeout/i.test(error.message)) {
        errorData = 'Request timed out';
        status = 504;
      }
      // no status or error message here, will fall back to default error
    } else {
      errorData = error.message;
    }
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject({
      data: errorData || getDefaultErrorForStatus(status),
      requestUrl,
      status,
    });
  },
);

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default http;
