import { CreateTaskRequest } from 'mapistry-shared';
import http from './httpClient';
import * as t from './types';

export type FetchTaskVariables = {
  projectId: string;
  taskId: string;
  dueDate?: string;
};

type FetchTaskResponse = t.Task;

export async function fetchTask(variables: FetchTaskVariables) {
  const { projectId, taskId, dueDate } = variables;
  const res = await http.get<FetchTaskResponse>(
    `/api/v2/projects/${projectId}/tasks/${taskId}${
      dueDate ? `/${dueDate}` : ''
    }`,
  );
  return res.data;
}

export type SaveTaskVariables = {
  projectId: string;
  taskValues: t.TaskValues;
};

type SaveTaskResponse = t.Task;

export async function saveTask(variables: SaveTaskVariables) {
  const { projectId, taskValues } = variables;
  const res = await http.post<SaveTaskResponse>(
    `/api/v2/projects/${projectId}/tasks`,
    {
      task: taskValues,
    },
  );
  return res.data;
}

// The server request will either succeed or throw an error.
type CreateTasksBulkResponse = {
  success: true;
};

export async function createTasksBulk(
  tasks: CreateTaskRequest[],
): Promise<CreateTasksBulkResponse> {
  const res = await http.post<CreateTasksBulkResponse>(
    `/api/v2/tasks/bulk`,
    tasks,
  );
  return res.data;
}

type DeleteTaskRequest = {
  allFuture: boolean;
  dueDate: string;
  id: string;
  isConcrete: boolean;
  isRecurring: boolean;
  projectId: string;
  templateId: string;
};

export async function deleteTask(deleteTaskRequest: DeleteTaskRequest) {
  const {
    allFuture,
    dueDate,
    isRecurring,
    isConcrete,
    projectId,
    id,
    templateId,
  } = deleteTaskRequest;
  const idForDelete = isConcrete ? id : templateId;
  const baseUrl = `/api/v2/projects/${projectId}/tasks/${idForDelete}`;
  await http.delete(
    isRecurring ? `${baseUrl}/${dueDate}?allFuture=${allFuture}` : baseUrl,
  );
  return deleteTaskRequest;
}
