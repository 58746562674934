/* eslint-disable */
import DefaultIcon from '@svg/m-event-calendar-default.svg';
import ExpandIcon from '@svg/m-event-calendar-expand.svg';
import FailureIcon from '@svg/m-event-calendar-failure.svg';
import SuccessIcon from '@svg/m-event-calendar-success.svg';
import WarningIcon from '@svg/m-event-calendar-warning.svg';
import {
  CalendarEventStatus,
  FullMonths,
  IntervalFrequencyEnum,
  IntervalFrequencyFactory,
  ShortMonths,
} from 'mapistry-shared';
import PropTypes from 'prop-types';
import React from 'react';
import { IconButton, MapistryTooltip } from '../../../elements';

const MonthNames = Object.values(FullMonths).map((month) =>
  month.toUpperCase(),
);

const AbbreviatedMonths = Object.values(ShortMonths).map((month) =>
  month.toUpperCase(),
);

const LowFrequencyEventCalendar = (props) => {
  const {
    eventGroups,
    helpTextForEvent,
    tooltipData,
    tooltipTitle,
    isEventClickable,
    onEventClick,
  } = props;

  const colorClass = (events, tooMuchData) => {
    if (tooMuchData) {
      return 'expand';
    }
    if (
      events.every(
        (e) =>
          e.status === CalendarEventStatus.COMPLETE ||
          e.status === CalendarEventStatus.EXTRA,
      )
    ) {
      return 'success';
    }
    if (events.some((e) => e.status === CalendarEventStatus.OVERDUE)) {
      return 'failure';
    }
    if (events.some((e) => e.status === CalendarEventStatus.UPCOMING)) {
      return 'warning';
    }
    return 'default';
  };

  const eventIconIdentifier = (event, tooMuchData) => {
    if (tooMuchData) {
      return 'expand';
    }
    switch (event.status) {
      case CalendarEventStatus.COMPLETE:
        return 'success';
      case CalendarEventStatus.OVERDUE:
        return 'failure';
      case CalendarEventStatus.UPCOMING:
        return 'warning';
      default:
        return 'default';
    }
  };

  const EventIcon = ({ event, tooMuchData }) => {
    const iconIdentifier = eventIconIdentifier(event, tooMuchData);
    const className = `m-event-calendar__${iconIdentifier}`;

    const iconIdentifierToIcon = {
      expand: ExpandIcon,
      success: SuccessIcon,
      failure: FailureIcon,
      warning: WarningIcon,
      default: DefaultIcon,
    };

    const Icon = iconIdentifierToIcon[iconIdentifier];

    return <Icon className={`m-icon ${className}`} />;
  };

  const getAbbreviatedLabel = (startDate, dueDate) =>
    `${AbbreviatedMonths[startDate.getMonth()]} - ${
      AbbreviatedMonths[dueDate.getMonth()]
    }`;

  const getYearLabel = (startDate, dueDate) => {
    if (startDate.getYear() !== dueDate.getYear()) {
      const startMonth = MonthNames[startDate.getMonth()];
      const dueMonth = MonthNames[dueDate.getMonth()];
      return `${startMonth} ${startDate.getFullYear()} - ${dueMonth} ${dueDate.getFullYear()}`;
    }
    return `${MonthNames[startDate.getMonth()]} - ${
      MonthNames[dueDate.getMonth()]
    }`;
  };

  const label = (event) => {
    const { frequency, startDate, dueDate, customFrequency } = event;

    if (customFrequency) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line default-case
      switch (frequency) {
        case IntervalFrequencyEnum.MONTH:
          if (customFrequency?.every >= 6) {
            return getYearLabel(startDate, dueDate);
          }
          if (customFrequency?.every > 1) {
            return getAbbreviatedLabel(startDate, dueDate);
          }
          return MonthNames[dueDate.getMonth()];

        case IntervalFrequencyEnum.YEAR:
          return getYearLabel(startDate, dueDate);
      }
    }

    switch (frequency) {
      case IntervalFrequencyEnum.MONTH:
        return MonthNames[dueDate.getMonth()];

      case IntervalFrequencyEnum.QUARTER:
        return getAbbreviatedLabel(startDate, dueDate);

      case IntervalFrequencyEnum.BIANNUAL:
      case IntervalFrequencyEnum.TWICE_BIANNUAL:
      case IntervalFrequencyEnum.YEAR:
        return getYearLabel(startDate, dueDate);

      default: {
        const labelDate = dueDate || startDate;
        return MonthNames[labelDate.getMonth()];
      }
    }
  };

  const renderEvents = (events, tooMuchData, isClickable) => (
    <div>
      {events.map((event) => {
        const title = tooltipTitle([event], tooMuchData, tooltipData);

        return (
          <MapistryTooltip key={event.key} title={title}>
            <span className="m-event-calendar__type-icon">
              <IconButton
                disabled={!isClickable && !tooMuchData}
                onClick={(clickEvent) =>
                  onEventClick(clickEvent, [event], tooMuchData)
                }
              >
                <EventIcon event={event} tooMuchData={tooMuchData} />
              </IconButton>
            </span>
          </MapistryTooltip>
        );
      })}
    </div>
  );

  const renderEventGroup = (eventGroup) => {
    const lowFrequencyEvents = [];
    const otherEvents = [];
    eventGroup.forEach((e) =>
      IntervalFrequencyFactory.For(e.frequency, e.customFrequency).isLow()
        ? lowFrequencyEvents.push(e)
        : otherEvents.push(e),
    );
    const tooMuchData = lowFrequencyEvents.length === 0;
    const eventsToRender = tooMuchData ? [otherEvents[0]] : lowFrequencyEvents;
    const sampleEvent = eventsToRender[0];
    const subtext =
      eventsToRender.length === 1
        ? helpTextForEvent(sampleEvent, tooMuchData)
        : '';
    const isClickable = isEventClickable && sampleEvent.startDate <= new Date();
    const disabledClass = isClickable
      ? ''
      : ' m-event-calendar__disabled-group';

    return (
      <div
        key={sampleEvent.dueDate || sampleEvent.startDate}
        className={`m-event-calendar__list__item m-event-calendar__type--${colorClass(
          eventsToRender,
          tooMuchData,
        )}${disabledClass}`}
      >
        <div className="m-event-calendar__tooltip-wrapper">
          {renderEvents(eventsToRender, tooMuchData, isClickable)}
          <span className="m-event-calendar__list__item-time">
            {label(sampleEvent)}
          </span>
          <span className="m-event-calendar__item-divider" />
          <span className="m-event-calendar__item-text">{subtext}</span>
        </div>
      </div>
    );
  };

  const anyMonthlyEvents = () =>
    Object.keys(eventGroups).some((key) => {
      const event = eventGroups[key][0];
      const intervalFrequency = IntervalFrequencyFactory.For(
        event.frequency,
        event.customFrequency,
      );
      return intervalFrequency.isMonthlyPermutation();
    });

  const groupClass = (divGroup) => {
    const sampleEvent = divGroup[0][0];

    if (sampleEvent.frequency === IntervalFrequencyEnum.YEAR) {
      return 'single-group';
    }

    const isCustomMonth =
      sampleEvent.customFrequency &&
      sampleEvent.frequency === IntervalFrequencyEnum.MONTH;

    const isCustomQuarter =
      isCustomMonth &&
      sampleEvent.customFrequency.every > 1 &&
      sampleEvent.customFrequency.every <= 3;

    const isCustomBiannual =
      isCustomMonth && sampleEvent.customFrequency.every > 3;

    if (
      isCustomBiannual ||
      sampleEvent.frequency === IntervalFrequencyEnum.BIANNUAL ||
      sampleEvent.frequency === IntervalFrequencyEnum.TWICE_BIANNUAL ||
      ((sampleEvent.frequency === IntervalFrequencyEnum.QUARTER ||
        isCustomQuarter) &&
        anyMonthlyEvents())
    ) {
      return 'double-group';
    }
    if (
      sampleEvent.frequency === IntervalFrequencyEnum.QUARTER ||
      isCustomQuarter
    ) {
      return 'quad-group';
    }
    return 'multi-group';
  };

  const divyEventGroups = () => {
    let prevFrequency = IntervalFrequencyFactory.For(
      IntervalFrequencyEnum.NEVER,
    );
    const monthlyFrequency = IntervalFrequencyFactory.For(
      IntervalFrequencyEnum.MONTH,
    );

    return Object.keys(eventGroups).reduce((acc, key) => {
      const sampleEvent = eventGroups[key][0];
      const currentFrequency = IntervalFrequencyFactory.For(
        sampleEvent.frequency,
        sampleEvent.customFrequency,
      );
      const lastDiv = acc[acc.length - 1];

      if (
        lastDiv &&
        (Number(prevFrequency) === Number(currentFrequency) ||
          (prevFrequency >= monthlyFrequency &&
            currentFrequency >= monthlyFrequency) ||
          sampleEvent.frequency === IntervalFrequencyEnum.AS_NEEDED)
      ) {
        lastDiv.push(eventGroups[key]);
      } else {
        acc.push([eventGroups[key]]);
      }

      prevFrequency = currentFrequency;
      return acc;
    }, []);
  };

  const renderEventGroups = () => {
    const dividedGroups = divyEventGroups();
    return dividedGroups.map((dividedGroup) => (
      <div
        key={dividedGroup[0][0].dueDate}
        className={`m-event-calendar__${groupClass(dividedGroup)}__list`}
      >
        {dividedGroup.map((eventGroup) => renderEventGroup(eventGroup))}
      </div>
    ));
  };

  return <div className="m-event-calendar__scroll">{renderEventGroups()}</div>;
};

/*
  eventGroups: {
    Date: [
      {
        startDate: Date,
        dueDate: Date,
        frequency: string,
        status: string
      }
    ]
  }
*/
LowFrequencyEventCalendar.propTypes = {
  eventGroups: PropTypes.shape({}).isRequired,
  helpTextForEvent: PropTypes.func.isRequired,
  isEventClickable: PropTypes.bool,
  onEventClick: PropTypes.func,
  tooltipData: PropTypes.any, // eslint-disable-line react/forbid-prop-types
  tooltipTitle: PropTypes.func.isRequired,
};

LowFrequencyEventCalendar.defaultProps = {
  isEventClickable: false,
  onEventClick: null,
  tooltipData: null,
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default LowFrequencyEventCalendar;
