import React from 'react';
import PropTypes from 'prop-types';
import { KeyService } from '@monorepo/shared/KeyService';
import { ArrowDropDown, ArrowRight } from './MuiIcon';

// Bootstrap expects header to be <a> element, but we use div with button role for accessibility,
// that's why have to applying styles we need ourselves
const headerStyles = {
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'center',
  padding: '5px 10px 5px 0',
};

// This component is used only in old Wastewater suite and it's built with Bootstrap,
// its styles need to be revised before using in other parts in our code
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default class AccordionPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isExpanded: props.initialIsExpanded };
  }

  render() {
    const { isExpanded } = this.state;
    const { children, className, headingView } = this.props;
    const expandIcon = isExpanded ? <ArrowDropDown /> : <ArrowRight />;

    return (
      <div className={`panel panel-default ${className}`}>
        <div className="panel-heading">
          <div
            aria-expanded={isExpanded}
            onClick={() => this.setState({ isExpanded: !isExpanded })}
            onKeyDown={(e) => {
              if (KeyService.triggersButtonClick(e.keyCode)) {
                this.setState({ isExpanded: !isExpanded });
              }
            }}
            role="button"
            style={headerStyles}
            tabIndex={0}
          >
            {expandIcon}
            {headingView}
          </div>
        </div>
        {isExpanded && <div className="panel-collapse">{children}</div>}
      </div>
    );
  }
}

AccordionPanel.defaultProps = {
  className: '',
  initialIsExpanded: true,
};

AccordionPanel.propTypes = {
  className: PropTypes.string,
  headingView: PropTypes.element.isRequired,
  initialIsExpanded: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
};
