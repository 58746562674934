import http from '@monorepo/shared/apiClient/httpClient';
import { BaseService } from './BaseService';

class ProjectService extends BaseService {
  async getById(projectId) {
    const url = `/api/v2/projects/${projectId}`;
    try {
      const res = await http.get(url);
      return res.data;
    } catch {
      return null;
    }
  }

  async getCalendars(projectId) {
    const url = `/api/v2/projects/${projectId}/calendars`;

    try {
      const res = await http.get(url);
      return res.data;
    } catch {
      return null;
    }
  }

  async getModules(projectId) {
    const url = `/api/v2/projects/${projectId}/modules`;

    try {
      const res = await http.get(url);
      return res.data;
    } catch {
      return null;
    }
  }
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default ProjectService;
