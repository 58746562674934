import React from 'react';
import PropTypes from 'prop-types';
import { CalendarEventStatus } from 'mapistry-shared';
import {
  differenceInCalendarDays,
  format,
  isAfter,
  isToday,
  isValid,
  endOfYear,
} from 'date-fns';
import { localEquivalentOfUTC } from '../../utils';

const DueDate = (props) => {
  const { dueDateInUTC, status } = props;
  const dueDate = localEquivalentOfUTC(dueDateInUTC);
  if (!isValid(dueDate)) {
    return null;
  }

  let className;
  let message;
  const now = new Date();
  const daysOverdue = differenceInCalendarDays(now, dueDate);
  if (isToday(dueDate)) {
    className = 'color-warning-yellow';
    message = '(Due Today)';
  } else if (status === CalendarEventStatus.OVERDUE && daysOverdue > 0) {
    className = 'color-red';
    const days = daysOverdue === 1 ? 'day' : 'days';
    message = `${daysOverdue} ${days} overdue`;
  } else {
    if (
      status === CalendarEventStatus.UPCOMING ||
      (status === CalendarEventStatus.OVERDUE && daysOverdue < 1) // BE calcs stauses based on UTC dates
    ) {
      className = 'color-warning-yellow';
    }
    const isAfterThisYear = isAfter(dueDate, endOfYear(new Date()));
    const formattedDate = isAfterThisYear
      ? format(dueDate, 'MMM do, yyyy')
      : format(dueDate, 'MMM do');
    message = `(Due ${formattedDate})`;
  }

  return <span className={className}>{message}</span>;
};

DueDate.propTypes = {
  dueDateInUTC: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]).isRequired,
  status: PropTypes.oneOf(Object.values(CalendarEventStatus)),
};

DueDate.defaultProps = {
  status: CalendarEventStatus.INCOMPLETE,
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default DueDate;
