import React from 'react';
import { useCurrentLogProject } from '../LogProjectContext';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const InformationalText = () => {
  const { isLoading, logProject } = useCurrentLogProject();
  if (isLoading || !logProject) return null;
  const { informationalText } = logProject;
  return <div className="informational-text">{informationalText}</div>;
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default InformationalText;
