import {
  DateLike,
  Days,
  IntervalFrequencyEnum,
  Months,
  UnitString,
} from 'mapistry-shared';
import qs from 'qs';
import { ParameterLimitType } from '../../../old-web/js/components/wastewater/SamplingResultRecordPanel/EditParametersModal/types';
import http from '../httpClient';

export type FetchWastewaterCalendarEventsProps = {
  projectId: string;
  parameterSlug: string;
  date: DateLike;
};

// TODO: the wastewater-sampling-parameters endpoint currently returns snake case.
// In the future, it would be great to switch this to camel case. I did not have room to
// fix all the places that would be affected by it now.
type CustomFrequencyModel = {
  number_of_events: number;
  can_add_more?: boolean;
  every: number;
  start_month?: number | null;
  start_day?: number | null;
  repeat_on?: Days[] | Months[] | null;
  non_custom_frequency?: IntervalFrequencyEnum;
};

type SamplingParametersResponse = {
  readonly created_at: DateLike;
  id: string;
  limitType: ParameterLimitType;
  parameter_slug: string;
  project_id: string;
  sp_monitoring_locations: [
    | {
        calculation_methodology: null;
        readonly created_at: DateLike;
        custom_frequency: CustomFrequencyModel;
        frequency: IntervalFrequencyEnum;
        id: string;
        limit_lower: null;
        limit_upper: null;
        monitoring_location_id: string;
        sampling_type: string;
        units: UnitString;
        readonly updated_at?: DateLike;
        wastewater_sampling_parameter_id: string;
      }
    | {
        frequency: 'as-needed';
        is_qa_location: null | boolean;
        monitoring_location_id: string;
        wastewater_sampling_parameter_id: string;
      },
  ];
  readonly updated_at?: DateLike;
}[];

export async function fetchWastewaterSamplingParameters(projectId: string) {
  const res = await http.get<SamplingParametersResponse>(
    `/api/v2/${projectId}/wastewater-sampling-parameters`,
  );
  return res.data;
}
export async function deleteWastewaterSamplingEvent(
  projectId: string,
  eventId: string,
) {
  const res = await http.delete<boolean>(
    `/api/v2/${projectId}/wastewater-sampling-events/${eventId}`,
  );
  return res.data;
}

export async function fetchWastewaterCalendarEvents({
  projectId,
  parameterSlug,
  date,
}: FetchWastewaterCalendarEventsProps) {
  const url = `/api/v2/${projectId}/calendar-events/wastewaterSampling?${qs.stringify(
    {
      date,
      parameters: parameterSlug,
    },
  )}`;

  const res = await http.get(url);
  return res.data;
}
