export const defaultErrorMsg =
  "Oops! There's been a problem. Please try again or reach out to support@mapistry.com for assistance.";

export const getDefaultErrorForStatus = (status?: number): string => {
  // We can eventually show different default messages based on server status code.
  // For now I am just returning one default error message regardless of status.
  // You can override this default message by sending an error payload from the server,
  // or by providing a custom error message in the correct reducer.
  switch (status) {
    case 404:
      return "We couldn't find an item you are trying to get or update.";
    case 422:
    case 401:
    default:
      return defaultErrorMsg;
  }
};
