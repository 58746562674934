import { Api } from '@monorepo/shared/apiClient';
import { useCurrentUser } from '@monorepo/shared/hooks/useCurrentUser';
import { useCallback } from 'react';
import { MutationConfig, queryCache, useMutation } from 'react-query';

type Input = {
  config?: MutationConfig<Api.User>;
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useUpdateCurrentUser(input?: Input) {
  const { currentUser } = useCurrentUser();
  const updateCurrentUser = useCallback(
    (userValues: Api.UserValues) =>
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      Api.updateUser({ userId: currentUser!.id, userValues }),
    [currentUser],
  );
  const mutationInfo = useMutation(updateCurrentUser, {
    ...input?.config,
    onSuccess: (user, variables) => {
      queryCache.setQueryData('currentUser', user);
      return input?.config?.onSuccess?.(user, variables);
    },
  });
  return mutationInfo;
}
