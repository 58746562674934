import React, { useMemo } from 'react';
import { useField, UseFieldConfig } from 'react-final-form';
import { ControlledTextInputProps, TextInput } from '../TextInput';

type TextFieldProps<ValueType> = ControlledTextInputProps & {
  label?: string;
  name: string;
  validate?: UseFieldConfig<ValueType>['validate'];
};

export const TextField = <ValueType,>({
  helperText,
  label,
  name,
  validate,
  ...rest
}: TextFieldProps<ValueType>) => {
  const { input, meta } = useField<ValueType>(name, { validate });
  const helpText = useMemo<string | null>(() => {
    if (meta.error && (meta.touched || meta.modified)) {
      return meta.error;
    }
    return helperText ?? null;
  }, [helperText, meta.error, meta.modified, meta.touched]);
  return (
    <TextInput
      {...rest}
      controlled
      error={!!meta.error && (meta.touched || meta.modified)}
      helperText={helpText}
      id={`text-field-${name}`}
      label={label}
      onChange={input.onChange}
      value={input.value}
    />
  );
};
