import { requestFormPdfUrl } from '../apiCaller';
import { ApiError, BaseService } from './BaseService';

export class FormSubmissionService extends BaseService {
  async save(formSubmission) {
    const url = `${this.apiUrl}/api/v2/${formSubmission.projectId}/form-submissions`;
    return fetch(url, {
      credentials: 'include',
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formSubmission),
    }).then((result) => {
      if (result.ok) {
        return result.json();
      }

      if (result.status >= 400 && result.status < 500) {
        return result.text().then((errorMessage) => {
          throw new ApiError(errorMessage);
        });
      }

      throw new ApiError('There was a problem saving the form.');
    });
  }

  delete(projectId, submissionId) {
    const url = `${this.apiUrl}/api/v2/${projectId}/form-submissions/${submissionId}`;
    return fetch(url, {
      credentials: 'include',
      method: 'DELETE',
    }).then((result) => {
      if (result.ok) {
        return result.json();
      }
      throw new ApiError('failed to delete submission');
    });
  }

  async getBySlug(projectId, submissionSlug, templateSlug, calendarName) {
    let url = `${this.apiUrl}/api/v2/${projectId}/form-submissions/${submissionSlug}/${templateSlug}`;
    if (calendarName) {
      url = `${url}/${calendarName}`;
    }

    return fetch(url, { credentials: 'include' }).then((result) => {
      if (result.ok) {
        return result.json();
      }
      return null;
    });
  }

  async requestLock(projectId, calendarName, submissionSlug) {
    const url = `${this.apiUrl}/api/v2/${projectId}/form-submissions/${calendarName}/${submissionSlug}/lock`;

    return fetch(url, {
      credentials: 'include',
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((result) => {
      if (result.ok) {
        return result.json();
      }
      // When the request for the lock fails, we don't do anything. We still allow the user to edit and make changes.
      // We just don't show lock information.
      return null;
    });
  }

  async releaseLock(projectId, calendarName, submissionSlug) {
    const url = `${this.apiUrl}/api/v2/${projectId}/form-submissions/${calendarName}/${submissionSlug}/lock`;

    return fetch(url, {
      credentials: 'include',
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      keepalive: true,
    }).then((result) => {
      if (result.ok) {
        // eslint-disable-next-line no-useless-return
        return;
      }
      // Maybe retry the releaseLock eventually.
      // Right now we rely on the lock timing out after 45 minutes either way.
    });
  }

  isComplete(formSubmission) {
    const url = `${this.apiUrl}/api/v2/${formSubmission.projectId}/form-submissions/is-complete`;
    return fetch(url, {
      credentials: 'include',
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formSubmission),
    }).then((result) => {
      if (result.ok) {
        return result.json();
      }
      throw new ApiError('failed to get completion information');
    });
  }

  requestPdf(submissionId) {
    return fetch(requestFormPdfUrl(submissionId), {
      credentials: 'include',
    }).then((result) => {
      if (result.ok) {
        return true;
      }
      throw new ApiError('failed to request form submission pdf');
    });
  }
}

export const REQUEST_PDF_SUCCEEDED_MESSAGE =
  'The files you requested are being processed and you will receive an email with a link to download them. Keep in mind, large numbers of files can take up to 30 minutes to process.';
