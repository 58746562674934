import React, { useCallback, useState } from 'react';
import { Input } from '@material-ui/core';
import { Button } from '@monorepo/shared/components/buttons/Button';
import { BaseDialog } from './BaseDialog';

interface PromptDialogProps {
  cancelButtonText?: string;
  confirmButtonText?: string;
  danger?: boolean;
  description?: string;
  initialInputValue?: string;
  multiline?: boolean;
  onConfirm: (inputValue: string) => void;
  onCancel: () => void;
  open: boolean;
  showCloseButton?: boolean;
  title: string;
}

export function PromptDialog(props: PromptDialogProps) {
  const {
    open,
    title,
    description,
    initialInputValue,
    onConfirm,
    onCancel,
    danger,
    multiline,
    showCloseButton,
  } = props;
  const [inputValue, setInputValue] = useState(initialInputValue ?? '');

  const handleConfirm = useCallback(() => {
    onConfirm(inputValue);
  }, [onConfirm, inputValue]);

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line prefer-destructuring
      const value = e.target.value;
      setInputValue(value);
    },
    [],
  );

  const handleKeyPress = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        handleConfirm();
      }
    },
    [handleConfirm],
  );

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/destructuring-assignment
  const confirmButtonText = props.confirmButtonText ?? 'OK';
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/destructuring-assignment
  const cancelButtonText = props.cancelButtonText ?? 'Cancel';

  return (
    <BaseDialog
      open={open}
      title={title}
      description={description}
      buttons={
        <>
          <Button onClick={onCancel} color="secondary">
            {cancelButtonText}
          </Button>
          <Button onClick={handleConfirm} color={danger ? 'danger' : 'primary'}>
            {confirmButtonText}
          </Button>
        </>
      }
      onClose={onCancel}
      showCloseButton={showCloseButton}
    >
      <Input
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={multiline ? undefined : handleKeyPress}
        multiline={multiline}
        autoFocus
        fullWidth
      />
    </BaseDialog>
  );
}
