import { initializeFlatfile } from '@flatfile/javascript';
import { colors } from '../../../themes/colors';

// Some of the flatfile styling has to happen via direct css overrides,
// check out _interactiveFileUploadModal.scss
type FlatfileThemeConfig = Parameters<
  typeof initializeFlatfile
>[0]['themeConfig'];

// Documentation
// - https://flatfile.com/docs/guides/theming
// - https://flatfile.com/docs/apps/embedding/javascript/new_space_quickstart#6-match-your-brand
export const flatfileThemeConfig: FlatfileThemeConfig = {
  root: {
    fontFamily: 'Roboto, sans-serif',
    primaryColor: colors.darkGreen,
    actionColor: colors.darkGreen,
    dangerColor: colors.red,
    warningColor: colors.yellow,
    successColor: colors.green,
  },
};
