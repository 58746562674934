/* eslint-disable */
// when editing this file please uncomment the above and fix the linting errors
import React from 'react';
import PropTypes from 'prop-types';
import MapistryTooltip from './MapistryTooltip';
import { SvgIcon } from '@monorepo/shared/components/icons/SvgIcon';

class FieldWrapper extends React.Component {
  header() {
    const { error, headingRef, label } = this.props;
    const errorClass = error ? ' error' : '';

    return (
      <div className="field-heading" ref={headingRef}>
        <div className="main-heading">
          <span className={`field-label${errorClass}`}>{label}</span>
          {this.requiredText()}
          {this.helpIcon()}
        </div>
        {this.subtext()}
      </div>
    );
  }

  footer() {
    const { footerText } = this.props;
    return footerText && <div className="field-footer">{footerText}</div>;
  }

  requiredText() {
    const { isRequired } = this.props;

    if (isRequired) {
      return <span className="field-required">(required)</span>;
    }
    return null;
  }

  helpIcon() {
    /* eslint-disable react/no-danger */
    const { helpText, helpTextPlacement } = this.props;

    if (helpText) {
      return (
        <MapistryTooltip
          placement={helpTextPlacement}
          title={<span dangerouslySetInnerHTML={{ __html: helpText }} />}
        >
          <span tabIndex="0" aria-label={helpText} className="field-help">
            <SvgIcon identifier="help" className="help-icon" />
          </span>
        </MapistryTooltip>
      );
    }
    return null;
  }

  subtext() {
    const { subtext } = this.props;
    if (subtext) {
      return <div className="heading-subtext">{subtext}</div>;
    }
    return null;
  }

  errorMessage() {
    const { errorMessage } = this.props;
    if (errorMessage) {
      return <div className="field-error-text">{errorMessage}</div>;
    }
    return null;
  }

  render() {
    const { className, children } = this.props;
    const propClass = className ? ` ${className}` : '';
    return (
      <div className={`mapistry-field${propClass}`}>
        {this.header()}
        <div className="flex-div">
          <div className="field-input">{children}</div>
          {this.footer()}
        </div>
        {this.errorMessage()}
      </div>
    );
  }
}

FieldWrapper.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  error: PropTypes.bool,
  errorMessage: PropTypes.node,
  footerText: PropTypes.node,
  headingRef: PropTypes.any,
  helpText: PropTypes.string,
  helpTextPlacement: PropTypes.string,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  subtext: PropTypes.string,
};

FieldWrapper.defaultProps = {
  children: [],
  className: null,
  error: false,
  errorMessage: null,
  footerText: null,
  helpText: null,
  helpTextPlacement: 'top',
  isRequired: false,
  label: '',
  subtext: null,
};

export default FieldWrapper;
