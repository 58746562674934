/* eslint-disable */
import _ from 'underscore';
import qs from 'qs';

export const DELETE = 'DELETE';
export const GET = 'GET';
export const PATCH = 'PATCH';
export const POST = 'POST';
export const PUT = 'PUT';

export const Types = {
  DELETE,
  GET,
  PATCH,
  POST,
  PUT,
};

export function makeCall(url, verb, data, auth, headers) {
  const config = {
    // Default to application/json on POST and PUT unless explicitly overridden
    headers: _.contains([Types.POST, Types.PUT], verb)
      ? {
          'Content-Type': 'application/json',
          ...headers,
        }
      : headers,
    method: verb,
  };

  if (auth) {
    config.credentials = 'include';
  }

  let fullUrl = url;
  if (!_.isUndefined(data) && _.isObject(data)) {
    if (verb === Types.GET) {
      fullUrl += qs.stringify(data, { addQueryPrefix: true });
    } else {
      config.body = JSON.stringify(data);
    }
  }

  return (
    window
      .fetch(fullUrl, config)
      .then((response) => {
        // Don't try to parse body for opaque responses or responses that return image
        if (
          response.type === 'opaque' ||
          _.includes(response.headers.get('Content-Type'), 'image/')
        ) {
          return { response };
        }

        // If server request failed and response is in html format
        // try to parse response body to get server error
        // @see Fetch API ReadableStream documentation: https://developer.mozilla.org/en-US/docs/Web/API/ReadableStream
        if (!response.ok && response.body instanceof ReadableStream) {
          const bodyStreamPromise = new Response(response.body, {
            headers: { 'Content-Type': 'text/html' },
          }).text();
          return bodyStreamPromise
            .then((parsedBody) => ({ error: parsedBody }))
            .catch((e) => ({
              error: 'Server request failed.',
              parsingException: e,
            }));
        }

        // Parse the response into JSON if possible
        return (
          response
            .json()
            .then((body) => ({ body, response }))
            // If JSON parsing fails, `response` is still available
            .catch((e) => ({ parsingException: e, response }))
        );
      })
      // Handle an error in fetch() itself
      .catch((e) => ({ error: e }))
      // Response body defaults to empty object
      .then(({ body = {}, error, response }) => {
        // The promise is rejected if:
        //  - If response status isnt OK
        //  - If `fetch()` threw an error
        //  - JSON parsing fails
        if (!response || !response.ok || error) {
          return Promise.reject({ body, error, response });
        }
        return { body, response };
      })
  );
}
