import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { Toast, ToastProps, ToastStatus } from '../components/Toast';
import { UserFriendlyErrorMessage } from '../componentsV2/errorDisplays/UserFriendlyErrorMessage';

type ToastOptions = Partial<
  Omit<ToastProps, 'isOpen' | 'message' | 'setIsOpen' | 'status'>
>;
type ToastMessage = ToastProps['message'];
type ToastState = Partial<
  Omit<ToastProps, 'isOpen' | 'message' | 'setIsOpen' | 'status'>
> & { message: ToastMessage; status: ToastStatus };
type PropSetter = (message: ToastMessage, options?: ToastOptions) => void;
interface ToastContextValue {
  error: PropSetter;
  info: PropSetter;
  success: PropSetter;
  warning: PropSetter;
}

interface ToastProviderProps {
  children: React.ReactNode;
}

export const ToastContext = createContext<ToastContextValue | undefined>(
  undefined,
);

export const ToastProvider = ({ children }: ToastProviderProps) => {
  const [toastProps, setToastProps] = useState<ToastState>({
    message: '',
    status: ToastStatus.STATUS_INFO,
  });
  const [open, setOpen] = useState(false);

  const setProps = useCallback(
    (status: ToastStatus) => (message: ToastMessage, options?: ToastOptions) => {
      setToastProps({ message, status, ...options });
      setOpen(true);
    },
    [],
  );

  const toasts = useMemo(
    () => ({
      error: setProps(ToastStatus.STATUS_ERROR),
      info: setProps(ToastStatus.STATUS_INFO),
      success: setProps(ToastStatus.STATUS_SUCCESS),
      warning: setProps(ToastStatus.STATUS_WARNING),
    }),
    [setProps],
  );

  return (
    <ToastContext.Provider value={toasts}>
      <Toast isOpen={open} setIsOpen={setOpen} {...toastProps} />
      {children}
    </ToastContext.Provider>
  );
};

export const useToast = () => {
  const toast = useContext(ToastContext);
  if (!toast) {
    throw new Error('Need to use the ToastProvider!');
  }

  const showUserFriendlyErrorToast = useCallback(
    (error: unknown, prependText?: string, options?: ToastOptions) =>
      toast.error(
        <UserFriendlyErrorMessage error={error} prependedText={prependText} />,
        options,
      ),
    [toast],
  );

  const toastWithUserFriendlyError = useMemo(
    () => ({ ...toast, showUserFriendlyErrorToast }),
    [toast, showUserFriendlyErrorToast],
  );

  return toastWithUserFriendlyError;
};
