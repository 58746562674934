import { Divider, InputLabel } from '@material-ui/core';
import cn from 'classnames';
import React from 'react';
import ReactSelect from 'react-select';

type ReactSelectProps = React.ComponentProps<typeof ReactSelect>;

type SelectProps = ReactSelectProps & {
  error?: boolean;
  errorMessage?: string;
  isFixed?: boolean;
  label?: string;
  name?: string;
  noOptionsMessage?: string;
};

export const Select = ({
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/prop-types
  className,
  error,
  errorMessage,
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/prop-types
  formatGroupLabel,
  isFixed,
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/prop-types
  isMulti,
  label,
  name,
  noOptionsMessage,
  ...other
}: SelectProps) => {
  const customStyles = {
    /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
    menuList: (base) => ({
      ...base,
      maxHeight: 375,
    }),
  };

  const handleFormatGroupLabel = () => (
    <div className="mapistry-select--divider">
      <Divider />
    </div>
  );

  return (
    <div
      className="mapistry-select-with-label"
      title={`Select ${label || name}`}
    >
      {label && (
        <InputLabel className={cn({ error })} id={`select-${name}-label`}>
          {label}
        </InputLabel>
      )}
      <ReactSelect
        {...other}
        aria-labelledby={`select-${name}-label`}
        className={cn([
          'mapistry-select',
          [className],
          { error, 'mapistry-select--multi': isMulti },
        ])}
        classNamePrefix="mapistry-select"
        error={error}
        formatGroupLabel={formatGroupLabel ?? handleFormatGroupLabel}
        id={`select-${name}`}
        isMulti={isMulti}
        menuPosition={isFixed ? 'fixed' : 'absolute'}
        noOptionsMessage={() => noOptionsMessage || 'No Options'}
        styles={customStyles}
      />
      {error && (
        <p className="mapistry-select__error-message">{errorMessage}</p>
      )}
    </div>
  );
};
