/*
  Codeship builds were failing with `Error: EMFILE: too many open files...` during the
  browserify bundling task, which appeared to stem from browserify trying to bundle
  the thousands of files in `@material-ui/icons` into the app. It's unclear if the
  frontend build supports proper tree-shaking, hence using the Material UI recommended
  way of importing these icons for now (see the `@material-ui/icons` package README):

    import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
    import ThreeDRotation from '@material-ui/icons/ThreeDRotation';

  Potential alternate (already tested) solution if the problem becomes more widespread:
  patch Node's `fs` with `graceful-fs.gracefulify` in the gulpfile.js.
  See https://stackoverflow.com/a/38099737 for more details.
*/
import Add from '@material-ui/icons/Add';
import AccessTime from '@material-ui/icons/AccessTime';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import ArrowRight from '@material-ui/icons/ArrowRight';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import Close from '@material-ui/icons/Close';
import ExpandMore from '@material-ui/icons/ExpandMore';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import MoreVert from '@material-ui/icons/MoreVert';
import PlayArrow from '@material-ui/icons/PlayArrow';
import RadioButtonChecked from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUnchecked from '@material-ui/icons/RadioButtonUnchecked';

export {
  Add,
  AccessTime,
  ArrowDropDown,
  ArrowRight,
  CancelIcon,
  CheckBoxIcon,
  CheckBoxOutlineBlank,
  ChevronRight,
  Clear,
  Close,
  ExpandMore,
  KeyboardArrowDown,
  MoreHoriz,
  MoreVert,
  PlayArrow,
  RadioButtonChecked,
  RadioButtonUnchecked,
};
