import { FlatfileRecord } from '@flatfile/plugin-record-hook';

export function setWithMessage(
  fieldKey: string,
  record: FlatfileRecord,
  value: Parameters<FlatfileRecord['set']>[1],
  message: string,
) {
  record.set(fieldKey, value);
  record.addInfo(fieldKey, message);
}
