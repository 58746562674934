import React, { useMemo } from 'react';
import { Button } from '@monorepo/shared/components/buttons/Button';
import { BaseDialog } from './BaseDialog';

type ButtonProps = React.ComponentProps<typeof Button>;

interface ConfirmDialogChoice {
  label: string;
  value: string;
  buttonProps: Pick<ButtonProps, 'color' | 'variant'>;
}

interface BaseConfirmDialogProps {
  cancelButtonText?: string;
  description?: string;
  onCancel: () => void;
  open: boolean;
  showCloseButton?: boolean;
  title: string;
}

export interface SingleChoiceConfirmDialogProps extends BaseConfirmDialogProps {
  confirmButtonText?: string;
  danger?: boolean;
  onConfirm: () => void;
}

export interface MultiChoiceConfirmDialogProps extends BaseConfirmDialogProps {
  confirmChoices: ConfirmDialogChoice[];
  onConfirm: (choice: string) => void;
}

type ConfirmDialogProps =
  | SingleChoiceConfirmDialogProps
  | MultiChoiceConfirmDialogProps;

export function ConfirmDialog(props: ConfirmDialogProps) {
  const { open, title, description, onCancel, showCloseButton } = props;

  const confirmButtons = useMemo(() => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    if (isMultiChoice(props)) {
      const { onConfirm, confirmChoices } = props;
      return confirmChoices.reverse().map((opt) => {
        const { label, value, buttonProps } = opt;
        return (
          <Button key={value} {...buttonProps} onClick={() => onConfirm(value)}>
            {label}
          </Button>
        );
      });
    }

    const { onConfirm, danger } = props;
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/destructuring-assignment
    const confirmButtonText = props.confirmButtonText || 'OK';
    return (
      <Button onClick={onConfirm} color={danger ? 'danger' : 'primary'}>
        {confirmButtonText}
      </Button>
    );
  }, [props]);

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/destructuring-assignment
  const cancelButtonText = props.cancelButtonText ?? 'Cancel';

  return (
    <BaseDialog
      open={open}
      title={title}
      description={description}
      buttons={
        <>
          <Button onClick={onCancel} color="secondary">
            {cancelButtonText}
          </Button>
          {confirmButtons}
        </>
      }
      onClose={onCancel}
      showCloseButton={showCloseButton}
    />
  );
}

function isMultiChoice(
  props: ConfirmDialogProps,
): props is MultiChoiceConfirmDialogProps {
  return 'confirmChoices' in props && props.confirmChoices != null;
}
