import { BaseProperty, Property } from '@flatfile/api/api';
import { FlatfileSingleSelectOption } from '../types/flatfileTypes';
import {
  WithoutNilValues,
  removeKeysWithNilValues,
} from './flatfileSettingsHelpers';

export const dateFormatExamples = '"MM/DD/YYYY" or "YYYY-MM-DD"';
export const dateTimeFormatExamples =
  '"MM/DD/YYYY HH:MM PM" or "YYYY-MM-DD HH:MM"';
export const timeFormatExamples = '"HH:MM PM" or "HH:MM" or "HHMM"';

enum FlatfileColumnTypes {
  BOOLEAN = 'boolean',
  STRING = 'string',
  NUMBER = 'number',
  ENUM = 'enum',
}

export type ColumnInfo = {
  key: string;
  label: string;
  isRequired?: boolean;
  description?: string;
  alternativeNames?: string[];
  readonly?: boolean;
};

export type SheetOptions = { nothingIsRequired?: boolean };

function baseSettings(columnInfo: ColumnInfo): WithoutNilValues<BaseProperty> {
  const { key, label, description, alternativeNames, readonly } = columnInfo;
  const settings: BaseProperty = {
    key,
    label,
    description,
    alternativeNames,
    readonly,
  };

  return removeKeysWithNilValues(settings);
}

export function getBooleanColumnSetting(
  columnInfo: ColumnInfo,
  { nothingIsRequired }: SheetOptions = { nothingIsRequired: false },
): Property.Boolean {
  const isRequired = columnInfo.isRequired && !nothingIsRequired;
  return {
    ...baseSettings(columnInfo),
    type: FlatfileColumnTypes.BOOLEAN,
    constraints: isRequired ? [{ type: 'required' }] : [],
  };
}

export function getTextColumnSetting(
  columnInfo: ColumnInfo,
  { nothingIsRequired }: SheetOptions = { nothingIsRequired: false },
): Property.String {
  const isRequired = columnInfo.isRequired && !nothingIsRequired;
  return {
    ...baseSettings(columnInfo),
    type: FlatfileColumnTypes.STRING,
    constraints: isRequired ? [{ type: 'required' }] : [],
  };
}

export function getNumericColumnSetting(
  columnInfo: ColumnInfo,
  { nothingIsRequired }: SheetOptions = { nothingIsRequired: false },
): Property.Number {
  const isRequired = columnInfo.isRequired && !nothingIsRequired;

  return {
    ...baseSettings(columnInfo),
    type: FlatfileColumnTypes.NUMBER,
    constraints: isRequired ? [{ type: 'required' }] : [],
  };
}

/**
 * Pair this with a validateDateColumn record hook.
 * Flatfile has a date type column, but that doesn't allow us to do our own validation and formatting of the value as easily.
 */
export function getDateColumnSetting(
  columnInfo: ColumnInfo,
  { nothingIsRequired }: SheetOptions = { nothingIsRequired: false },
): Property.String {
  return getTextColumnSetting(
    { ...columnInfo, description: `e.g. in format ${dateFormatExamples}` },
    { nothingIsRequired },
  );
}

export function getDateTimeColumnSetting(
  columnInfo: ColumnInfo,
  { nothingIsRequired }: SheetOptions = { nothingIsRequired: false },
): Property.String {
  return getTextColumnSetting(
    { ...columnInfo, description: `e.g. in format ${dateTimeFormatExamples}` },
    { nothingIsRequired },
  );
}

export function getTimeColumnSetting(
  columnInfo: ColumnInfo,
  { nothingIsRequired }: SheetOptions = { nothingIsRequired: false },
): Property.String {
  return getTextColumnSetting(
    { ...columnInfo, description: `e.g. in format ${timeFormatExamples}` },
    { nothingIsRequired },
  );
}

export function getSingleSelectColumnSetting(
  columnInfo: ColumnInfo,
  options: FlatfileSingleSelectOption[],
  { nothingIsRequired }: SheetOptions = { nothingIsRequired: false },
): Property.Enum {
  const isRequired = columnInfo.isRequired && !nothingIsRequired;
  const optionsWithoutNull = options.map(removeKeysWithNilValues);

  return {
    ...baseSettings(columnInfo),
    type: FlatfileColumnTypes.ENUM,
    config: {
      allowCustom: false,
      options: optionsWithoutNull,
    },
    multi: false,
    constraints: isRequired ? [{ type: 'required' }] : [],
  };
}

export function getSingleSelectColumnSettingFromStringOptions(
  columnInfo: ColumnInfo & { options: string[] },
  { nothingIsRequired }: SheetOptions = { nothingIsRequired: false },
): Property.Enum {
  if (!('options' in columnInfo && columnInfo.options)) {
    throw new Error(
      `Couldn't generate columns for upload: Single Select field doesn't have options (${columnInfo.key})`,
    );
  }
  const options = columnInfo.options.map((op) => ({
    label: op,
    value: op,
  }));
  return getSingleSelectColumnSetting(columnInfo, options, {
    nothingIsRequired,
  });
}
