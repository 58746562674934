import {
  useHasOrgUpdatePermissionOrSuperAdmin,
  useHasProjectSettingsPermissions,
  useHasProjectUpdatePermissions,
  useHasProjectViewPermissions,
} from '@monorepo/shared/hooks/permissions/useHasPermissions';
import React from 'react';

type WithPermissionProps = {
  hasProjectUpdatePermission: boolean;
  hasProjectViewPermission: boolean;
  hasProjectSettingsPermission: boolean;
  hasOrgUpdatePermissionOrSuperAdmin: boolean;
};

export const withPermissions =
  (Component: React.ComponentType<WithPermissionProps>) =>
  (props: { projectId: string; organizationId?: string }) => {
    const { projectId, organizationId } = props;

    const hasProjectUpdatePermission =
      useHasProjectUpdatePermissions(projectId);
    const hasProjectViewPermission = useHasProjectViewPermissions(projectId);
    const hasProjectSettingsPermission =
      useHasProjectSettingsPermissions(projectId);
    const hasOrgUpdatePermissionOrSuperAdmin =
      useHasOrgUpdatePermissionOrSuperAdmin(organizationId);

    return (
      <Component
        {...props}
        hasProjectUpdatePermission={hasProjectUpdatePermission}
        hasProjectViewPermission={hasProjectViewPermission}
        hasProjectSettingsPermission={hasProjectSettingsPermission}
        hasOrgUpdatePermissionOrSuperAdmin={hasOrgUpdatePermissionOrSuperAdmin}
      />
    );
  };
