import { CircularProgress } from '@material-ui/core';
import { SaveState } from '@monorepo/shared/types/SaveState';
import PropTypes from 'prop-types';
import React from 'react';
import { FormSaveStateType } from '../../propTypes';
import Button from './Button';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default function SaveButton(props) {
  const { disabled, label, onSave, saveState } = props;
  const stateClass = saveState === SaveState.SAVED ? ' saved' : '';

  const saveButtonText = (state) => {
    switch (state) {
      case SaveState.SAVED:
        return 'Saved';
      case SaveState.SAVING:
        return 'Saving';
      default:
        return label || 'Save';
    }
  };

  return (
    <Button
      className={`save-button${stateClass}`}
      disabled={saveState !== SaveState.DIRTY || disabled}
      onClick={onSave}
    >
      {saveButtonText(saveState)}
      {saveState === SaveState.SAVING && (
        <CircularProgress
          classes={{ colorPrimary: 'circular-progress color-white' }}
          size={15}
        />
      )}
    </Button>
  );
}

SaveButton.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string,
  onSave: PropTypes.func.isRequired,
  saveState: FormSaveStateType.isRequired,
};

SaveButton.defaultProps = {
  disabled: false,
  label: null,
};
