import APP from '@monorepo/old-web/js/config';
import { RadioGroup } from '@monorepo/shared/components/RadioGroup';
import { useCurrentUser } from '@monorepo/shared/hooks/useCurrentUser';
import { useProjectUserOptions } from '@monorepo/shared/hooks/useProjectUserOptions';
import { GenericLogLoggedItemType } from 'mapistry-shared';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { useUpdateCurrentUser } from '../../../hooks/users/useUpdateCurrentUser';
import { isValidDate } from '../../../utils';
import {
  DatePicker,
  MultiSignature,
  Select,
  TextField,
  TimePicker,
} from '../../elements';
import { SelectTypeOption } from './EditLogModalTypes';

type EditLogFieldFactoryProps = {
  disabled: boolean;
  error: boolean;
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (e: any) => void;
  options?: SelectTypeOption[] | null;
  value?: string | number;
  type: GenericLogLoggedItemType;
};

/* @ts-expect-error - TODO: Fix this the next time the file is edited. */
const formatDateValue = (date) => {
  const zeroPaddedMonth = `0${date.getMonth() + 1}`.slice(-2);
  const zeroPaddedDay = `0${date.getDate()}`.slice(-2);
  return `${date.getFullYear()}-${zeroPaddedMonth}-${zeroPaddedDay}`;
};

/* @ts-expect-error - TODO: Fix this the next time the file is edited. */
const formatTimeValue = (date) => {
  const zeroPaddedHours = `0${date.getHours()}`.slice(-2);
  const zeroPaddedMinutes = `0${date.getMinutes()}`.slice(-2);
  return `${zeroPaddedHours}${zeroPaddedMinutes}`;
};

/* @ts-expect-error - TODO: Fix this the next time the file is edited. */
const getDateValue = (value) => {
  if (!value) {
    return null;
  }
  return new Date(`${value}T00:00:00`);
};

/* @ts-expect-error - TODO: Fix this the next time the file is edited. */
const getTimeValue = (value) => {
  if (!value) {
    return null;
  }
  const hours = value.substring(0, 2);
  const minutes = value.substring(2, 4);
  const today = new Date();
  try {
    today.setHours(parseInt(hours, 10));
    today.setMinutes(parseInt(minutes, 10));
    return today;
  } catch (e) {
    return null;
  }
};

/* @ts-expect-error - TODO: Fix this the next time the file is edited. */
const handleDateChange = (date, onChange) => {
  if (date && isValidDate(date)) {
    const dateString = formatDateValue(date);
    onChange(dateString);
  } else {
    onChange('');
  }
};

/* @ts-expect-error - TODO: Fix this the next time the file is edited. */
const handleTimeChange = (date, onChange) => {
  if (date && isValidDate(date)) {
    const timeString = formatTimeValue(date);
    onChange(timeString);
  } else {
    onChange('');
  }
};

const EditLogFieldFactory: FunctionComponent<EditLogFieldFactoryProps> = (
  props,
) => {
  const { disabled, error, onChange, options, type, value } = props;

  const { currentUser } = useCurrentUser();
  const [updateCurrentUser] = useUpdateCurrentUser();

  const [originalValue] = useState(value);
  const { userOptions } = useProjectUserOptions({
    projectId: APP.projectId,
    previouslySavedUserId: originalValue?.toString(),
  });

  const handleUpdateSignature = useCallback(
    (user) => {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      updateCurrentUser(user);
    },
    [updateCurrentUser],
  );

  switch (type) {
    case GenericLogLoggedItemType.BOOLEAN:
      return (
        <RadioGroup
          options={['Yes', 'No'].map((opt) => ({
            value: opt,
            label: opt,
            disabled,
          }))}
          onChange={(e) => onChange(e.target.value)}
          value={value}
          error={error}
        />
      );
    case GenericLogLoggedItemType.DATE:
      return (
        <DatePicker
          autoOk
          className="date-field"
          clearable
          disabled={disabled}
          error={error}
          onChange={(date) => handleDateChange(date, onChange)}
          value={getDateValue(value)}
        />
      );

    case GenericLogLoggedItemType.NUMBER:
      return (
        <TextField
          disabled={disabled}
          error={error}
          InputProps={{ inputProps: { min: 0 } }}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          type="number"
        />
      );
    case GenericLogLoggedItemType.TEXT:
      return (
        <TextField
          disabled={disabled}
          error={error}
          value={value}
          onChange={(e) => onChange(e.target.value)}
        />
      );
    case GenericLogLoggedItemType.SINGLE_SELECT:
      return (
        <Select
          isClearable
          isDisabled={disabled}
          isFixed
          options={options || []}
          onChange={(option) => onChange(option.value)}
          value={options?.find((opt) => opt.value === value)}
        />
      );
    case GenericLogLoggedItemType.SIGNATURE: {
      const parsedValue = typeof value === 'string' ? JSON.parse(value) : value;
      const signatureValue = value ? [parsedValue] : [];

      return (
        <MultiSignature
          currentUser={currentUser}
          disabled={disabled}
          /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
          onChange={(signers) => onChange(signers[0])}
          value={signatureValue}
          users={[currentUser]}
          errorMessage={error ? 'Error adding signature' : null}
          onUpdateSignature={handleUpdateSignature}
        />
      );
    }
    case GenericLogLoggedItemType.TIME:
      return (
        <TimePicker
          autoOk
          className="time-field"
          clearable
          disabled={disabled}
          error={error}
          /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
          onChange={(date) => handleTimeChange(date, onChange)}
          value={getTimeValue(value)}
        />
      );
    case GenericLogLoggedItemType.USER:
      return (
        <Select
          isClearable
          isDisabled={disabled}
          options={userOptions || []}
          value={userOptions?.find((opt) => opt.value === value)}
          onChange={(option) => onChange(option.value)}
          error={error}
        />
      );
    default:
      return null;
  }
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default EditLogFieldFactory;
