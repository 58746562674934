import qs from 'qs';
import {
  LoggedItemRecord,
  LogBulkUploadForApi,
} from '@monorepo/old-web/js/components/genericLogs/EditLogModal/EditLogModalTypes';
import http from '../httpClient';

interface FetchExistingLogResponse {
  logProjectId: string;
  projectHasLogs: boolean;
  record: {
    datetime: string;
    logProjectId: string;
    projectId: string;
    loggedItems: LoggedItemRecord[];
  };
}

export async function fetchExistingLog(
  projectId: string,
  logProjectId: string,
  loggedItemId: string,
  dateTime: string,
) {
  const url = `/api/v2/${projectId}/generic-logs/${logProjectId}/logs?${qs.stringify(
    { logDatetime: dateTime, loggedItemId },
  )}`;
  const res = await http.get<FetchExistingLogResponse>(url);
  return res.data;
}

export async function saveNewLoggedValues(
  projectId: string,
  logProjectId: string,
  logs: LogBulkUploadForApi[],
) {
  // insert only
  const res = await http.post(
    `/api/v2/${projectId}/generic-logs/${logProjectId}/logs/batch
      `,
    logs,
  );

  return res.data;
}
