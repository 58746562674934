import { withPermissions } from '@monorepo/shared/hooks/permissions/withPermissions';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { AddButton } from '../../elements';
import { ProjectTaskType } from '../../propTypes';
import TasksValidator from '../../views/formSubmissionEditor/validations/TasksValidator';
import { TaskForm as TaskFormView } from './TaskForm';

class TaskFormContainer extends Component {
  componentDidMount() {
    const { addingTask } = this.props;
    if (addingTask) {
      this.handleCreateTask();
    }
  }

  getNewTask(partialTaskProps = {}) {
    const { folderSlugPrefix } = this.props;
    const taskId = uuidv4();
    return {
      id: taskId,
      title: null,
      description: null,
      notes: null,
      assignees: [],
      dueDate: null,
      deficiency: null,
      completedDate: null,
      markedAsCompletedDate: null,
      folderSlug: `${folderSlugPrefix}-${taskId}`,
      subTasks: [],
      permissions: {
        isAllowedToEdit: true,
        isAllowedToDelete: true,
        isAllowedToComplete: true,
      },
      ...partialTaskProps,
    };
  }

  getUpdatedTasks(parentTask, taskToUpdate, newValues) {
    const { tasks } = this.props;
    return parentTask
      ? this.mapTaskChange(tasks, parentTask, {
          subTasks: this.mapTaskChange(
            parentTask.subTasks,
            taskToUpdate,
            newValues,
          ),
        })
      : this.mapTaskChange(tasks, taskToUpdate, newValues);
  }

  handleCreateTask = (parentTask) => {
    const { onChange, tasks } = this.props;
    const { isTemplate = false } = parentTask || {};

    const recurringProps = {
      isTemplate,
      isRecurring: isTemplate,
    };

    const newTasks = parentTask
      ? this.mapTaskChange(tasks, parentTask, {
          subTasks: [...parentTask.subTasks, this.getNewTask(recurringProps)],
        })
      : [...tasks, this.getNewTask(recurringProps)];

    onChange(newTasks);
  };

  handleIsRecurringChange = (parentTask, taskToUpdate, isRecurring) => {
    const { dueDate, startDate, subTasks } = taskToUpdate;

    const updated = {
      isRecurring,
      isTemplate: isRecurring,
      subTasks: subTasks.map((su) => ({ ...su, isTemplate: isRecurring })),
    };
    if (!isRecurring) {
      updated.frequency = null;
      updated.customFrequency = null;
      updated.startDate = null;
      updated.dueDate = startDate;
    } else {
      updated.startDate = dueDate;
      updated.dueDate = null;
    }
    this.handleChange(parentTask, taskToUpdate, updated);
  };

  handleChange = (parentTask, taskToUpdate, updated, value = undefined) => {
    const { onChange } = this.props;

    const updatedPartial =
      typeof updated === 'string' ? { [updated]: value } : updated;
    const newTasks = this.getUpdatedTasks(
      parentTask,
      taskToUpdate,
      updatedPartial,
    );

    const keys = typeof updated === 'string' ? [updated] : Object.keys(updated);
    const newErrors = this.removeErrorField(parentTask, taskToUpdate.id, keys);

    onChange(newTasks, newErrors);
  };

  handleDestroyTask = (parentTask, taskToDelete) => {
    const { onChange } = this.props;
    const newTasks = this.getUpdatedTasks(parentTask, taskToDelete, {
      deleted: true,
    });
    const newErrors = this.removeTaskError(parentTask, taskToDelete.id);
    onChange(newTasks, newErrors);
  };

  handleCompleteTask = (parentTask, taskToUpdate, completedDate) => {
    const { onChange, onError, tasks } = this.props;
    const validator = new TasksValidator();
    const validationError = validator.validateTask(parentTask, taskToUpdate);
    if (validationError) {
      const newErrors = this.replaceTaskError(
        parentTask,
        taskToUpdate.id,
        validationError,
      );
      onChange(tasks, newErrors);
      onError(
        'The task cannot be completed. Please review the form and address each issue',
      );
      return;
    }

    const newValues = {
      completedDate,
      markedAsCompletedDate: completedDate,
    };
    const newTasks = this.getUpdatedTasks(parentTask, taskToUpdate, newValues);

    const newErrors = this.removeTaskError(parentTask, taskToUpdate.id);
    onChange(newTasks, newErrors);
  };

  mapTaskChange(tasks, taskToUpdate, newValues) {
    return tasks.map((task) =>
      task.id !== taskToUpdate.id ? task : { ...task, ...newValues },
    );
  }

  removeErrorField(parentTask, id, fieldKeys) {
    const { errors } = this.props;

    const updateFieldErrors = (err) =>
      fieldKeys.reduce((fieldErrors, fieldKey) => {
        const errorField = `${fieldKey}Error`;
        return { ...fieldErrors, [errorField]: null };
      }, err.fieldErrors);

    return parentTask
      ? this.mapErrorChange(errors, parentTask.id, 'subTaskErrors', (error) =>
          this.mapErrorChange(
            error.subTaskErrors,
            id,
            'fieldErrors',
            updateFieldErrors,
          ),
        )
      : this.mapErrorChange(errors, id, 'fieldErrors', updateFieldErrors);
  }

  removeTaskError(parentTask, id) {
    const { errors } = this.props;

    return parentTask
      ? this.mapErrorChange(errors, parentTask.id, 'subTaskErrors', (error) =>
          error.subTaskErrors.filter((subError) => subError.taskId !== id),
        )
      : errors.filter((error) => error.taskId !== id);
  }

  replaceTaskError(parentTask, id, newError) {
    const { errors } = this.props;
    this.removeTaskError(parentTask, id);
    if (!parentTask) {
      return [...errors, newError];
    }

    const parentError = errors.find((error) => error.taskId !== id);
    return parentError
      ? this.mapErrorChange(errors, parentTask.id, 'subTaskErrors', (error) => [
          ...error.subTaskErrors,
          newError,
        ])
      : [
          ...errors,
          {
            taskId: parentTask.id,
            fieldErrors: {},
            subTaskErrors: [newError],
          },
        ];
  }

  mapErrorChange(errors, id, fieldKey, fieldValueFunc) {
    return (errors || []).reduce((result, error) => {
      if (error.taskId !== id) {
        result.push(error);
      }
      const newError = { ...error, [fieldKey]: fieldValueFunc(error) };
      if (this.hasErrors(newError)) {
        result.push(newError);
      }
      return result;
    }, []);
  }

  hasErrors(error) {
    return (
      Object.values(error.fieldErrors).some((v) => v) ||
      (error.subTaskErrors && error.subTaskErrors.length)
    );
  }

  render() {
    const {
      addingTask,
      canRecur,
      disabled,
      errors,
      folderSlugPrefix,
      hasProjectUpdatePermission,
      multi,
      noHeader,
      onError,
      onSetSaveDisabled,
      projectId,
      tasks,
    } = this.props;

    return (
      <>
        {multi && (
          <div className="task-form__create-task-button">
            <AddButton
              disabled={disabled || !hasProjectUpdatePermission}
              label="Add a task"
              onClick={() => this.handleCreateTask()}
            />
          </div>
        )}
        <div className="task-form__list">
          {tasks.map((task) => (
            <TaskFormView
              addingTask={addingTask}
              canRecur={canRecur}
              disabled={disabled}
              error={errors.find((e) => e.taskId === task.id)}
              folderSlugPrefix={
                task.isRecurring ? 'recurring' : folderSlugPrefix
              }
              key={task.id}
              noHeader={noHeader}
              onAddSubtask={this.handleCreateTask}
              onComplete={this.handleCompleteTask}
              onDelete={this.handleDestroyTask}
              onError={onError}
              onFieldChange={this.handleChange}
              onIsRecurringChange={this.handleIsRecurringChange}
              onSetSaveDisabled={onSetSaveDisabled}
              projectId={projectId}
              task={task}
            />
          ))}
        </div>
      </>
    );
  }
}

export const TaskForm = withPermissions(TaskFormContainer);

TaskFormContainer.propTypes = {
  addingTask: PropTypes.bool,
  canRecur: PropTypes.bool,
  disabled: PropTypes.bool,
  errors: PropTypes.arrayOf(
    PropTypes.shape({
      taskId: PropTypes.string.isRequired,
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line react/forbid-prop-types
      fieldErrors: PropTypes.object,
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line react/forbid-prop-types
      subTaskErrors: PropTypes.arrayOf(PropTypes.object),
    }),
  ),
  folderSlugPrefix: PropTypes.string.isRequired,
  hasProjectUpdatePermission: PropTypes.bool.isRequired,
  multi: PropTypes.bool,
  noHeader: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired, // accepts error message as string
  onSetSaveDisabled: PropTypes.func,
  projectId: PropTypes.string.isRequired,
  tasks: PropTypes.arrayOf(ProjectTaskType).isRequired,
};

TaskFormContainer.defaultProps = {
  addingTask: false,
  canRecur: false,
  disabled: false,
  errors: [],
  multi: false,
  noHeader: false,
  onSetSaveDisabled: () => undefined,
};
