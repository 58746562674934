import React from 'react';

type ChartCardSettingsContentProps = {
  children: NonNullable<React.ReactNode>;
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function ChartCardSettingsContent(props: ChartCardSettingsContentProps) {
  const { children } = props;
  return <div className="chart-card--settings-content">{children}</div>;
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default ChartCardSettingsContent;
