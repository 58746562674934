import React from 'react';
import PropTypes from 'prop-types';
import { SvgIcon } from '@monorepo/shared/components/icons/SvgIcon';
import { Editor, Transforms } from 'slate';
import { useSlate } from 'slate-react';

import IconButton from '../buttons/IconButton';

export const SUPPORTED_BLOCKS = {
  HEADING_ONE: {
    element: 'heading-one',
    label: 'Heading',
    icon: 'h1',
  },
  HEADING_TWO: {
    element: 'heading-two',
    label: 'Subheading',
    icon: 'h2',
  },
  BLOCK_QUOTE: {
    element: 'block-quote',
    label: 'Quote',
    icon: 'blockQuote',
  },
  ORDERED_LIST: {
    element: 'numbered-list',
    label: 'Ordered List',
    icon: 'orderedList',
  },
  UNORDERED_LIST: {
    element: 'bulleted-list',
    label: 'Unordered list',
    icon: 'unorderedList',
  },
  PARAGRAPH: {
    element: 'paragraph',
    label: 'Paragraph',
    icon: 'paragraph',
  },
};

const isBlockActive = (editor, block) => {
  const [match] = Editor.nodes(editor, {
    match: (n) => n.type === block,
  });
  return !!match;
};

const toggleBlock = (editor, format, isActive) => {
  const isList = ['numbered-list', 'bulleted-list'].includes(format);

  Transforms.unwrapNodes(editor, {
    match: (n) => ['numbered-list', 'bulleted-list'].includes(n.type),
    split: true,
  });

  const nextType = () => {
    if (isList) {
      return 'list-item';
    }
    return format;
  };

  Transforms.setNodes(editor, {
    type: nextType(),
  });

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

const BlockButton = (props) => {
  const { type } = props;
  const editor = useSlate();
  const isActive = isBlockActive(editor, type.element);
  const colorClassname = isActive ? 'white bg-green' : 'dark-gray';
  return (
    <IconButton
      onClick={(e) => {
        e.preventDefault();
        toggleBlock(editor, type.element, isActive);
      }}
      tooltipText={type.label}
    >
      <span className={`text-editor__button-wrapper ${colorClassname}`}>
        <SvgIcon identifier={type.icon} />
      </span>
    </IconButton>
  );
};

BlockButton.propTypes = {
  type: PropTypes.oneOf(Object.values(SUPPORTED_BLOCKS)).isRequired,
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default BlockButton;
