import { FetchCurrentUserPermissionsResponse } from '@monorepo/shared/apiClient/currentUserPermissions';

export enum PermissionCategory {
  ORGANIZATION = 'organization',
  PROJECT = 'project',
}

/* This is copied from the SharedPermissionArbiter.
Permissions Response looks something like this:
{
  'user|e17310cc-1c70-40e1-8220-f87c0fe0de53|project|0aebfe84-52ad-4b60-81e6-351e82c39231|permissions':
    'ASSIGN_TASKS_TO,DELETE_TASK,PROJECT_CREATE,PROJECT_DATA_UPDATE,PROJECT_DELETE,PROJECT_DETAILS_READ,PROJECT_DETAILS_UPDATE,PROJECT_STYLE_UPDATE,PROJECT_USER_UPDATE',
  'user|e17310cc-1c70-40e1-8220-f87c0fe0de53|project|0aebfe84-52ad-4b60-81e6-351e82c39231|roles':
    'PROJECT_ADMIN',
};
*/
const formatKey = {
  forUserRoles(
    userId: string,
    projectOrOrganizationId: string,
    category: PermissionCategory,
  ) {
    return ['user', userId, category, projectOrOrganizationId, 'roles'].join(
      '|',
    );
  },

  forUserPermissions(
    userId: string,
    projectOrOrganizationId: string,
    category: PermissionCategory,
  ) {
    return [
      'user',
      userId,
      category,
      projectOrOrganizationId,
      'permissions',
    ].join('|');
  },
};

export function getUserPermissions(
  permissionsResponse: FetchCurrentUserPermissionsResponse,
  userId: string,
  projectOrOrganizationId = '',
  category = PermissionCategory.PROJECT,
): string[] {
  const projectPermissionsKey = formatKey.forUserPermissions(
    userId,
    projectOrOrganizationId,
    category,
  );
  return (permissionsResponse[projectPermissionsKey] || '').split(',');
}

export function getUserRoles(
  permissionsResponse: FetchCurrentUserPermissionsResponse,
  userId: string,
  projectOrOrganizationId = '',
  category = PermissionCategory.PROJECT,
): string[] {
  const projectPermissionsKey = formatKey.forUserRoles(
    userId,
    projectOrOrganizationId,
    category,
  );
  return (permissionsResponse[projectPermissionsKey] || '').split(',');
}
