import { handleActions } from 'redux-actions';
import update from 'immutability-helper';

import {
  FETCH_USERS_REQUEST,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_FAILURE,
} from '../actions';

import { failed, fetching, notFetching } from '.'; // eslint-disable-line import/no-cycle

const INITIAL_STATE = {
  forProject: null,
  isFetching: {
    users: false,
  },
  users: [],
};

const reducers = handleActions(
  {
    [FETCH_USERS_REQUEST]: (state, action) =>
      fetching({ ...state, forProject: action.payload.projectId }, 'users'),
    [FETCH_USERS_SUCCESS]: (state, action) =>
      notFetching(update(state, { users: { $set: action.payload } }), 'users'),
    [FETCH_USERS_FAILURE]: (state, action) =>
      failed(notFetching(state, 'users'), action.payload),
  },
  INITIAL_STATE,
);

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default reducers;
