import React from 'react';
import cn from 'classnames';
import { Tooltip } from '../../Tooltip';

type TooltipProps = React.ComponentProps<typeof Tooltip>;

interface SvgIconProps {
  className?: string;
  identifier: string; // name of a file in /public/svg directory, e.g. "add", "cog", "edit", etc.
  tooltipPlacement?: TooltipProps['placement'];
  tooltipTitle?: TooltipProps['title'];
  withBorder?: boolean;
}

export const SvgIcon = ({
  className,
  identifier,
  tooltipPlacement = 'top',
  tooltipTitle,
  withBorder,
}: SvgIconProps) => {
  const svgIcon = (
    <svg className={cn('m-icon', className)}>
      <use xlinkHref={`#${identifier}`} />
    </svg>
  );
  const svgIconWithTooltip = tooltipTitle ? (
    <Tooltip placement={tooltipPlacement} title={tooltipTitle}>
      {svgIcon}
    </Tooltip>
  ) : (
    svgIcon
  );

  return withBorder ? (
    <div className={cn('svg-icon-outline', className)}>
      {svgIconWithTooltip}
    </div>
  ) : (
    svgIconWithTooltip
  );
};
