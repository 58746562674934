import moment from 'moment';
import { IntervalFrequencyEnum } from 'mapistry-shared';

export const getFormattedCalendarDate = (utcString, formatType) => {
  const calendarMoment = moment.utc(utcString);
  const formats = {
    biannual: 'YYYY MMMM',
    // As-needed frequency looks the same as daily in UI
    [IntervalFrequencyEnum.AS_NEEDED]: 'DD MMMM',
    day: 'DD MMMM',
    daily: 'DD MMMM',
    month: 'YYYY MMMM',
    monthly: 'YYYY MMMM',
    semiAnnual: 'YYYY MMMM',
    'twice-per-year': 'YYYY MMMM',
    week: 'DD MMM',
    weekly: 'DD MMM',
    year: 'YYYY',
    yearly: 'YYYY',
  };
  if (formats[formatType] != null) {
    return calendarMoment.format(formats[formatType]);
  }
  switch (formatType) {
    case 'hour':
    case 'hourly':
      return `${calendarMoment.format('HH')}00 hours`;
    case 'quarter':
    case 'quarterly':
      return `${calendarMoment.format('YYYY')} Q${calendarMoment.format('Q')}`;
    default:
      return calendarMoment.format('MMM Do, h:mm a');
  }
};

export const formattedCalendarDateFromEvent = (event) => {
  const { start, end } = event;
  // when moving to new IntervalFrequencies, formerly "quarter" (etc.) frequencies
  // might be stored as a "monthly" frequency with customFrequency settings
  // (every: 3, and nonCustomFrequency='monthly'). Thus checking both.
  const frequency =
    event.customFrequency?.nonCustomFrequency || event.frequency;
  if (frequency === 'weekly') {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line no-underscore-dangle
    const _start = getFormattedCalendarDate(start, frequency);
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line no-underscore-dangle
    const _end = getFormattedCalendarDate(end, frequency);
    return `${_start} - ${_end}`;
  }
  const utcDateString = new Date(start).toISOString();
  return getFormattedCalendarDate(utcDateString, frequency);
};
