/* eslint-disable */
import APP from '@monorepo/old-web/js/config';
import { ProjectPermissionRoles } from 'mapistry-shared';
import logger from '../../../../server/config/logger';
import { ApiError } from '../services/BaseService';

const _ = require('underscore');
const apiCaller = require('../apiCaller');

class PendoManager {
  constructor() {
    if (!PendoManager.instance) {
      PendoManager.instance = this;
      logger.info({
        message: 'Creating Pendo instance for stormwater-frontend',
      });
    }
    return PendoManager.instance;
  }

  // This method should be invoked by currentUser only to either initialize
  // identify a user. As per pendo support, pendo initialization should happen
  // once per user.
  async configurePendo(user) {
    if (!user.id) {
      logger.warn({
        message:
          'Received empty/anonymous user info during Pendo initialization.',
      });
      return null;
    }
    if (this.isInitialized(user)) {
      logger.warn('User already initialized in Pendo');
      return;
    }
    this.initializedUserId = user.id;
    const basicUserInfo = this._getBasicUserInfo(user);

    return this._setUserProjectInfo(basicUserInfo).then((userInfo) =>
      this._setUserOrgInfo(userInfo).then(async (info) => {
        const { visitor, account } = info;

        if (pendo) {
          //necessary to do both initialize AND identify to make sure that the account is reset
          //as users navigate from one site to another.
          pendo.initialize({ visitor, account });
          pendo.identify({ visitor, account });
          this._logSuccess(visitor, account);
        } else {
          logger.warn({
            message:
              'Pendo could not be initialized because pendo not defined.',
          });
        }
      }),
    );
  }

  isInitialized(user) {
    return this.initializedUserId === user?.id;
  }

  trackCustomEvent(trackType, userMetadata) {
    pendo.track(trackType, userMetadata);
  }

  _logSuccess(visitor, account) {
    logger.info({
      userId: visitor.internalId || '',
      visitor,
      account,
      message: 'Initializing Pendo for stormwater-frontend.',
    });
  }

  _getBasicUserInfo(user) {
    const email = user.email;
    const info = {
      id: email || 'guest',
      internalId: user.id,
      Email: email,
      Is_Super_Admin: user.is_super_admin,
    };

    if (user.job_title) {
      info['Job_Title_(self_reported)'] = user.job_title;
    }
    if (user.mobile_phone_number) {
      info['Mobile_Phone_Number_(self_reported)'] = user.mobile_phone_number;
    }
    if (user.name) {
      info.User_Name = user.name;
    }

    return info;
  }

  async _setUserOrgInfo(userInfo) {
    let accountInfo = {};
    const orgInfo = await fetch(APP.apiUrl + '/organizations', {
      credentials: 'include',
    }).then((result) => {
      if (result.ok) {
        return result.json();
      }
      throw new ApiError('failed to fetch projects for Pendo');
    });

    if (!orgInfo.length) {
      return { visitor: userInfo, account: accountInfo };
    }

    const org = orgInfo[0];

    accountInfo = {
      id: org.name || 'unknown',
      internalId: org.id,
    };

    if (org.name) {
      accountInfo.Organization_Name = org.name;
      userInfo.Organization_Role = org.roleName;
    }

    return apiCaller.getNumSitesForOrganization(org.id).then((numSites) => {
      accountInfo.Number_of_Sites_in_Organization = numSites[0].count;

      return { visitor: userInfo, account: accountInfo };
    });
  }

  async _setUserProjectInfo(userInfo) {
    const projects = await fetch(APP.apiUrl + '/api/projects', {
      credentials: 'include',
    }).then((result) => {
      if (result.ok) {
        return result.json();
      }
      throw new ApiError('failed to fetch projects for Pendo');
    });

    userInfo.Number_of_Sites_User_Belongs_To = projects.length;
    this._setHighestSiteRoleForPendo(userInfo, projects);

    return userInfo;
  }

  _setHighestSiteRoleForPendo(userInfo, projectInfo) {
    const roles = _.uniq(projectInfo.map((p) => p.role_name));
    if (_.contains(roles, ProjectPermissionRoles.PROJECT_PRIMARY_ADMIN)) {
      userInfo.Highest_Site_Role = ProjectPermissionRoles.PROJECT_PRIMARY_ADMIN;
    } else if (_.contains(roles, ProjectPermissionRoles.PROJECT_ADMIN)) {
      userInfo.Highest_Site_Role = ProjectPermissionRoles.PROJECT_ADMIN;
    } else if (_.contains(roles, ProjectPermissionRoles.PROJECT_CONTRIBUTOR)) {
      userInfo.Highest_Site_Role = ProjectPermissionRoles.PROJECT_CONTRIBUTOR;
    } else if (_.contains(roles, ProjectPermissionRoles.PROJECT_VIEWER)) {
      userInfo.Highest_Site_Role = ProjectPermissionRoles.PROJECT_VIEWER;
    } else {
      userInfo.Highest_Site_Role = ProjectPermissionRoles.SITE_MAINTENANCE;
    }
    return userInfo;
  }
}

const instance = new PendoManager();
export default instance;
