import { KeyCodes } from 'mapistry-shared';

export const KeyService = {
  /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
  isDeletionKey(keycode) {
    return keycode === KeyCodes.BACKSPACE || keycode === KeyCodes.DELETE;
  },

  /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
  isEnterKey(keycode) {
    return keycode === KeyCodes.ENTER;
  },

  /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
  isEscapeKey(keycode) {
    return keycode === KeyCodes.ESCAPE;
  },

  /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
  isTabKey(keycode) {
    return keycode === KeyCodes.TAB;
  },

  /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
  triggersButtonClick(keycode) {
    return keycode === KeyCodes.ENTER || keycode === KeyCodes.SPACE;
  },
};
