import React from 'react';
import { Button as MuiButton } from '@material-ui/core';

type MuiButtonProps = React.ComponentProps<typeof MuiButton>;

export type ButtonProps = {
  children: NonNullable<React.ReactNode>;
  className?: string;
  color?: 'primary' | 'secondary' | 'danger';
  size?: 'small' | 'medium' | 'large';
  disabled?: boolean;
  form?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  startIcon?: MuiButtonProps['startIcon'];
  type?: MuiButtonProps['type'];
  variant?: MuiButtonProps['variant'];
};

const defaultProps = {
  color: 'primary',
};

/**
 * @deprecated Use packages/shared/components/buttons/Button.tsx
 */
class Button extends React.Component<ButtonProps> {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/static-property-placement
  static defaultProps = defaultProps;

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  render() {
    const {
      className,
      color,
      size = 'medium',
      children,
      disabled,
      form,
      onClick,
      startIcon,
      type,
      variant,
    } = this.props;

    const colorClass = (() => {
      if (disabled) {
        return 'disabled';
      }
      return color || 'primary';
    })();
    const variantClass = (() => {
      if (variant === 'text') return 'text';
      return '';
    })();
    const propClassName = className ? ` ${className}` : '';

    return (
      <MuiButton
        className={`mapistry-button${propClassName} ${colorClass} ${variantClass} ${size}`}
        classes={{ label: 'mapistry-button__label' }}
        disabled={disabled}
        disableRipple
        form={form}
        onClick={onClick}
        startIcon={startIcon}
        type={type}
        variant={variant || 'contained'}
      >
        {children}
      </MuiButton>
    );
  }
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default Button;
