import qs from 'qs';
import http from './httpClient';

export interface FetchFlowLogsProps {
  endDate?: Date;
  locationId?: string;
  projectId?: string;
  startDate?: Date;
}

export interface FlowLog {
  dateOfDischarge: Date;
  flowReading: number;
  hoursOfDischarge?: number;
  monitoringLocationId: string;
  personReportingDischarge: string;
}

type FetchFlowLogsResponse = FlowLog[];

export async function fetchFlowLogs({
  endDate,
  locationId,
  projectId,
  startDate,
}: FetchFlowLogsProps): Promise<FlowLog[]> {
  const res = await http.get<FetchFlowLogsResponse>(
    `/api/v2/${projectId}/wastewater/flow-log?${qs.stringify({
      endDate,
      locationId,
      startDate,
    })}`,
  );
  return res.data;
}

export interface BulkUploadFlowLogsProps {
  flowLogs: FlowLog[];
  projectId?: string;
}

interface BulkUploadFlowLogsResponse {
  errors: FlowLog[];
  successes: FlowLog[];
}

export async function bulkUploadFlowLogs({
  flowLogs,
  projectId,
}: BulkUploadFlowLogsProps): Promise<BulkUploadFlowLogsResponse> {
  const res = await http.post<BulkUploadFlowLogsResponse>(
    `/api/v2/${projectId}/wastewater/flow-log/bulk`,
    flowLogs,
  );
  return res.data;
}
