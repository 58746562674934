import React from 'react';
import PropTypes from 'prop-types';
import { SvgIcon } from '@monorepo/shared/components/icons/SvgIcon';
import MapistryTooltip from './MapistryTooltip';

/**
 * @deprecated Use packages/shared/components/icons/HelpIcon
 */
function HelpIcon(props) {
  const { text, placement } = props;

  return (
    <MapistryTooltip title={text} placement={placement}>
      <span className="help-icon-wrapper">
        <SvgIcon identifier="help" className="help-icon" />
      </span>
    </MapistryTooltip>
  );
}

HelpIcon.propTypes = {
  placement: PropTypes.string,
  text: PropTypes.string.isRequired,
};

HelpIcon.defaultProps = {
  placement: 'top',
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default HelpIcon;
