import React, { useCallback, useState } from 'react';
import { TextInput } from '../TextInput';

interface EditableTextProps {
  children?: string;
  /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
  onEndEdit?: (updatedText) => void;
  onStartEdit?: () => void;
  text?: string;
}

export const EditableText = ({
  children,
  onEndEdit,
  onStartEdit,
  text,
}: EditableTextProps) => {
  const [isEditing, setIsEditing] = useState(false);

  const handleStartEditing = useCallback(
    (e) => {
      e.stopPropagation();
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      onStartEdit && onStartEdit();
      setIsEditing(true);
    },
    [onStartEdit, setIsEditing],
  );
  const handleStopEditing = useCallback(
    (value) => {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      onEndEdit && onEndEdit(value);
      setIsEditing(false);
    },
    [onEndEdit],
  );
  return isEditing ? (
    <TextInput
      autoFocus
      controlled={false}
      defaultValue={text ?? children}
      onBlur={(e) => handleStopEditing(e.target.value)}
    />
  ) : (
    // TODO: Update the CSS to make this a real button
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <span
      role="button"
      className="hover-edit-identifier"
      onClick={(e) => handleStartEditing(e)}
      onKeyPress={(e) => handleStartEditing(e)}
      tabIndex={0}
    >
      {text ?? children}
    </span>
  );
};
