import http from './httpClient';

/* Permissions Response looks something like this:
{
  'user|e17310cc-1c70-40e1-8220-f87c0fe0de53|project|0aebfe84-52ad-4b60-81e6-351e82c39231|permissions':
    'ASSIGN_TASKS_TO,DELETE_TASK,PROJECT_CREATE,PROJECT_DATA_UPDATE,PROJECT_DELETE,PROJECT_DETAILS_READ,PROJECT_DETAILS_UPDATE,PROJECT_STYLE_UPDATE,PROJECT_USER_UPDATE',
  'user|e17310cc-1c70-40e1-8220-f87c0fe0de53|project|0aebfe84-52ad-4b60-81e6-351e82c39231|roles':
    'PROJECT_ADMIN',
};
*/
export type FetchCurrentUserPermissionsResponse = {
  [permissionsString: string]: string;
};

export async function fetchCurrentUserPermissions(): Promise<FetchCurrentUserPermissionsResponse> {
  const res = await http.get<FetchCurrentUserPermissionsResponse>(
    `/userPermissions`,
  );
  return res.data;
}

const requestSiteAccessUrl = `/api/organizations/request-access`;

export async function fetchProjectAccessRequestEmails() {
  const res = await http.get(requestSiteAccessUrl);
  return res.data;
}

export async function sendProjectAccessRequestEmail() {
  const res = await http.post(requestSiteAccessUrl);
  return res.data;
}
