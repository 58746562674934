import React from 'react';
import { ReactQueryConfigProvider } from 'react-query';

interface ErrorResponse {
  status: number;
  data: string;
}

function isNetworkError(error: unknown): error is ErrorResponse {
  if (error && typeof error === 'object') {
    return 'status' in error && 'data' in error;
  }
  return false;
}

function is500Error(error: unknown): boolean {
  if (isNetworkError(error)) {
    return Boolean(error.status && error.status >= 500);
  }
  return false;
}

function isRetryableError(error: unknown): boolean {
  return is500Error(error);
}

// Retry only three times and only if error is retryable, e.g. not 401 or 404.
function defaultRetryHeuristic(failureCount: number, error: unknown): boolean {
  if (isRetryableError(error)) {
    return failureCount < 3;
  }
  return false;
}

const config = {
  queries: {
    cacheTime: 10 * 60 * 1000, // cache will be cleared (garbage collected) if no one is using it for 10 min
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: false,
    retry: defaultRetryHeuristic,
    staleTime: Infinity, // If cache wasn't invalidated or garbage collected – don't refetch
  },
};

type ReactQueryConfigProps = {
  children: NonNullable<React.ReactNode>;
};

export function ReactQueryConfig(props: ReactQueryConfigProps) {
  const { children } = props;
  return (
    <ReactQueryConfigProvider config={config}>
      {children}
    </ReactQueryConfigProvider>
  );
}
