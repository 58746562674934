import { ButtonProps as MuiButtonProps } from '@material-ui/core/Button';
import React from 'react';
import { ContainedDefaultButton } from './contained/ContainedDefaultButton';
import { ContainedPrimaryButton } from './contained/ContainedPrimaryButton';
import { ContainedSecondaryButton } from './contained/ContainedSecondaryButton';
import { OutlinedPrimaryButton } from './outlined/OutlinedPrimaryButton';
import { TextDefaultButton } from './text/TextDefaultButton';
import { TextPrimaryButton } from './text/TextPrimaryButton';

interface ButtonOptions {
  disableElevation?: true;
  disableRipple?: true;
  focusableWhenDisabled?: boolean;
}

export const Button = React.forwardRef(
  // Type to work with "component" from here: https://v4.mui.com/guides/typescript/#usage-of-component-prop
  <C extends React.ElementType>(
    props: MuiButtonProps<C, { component?: C }> & ButtonOptions,
    ref: React.Ref<HTMLButtonElement>,
  ) => {
    const { className, color, disabled, focusableWhenDisabled, variant } =
      props;
    // don't pass focusableWhenDisabled through to the Button component
    // eslint-disable-next-line prefer-const
    let { focusableWhenDisabled: _, ...transformedProps } = props;

    if (focusableWhenDisabled && disabled) {
      // https://github.com/mui/material-ui/issues/32917
      transformedProps = {
        ...transformedProps,
        // disabling the button makes it un-focusable
        disabled: false,
        // the remaining properties mimic a disabled button while still leaving it focusable
        'aria-disabled': true,
        onClick: null,
        className: `${className} Mui-disabled`,
      };
    }

    const transformedPropsAndRef = { ...transformedProps, ref };

    if (variant === 'contained') {
      switch (color) {
        case 'primary':
          return <ContainedPrimaryButton {...transformedPropsAndRef} />;
        case 'secondary':
          return <ContainedSecondaryButton {...transformedPropsAndRef} />;
        default:
          return <ContainedDefaultButton {...transformedPropsAndRef} />;
      }
    }

    if (variant === 'outlined') {
      return <OutlinedPrimaryButton {...transformedPropsAndRef} />;
    }

    switch (color) {
      case 'primary':
        return <TextPrimaryButton {...transformedPropsAndRef} />;
      default:
        return <TextDefaultButton {...transformedPropsAndRef} />;
    }
  },
);
