import { RollingCalculation } from 'mapistry-shared';
import http from '../httpClient';

export interface FetchRollingCalculationsProps {
  logProjectId?: string;
  projectId?: string;
}

interface FetchRollingCalculationsResponse {
  logProjectId: string;
  records: RollingCalculation[];
}

export async function fetchRollingCalculations({
  logProjectId,
  projectId,
}: FetchRollingCalculationsProps): Promise<RollingCalculation[]> {
  const res = await http.get<FetchRollingCalculationsResponse>(
    `/api/v2/${projectId}/generic-logs/${logProjectId}/rolling-calculations`,
  );
  return res.data?.records;
}
