import React from 'react';
import PropTypes from 'prop-types';

const NeverEventCalendar = (props) => {
  const { additionalInfo, calendarTitle } = props;

  return (
    <div className="m-event-calendar__never">
      <span className="m-event-calendar__never__text">
        This site is not doing any
        <strong>{` ${calendarTitle} `}</strong>
        for this time period.
      </span>
      {additionalInfo}
    </div>
  );
};

NeverEventCalendar.defaultProps = {
  additionalInfo: null,
};

NeverEventCalendar.propTypes = {
  additionalInfo: PropTypes.node,
  calendarTitle: PropTypes.string.isRequired,
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default NeverEventCalendar;
