import { ToggleButtons } from '@monorepo/shared/components/ToggleButtons';
import { FrequencyContext } from '@monorepo/shared/contexts/FrequencyContext';
import { DefaultCustomFrequency } from '@monorepo/shared/contexts/FrequencyContext/types';
import { CustomFrequencyErrors } from '@monorepo/shared/utils/CustomFrequencyValidator';
import { customFrequencyIntervalOptions } from '@monorepo/shared/utils/frequencyUtils';
import cn from 'classnames';
import { Days, IntervalFrequencyEnum, Months } from 'mapistry-shared';
import React, { FC, useContext } from 'react';
import { SingleOption } from '../../../types/ts-types';
import {
  Button,
  FieldWrapper,
  Popper,
  SaveButton,
  Select,
  TextField,
} from '../../elements';
import FrequencyDateSelector from '../FrequencyDateSelector';

type CustomFrequencyPopperProps = {
  anchorEl?: Element | null;
  errors?: CustomFrequencyErrors | null;
  header?: string;
  helperText: string;
  subHeader?: string;
};

const defaultProps = { header: 'Custom frequency', subHeader: 'Repeat' };
const MAX_OCCURRENCES = 10;

const CustomFrequencyPopper: FC<CustomFrequencyPopperProps> = (
  props: CustomFrequencyPopperProps,
) => {
  const { anchorEl, errors, header, helperText, subHeader } = props;

  const context = useContext(FrequencyContext);
  const {
    customPopperOpen,
    onCustomPopperCancel,
    onCustomPopperSave,
    onFrequencySelect,
    noRepeat,
    noRepeatOn,
    noStartDate,
    repeatOptions,
    saveState,
    selectedFrequency,
  } = context;

  const customFrequency = context.customFrequency || DefaultCustomFrequency;

  const { numberOfEvents, every, repeatOn } = customFrequency;
  const isMonth = selectedFrequency === IntervalFrequencyEnum.MONTH;
  const hasErrors = (errors?.messages?.length ?? 0) > 0;

  const getSelectedFrequency = () =>
    customFrequencyIntervalOptions.find((f) => f.value === selectedFrequency);

  return (
    <Popper anchorEl={anchorEl} open={!!customPopperOpen}>
      <div className="custom-frequency__wrapper">
        <div className="custom-frequency__header">{header}</div>
        <div className="custom-frequency__content">
          <FieldWrapper
            label={subHeader}
            errorMessage={errors?.messages?.map((error, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <div key={i}>{error}</div>
            ))}
          >
            {!noRepeat ? (
              <>
                <div className="input">
                  <TextField
                    controlled
                    error={errors?.numberOfEventsError}
                    type="number"
                    InputProps={{
                      inputProps: { min: 1, max: MAX_OCCURRENCES },
                    }}
                    value={numberOfEvents}
                    onChange={(e: { target: { value: string } }) =>
                      onFrequencySelect(selectedFrequency, {
                        ...customFrequency,
                        numberOfEvents: Math.min(
                          parseInt(e.target.value, 10),
                          MAX_OCCURRENCES,
                        ),
                      })
                    }
                  />
                </div>{' '}
                time(s) every{' '}
              </>
            ) : (
              <>Every </>
            )}
            <div className="input">
              <TextField
                controlled
                error={errors?.everyError}
                type="number"
                InputProps={{ inputProps: { min: 1 } }}
                value={every}
                onChange={(e: { target: { value: string } }) =>
                  onFrequencySelect(selectedFrequency, {
                    ...customFrequency,
                    every: parseInt(e.target.value, 10),
                  })
                }
              />
            </div>{' '}
            <div className="select">
              <Select
                isClearable={false}
                options={customFrequencyIntervalOptions}
                value={getSelectedFrequency()}
                onChange={(value: SingleOption<IntervalFrequencyEnum>) =>
                  onFrequencySelect(value.value, customFrequency)
                }
              />
            </div>{' '}
          </FieldWrapper>
          {!noStartDate && (
            <FieldWrapper label="Effective date">
              <FrequencyDateSelector />
            </FieldWrapper>
          )}
          {!noRepeatOn && repeatOptions.length > 0 && (
            <FieldWrapper
              label={isMonth ? 'Months of the year' : 'Days of the week'}
            >
              <div
                className={cn({ months: isMonth, large: isMonth && every > 1 })}
              >
                <ToggleButtons
                  onChange={(value) =>
                    onFrequencySelect(selectedFrequency, {
                      ...customFrequency,
                      repeatOn: value as Days[] | Months[],
                    })
                  }
                  options={repeatOptions as SingleOption<string>[]}
                  size={every === 1 ? 'small' : 'medium'}
                  value={repeatOn ?? []}
                />
              </div>
            </FieldWrapper>
          )}
          {!hasErrors && <FieldWrapper>{helperText}</FieldWrapper>}
        </div>
        <div className="custom-frequency__footer">
          <Button color="secondary" onClick={onCustomPopperCancel}>
            Cancel
          </Button>
          <SaveButton
            onSave={onCustomPopperSave}
            saveState={saveState}
            disabled={hasErrors}
          />
        </div>
      </div>
    </Popper>
  );
};

CustomFrequencyPopper.defaultProps = defaultProps;

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default CustomFrequencyPopper;
