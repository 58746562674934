import React from 'react';
import { IconButton } from '../../buttons/IconButton';
import { SvgIcon } from '../SvgIcon';

type IconButtonProps = React.ComponentProps<typeof IconButton>;

type CloseIconProps = Pick<IconButtonProps, 'onClick' | 'title'>;

export const CloseIcon = ({ onClick, title }: CloseIconProps) => (
  <IconButton onClick={onClick} title={title}>
    <SvgIcon identifier="close" />
  </IconButton>
);
