import AddIcon from '@svg/add.svg';
import cn from 'classnames';
import React from 'react';
import IconButton from './IconButton';

type IconButtonProps = React.ComponentProps<typeof IconButton>;

interface AddButtonProps extends IconButtonProps {
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  label?: string;
  onClick?: () => void;
  onKeyPress?: () => void;
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default function AddButton(props: AddButtonProps) {
  const { disabled, onClick, onKeyPress, ...restProps } = props;
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/destructuring-assignment
  const text = props.children ?? props.label;
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/destructuring-assignment
  const className = cn('add-button', props.className, {
    'add-button--active': !disabled,
  });

  return (
    <IconButton
      {...restProps}
      className={className}
      disabled={disabled}
      onClick={onClick}
      onKeyPress={onKeyPress || onClick}
    >
      <AddIcon className="m-icon relative-sized" />
      {text && <span>{text}</span>}
    </IconButton>
  );
}
