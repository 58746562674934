import { Modal } from '@monorepo/old-web/js/components/elements';
import React from 'react';
import { UserFriendlyErrorMessage } from '../../componentsV2/errorDisplays/UserFriendlyErrorMessage';
import { Loading } from '../Loading';
import { MessageBox, MessageBoxStatus } from '../MessageBox';

type InteractiveFileUploadPlaceholderLoaderModalProps = {
  title?: string;
  isOpen: boolean;
  onClose: () => void;
  isLoading?: boolean;
  error?: Error;
};

function LoadingContent() {
  return (
    <div className="interactive-file-upload-modal-placeholder-content interactive-file-upload-modal-placeholder-loader">
      <Loading size="large" />
      <div>Loading interactive file uploader...</div>
    </div>
  );
}

function ErrorContent({ error }: { error: Error }) {
  return (
    <div className="interactive-file-upload-modal-placeholder-content">
      <MessageBox
        message={
          <UserFriendlyErrorMessage
            error={error}
            prependedText="An unexpected error occurred within the upload. Try again, or contact Mapistry support."
          />
        }
        status={MessageBoxStatus.STATUS_ERROR}
      />
    </div>
  );
}

export function InteractiveFileUploadPlaceholderModal(
  props: InteractiveFileUploadPlaceholderLoaderModalProps,
) {
  const { title, onClose, isOpen, isLoading, error } = props;

  return (
    <Modal
      header={
        <h1 className="interactive-file-upload-modal-placeholder-title">
          {title || 'Please wait...'}
        </h1>
      }
      onClose={onClose}
      open={isOpen}
      scrollPaperClassName="interactive-file-upload-modal-placeholder-container"
    >
      {isLoading && <LoadingContent />}
      {error && <ErrorContent error={error} />}
    </Modal>
  );
}
