import { BaseService, ApiError } from './BaseService';

class UserService extends BaseService {
  getCurrentUser() {
    const url = `${this.apiUrl}/user`;
    return fetch(url, { credentials: 'include' }).then((result) => {
      if (result.ok) {
        return result.json();
      }
      throw new ApiError('failed to get current user');
    });
  }

  getAllForProject(projectId) {
    const url = `${this.apiUrl}/api/projects/${projectId}/users`;
    return fetch(url, { credentials: 'include' }).then((result) => {
      if (result.ok) {
        return result.json();
      }
      return null;
    });
  }

  update(user) {
    const url = `${this.apiUrl}/users/${user.id}`;
    return fetch(url, {
      credentials: 'include',
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(user),
    }).then((result) => {
      if (result.ok) {
        return result.json();
      }
      throw new ApiError('failed to update user');
    });
  }
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default UserService;
