import { SaveState } from '@monorepo/shared/types/SaveState';
import PropTypes from 'prop-types';
import React from 'react';
import { FormSaveStateType } from '../propTypes';
import Button from './buttons/Button';
import SaveButton from './buttons/SaveButton';

const FormActions = (props) => {
  const { formSaveState, onClose, onSave, saveText } = props;
  return (
    <>
      <Button color="secondary" onClick={onClose}>
        Exit
      </Button>
      <span className="ph2" />
      <SaveButton onSave={onSave} saveState={formSaveState} label={saveText} />
    </>
  );
};

FormActions.propTypes = {
  formSaveState: FormSaveStateType,
  onClose: PropTypes.func,
  onSave: PropTypes.func.isRequired,
  saveText: PropTypes.string,
};

FormActions.defaultProps = {
  formSaveState: SaveState.CLEAN,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onClose: () => {},
  saveText: '',
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default FormActions;
