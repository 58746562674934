import { useQuery } from 'react-query';
import { Api } from '../apiClient';

export const createProjectKey = ({ projectId }: Api.FetchProjectVariables) =>
  ['projects', projectId] as const;

type ProjectFetcher = Api.DataHookQueryFn<
  typeof createProjectKey,
  typeof Api.fetchProject
>;

const fetcher: ProjectFetcher = (_, projectId) =>
  Api.fetchProject({
    projectId,
  });

type Input = Api.DataHookInput<
  Api.FetchProjectVariables,
  Api.FetchProjectResponse
>;

export function useProject(input: Input) {
  const key = Api.isDisabledDataHookInput(input)
    ? undefined
    : createProjectKey(input.variables);
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line prefer-destructuring
  const config = input.config;

  const { data, ...queryInfo } = useQuery(key, fetcher, config);

  return {
    ...queryInfo,
    project: data,
  };
}
