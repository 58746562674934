import { ApexOptions } from 'apexcharts';
import React, { useMemo } from 'react';
import Chart from 'react-apexcharts';
import { humanReadableNumber } from '../../utils';
import AnimatedComponent, { ANIMATION_TYPES } from './AnimatedComponent';

type ApexSeries = Exclude<NonNullable<ApexOptions['series']>, number[]>;

type ApexPlotOptions = NonNullable<ApexOptions['plotOptions']>;

type ApexXAxis = NonNullable<ApexOptions['xaxis']>;

type ApexYAxis = NonNullable<ApexOptions['yaxis']>;

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ApexSingleYAxis = Exclude<ApexYAxis, any[]>;

type ApexSingleAxis = ApexXAxis | ApexSingleYAxis;

type MultiAxisChartProps = {
  categories: string[];
  colors: string[];
  isLoading?: boolean;
  plotOptions?: ApexPlotOptions;
  series: ApexSeries;
  title?: string;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
};

const buildSingleAxis = (axis: ApexSingleAxis) => ({
  ...axis,
  title: {
    style: {
      cssClass: 'mapistry-chart__axis-label',
    },
    ...axis.title,
  },
});

const buildXAxis = buildSingleAxis;

const buildYAxis = (axis: ApexYAxis) => {
  const labels = {
    formatter: (val) => humanReadableNumber(val, 0),
  } as ApexSingleYAxis['labels'];
  if (Array.isArray(axis)) {
    return axis.map((ax) =>
      buildSingleAxis({
        ...ax,
        labels,
      }),
    );
  }
  return buildSingleAxis({
    ...axis,
    labels,
  });
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function MultiAxisChart(props: MultiAxisChartProps) {
  const {
    series,
    categories,
    title,
    xaxis,
    yaxis,
    colors,
    plotOptions,
    isLoading,
  } = props;
  const optionsWithDefaults = useMemo(() => {
    const xaxisWithDefaults = buildXAxis({
      categories,
      ...xaxis,
    });
    const yaxisWithDefaults = buildYAxis(yaxis);
    const showLegend = series.length > 1;
    return {
      chart: {
        fontFamily: 'Roboto, sans-serif',
        toolbar: {
          show: false,
        },
      },
      stroke: {
        width: 2,
      },
      title: {
        text: title,
        offsetX: -7,
      },
      xaxis: xaxisWithDefaults,
      yaxis: yaxisWithDefaults,
      colors,
      plotOptions,
      legend: {
        show: showLegend,
      },
    };
  }, [categories, title, xaxis, yaxis, colors, plotOptions, series.length]);
  const chartHasNoData = !isLoading && categories && categories.length === 0;

  if (chartHasNoData) {
    return (
      <div className="red">
        There are no records in this period. Try adjusting the date range for
        the chart.
      </div>
    );
  }

  return (
    <div className="mapistry-chart__container">
      {isLoading && (
        <img
          className="w-100 h-100 shimmer"
          alt="Chart loading"
          src="https://s3.amazonaws.com/mapistryAssets/chartDefault1.png"
        />
      )}
      {!isLoading && (
        <AnimatedComponent type={ANIMATION_TYPES.FADE_IN_OUT}>
          <Chart options={optionsWithDefaults} series={series} height={283} />
        </AnimatedComponent>
      )}
    </div>
  );
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default MultiAxisChart;
